/**
 *
 * Projects of user selector
 *
 */

export const selectorProjectsOfUser = (state) => {
  const { projectsOfUser: { data } } = state;

  return data && data.map((x) => (
    x.project
  ));
};

export default selectorProjectsOfUser;
