import instanceAPI from 'services/apis/axiosconfig';

/**
 * Request to get the roles
 * API Doc: https://hendrix.vincoorbisdev.com/docs/api/#api-roles-GetRoles
 * @param {string} accountName - User's account name
 *
 */
export const getRolesAPI = (
  accountName,
  offset,
  limit,
  orderBy,
  filter,

) => {
  let params = '';

  Object.keys(filter).forEach((key) => {
    if (filter[key] !== undefined) params += `${key}=${filter[key]}&`;
  });

  params = `${orderBy ? `&order_by=${orderBy}` : ''}${
    params ? `&${params}` : ''
  }`;

  const request = instanceAPI.get(
    `/accounts/${accountName}/roles?offset=${offset}&limit=${limit}${params}`,
  );
  return request.then((res) => ({
    ...res,
    data: {
      results: res.data.results,
      pagination: {
        offset,
        limit,
        orderBy,
        totalCount: res.data.x_total_count,
        filter,
      },
    },
  }));
};
