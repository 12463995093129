import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CreateCompanyForm from 'components/createCompany';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { selectorCompany } from 'services/selectors/company';

function CreateCompanyContainer(props) {
  const {
    editing,
    resetEdit,
    handleSend,
    helperTexts,
  } = props;

  const dispatch = useDispatch();
  const { id } = useParams();
  const company = useSelector(selectorCompany);

  const [nameCompany, setNameCompany] = useState('');
  const [rfc, setRfc] = useState('');
  const [invoiceIssuer, setInvoiceIssuer] = useState(false);
  const [isDefault, setIsDefault] = useState(false);

  useEffect(() => {
    if (company.data !== null && editing) {
      setNameCompany(company.data.name);
      setRfc(company.data.tax_id_number);
      setInvoiceIssuer(company.data.is_invoice_issuer);
      setIsDefault(company.data.is_default);
    } else if (!editing) {
      setNameCompany('');
      setRfc('');
      setInvoiceIssuer(false);
      setIsDefault(false);
    }
  }, [dispatch, company.data, editing]);


  return (
    <div>
      <CreateCompanyForm
        resetEdit={resetEdit}
        editing={editing}
        id={id}
        setName={setNameCompany}
        name={nameCompany}
        setTax={setRfc}
        tax={rfc}
        setIssuer={setInvoiceIssuer}
        issuer={invoiceIssuer}
        setDefault={setIsDefault}
        isDefault={isDefault}
        handleSend={handleSend}
        helperTexts={helperTexts} />
    </div>
  );
}

CreateCompanyContainer.defaultProps = {
  editing: false,
  resetEdit: () => {},
};

CreateCompanyContainer.propTypes = {
  editing: PropTypes.bool,
  resetEdit: PropTypes.func,
  handleSend: PropTypes.func.isRequired,
  helperTexts: PropTypes.instanceOf(Object).isRequired,
};

export default CreateCompanyContainer;
