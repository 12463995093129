import React from 'react';
import colors from 'constants/colors';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ShowdownHendrix } from 'utils/showdownHendrix';
import moment from 'moment';
import {
  Grid,
  Tooltip,
} from '@material-ui/core';
import useStyles from './styles';

function SystemConversations(props) {
  const classes = useStyles();
  const converter = new ShowdownHendrix();
  const {
    conversations,
  } = props;

  return (
    <div>
      {conversations.map((conversation) => (
        <Grid
          container
          spacing={2}
          key={`${conversation.author_name}-${conversation.id}`}
          justify="center"
          alignItems="flex-start">
          <Grid item xs={10} sm={11} container wrap="nowrap" direction="column">
            <Grid item container xs>
              <Grid item xs={6}>
                <Tooltip title={moment(conversation.created_at).format('MMM Do YY, h:mm:ss a')}>
                  <div fontSize={12} className={classes.date}>
                    {
                    `${moment(conversation.created_at).fromNow()} `
                    + `(${moment(conversation.created_at).format('DD/MM/YY')})`
                  }
                  </div>
                </Tooltip>
              </Grid>
            </Grid>
            <Grid
              item
              id={`grid-content-${conversation.id}`}
              xs={12}
              className={classes.conversationSystem}>
              <div
                className={classNames(classes.preview, 'markdown-body')}
            // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: `<span style="color: ${colors.secondary}">
                          ${conversation.author.nickname}
                        </span>
                        <br>
                        ${conversation.content
                    ? converter.makeHtml(conversation.content)
                    : conversation.operation.name
                        }`,
                }} />
            </Grid>
          </Grid>
        </Grid>
      ))}
    </div>
  );
}
SystemConversations.propTypes = {
  conversations: PropTypes.instanceOf(Array).isRequired,
};
export default SystemConversations;
