import {
  getUsersOfProject,
} from 'services/apis/usersOfProject';
import {
  GET_USERS_OF_PROJECT,
} from 'constants/usersOfProject';

export const usersOfProject = (account, data) => ({
  type: GET_USERS_OF_PROJECT,
  payload: getUsersOfProject(account, data),
});

export default usersOfProject;
