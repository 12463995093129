import { makeStyles } from '@material-ui/core/styles';
import colors from 'constants/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '100%',
    paddingBottom: '0',
    backgroundColor: theme.palette.background.paper,
  },
  checkStyle: {
    minWidth: '20px',
  },
  avatarStyle: {
    padding: '0',
    paddingRight: '7px',
    paddingLeft: '3.5px',
  },
  checkbox: {
    padding: '0',
    paddingRight: '3.5px',
    paddingLeft: '7px',
  },
  small: {
    width: '26px',
    height: '26px',
    fontSize: '14px',
  },
  iconBtn: {
    padding: '0',
    paddingLeft: '1px',
  },
  textItem: {
    padding: '0 10px',
    marginTop: '9px',
  },
  textResponsiveItem: {
    paddingLeft: '25px',
    marginTop: '9px',
  },
  planeLink: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      color: colors.primary,
    },
    color: colors.secondary,
  },
  activeLink: {
    visited: { color: colors.secondary },
    textDecoration: 'underline',
    color: colors.primary,
  },
  activeItem: {
    paddingBottom: '0px',
    paddingTop: '0px',
    zIndex: 1,
    borderLeft: '1px solid',
    borderBottom: '1px solid',
    borderTop: '1px solid',
  },
  item: {
    paddingBottom: '0px',
    paddingTop: '0px',
    '@media (max-width: 400px)': {
      padding: '0px',
    },
  },
  preview: {
    '& .MuiBox-root': {
      paddingBottom: '15',
    },
    '& h1': {
      fontSize: '1.2em',
    },
    '& img': { maxWidth: '100%', display: 'block' },
    '& h2': {
      fontSize: '1.2em',
      color: '#595454',
    },
    '& h3': {
      fontSize: '1em',
    },
    '& blockquote': {
      padding: '10px',
      fontSize: '1.2em',
      fontStyle: 'italic',
      background: '#e8e8e8',
    },
    '& a': {
      textDecoration: 'underline',
      color: '#0000ff',
    },
    '& ol': {
      fontSize: '1.2em',
      color: '#595454',
    },
    '& table': {
      padding: '10px',
      borderCollapse: 'collapse',
      border: '1px solid',
      borderspacing: '10px',
      fontSize: '0.8em',
      background: '#ffffff',
    },
    '& tr': {
      border: 'solid',
      borderWidth: '1px 0',
    },
    '& th': {
      textAlign: 'left',
    },
    '& td': {
      paddingRight: '15px',
      textAlign: 'left',
    },
    '& code': {
      fontSize: '1.2em',
      fontWeight: 'normal',
      fontFamily: 'Andale Mono,AndaleMono,monospace;',
      textAlign: 'left',
      color: '#595454',
      whiteSpace: 'nowrap',
      overflow: 'scroll',
      display: 'block',
      width: '100%',
    },
    '& > p': {
      margin: 'initial',
    },
  },
  iconsStyles: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  textAlign: {
    padding: '0 10px',
    marginTop: '0px',
  },
  key: {
    fontSize: '1em',
    padding: '0 7px',
    borderRadius: 0,
    height: '20px',
    marginBottom: '5px',
  },
}));

export default useStyles;
