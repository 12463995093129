import { makeStyles } from '@material-ui/core/styles';
import colors from 'constants/colors';

const useStyles = makeStyles((theme) => ({
  form: {
    padding: '10px',
  },
  field: {
    height: '30px',
    width: '700px',
    color: '#9A9895',
    borderTopWidth: '0px',
    borderRightWidth: '0px',
    borderBottomWidth: '1px',
    borderLeftWidth: '0px',
    fontSize: '15px',
    fontFamily: 'Muli',
    fontWeight: '400',
    borderBottomColor: colors.primary,
    [theme.breakpoints.down('sm')]: {
      width: '320px',
    },
    [theme.breakpoints.up('sm')]: {
      width: '600px',
    },
    '&:focus': {
      outlineColor: '-webkit-focus-ring-color',
      outlineStyle: 'auto',
      outlineWidth: '0px',
    },
  },
  iconNextConcept: {
    color: colors.primary,
    cursor: 'pointer',
  },
  iconNext: {
    color: colors.primary,
    cursor: 'pointer',
    position: 'relative',
    bottom: '-7px',
    paddingLeft: '1px',
  },
  gridPadding: {
    paddingTop: '0px',
  },
  buttonCancel: {
    marginTop: '20px',
    marginRight: '15px',
    color: '#FFFFFF',
    backgroundColor: '#CBC9C6',
    fontWeight: 'bold',
    fontSize: '13px',
  },
  buttonCreateTask: {
    color: '#FFFFFF',
    backgroundColor: colors.primary,
    marginLeft: '10px',
    '&:hover': {
      backgroundColor: colors.primary,
      boxShadow: 'none',
    },
  },
  text: {
    fontSize: '15px',
    [theme.breakpoints.up('sm')]: {
      '& br': {
        display: 'none',
        fontSize: '13px',
        paddingBottom: '10px',
      },
    },
  },
}));

export default useStyles;
