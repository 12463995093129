/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect, useState } from 'react';
import { Checkbox } from '@material-ui/core';
import PropTypes from 'prop-types';

const RenderCheckbox = (props) => {
  const {
    input,
    label,
    style,
    inputProps: { value },
    setValue,
  } = props;

  const [content, setContent] = useState(value);

  useEffect(() => {
    setContent(value);
    input.onChange(value);
  }, [input, input.onChange, value]);

  const handleChange = () => {
    setContent(!value);
    setValue(!value);
    input.onChange(!value);
  };

  return (
    <Checkbox
      style={style}
      label={label}
      checked={content}
      onClick={(e) => handleChange(e)} />
  );
};

RenderCheckbox.defaultProps = {
  style: {},
  setValue: () => {},
  inputProps: { value: false },
};
RenderCheckbox.propTypes = {
  style: PropTypes.shape({}),
  label: PropTypes.string.isRequired,
  input: PropTypes.instanceOf(Object).isRequired,
  inputProps: PropTypes.instanceOf(Object),
  setValue: PropTypes.func,
};

export default RenderCheckbox;
