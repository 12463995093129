import React from 'react';

export const titleCustom = (title, text) => (
  <div style={{ fontSize: '12px' }}>
    {title}
    :
    <span style={{ fontSize: '12px' }}>
      {' '}
      {text}
    </span>
  </div>
);
