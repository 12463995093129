import React from 'react';
import PropTypes from 'prop-types';
import { Badge, IconButton } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import PersonIcon from '@material-ui/icons/Person';

const StyledBadge = withStyles(() => ({
  badge: {
    right: 1,
    top: 2,
    padding: '0 3px',
  },
}))(Badge);

const useStyles = makeStyles((theme) => ({
  iconButton: {
    marginRight: '15px',
    padding: '0px',
  },
  iconBadge: {
    color: '#E26376',
    width: '26px',
    height: '26px',
    '&:disabled': {
      color: '#6E6E6B',
    },
    '&:active': {
      color: 'rgba(226, 99, 118, .88)',
    },
    [theme.breakpoints.down('sm')]: {
      width: '22px',
      height: '26px',
    },
  },
}));

export default function SecondaryIconBadge(props) {
  const classes = useStyles();
  const { numNotify,
    IconBadge,
    handleClick,
    disabled } = props;

  return (
    <IconButton className={classes.iconButton} disabled={disabled} onClick={handleClick}>
      <StyledBadge
        badgeContent={numNotify}
        max={100}
        showZero={false}
        color={disabled ? 'secondary' : 'primary'}>
        <IconBadge className={classes.iconBadge} />
      </StyledBadge>
    </IconButton>
  );
}

SecondaryIconBadge.defaultProps = {
  numNotify: 0,
  disabled: false,
  IconBadge: PersonIcon,
};

SecondaryIconBadge.propTypes = {
  numNotify: PropTypes.number,
  disabled: PropTypes.bool,
  IconBadge: PropTypes.elementType,
  handleClick: PropTypes.func.isRequired,
};
