import {
  getProjectsOfUser,
} from 'services/apis/projectsOfUser';
import {
  GET_PROJECTS_USER,
} from 'constants/projectsOfUser';

export const getProjects = (account, data) => ({
  type: GET_PROJECTS_USER,
  payload: getProjectsOfUser(account, data),
});

export default getProjects;
