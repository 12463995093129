import { GET_PROJECTS, RESET_PROJECTS } from 'constants/projects';
import { getProjectsAPI } from 'services/apis/projectsList';

/*
 *
 * This function get a projects.
 *
 * @param {string} account - account related to user.
 */

export const getProjectsList = (
  account,
  offset = 0,
  limit = 10,
  orderBy = '',
  filter = {},
) => ({
  type: GET_PROJECTS,
  payload: getProjectsAPI(account, offset, limit, orderBy, filter),
});

export const resetProjects = () => ({
  type: RESET_PROJECTS,
  payload: null,
});
