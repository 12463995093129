import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { createTimesheets, deleteTimesheets, updateTimesheets } from 'services/actions/timesheetsTask';
import { selectorActualAccountName } from 'services/selectors/account';
import { convertHoursToMins, convertMinsToTime, verifyTimesOfCurrentDay } from 'utils/convertMinsToTime';
import { timesheetsTask } from 'services/selectors/timesheetsTask';
import { selectorUserNickName } from 'services/selectors/userProfile';
import CreateTimesheetForm from 'components/createTimeSheet';


function AddTimeContainer(props) {
  const {
    setTimeMessage,
    handleOpenTime,
    handleClose,
  } = props;

  const dispatch = useDispatch();

  const account = useSelector(selectorActualAccountName);
  const timesheets = useSelector(timesheetsTask);
  const nickname = useSelector(selectorUserNickName);

  let timeValue = '00:00';
  const currentDayTime = verifyTimesOfCurrentDay(timesheets, nickname);

  if (currentDayTime) {
    const { time } = currentDayTime;
    timeValue = convertMinsToTime(time);
  }

  const date = moment().format('YYYY-MM-DD');
  const { id } = useParams();

  const handleSend = async (dataTime) => {
    const timesRecordedToday = verifyTimesOfCurrentDay(timesheets, nickname);
    const { addTime } = dataTime;

    const request = {};
    let response;

    if (timesRecordedToday) {
      if (addTime === '00:00') {
        try {
          response = await dispatch(deleteTimesheets(account, timesRecordedToday.id));
        } catch (error) {
          response = error;
        }
      } else {
        request.time = convertHoursToMins(addTime);
        request.on_behalf_of = timesRecordedToday.on_behalf_of;
        request.date = date;
        try {
          response = await dispatch(updateTimesheets(account, timesRecordedToday.id, request));
        } catch (error) {
          response = error;
        }
      }
    } else {
      if (addTime === '00:00') {
        setTimeMessage('Hubo un error, por favor intenta ingresar un tiempo superior a 0.');
        handleOpenTime();
        return;
      }

      request.task_id = id;
      request.time = convertHoursToMins(addTime);
      request.date = date;
      try {
        response = await dispatch(createTimesheets(account, request));
      } catch (error) {
        response = error;
      }
    }

    const {
      status,
      data,
    } = response;

    if (status >= 500 || status === 404) {
      setTimeMessage('Hubo un error, por favor intenta más tarde.');
      handleOpenTime();
    } else if (status > 399 && status <= 499) {
      const errors = {};
      data.forEach((item) => {
        errors[item.param] = item.message;
      });
      setTimeMessage(errors);
      handleOpenTime();
    } else {
      setTimeMessage('Tiempo modificado de manera exitosa.');
      handleOpenTime();
      handleClose();
    }
  };

  return (
    <CreateTimesheetForm
      onSubmit={handleSend}
      timeValue={timeValue}
      handleClose={handleClose} />
  );
}

AddTimeContainer.propTypes = {
  setTimeMessage: PropTypes.func.isRequired,
  handleOpenTime: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default AddTimeContainer;
