import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import RenderTextField from 'components/renderTextField';
import { makeStyles } from '@material-ui/core/styles';
import { alphaNumericSimbols } from 'utils/formValidation';
import {
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core/';
import { TransparentDialog } from 'components/transparentDialog/index';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

const useStyles = makeStyles({
  fieldText: {
    '& label.Mui-focused': {
      color: '#CBC9C6',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#CBC9C6',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#CBC9C6',
    },
    width: '100%',
    '& .MuiSelect-select': {
      color: '#CBC9C6',
    },
    '& .MuiInputLabel-animated': {
      color: '#6F6E6B',
    },
  },
});

const validate = (values) => {
  const errors = {};
  const fields = {
    nameAccount: 'nombre de cuenta',
  };

  Object.keys(fields).forEach((key) => {
    if (!values[key]) errors[key] = `El campo ${fields[key]} es requerido`;
  });

  if (values.nameAccount) errors.nameAccount = alphaNumericSimbols(values.nameAccount);

  return errors;
};

const CreateAccount = (props) => {
  const classes = useStyles();
  const {
    open,
    pristine,
    submitting,
    handleSend,
    currencies,
    account,
    currency,
    description,
    handleInputChange,
    handleDescChange,
    handleChange,
    handleClose,
  } = props;

  const colorText = { color: '#CBC9C6', paddingBottom: '12px' };

  return (
    <div>
      <TransparentDialog
        handleClose={handleClose}
        open={open}
        aria-labelledby="dialog-create-account"
        buttonProps={{
          fullWidth: true,
          type: 'submit',
          disabled: pristine || submitting,
          onClick: handleSend,
        }}
        disableBackdropClick
        fullWidth
        maxWidth="xs"
        actionText="Registrar nueva cuenta">
        <form onSubmit={handleSend} autoComplete="off" name="formCreateAccount">
          <Grid container spacing={1}>
            <Grid item xs={2} sm={2}>
              <PersonAddIcon color="secondary" />
            </Grid>
            <Grid item xs={8} sm={8}>
              <Typography variant="subtitle1" style={{ color: 'white' }}>
                Registrar una nueva cuenta
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Field
                name="nameAccount"
                id="account"
                placeholder="Ingresa nombre de cuenta"
                component={RenderTextField}
                inputProps={{ value: account }}
                type="text"
                fullWidth
                value={account}
                className={classes.fieldText}
                style={colorText}
                setTextContent={handleInputChange} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="standard-select-currency"
                select
                label="Selecciona una moneda"
                value={currency}
                onChange={handleChange}
                fullWidth
                className={classes.fieldText}>
                {currencies && currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <Field
                name="description"
                id="description"
                label="Breve descripción"
                component={RenderTextField}
                inputProps={{ value: description }}
                fullWidth
                value={description}
                className={classes.fieldText}
                style={colorText}
                setTextContent={handleDescChange} />
            </Grid>
          </Grid>
        </form>
      </TransparentDialog>
    </div>
  );
};

CreateAccount.propTypes = {
  open: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleSend: PropTypes.func.isRequired,
  currencies: PropTypes.instanceOf(Object).isRequired,
  account: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleDescChange: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default (reduxForm({
  form: 'formCreateAccount',
  validate,
}))(CreateAccount);
