import React from 'react';
import Menu from 'components/menu/index';
import PropTypes from 'prop-types';
import { menuItems } from 'constants/menu';

function MenuContainer(props) {
  const {
    open,
    handleOpen,
    handleClose,
  } = props;

  return (
    <Menu
      handleOpen={handleOpen}
      menuItems={menuItems}
      handleClose={handleClose}
      open={open} />
  );
}

MenuContainer.propTypes = {
  open: PropTypes.bool.isRequired,
  handleOpen: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default MenuContainer;
