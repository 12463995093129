export const alphaNumeric = (value) => (value && !/^[a-zA-Z0-9]+$/i.test(value)
  ? 'El nombre de usuario es inválido'
  : undefined);

export const email = (value) => (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
  ? 'El correo electrónico es inválido'
  : undefined);

export const userAccount = (value) => (value && !/@/i.test(value) ? alphaNumeric(value) : email(value));

export const required = (value) => (value ? undefined : 'Este campo es requerido');

export const alphaNumericSimbols = (value) => (value && !/^([a-zA-Z0-9]+(_|-)*)+$/i.test(value)
  ? 'El campo debe ser alfanumérico y puede contener guiones.'
  : undefined);

export const alphaNumericProject = (value) => (value && !(/^([a-zA-Z0-9 _-]+)$/).test(value)
  ? 'la descripción debe ser alfanumerica y puede contener los siguientes caracteres: (.-_+).'
  : undefined);

export const startDateProject = (startDate, endDate) => {
  if (Date.parse(endDate) < Date.parse(startDate)) {
    return 'La fecha de inicio debe ser igual o menor a la fecha de fin.';
  }
  return undefined;
};
