import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import PropTypes from 'prop-types';

function RenderPasswordField(props) {
  const {
    style,
    helperText,
    fullWidth,
    placeholder,
    meta: { touched, error },
    input,
    classes,
    id,
    autocomplete,
  } = props;
  const [password, setPassword] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);

  const handleChange = (e) => {
    setPassword(e.target.value);
    input.onChange(e.target.value);
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className={classes && classes.root ? classes.root : ''}>
      <Input
        autoComplete={autocomplete}
        id={id}
        margin="dense"
        type={showPassword ? 'text' : 'password'}
        value={password}
        onChange={(e) => handleChange(e)}
        endAdornment={(
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleShowPassword}
              style={style}>
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        )}
        onDragStart={input.onDragStart}
        onDrop={input.onDrop}
        onFocus={input.onFocus}
        onBlur={input.onBlur}
        style={style}
        name={input.name}
        fullWidth={fullWidth}
        placeholder={placeholder} />
      <FormHelperText
        style={touched && error ? {} : style}
        error={touched && error !== ''}>
        {touched && error ? error : helperText}
      </FormHelperText>
    </div>
  );
}
RenderPasswordField.propTypes = {
  autocomplete: PropTypes.string.isRequired,
  style: PropTypes.shape({}),
  helperText: PropTypes.string,
  fullWidth: PropTypes.bool,
  placeholder: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }).isRequired,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onBlur: PropTypes.func.isRequired,
    onDragStart: PropTypes.func.isRequired,
    onDrop: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  classes: PropTypes.shape({
    root: PropTypes.string,
  }),
  id: PropTypes.string,
};

RenderPasswordField.defaultProps = {
  style: {},
  helperText: '',
  fullWidth: true,
  classes: {},
  id: '',
};
export default RenderPasswordField;
