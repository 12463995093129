import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    color: '#9A9895',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(0),
    top: theme.spacing(0),
    color: theme.palette.grey[500],
  },
  withModal: {
    width: '400px',
    paddingLeft: '70px',
    paddingRight: '0px',
    fontWeight: 'bold',
    color: '#9A9895',
  },
  marginRegister: {
    margin: theme.spacing(2),
    marginRight: '20px',
    color: '#E26376',
  },
  margin: {
    margin: theme.spacing(2),
    marginRight: '20px',
    color: '#9A9895',
  },
}));

function DialogWrongCredentials(props) {
  const {
    opn,
    reset,
    data,
    errorApi,
    handleOpenCreateUser,
    handleOpenRecoverPassword,
  } = props;

  const [open, setOpen] = useState(opn);

  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const handleCreateUser = () => {
    handleOpenCreateUser();
    setOpen(false);
    reset();
  };

  const handleRecoverPassword = () => {
    handleOpenRecoverPassword();
    setOpen(false);
    reset();
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        disableBackdropClick
        disableEscapeKeyDown
        BackdropProps={{ style: { backgroundColor: 'transparent' } }}
        maxWidth="lg">
        <DialogTitle id="simple-dialog-title">
          <DialogTitle disableTypography className={classes.root}>
            <Typography variant="h6" align="center">Credenciales Incorrectas</Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </DialogTitle>
        </DialogTitle>
        <MuiDialogContent className={classes.withModal}>
          Verifica tus credenciales
          <br />
          <List>
            <ListItem key={errorApi}>
              -
              {' '}
              {errorApi}
            </ListItem>
            {data && data.map((item) => (
              <ListItem key={item.id}>
                {item.text}
              </ListItem>
            ))}
          </List>
        </MuiDialogContent>
        <MuiDialogActions>
          <Button
            size="small"
            className={classes.margin}
            onClick={handleRecoverPassword}>
            RECUPERAR CONTRASEÑA
          </Button>
          <Button
            size="small"
            className={classes.marginRegister}
            onClick={handleCreateUser}>
            REGISTRAR USUARIO NUEVO
          </Button>
        </MuiDialogActions>
      </Dialog>
    </div>
  );
}

DialogWrongCredentials.propTypes = {
  opn: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ).isRequired,
  errorApi: PropTypes.string.isRequired,
  handleOpenCreateUser: PropTypes.func.isRequired,
  handleOpenRecoverPassword: PropTypes.func.isRequired,
};

export default DialogWrongCredentials;
