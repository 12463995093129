import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Tooltip, IconButton } from '@material-ui/core';

import {
  ZoomIn,
  Delete,
  Inbox,
  BeachAccess,
  Done,
  CollectionsBookmark,
  PlayCircleFilledWhite,
  CheckCircle,
  DirectionsBike,
  Cancel,
  TrackChanges,
} from '@material-ui/icons';
import buildIcon from 'assets/iconsSvgs/construction.svg';
import { titleCustom } from 'utils/customTitleBioColor';

const useStyles = makeStyles(() => ({
  reference: {
    color: '#9F9D9C',
  },
  trash: {
    color: '#9F9D9C',
  },
  someday: {
    color: '#9F9D9C',
  },
  accepted: {
    color: '#3F90BB',
  },
  finished: {
    color: '#88B572',
  },
  inbox: {
    color: '#C86375',
  },
  review: {
    color: '#FCD11E',
  },
  validated: {
    color: '#88B572',
  },
  developing: {
    color: '#3F90BB',
  },
  cancel: {
    color: '#9F9D9C',
  },
  prospect: {
    color: '#84658C',
  },
  icon: {
    width: '18px',
    height: '18px',
  },
  iconName: {
    padding: '0 7px',
    borderRadius: 0,
    height: '20px',
    marginTop: '2px',
  },
  iconButton: {
    padding: '0 7px',
    borderRight: '1px solid #9A9895',
    borderRadius: 0,
    height: '20px',
    marginTop: '2px',
  },
  iconList: {
    borderLeft: '1px solid #9A9895',
    padding: '0 7px',
    borderRight: '1px solid #9A9895',
    borderRadius: 0,
    height: '20px',
    marginTop: '2px',
  },
}));

const IconInbox = (props) => {
  const classes = useStyles();
  const { status, statusName, statusType } = props;

  const checkStatus = {
    1: {
      icon: <CollectionsBookmark className={`${classes.reference} ${classes.icon}`} />,
      color: '#9F9D9C',
    },
    2: {
      icon: <Delete className={`${classes.trash} ${classes.icon}`} />,
      color: '#9F9D9C',
    },
    3: {
      icon: <BeachAccess className={`${classes.someday} ${classes.icon}`} />,
      color: '#9F9D9C',
    },
    4: {
      icon: <PlayCircleFilledWhite className={`${classes.accepted} ${classes.icon}`} />,
      color: '#3F90BB',
    },
    7: {
      icon: <CheckCircle className={`${classes.finished} ${classes.icon}`} />,
      color: '#88B572',
    },
    8: {
      icon: <Inbox className={`${classes.inbox} ${classes.icon}`} />,
      color: '#C86375',
    },
    10: {
      icon: <ZoomIn className={`${classes.review} ${classes.icon}`} />,
      color: '#FCD11E',
    },
    11: {
      icon: <Done className={`${classes.validated} ${classes.icon}`} />,
      color: '#88B572',
    },
    12: {
      icon: <DirectionsBike className={`${classes.developing} ${classes.icon}`} />,
    },
    1223: {
      icon: <DirectionsBike className={`${classes.developing} ${classes.icon}`} />,
      color: '#3F90BB',
    },
    1224: {
      icon: <img src={buildIcon} alt="build icon" className={`${classes.icon}`} />,
      color: '#E2AB3D',
    },
    1225: {
      icon: <TrackChanges className={`${classes.prospect} ${classes.icon}`} />,
      color: '#84658C',
    },
    1226: {
      icon: <CheckCircle className={`${classes.finished} ${classes.icon}`} />,
      color: '#88B572',
    },
    1227: {
      icon: <Cancel className={`${classes.cancel} ${classes.icon}`} />,
      color: '#9F9D9C',
    },
  };

  if (statusType === 'iconName') {
    return (
      <>
        <IconButton
          aria-label="icons-tools"
          className={classes[statusType]}>
          <Tooltip title={titleCustom('Estado', statusName)}>
            {checkStatus[`${status}`].icon}
          </Tooltip>
        </IconButton>
        <span style={{ color: checkStatus[`${status}`].color }}>
          {statusName}
        </span>
      </>
    );
  }

  return (
    <IconButton
      aria-label="icons-tools"
      className={classes[statusType]}>
      <Tooltip title={titleCustom('Estado', statusName)}>
        {checkStatus[`${status}`].icon}
      </Tooltip>
    </IconButton>
  );
};

IconInbox.propTypes = {
  statusType: PropTypes.string,
  status: PropTypes.number.isRequired,
  statusName: PropTypes.string.isRequired,
};

IconInbox.defaultProps = {
  statusType: 'iconButton',
};
export default IconInbox;
