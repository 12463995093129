/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  makeStyles,
  TextField,
  ClickAwayListener,
  Paper,
  InputAdornment,
} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import NumberFormat from 'react-number-format';
import useOpenClose from 'hooks/useOpenClose';
import timeFormat from 'utils/numberFormat';
import { HourField, MinuteField } from 'components/hourMinuteField';
import { hours, minutes } from 'constants/timevalues';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '95px',
    margin: 0,
    display: 'inline-block',
    paddingLeft: '5px',
    paddingRight: '5px',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '7px',
    },
  },
  flex: {
    position: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    margin: 'initial auto',
  },
  menu: {
    padding: '0',
    display: 'inline-block',
    margin: '2px 5px 3px 0',
    heigth: '100%',
    width: '95%',
    overflowY: 'scroll',
    paddingRight: '17px',
    boxSizing: 'content-box;',
  },
  time: {
    paddingTop: '0px',
    '& .MuiInputBase-inputAdornedEnd': {
      paddingTop: '0px',
      paddingLeft: '5px',
    },
    '& .MuiInputAdornment-positionStart': {
      paddingBottom: '10px',
    },
    '& .MuiInput-underline:before': {
      borderColor: '#E26376',
    },
  },
}));

const ITEM_HEIGHT = 45;
function RenderTextField(props) {
  return (
    <TextField
      InputProps={{
        endAdornment: <InputAdornment position="start"><AccessTimeIcon style={{ width: '18px', height: '18px' }} /></InputAdornment>,
      }}
      {...props} />
  );
}

function RenderTime(props) {
  const {
    input: {
      name,
      onChange,
    },
    inputProps: {
      value,
    },
  } = props;

  const classes = useStyles();

  let hourValueInital = '00';
  let minuteValueInitial = '00';
  if (value) {
    const val = value.split(':');
    hourValueInital = val['0'];
    minuteValueInitial = val['1'];
  }

  const [hour, setHour] = useState(hours);
  const [minute, setMinute] = useState(minutes);
  const [inputHours, setInputHours] = useState(hourValueInital);
  const [inputMinutes, setInputMinutes] = useState(minuteValueInitial);
  const [time, setTime] = useState(value);

  useEffect(() => {
    const times = `${inputHours}:${inputMinutes}`;
    onChange(times);
  }, [inputHours, inputMinutes, onChange]);

  const {
    open: openModal,
    handleClose: handleCloseModal,
    handleOpen: handleOpenModal,
  } = useOpenClose();

  function handleHour(newHour) {
    setInputHours(newHour);
    const times = `${newHour}:${inputMinutes}`;
    setTime(times);
    onChange(times);
  }

  function handleMinute(newMinute) {
    setInputMinutes(newMinute);
    const times = `${inputHours}:${newMinute}`;
    setTime(times);
    onChange(times);
    handleCloseModal();
  }

  function updateHours(hourValue) {
    if (hourValue.length < 1) {
      setHour(hours);
    } else {
      if (hourValue.length === 1) {
        setHour(hour.filter((hr) => hr.indexOf(hourValue) === 0));
      }
      setInputHours(hourValue);
    }
  }

  function updateMinutes(hourValue) {
    if (hourValue.length < 1) {
      setMinute(minutes);
    } else {
      if (hourValue.length === 1) {
        setMinute(minute.filter((min) => min.indexOf(hourValue) === 0));
      }
      setInputMinutes(hourValue);
    }
  }

  function handleChange(e) {
    e.preventDefault();
    const timeValue = e.target.value;
    const valueSplited = e.target.value.split(':');

    updateHours(valueSplited[0]);

    if (typeof valueSplited[1] !== 'undefined') {
      updateMinutes(valueSplited[1]);
    } else {
      setMinute(minutes);
    }
    setTime(timeValue);
    onChange(timeValue);
  }

  return (
    <ClickAwayListener onClickAway={handleCloseModal}>
      <div className={classes.root}>
        <NumberFormat
          onFocus={handleOpenModal}
          onChange={handleChange}
          placeholder="hh:mm"
          customInput={RenderTextField}
          format={timeFormat}
          name={name}
          value={time}
          className={classes.time} />
        { openModal
          && (
          <Paper style={{ position: 'absolute', top: '100%', zIndex: '30' }}>
            <Grid container>
              <Grid item xs={6} style={{ overflow: 'hidden' }}>
                <ul className={`${classes.flex} ${classes.menu}`} style={{ maxHeight: ITEM_HEIGHT * 4.5 }}>
                  { hour.map((num) => (<HourField key={`hour-${num}`} currentHour={inputHours} handleHour={handleHour} number={num} />))}
                </ul>
              </Grid>
              <Grid item xs={6} style={{ overflow: 'hidden' }}>
                <ul className={`${classes.flex} ${classes.menu}`} style={{ maxHeight: ITEM_HEIGHT * 4.5 }}>
                  { minute.map((num) => (<MinuteField key={`minute-${num}`} currentMinute={inputMinutes} handleMinute={handleMinute} number={num} />))}
                </ul>
              </Grid>
            </Grid>
          </Paper>
          )}
      </div>
    </ClickAwayListener>
  );
}

export default RenderTime;

RenderTime.defaultProps = {
  input: {},
  inputProps: {},
};

RenderTime.propTypes = {
  input: PropTypes.instanceOf(Object),
  inputProps: PropTypes.instanceOf(Object),
};
