import React from 'react';
import PropTypes from 'prop-types';

const UserItem = ({ entity: { nickname, name } }) => (
  <div>{`${name}: ${nickname}`}</div>
);

UserItem.propTypes = {
  entity: PropTypes.shape({
    nickname: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default UserItem;
