import React from 'react';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import PropTypes from 'prop-types';
import useOpenClose from 'hooks/useOpenClose';
import RemoveIcon from '@material-ui/icons/Remove';
import useStyles from './styles';

export default function FloatingMenu(props) {
  const { actions, onAction, disabled } = props;
  const classes = useStyles();
  const { open, handleOpen, handleClose } = useOpenClose(false);

  const handleAction = (e) => {
    handleClose();
    onAction(e.currentTarget.value);
  };

  const handleClickMenu = () => {
    if (open) {
      handleClose();
    } else {
      handleOpen();
    }
  };

  return (
    <div className={classes.wrapper}>
      <SpeedDial
        ariaLabel="SpeedDial-menu"
        className={classes.speedDial}
        icon={<SpeedDialIcon openIcon={(<RemoveIcon />)} />}
        FabProps={{ size: 'small', color: open && !disabled ? 'secondary' : 'primary' }}
        onClick={handleClickMenu}
        open={open}
        direction="down">
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            value={action.name}
            className={disabled ? classes.actionItem : classes.activeItem}
            disabled={disabled}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={handleAction} />
        ))}
      </SpeedDial>
    </div>
  );
}

FloatingMenu.propTypes = {
  onAction: PropTypes.func.isRequired,
  actions: PropTypes.instanceOf(Array).isRequired,
  disabled: PropTypes.bool.isRequired,
};
