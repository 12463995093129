import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProjectsList from 'components/projectsList/index';
import { getProjectsList, resetProjects } from 'services/actions/projectsList';
import { selectorProjectsList } from 'services/selectors/projectsList';
import { selectorActualAccountName } from 'services/selectors/account';
import Error from 'components/error/index';
import Loading from 'components/loading/index';
import { notProjects, moreProjects } from 'constants/projects';
import Button from '@material-ui/core/Button';
import {
  Grid,
  Typography,
} from '@material-ui/core';

const ProjectsListContainer = () => {
  const dispatch = useDispatch();
  const {
    isLoading,
    data,
    pagination: {
      offset,
      limit,
      orderBy,
      totalCount,
    },
    error,
  } = useSelector(selectorProjectsList);

  const actualAccountName = useSelector(selectorActualAccountName);


  useEffect(() => {
    const searchProjects = () => {
      dispatch(resetProjects());
      dispatch(
        getProjectsList(
          actualAccountName,
          0,
          limit,
          '',
          {},
        ),
      );
    };
    searchProjects();
  }, [actualAccountName, dispatch, limit]);

  useEffect(() => () => {
    dispatch(resetProjects());
  }, [dispatch]);

  const handleGetMore = () => {
    dispatch(
      getProjectsList(
        actualAccountName,
        offset + limit,
        limit,
        orderBy,
        {},
        {},
      ),
    );
  };

  if (error) {
    return (
      <Error message={error.message} />
    );
  }

  return (
    <Grid container direction="column">
      <Grid item>
        <ProjectsList
          listItems={data}
          checkbox />
      </Grid>
      {isLoading && <Loading />}
      { !isLoading && data.length < 1
        ? (
          <Grid item>
            <center>
              <Typography color="primary">
                {notProjects}
              </Typography>
            </center>
          </Grid>
        ) : null}
      { !isLoading && totalCount > offset + limit
        ? (
          <Grid item>
            <Button color="primary" onClick={handleGetMore} fullWidth>
              {moreProjects}
            </Button>
          </Grid>
        ) : ''}
    </Grid>
  );
};

export default ProjectsListContainer;
