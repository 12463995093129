import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  gridText: {
    paddingLeft: '10px',
  },
  gridCheckbox: '10px',
}));

export default useStyles;
