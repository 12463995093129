import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import 'moment/locale/es';
import moment from 'moment';

// Own constants/actions/components
import configureStore from 'store/configure-store';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import App from './containers/App';
import * as serviceWorker from './serviceWorker';

// Styles
import 'styles/main.scss';

// Configure store
const { store, persistor } = configureStore();

moment.locale('es');

render(
  <Provider store={store}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </MuiPickersUtilsProvider>
  </Provider>,
  document.getElementById('root') || document.createElement('div'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
