import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Timeline as MuiTimeline,
  TimelineItem as MuiTimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineOppositeContent as MuiTimelineOppositeContent,
  TimelineDot,
} from '@material-ui/lab';
import TodayIcon from '@material-ui/icons/Today';
import Avatar from '@material-ui/core/Avatar';
import SystemConversations from 'components/systemConversations/index';
import useStyles from './styles';

const TimelineItem = withStyles({
  missingOppositeContent: {
    '&:before': {
      display: 'none',
    },
  },
})(MuiTimelineItem);

const Timeline = withStyles({
  root: {
    height: '100%',
    padding: '0px 0px',
    margin: 0,
  },
})(MuiTimeline);

const TimelineOppositeContent = withStyles({
  root: {
    textAlign: 'left',
  },
})(MuiTimelineOppositeContent);

export default function ConversationsTimeline(props) {
  const classes = useStyles();
  const {
    showDate,
    showSystemConversations,
    conversations,
    hasInsideView,
  } = props;

  const totalConversations = Object.keys(conversations).length - 1;

  return (
    <Timeline>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot className={classes.secondaryBackground}>
            <Tooltip title={showDate}>
              <TodayIcon style={{ fontSize: '1.7rem' }} />
            </Tooltip>
          </TimelineDot>
          <TimelineConnector className={classes.secondaryBackground} />
        </TimelineSeparator>
        {showSystemConversations ? <TimelineOppositeContent /> : ''}
      </TimelineItem>
      { Object.keys(conversations).map((date, index) => (
        <TimelineItem
          key={`${date}-date`}>
          <TimelineSeparator>
            <Tooltip title={date}>
              <Avatar
                className={classes.avatar}>
                {date.split('-')[2]}
              </Avatar>
            </Tooltip>
            <TimelineConnector
              className={
                totalConversations === index
                  ? classes.lastConnector
                  : classes.secondaryBackground
              } />
          </TimelineSeparator>
          { showSystemConversations ? (
            <TimelineOppositeContent>
              <SystemConversations
                hasInsideView={hasInsideView}
                conversations={conversations[date]} />
            </TimelineOppositeContent>
          ) : '' }
        </TimelineItem>
      ))}
    </Timeline>

  );
}
ConversationsTimeline.propTypes = {
  showDate: PropTypes.string.isRequired,
  showSystemConversations: PropTypes.bool.isRequired,
  hasInsideView: PropTypes.bool.isRequired,
  conversations: PropTypes.instanceOf(Object).isRequired,
};
