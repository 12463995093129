import { makeStyles } from '@material-ui/core/styles';
import colors from 'constants/colors';

const useStyles = makeStyles({
  outlined: {
    '&:disabled': {
      color: '#9A9895',
      border: '1px solid #9A9895',
    },
    '&:hover': {
      backgroundColor: 'rgba(198,99,117,0.04)',
    },
    fontSize: '13px',
    marginTop: '20px',
    color: '#E26376',
    border: '1px solid #E26376',
    fontWeight: 'bold',
    paddingRight: '16px',
    paddingLeft: '16px',
  },
  contained: {
    backgroundColor: colors.primary,
    color: '#fff',
    fontWeight: 'bold',
    marginTop: '4px',
    marginRight: '4px',
    '&:disabled': {
      backgroundColor: '#EC9DA9',
      color: '#fff',
    },
    '&:hover': {
      color: '#fff',
      backgroundColor: '#E3687A',
    },
  },
});

export default useStyles;
