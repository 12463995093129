import instanceAPI from 'services/apis/axiosconfig';

/*
 * Create a user
 *
 * @typedef {Object} dataUser
 * @property {string} username - User's username
 * @property {string} password . User's password
 * @property {confirm} confirm - Confirm user's password
 * @property {email} email - User's email
 *
 * This function create an user making request to the API
 *
 * @param {dataUser} dataUser - Data for creating a user
 *
 * @return {object} - data information about user created or errors
 */
export function createUserAPI(dataUser) {
  return instanceAPI.post(
    'users',
    dataUser,
  );
}

/*
 * Register a user
 *
 * This function register an user making request to the API
 *
 * @param {string} token - Data for registering an user
 *
 * @return {object} - data information about registered user or errors
 */
export function registerUserAPI(token) {
  return instanceAPI.put(
    `users/register/${token}`,
  ).then((response) => response).catch((response) => {
    const {
      response: { data, status },
    } = response;

    return {
      data,
      status,
    };
  });
}

export default createUserAPI;
