import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// Own actions/services/reducers
import { reducer as language } from 'services/reducers/language/string.reducer';
import currentHasAccount from 'services/reducers/searchAccount/';
import { loginReducer } from 'services/reducers/login';
import tasksList from 'services/reducers/tasksList';
import task from 'services/reducers/task';
import taskTag from 'services/reducers/taskTag';
import userProfile from 'services/reducers/userProfile';
import timesheetsTask from 'services/reducers/timesheetsTask';
import conversations from 'services/reducers/conversations';
import predecessorsTasks from 'services/reducers/predecessorsTasks';
import successorsTasks from 'services/reducers/successorsTasks';
import projectsOfUser from 'services/reducers/projectsOfUser';
import projectGroupers from 'services/reducers/projectGroupers';
import project from 'services/reducers/project';
import projectsList from 'services/reducers/projectsList';
import projectCompanies from 'services/reducers/projectCompanies';
import usersOfProject from 'services/reducers/usersOfProject';
import companies from 'services/reducers/companies';
import company from 'services/reducers/company';
import roles from 'services/reducers/roles';
import projectPrivileges from 'services/reducers/projectPrivileges';

const rootReducer = combineReducers({
  account: currentHasAccount,
  conversations,
  companies,
  company,
  form,
  language,
  login: loginReducer,
  task,
  taskTag,
  tasksList,
  timesheetsTask,
  predecessorsTasks,
  project,
  projectCompanies,
  projectGroupers,
  projectPrivileges,
  projectsList,
  projectsOfUser,
  roles,
  successorsTasks,
  userProfile,
  usersOfProject,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['login', 'account', 'language', 'form', 'allTask', 'predecessorsTasks', 'successorsTasks'],
};

export default persistReducer(persistConfig, rootReducer);
