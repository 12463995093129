import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

const RenderDatepicker = (props) => {
  const {
    className,
    id,
    style,
    meta: { touched, invalid, error },
    input,
    helperText,
    initialDate,
    setChangeDate,
  } = props;

  const [date, setDate] = React.useState(initialDate);
  let errorText = touched && error;
  if (helperText !== '') {
    errorText = helperText;
  }

  useEffect(() => {
    setDate(initialDate);
    input.onChange(initialDate);
  }, [input, input.onChange, initialDate]);

  const handleChange = (e) => {
    setDate(e.target.value);
    setChangeDate(e.target.value);
    input.onChange(e.target.value);
  };

  return (
    <TextField
      id={id}
      type="date"
      className={className}
      error={(touched && invalid) || helperText !== ''}
      helperText={errorText}
      onBlur={input.onBlur}
      onChange={(e) => handleChange(e)}
      onDragStart={input.onDragStart}
      onDrop={input.onDrop}
      onFocus={input.onFocus}
      value={date}
      inputProps={{
        style,
      }}
      InputLabelProps={{
        shrink: true,
      }} />
  );
};

RenderDatepicker.propTypes = {
  setChangeDate: PropTypes.func,
  style: PropTypes.instanceOf(Object),
  initialDate: PropTypes.string,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onDragStart: PropTypes.func.isRequired,
    onDrop: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    value: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    invalid: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  helperText: PropTypes.string,
};

RenderDatepicker.defaultProps = {
  setChangeDate: () => {},
  initialDate: '',
  style: {},
  className: '',
  helperText: '',
};

export default RenderDatepicker;
