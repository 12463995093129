import { RESET_ACCOUNT, NO_ACCOUNT, HAS_ACCOUNT, HAS_MULTIPLE_ACCOUNTS } from 'constants/searchAccount';

const noAccount = (accounts) => ({ type: NO_ACCOUNT, accounts });

const hasAccount = (accounts) => ({ type: HAS_ACCOUNT, accounts });

const hasMultipleAccounts = (accounts) => ({ type: HAS_MULTIPLE_ACCOUNTS, accounts });
const resetAccount = () => ({ type: RESET_ACCOUNT });

export const actions = {
  noAccount,
  hasAccount,
  hasMultipleAccounts,
  resetAccount,
};
