import instanceAPI from 'services/apis/axiosconfig';

/**
 * Request to get the companies
 * API Doc: https://hendrix.vincoorbisdev.com/docs/api/#api-companies-GetCompany
 * @param {string} accountName - User's account name
 *
 */
export const getCompaniesAPI = (
  accountName,
  offset,
  limit,
  orderBy,
  filter,

) => {
  let params = '';

  Object.keys(filter).forEach((key) => {
    if (filter[key] !== undefined) params += `${key}=${filter[key]}&`;
  });

  params = `${orderBy ? `&order_by=${orderBy}` : ''}${
    params ? `&${params}` : ''
  }`;

  const request = instanceAPI.get(
    `/accounts/${accountName}/companies?offset=${offset}&limit=${limit}${params}`,
  );
  return request.then((res) => ({
    ...res,
    data: {
      results: res.data.results,
      pagination: {
        offset,
        limit,
        orderBy,
        totalCount: res.data.x_total_count,
        filter,
      },
    },
  }));
};

/**
 * Request to delete a company
 * API Doc: https://hendrix.vincoorbisdev.com/docs/api/#api-companies-DeleteCompany
 * @param {string} accountName - User's account name
 * @param {Number} companyId - Identifier Company
 *
 */
export const deleteCompanyAPI = (accountName, companyId) => (
  instanceAPI.delete(
    `/accounts/${accountName}/companies/starring/${companyId}`,
  )
);

/**
 * Request to create a company
 *
 * @typedef {Object} dataCompany
 * @property {string} name - Company's name
 * @property {string} tax_id_number - TaxID for the company
 * @property {boolean} is_invoice_issuer  - Flag to identify if the company is invoice issuer
 * @property {boolean} is_default - Flag to identify if the company is default for create resource
 *
 * api doc: https://hendrix.vincoorbisdev.com/docs/api/#api-companies-PostCompany
 * @param {string} accountname - user's account name
 * @param {dataCompany} data - Data to create company
 *
 */
export const createCompanyAPI = (accountName, data) => (
  instanceAPI.post(
    `/accounts/${accountName}/companies`,
    data,
  )
);

/**
 * Request to update a company
 *
 * @typedef {Object} dataCompany
 * @property {string} name - Company's name
 * @property {string} tax_id_number - TaxID for the company
 * @property {boolean} is_invoice_issuer  - Flag to identify if the company is invoice issuer
 * @property {boolean} is_default - Flag to identify if the company is default for create resources
 *
 * @param {string} accountName - User's account name
 * @param {Number} companyId - Identifier Company
 * @param {dataCompany} data - data to update in company
 *
 */
export const updateCompanyAPI = (
  accountName,
  data,
) => (
  instanceAPI.put(
    `/accounts/${accountName}/companies/`,
    data,
  )
);

/**
 * Request to get a company
 * API Doc: https://hendrix.vincoorbisdev.com/docs/api/#api-companies-GetCompany
 * @param {string} accountName - User's account name
 * @param {Number} companyId - Identifier Company
 *
 */
export const getCompanyAPI = (
  accountName,
  companyId,
) => (
  instanceAPI.get(
    `/accounts/${accountName}/companies?id=${companyId}`,
  ).then((res) => ({
    ...res,
    data: res.data.results.length ? res.data.results[0] : null,
  }))
);

export default getCompaniesAPI;
