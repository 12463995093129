import React from 'react';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

const actionsCompanyMenu = [
  {
    icon: <EditIcon />,
    name: 'Editar empresa',
  },
  {
    icon: <DeleteIcon />,
    name: 'Eliminar empresa',
  },
];
export default actionsCompanyMenu;
