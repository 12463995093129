import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  List,
  ListItem,
  Dialog,
  Typography,
  Divider,
} from '@material-ui/core';
import Accounts from 'containers/selectAccount/index';
import { selectors } from 'services/selectors/searchAccount/index';
import { selectorLogin } from 'services/selectors/login/index';
import { editPreferences, signOff } from 'constants/user';
import { resetTasks } from 'services/actions/tasksList';

function DialogDataUser(props) {
  const dispatch = useDispatch();
  const { onClose, open, classes, logout } = props;

  const currentAccount = useSelector(selectors.accounts);
  const dataUser = useSelector(selectorLogin.login);
  const [showListAccounts, setShowListAccounts] = useState(false);

  const { accounts: current } = currentAccount;
  useEffect(() => {
    dispatch(resetTasks());
  }, [current, dispatch]);

  const { data } = dataUser;

  const handleClose = () => {
    onClose();
  };

  const handleListItemClick = () => {
    setShowListAccounts(true);
    onClose();
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        className={classes.modalAvatar}>
        <List className={classes.itemsList}>
          <ListItem>
            <Typography variant="caption" gutterBottom className={classes.text}>{data && data.username}</Typography>
          </ListItem>
          <ListItem autoFocus button>
            <Typography variant="caption" gutterBottom className={classes.text}>Cuenta:&nbsp;</Typography>
            <Typography
              variant="caption"
              gutterBottom
              onClick={() => handleListItemClick()}
              className={classes.account}>
              {current && current.name}
            </Typography>
          </ListItem>
          <Divider className={classes.divider} />
          <ListItem autoFocus button>
            <Typography variant="caption" gutterBottom className={classes.account}>{editPreferences}</Typography>
          </ListItem>
          <ListItem autoFocus button onClick={() => logout()}>
            <Typography variant="caption" gutterBottom className={classes.account}>{signOff}</Typography>
          </ListItem>
        </List>
      </Dialog>
      {data && data.accounts.length > 1 ? (
        <Accounts
          open={showListAccounts}
          handleCloseAccounts={setShowListAccounts}
          acts={data.accounts} />
      ) : ''}
    </div>
  );
}

DialogDataUser.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  classes: PropTypes.instanceOf(Object).isRequired,
  logout: PropTypes.func.isRequired,
};

export default DialogDataUser;
