import Showdown from 'showdown';
import 'showdown-prettify';

Showdown.extension('taskTag', () => [
  {
    type: 'lang',
    filter(text) {
      const regex = /(?:^|\s)(#[a-z]\S+)/gm;
      const rpltext = text.replace(regex, (match) => {
        let id;
        let slug;
        match.replace(/#id(.*?)&/g, (idMatch) => {
          let sharpen = idMatch.toString().replace('#id:', '');
          sharpen = sharpen.toString().replace('&', '');
          id = sharpen;
        });

        match.replace(/(&slug(.*?)\S+)/g, (slugMatch) => {
          let sharpen = slugMatch.toString().replace('&slug:', '');
          sharpen = sharpen.trim();
          slug = sharpen;
        });

        // get url from global window
        let url = window.location.hash;
        url = url.split('/');
        url.splice(-1, 1);
        url = url.join('/');

        return `${' '}<a href="${url}/${slug}" target="_blank" class="taskTag">md#${id}</a>`;
      });
      return rpltext;
    },
  },
]);

Showdown.extension('mentionTag', () => [
  {
    type: 'lang',
    filter(text) {
      const regex = /@\w+\b(?:\s\w+\b(?=\s#\w+))?/gm;
      const rpltext = text.replace(
        regex,
        (match) => `<a class="mentionTag">${match}</a>`,
      );
      return rpltext;
    },
  },
]);

export class ShowdownHendrix {
  constructor() {
    this.converter = new Showdown.Converter({
      tables: true,
      simplifiedAutoLink: true,
      strikethrough: true,
      tasklists: true,
      ghCodeBlocks: true,
      extensions: ['mentionTag', 'taskTag'],
    });

    this.converter.setFlavor('original');
  }

  makeHtml(content) {
    return this.converter.makeHtml(content);
  }
}

export default ShowdownHendrix;
