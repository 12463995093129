import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Loading from 'components/loading/index';
import UpdateTaskForm from 'components/updateTask/index';

import { getUserProfile } from 'services/actions/userProfile';
import { getProjects } from 'services/actions/projectsOfUser';
import { getGroupers } from 'services/actions/projectGroupers';
import { usersOfProject } from 'services/actions/usersOfProject';

import { selectorProjectsOfUser } from 'services/selectors/projectsOfUser';
import { selectorActualAccountName } from 'services/selectors/account';
import { selectorLogin } from 'services/selectors/login';
import { selectorUserProfileId } from 'services/selectors/userProfile';
import { projectGroupers } from 'services/selectors/projectGroupers';
import { usersOfProjectSelector } from 'services/selectors/usersOfProject';
import { getTimesheets } from 'services/actions/timesheetsTask';
import { timesheetsTask, estimatedTimeTask } from 'services/selectors/timesheetsTask';

import { statuses, statusesForResponsible } from 'constants/status';
import {
  convertMinsToTime,
  getTotalTimeOfTask,
  calculatePercentage,
} from 'utils/convertMinsToTime';

function UpdateTask(props) {
  const {
    taskId,
    task,
    editing,
    handleSend,
    handleCancel,
  } = props;

  const dispatch = useDispatch();
  const [characters, setCharacters] = useState(task && task.concept ? task.concept.length : 0);

  const { data: { id: globalId, username } } = useSelector(selectorLogin.login);
  const account = useSelector(selectorActualAccountName);
  const profileId = useSelector(selectorUserProfileId);

  const [projectAutocomplete, setProjectAutocomplete] = useState(
    task && task.project && task.project.name,
  );
  const [idProjectAutocomplete, setIdProjectAutocomplete] = useState(
    task && task.project && task.project.id,
  );

  const [groupersAutocomplete, setGroupersAutocomplete] = useState(
    task && task.group_id ? task.group.name : 'nombre del agrupador',
  );
  const [idGroupersAutocomplete, setIdGroupersAutocomplete] = useState(
    task && task.group_id ? task.group.id : 0,
  );

  const [petitionerAutocomplete, setPetitionerAutocomplete] = useState(
    task && task.petitioner ? task.petitioner.name : username,
  );
  const [idPetitionerAutocomplete, setIdPetitionerAutocomplete] = useState(
    task && task.petitioner ? task.petitioner.id : profileId,
  );

  const [responsibleAutocomplete, setResponsibleAutocomplete] = useState(
    task && task.responsible && task.responsible.name,
  );
  const [idResponsibleAutocomplete, setIdResponsibleAutocomplete] = useState(
    task && task.responsible && task.responsible.id,
  );

  const [priority, setPriority] = useState(
    task && task.priority && task.priority.name,
  );
  const [idPriority, setIdPriority] = useState(task && task.priority && task.priority.id);

  const [statusTask, setStatus] = useState(task && task.status && task.status.name);
  const [idStatus, setIdStatus] = useState(task && task.status && task.status.id);

  const [listStatus, setListStatus] = useState(statuses);

  const handleInvestPR = (idP, labelP, idR, labelR) => {
    setResponsibleAutocomplete(labelP);
    setIdResponsibleAutocomplete(idP);
    setPetitionerAutocomplete(labelR);
    setIdPetitionerAutocomplete(idR);
    setStatus('Bandeja');
    setIdStatus(8);
  };

  /*
   * useEffect for get projects related with the privileges of user
   * and get current user
   */
  useEffect(() => {
    if (profileId != null) {
      dispatch(getProjects(account, profileId));
      dispatch(getGroupers(account, idProjectAutocomplete));
      dispatch(usersOfProject(account, idProjectAutocomplete));
    }
    dispatch(getUserProfile(account, globalId));
  }, [account, globalId, profileId, dispatch, idProjectAutocomplete]);

  const projects = useSelector(selectorProjectsOfUser);

  useEffect(() => {
    if (idResponsibleAutocomplete !== profileId) {
      setListStatus(statusesForResponsible);
      setStatus('Bandeja');
      setIdStatus(8);
    } else {
      setListStatus(statuses);
    }
  }, [idResponsibleAutocomplete, profileId]);

  useEffect(() => {
    if (idProjectAutocomplete !== 0 && task && task.project
      && idProjectAutocomplete !== task.project.id) {
      setGroupersAutocomplete('nombre de agrupador');
      setIdGroupersAutocomplete(0);
      setResponsibleAutocomplete('usuario responsable');
      setIdResponsibleAutocomplete(0);
      dispatch(getGroupers(account, idProjectAutocomplete));
      dispatch(usersOfProject(account, idProjectAutocomplete));
    }
  }, [dispatch, idProjectAutocomplete, account, task]);

  const groupers = useSelector(projectGroupers);
  const userProject = useSelector(usersOfProjectSelector);

  useEffect(() => {
    if (taskId && taskId !== 0) {
      dispatch(getTimesheets(account, taskId));
    }
  }, [account, taskId, dispatch]);

  const timesheetsTaskDetail = useSelector(timesheetsTask);
  const estimatedTaskDetail = useSelector(estimatedTimeTask);
  const estimatedTime = convertMinsToTime(estimatedTaskDetail);

  let totalTime = 0;
  let registerTime = 0;
  if (timesheetsTaskDetail !== null) {
    totalTime = getTotalTimeOfTask(timesheetsTaskDetail);
    registerTime = convertMinsToTime(totalTime);
  }

  const estimateColor = calculatePercentage(totalTime, task && task.estimated_time);

  if (projects == null || profileId == null
    || account == null || username == null || !task) {
    return <Loading />;
  }

  return (
    <Grid container>
      {task && (
        <UpdateTaskForm
          estimateColor={estimateColor}
          registerTime={registerTime}
          estimatedTime={estimatedTime}
          totalTime={totalTime}
          editing={editing}
          task={task}
          onSubmit={handleSend}
          projects={projects && projects}
          groupers={groupers && groupers}
          responsible={userProject && userProject}
          petitioner={userProject && userProject}
          setCharacters={setCharacters}
          characters={characters}
          projectLabel={projectAutocomplete}
          changeProjectLabel={setProjectAutocomplete}
          groupersLabel={groupersAutocomplete}
          changeGroupersLabel={setGroupersAutocomplete}
          petitionerLabel={petitionerAutocomplete}
          changePetitionerLabel={setPetitionerAutocomplete}
          responsibleLabel={responsibleAutocomplete}
          changeResponsibleLabel={setResponsibleAutocomplete}
          idProject={idProjectAutocomplete}
          setIdProject={setIdProjectAutocomplete}
          idGroupers={idGroupersAutocomplete}
          setIdGroupers={setIdGroupersAutocomplete}
          idPetitioner={!idPetitionerAutocomplete ? profileId : idPetitionerAutocomplete}
          setIdPetitioner={setIdPetitionerAutocomplete}
          idResponsible={idResponsibleAutocomplete}
          setIdResponsible={setIdResponsibleAutocomplete}
          priority={priority}
          idPriority={idPriority}
          setIdPriority={setIdPriority}
          status={statusTask}
          statusesTaks={listStatus}
          idStatus={idStatus}
          setIdStatus={setIdStatus}
          setPriority={setPriority}
          setStatus={setStatus}
          handleCancel={handleCancel}
          handleInvestPR={handleInvestPR} />
      )}
    </Grid>
  );
}

UpdateTask.propTypes = {
  task: PropTypes.instanceOf(Object).isRequired,
  taskId: PropTypes.string.isRequired,
  editing: PropTypes.bool.isRequired,
  handleSend: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
};

export default UpdateTask;
