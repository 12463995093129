import instanceAPI from 'services/apis/axiosconfig';

/**
 * Request to get the projectPrivileges
 * API Doc: https://hendrix.vincoorbisdev.com/docs/api/#api-projectPrivileges-GetCompany
 * @param {string} accountName - User's account name
 * @param {number} projectId - project's id
 * @param {number} offset - number since we wish take the privileges for paganition
 * @param {number} limit - limit to get elements, pagination.
 * @param {object} orderBy - Object with filters in the request
 * @param {object} filter - Object with filters int the request
 *
 */
export const getProjectPrivilegesAPI = (
  accountName,
  projectId,
  offset,
  limit,
  orderBy,
  filter,
) => {
  let params = '';

  Object.keys(filter).forEach((key) => {
    if (filter[key] !== undefined) params += `${key}=${filter[key]}&`;
  });

  params = `${orderBy ? `&order_by=${orderBy}` : ''}${
    params ? `&${params}` : ''
  }`;

  const request = instanceAPI.get(
    `/accounts/${accountName}/projects/${projectId}/privileges?offset=${offset}&limit=${limit}${params}`,
  );
  return request.then((res) => ({
    ...res,
    data: {
      results: res.data.results,
      pagination: {
        offset,
        limit,
        orderBy,
        totalCount: res.data.x_total_count,
        filter,
      },
    },
  }));
};

export default getProjectPrivilegesAPI;
