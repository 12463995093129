import React, { useState, useEffect } from 'react';
import CreateProjectForm from 'components/createProject/index';
import { useDispatch, useSelector } from 'react-redux';
import { createProject } from 'services/actions/project';
import { getCompanies, resetCompanies } from 'services/actions/companies';
import { getRoles, resetRoles } from 'services/actions/roles';
import { selectorGetRoles } from 'services/selectors/roles';
import { selectorActualAccountName } from 'services/selectors/account';
import { selectorGetCompanies } from 'services/selectors/companies';
import { reset } from 'redux-form';
import useOpenClose from 'hooks/useOpenClose';
import { projectStatuses } from 'constants/projectStatusData';
import { useHistory } from 'react-router-dom';
import { idProjectDeveloping } from 'constants/project';

const CreateProjectContainer = () => {
  const account = useSelector(selectorActualAccountName);
  const dispatch = useDispatch();
  const [idStatus, setIdStatus] = useState(0);
  const [statusProject, setStatusProject] = useState('En Desarrollo');
  const [companyAutocomplete, setCompanyAutocomplete] = useState('Selecciona una');
  const [rolAutocomplete, setRolAutocomplete] = useState('Selecciona un rol');
  const [idCompanyAutocomplete, setIdCompanyAutocomplete] = useState(0);
  const [idRolAutocomplete, setIdRolAutocomplete] = useState(0);
  const companies = useSelector(selectorGetCompanies);
  const roles = useSelector(selectorGetRoles);
  const [helperTexts, setHelperTexts] = useState({
    nameProject: '',
    key: '',
    startDate: '',
    endDate: '',
    description: '',
  });
  const [messageSubmit, setMessageSubmit] = useState('');
  const [projectName, setProjectName] = useState('');
  const [projectDescription, setProjectDescription] = useState('');
  const [projectKey, setProjectKey] = useState('');
  const history = useHistory();

  useEffect(() => {
    dispatch(reset('CreateProjectForm'));
    setIdStatus(idProjectDeveloping);
    dispatch(getCompanies(account, 0, 500));
    dispatch(getRoles(account, 0, 500));
  }, [dispatch, account]);

  useEffect(() => {
    dispatch(resetRoles());
    dispatch(resetCompanies());
    dispatch(reset('CreateProjectForm'));
  }, [dispatch]);

  const {
    open: openSnackbar,
    handleClose: handleCloseSnackbar,
    handleOpen: handleOpenSnackbar,
  } = useOpenClose();

  const handleSubmit = async (dataProject) => {
    let data = {};
    let response;
    let status;

    data.name = dataProject.nameProject;
    data.key = dataProject.key;
    data.start_date = dataProject.startDate;
    data.end_date = dataProject.endDate;
    data.status_id = dataProject.statusId;
    data.conclusion_requirements = dataProject.conclusionRequirements;
    data.invoiced_by = dataProject.invoicedBy;
    data.default_ticket = dataProject.defaultTicket;
    data.description = dataProject.description;
    data.role_id = dataProject.roleId;
    const { invoicedBy, roleId } = dataProject;
    if (invoicedBy !== 0 && roleId !== 0) {
      try {
        response = await dispatch(createProject(account, data));
        status = response.action.payload.status;
      } catch (error) {
        status = error.status;
        data = error.data;
        setMessageSubmit(error.message);
      }

      if (status >= 500 || status === 404) {
        dispatch(reset('CreateProjectForm'));
        setMessageSubmit('Hubo un error, por favor intenta más tarde.');
      } else if (status === 409) {
        setHelperTexts({});
        setMessageSubmit('Ya existe un proyecto con el mismo nombre. Por favor inténtalo de nuevo.');
        handleOpenSnackbar();
      } else if (status === 400) {
        dispatch(reset('CreateProjectForm'));
        const errors = {};
        data.forEach((item) => {
          errors[item.param] = item.message;
        });
        setHelperTexts(errors);
      } else {
        const projectId = response.value.data.id;
        setHelperTexts({});
        dispatch(reset('CreateProjectForm'));
        setMessageSubmit('Se ha creado el proyecto correctamente.');
        handleOpenSnackbar();
        setTimeout(
          () => history.push(`/dashboard/projects/${projectId}`),
          3000,
        );
      }
    } else {
      if (invoicedBy === 0) {
        setHelperTexts({});
        setMessageSubmit('La compañia es requerida. ');
        handleOpenSnackbar();
      }
      if (roleId === 0) {
        setHelperTexts({});
        setMessageSubmit('El rol en el proyecto es requerido. ');
        handleOpenSnackbar();
      }
    }
  };

  const handleReset = () => {
    setIdStatus(idProjectDeveloping);
    setStatusProject('En Desarrollo');
    setCompanyAutocomplete('Selecciona un estado');
    setIdCompanyAutocomplete(0);
    setIdRolAutocomplete(0);
    setRolAutocomplete('Selecciona un rol');
    dispatch(reset('CreateProjectForm'));
  };

  return (
    <div>
      <CreateProjectForm
        handleSend={handleSubmit}
        helperTexts={helperTexts}
        messageAfterSubmit={messageSubmit}
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        handleReset={handleReset}
        idStatus={idStatus}
        setIdStatus={setIdStatus}
        setStatus={setStatusProject}
        status={statusProject}
        projectStatuses={projectStatuses}
        companyLabel={companyAutocomplete}
        changeCompanyLabel={setCompanyAutocomplete}
        idCompany={idCompanyAutocomplete}
        setIdCompany={setIdCompanyAutocomplete}
        companies={companies}
        roles={roles}
        rolLabel={rolAutocomplete}
        changeRolLabel={setRolAutocomplete}
        idRol={idRolAutocomplete}
        setIdRol={setIdRolAutocomplete}
        nameProject={projectName}
        setNameProject={setProjectName}
        projectDescription={projectDescription}
        setProjectDescription={setProjectDescription}
        projectKey={projectKey}
        setProjectKey={setProjectKey} />
    </div>
  );
};

export default CreateProjectContainer;
