export const statusesDefault = [
  {
    id: 1,
    name: 'Referencia',
  },
  {
    id: 2,
    name: 'Basura',
  },
  {
    id: 3,
    name: 'Algún día',
  },
  {
    id: 4,
    name: 'Aceptada',
  },
  {
    id: 7,
    name: 'Terminada',
  },
  {
    id: 8,
    name: 'Bandeja',
  },
  {
    id: 10,
    name: 'Revisión',
  },
  {
    id: 11,
    name: 'Validada',
  },
  {
    id: 12,
    name: 'En desarrollo',
  },
];

export const statusesForResponsible = statusesDefault.filter((item) => (
  item.id === 8 || item.id === 10 || item.id === 11
));

export const statuses = statusesDefault.filter((item) => (
  item.id !== 11
));

export default statuses;
