/**
 * Task selector
 *
 * This function create an selector
 *
 */

export const selectorUserProfileId = (state) => (
  state.userProfile.data ? state.userProfile.data.id : null
);

export const selectorUserHasInsideView = (state) => (
  state.userProfile.data ? state.userProfile.data.has_inside_view : null
);

export const selectorUserNickName = (state) => (
  state.userProfile.data ? state.userProfile.data.nickname : null
);

export default selectorUserProfileId;
