import { GET_PROJECT_COMPANIES } from 'constants/projectCompany';

/*
 *
 * Reducer of project companies
 *
 */

const initialState = {
  isLoading: false,
  data: [],
  error: null,
};

const projectCompanies = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case `${GET_PROJECT_COMPANIES}_REJECTED`: {
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    }
    case `${GET_PROJECT_COMPANIES}_PENDING`: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case `${GET_PROJECT_COMPANIES}_FULFILLED`: {
      return {
        ...state,
        isLoading: false,
        data: payload.data,
        error: null,
      };
    }
    default:
      return state;
  }
};

export default projectCompanies;
