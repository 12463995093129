import React from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import colors from 'constants/colors';

// Material UI dependencies
import CssBaseline from '@material-ui/core/CssBaseline';

// Own constants/actions/components
import Router from 'router/Router';

// Set Multi Font in the Project
const theme = createMuiTheme({
  palette: {
    text: {
      primary: colors.secondary,
      secondary: colors.third,
      third: colors.fourth,
      fourth: colors.fifth,
      fifth: colors.primary,
    },
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
  },
  typography: {
    fontFamily: [
      '"Muli"',
      'Roboto',
    ].join(','),
  },
});


const App = () => (
  <ThemeProvider theme={theme}>
    <React.Fragment key="app">
      <CssBaseline />
      <Router />
    </React.Fragment>
  </ThemeProvider>
);

export default App;
