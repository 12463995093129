import { createProjectAPI, updateProjectAPI, getProjectAPI } from 'services/apis/project';
import { CREATE_PROJECTS, UPDATE_PROJECTS, GET_PROJECT } from 'constants/project';
/*
 *  Action projects
 */

/*
 *
 * @typedef {Object} dataProject
 * @property {string} name - Name (unique) for the project, field required.
 * @property {string} key - Key (unique) for the project, field required.
 * @property {date} start_date - Date when start the project, field required.
 * @property {date} end_date - Date when finish the project, field required.
 * @property {integer} status_id - Status for project, field required.
 * @property {string} conclusion_requirements - Specific. to terminate the project, field optional.
 * @property {integer} invoiced_by - Company who paid this project, field required.
 * @property {string} default_ticket - Text for default description ticket, field optional.
 * @property {string} description - Text for describing the project, field optional.
 * @property {integer} role_id  - Role to take the user in this project, field required.
 *
 *
 * This function make request post to create a Project.
 *
 * @param {string} account - account related to user.
 * @param {data} data - Data for creating a project.
 *
 */

export const createProject = (account, data) => ({
  type: CREATE_PROJECTS,
  payload: createProjectAPI(account, data),
});


/*
 * This function makes a request PUT a project.
 *
 * @typedef {Object} dataProject
 * @property {string} name - Name (unique) for the project, field required.
 * @property {string} key - Key (unique) for the project, field required.
 * @property {date} start_date - Date when start the project, field required.
 * @property {date} end_date - Date when finish the project, field required.
 * @property {integer} status_id - Status for project, field required.
 * @property {string} conclusion_requirements - Specif. to terminate the project, field optional.
 * @property {integer} invoiced_by - Company who paid this project, field required.
 * @property {string} default_ticket - Text for default description ticket, field optional.
 * @property {string} description - Text for describing the project, field optional.
 * @property {integer} role_id  - Role to take the user in this project, field required.
 * @property {string} message - When the project changes its status to inactive,
 * this is used for notificate to all stake holder that the project is inactive.
 *
 *
 * This function make request put to update a Project.
 *
 * @param {string} account - account related to user.
 * @param {data} dataProject - Data for updating a project.
 *
 */

export const updateProject = (account, id, data) => ({
  type: UPDATE_PROJECTS,
  payload: updateProjectAPI(account, id, data),
});

/*
 *
 * This function get a project by id.
 *
 * @param {string} account - account related to user.
 * @param {number} id - Project id.
 *
 */
export const getProject = (account, id) => ({
  type: GET_PROJECT,
  payload: getProjectAPI(account, id),
});
