import instanceAPI from 'services/apis/axiosconfig';

/*
 * Returns a list of users of project.
 *
 * @param {account} account - Account related to user
 * @param {projectId} projectId - projectId related to user
 *
 * @return {object} - data information about projects objects and the total count of rows.
 */
export function getUsersOfProject(account, projectId) {
  const request = instanceAPI.get(`/accounts/${account}/projects/${projectId}/privileges`);
  return request;
}

export default getUsersOfProject;
