import instanceAPI from 'services/apis/axiosconfig';

/*
 * Recover user Password
 *
 * @property {email} email - User's email
 *
 * This function create a request to change password to the API
 *
 * @param {emailUser} emailUser - Data for request a change of password
 *
 * @return {object} - data information about user created or errors
 */
export function recoverUserPasswordAPI(emailUser) {
  return instanceAPI.post(
    '/users/recovery',
    emailUser,
  ).then((response) => response).catch((error) => error);
}

export default recoverUserPasswordAPI;
