import { GET_SUCCESSORS } from 'constants/task';
/*
* successor tasks
*/
const initialState = {
  isFetching: false,
  data: [],
  error: null,
};

const successorsTasks = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case `${GET_SUCCESSORS}_FULFILLED`: {
      const { data: { results } } = payload;
      const newResults = results.map((list) => ({
        id: list.id,
        text: list.successor.concept,
        priority: list.successor.priority.id,
        priorityName: list.successor.priority.name,
        status: list.successor.status.id,
        statusName: list.successor.status.name,
        responsible: {
          nickname: list.successor.responsible.nickname,
        },
      }));
      return {
        ...state,
        isFetching: false,
        data: [...newResults],
        error: null,
      };
    }
    case `${GET_SUCCESSORS}_PENDING`: {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    }
    case `${GET_SUCCESSORS}_REJECTED`: {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    }

    default:
      return state;
  }
};

export default successorsTasks;
