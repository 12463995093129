import { LOGIN, RESET_LOGIN } from 'constants/login';
import { loginUserAPI } from 'services/apis/login';
/*
 * @property {string} usernameEmail - User email or username
 * @property {string} password - User password
 * @property {Bool} shouldRemember - User showremember data
 *
 *
 * This function login an user
 *
 */

export const createLogin = (data) => ({
  type: LOGIN,
  payload: loginUserAPI(data),
});

export const resetLogin = () => ({
  type: RESET_LOGIN,
  payload: {},
});

export default createLogin;
