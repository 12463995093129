import { LOGIN, RESET_LOGIN } from 'constants/login';
/*
* Reducer login
*/
const initialState = {
  isFetching: false,
  data: null,
  error: null,
};

export const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${RESET_LOGIN}`:
      return { ...initialState };
    case `${LOGIN}_PENDING`: {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    }
    case `${LOGIN}_FULFILLED`: {
      const {
        payload: {
          data,
        },
      } = action;

      return {
        ...state,
        isFetching: false,
        data,
        error: null,
      };
    }
    case `${LOGIN}_REJECTED`: {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    }
    default:
      return state;
  }
};
