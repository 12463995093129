import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  independentScrollItems: {
    height: '100vh',

    overflow: 'hidden',
    paddingTop: theme.mixins.toolbar.minHeight,
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.mixins.toolbar['@media (min-width:600px)'].minHeight,
    },
  },
  detailsColumnContainer: {
    position: 'relative',
    padding: '10px',
    height: '90vh',
    overflow: 'auto',
    '-webkit-overflow-scrolling': 'touch',
    '-ms-overflow-style': 'none',
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
      padding: '5px',
      width: '100vw',
      right: '-100vw',
      height: 'calc(100% - 64px)',
      transition: theme.transitions.create('right', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  detailsShowReturnColumnContainer: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'inline-flex',
      textTransform: 'none',
    },
  },
  detailsShowIconReturnColumnContainer: {
    color: '#E26376',
    [theme.breakpoints.down('sm')]: {
      width: '13px',
      height: '13pt',
    },
  },
  listColumn: {
    position: 'relative',
    padding: '0px 0',
    height: '90vh',
    overflow: 'auto',
    scrollBehavior: 'smooth',
    '-webkit-overflow-scrolling': 'touch',
    '-ms-overflow-style': 'none',
    [theme.breakpoints.down('sm')]: {
      padding: 15,
      position: 'relative',
      right: 0,
      transition: theme.transitions.create('right', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  detailsColumnActive: {
    [theme.breakpoints.down('sm')]: {
      right: 0,
    },
  },
  listColumnInactive: {
    [theme.breakpoints.down('sm')]: {
      right: '100vw',
    },
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flex: 1,
    padding: theme.spacing(0),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  root: {
    width: '100%',
    maxWidth: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default useStyles;
