
export function formatResponse(response) {
  const {
    status,
    data: firstData,
    data: {
      data,
      message,
    },
  } = response;

  let formatedResponse = {};

  if (data && Object.keys(data).length > 0) {
    formatedResponse = {
      status,
      message,
      data,
    };
  } else if (Object.keys(firstData).length > 0) {
    formatedResponse = {
      status,
      message: '',
      data: firstData,
    };
  } else {
    formatedResponse = {
      status,
      message,
      data: {},
    };
  }

  return formatedResponse;
}

function arrayErrors(errors) {
  return errors.map((x) => ({
    message: x.msg ? x.msg : x.message,
    type: x.type,
    param: x.param ? x.param : x.path,
    value: x.value,
  }));
}

export function formatError(error) {
  if (!error.response) {
    return {
      status: 502,
      message: 'Error con el gateway, intenta más tarde.',
      data: [],
    };
  }
  const {
    status,
    data: dataContent,
    data: {
      data,
      recaptcha,
      message,
    },
  } = error.response;

  let formatedError = {};

  if (Array.isArray(dataContent)) {
    formatedError = {
      status,
      message: 'Error',
      data: arrayErrors(dataContent),
    };
  } else if (data && (Array.isArray(data) || Object.keys(data).length > 0)) {
    formatedError = {
      status,
      message,
      data: arrayErrors(data),
    };
  } else if (recaptcha) {
    formatedError = {
      status,
      message: 'Has excedido el número de intentos. Intenta más tarde.',
      data: [],
    };
  } else {
    formatedError = {
      status,
      message,
      data: [],
    };
  }
  throw formatedError;
}

export default formatResponse;
