import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Chip,
  Grid,
  Button,
  TextField,
  Typography,
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ReplayIcon from '@material-ui/icons/Replay';
import HourglassMid from 'assets/iconsSvgs/iconptiempo.svg';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import ExpandAddIcon from 'components/expandAddIcon/index';
import ExpandRemoveIcon from 'components/expandRemoveIcon/index';
import useStyles from './styles';

const TagsAccordion = (props) => {
  const { tagsData } = props;
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const ArrayEmpty = Array.isArray(tagsData) && tagsData.length;
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleDelete = (deleteItem) => deleteItem;

  return (
    <Grid container direction="row">
      <Grid item>
        <ExpansionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')} className={classes.borderPanel}>
          <ExpansionPanelSummary
            className={classes.borderPanelSummary}
            expandIcon={expanded === 'panel1' ? <ExpandRemoveIcon totalTags={tagsData.length} /> : <ExpandAddIcon totalTags={tagsData.length} className={classes.expandTransform} />}
            aria-controls="panel1bh-content"
            id="panel1bh-header">
            <Typography className={ArrayEmpty ? classes.text : classes.textEmpty}>
              {
                ArrayEmpty
                  ? tagsData.slice().splice(0, 6).map((data) => `[${data['tag.name']}]`)
                  : 'Aún no existen tags en esta tarea. Agrega uno o los que consideres necesarios. '
              }
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.borderPanelExpanded}>
            <Grid container spacing={3}>
              <ul className={classes.ulChip}>
                {tagsData.map((data) => (
                  <li key={data.id}>
                    <Chip
                      size="small"
                      label={`[${data['tag.name']}]`}
                      onDelete={() => handleDelete(data['tag.name'])}
                      className={classes.chip}
                      variant="outlined" />
                  </li>
                ))}
              </ul>
              <Grid container direction="row" className={classes.gridOptions}>
                <Grid item xs={6} sm={3}>
                  <TextField
                    id="input-find-tag"
                    className={classes.inputTag}
                    size="small"
                    placeholder="| Busca un tag"
                    InputProps={{
                      classes: { input: classes.inputTag },
                    }} />
                </Grid>
                <Button color="secondary" className={classes.buttonTag}>AGREGAR TAG</Button>
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Grid>
      <Grid item>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          className={classes.gridDetail}>
          <Typography className={classes.detailSeparatedIcon}>|</Typography>
          <VisibilityIcon className={classes.detailIcons} />
          <ReplayIcon className={classes.detailIconReplay} />
          <img src={HourglassMid} alt="logo" className={classes.detailHourglass} />
          <DirectionsRunIcon className={classes.detailIcons} />
        </Grid>
      </Grid>
    </Grid>
  );
};

TagsAccordion.propTypes = {
  tagsData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      'tag.name': PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default TagsAccordion;
