import instanceAPI from 'services/apis/axiosconfig';

/*
 * Returns a list of projects objects and the total count of rows.
 *
 * @param {account} account - Account related to user
 * @param {profileId} profileId - profileId related to user
 *
 * @return {object} - data information about projects objects and the total count of rows.
 */
export function getProjectsOfUser(account, profileId) {
  const request = instanceAPI.get(`/accounts/${account}/profiles/${profileId}/projects`);
  return request;
}

export default getProjectsOfUser;
