import React from 'react';
import PropTypes from 'prop-types';

const EmojiItem = ({ entity: { name, char } }) => (
  <div>{`${name}: ${char}`}</div>
);

EmojiItem.propTypes = {
  entity: PropTypes.shape({
    name: PropTypes.string.isRequired,
    char: PropTypes.string.isRequired,
  }).isRequired,
};

export default EmojiItem;
