import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Typography } from '@material-ui/core/';
import colors from 'constants/colors';

const useStyles = makeStyles({
  iconBadge: {
    '&:disabled': {
      color: '#CBC9C6',
    },
    '&:active': {
      color: 'rgba(111, 110, 107, .88)',
    },
    color: colors.secondary,
    width: '25px',
  },
  arrowIcon: {
    '&:active': {
      color: 'rgba(111, 110, 107, .88)',
    },
    color: colors.primary,
    width: '19px',
  },
  inactiveArrow: {
    '&:disabled': {
      color: '#CBC9C6',
    },
    '&:active': {
      color: 'rgba(111, 110, 107, .88)',
    },
    color: colors.secondary,
    width: '19px',
    transform: 'rotate(180deg)',
  },
});

const ToolbarMDButton = (props) => {
  const classes = useStyles();

  const {
    handleClick,
    disabled,
    active,
  } = props;
  return (
    <IconButton disabled={disabled} onClick={handleClick} size="medium">
      <Typography className={classes.iconBadge}>A</Typography>
      { active
        ? (<ExpandLessIcon fontSize="small" />)
        : (<ExpandMoreIcon className={classes.arrowIcon} />)}
    </IconButton>
  );
};

ToolbarMDButton.defaultProps = {
  active: true,
  disabled: false,
};

ToolbarMDButton.propTypes = {
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
};

export default ToolbarMDButton;
