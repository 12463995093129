/**
 * Projects List selector
 *
 * This function create an selector
 *
 */

export const selectorProjectsList = (state) => state.projectsList;

export default selectorProjectsList;
