import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getTaskTags } from 'services/actions/taskTag';
import { selectorActualAccountName } from 'services/selectors/account';
import { selectorTags } from 'services/selectors/tag';
import { Grid } from '@material-ui/core';
import Error from 'components/error/index';
import Loading from 'components/loading/index';
import TagsAccordion from 'components/tagsAccordion/index';

const TagListContainer = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    isLoading,
    data,
    error,
  } = useSelector(selectorTags);
  const actualAccountName = useSelector(selectorActualAccountName);

  useEffect(() => {
    dispatch(getTaskTags(actualAccountName, id));
  }, [actualAccountName, id, dispatch]);

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return (
      <Error message={error.message} />
    );
  }
  return (
    <Grid container>
      <Grid item>
        <TagsAccordion
          tagsData={data} />
      </Grid>
    </Grid>
  );
};

export default TagListContainer;
