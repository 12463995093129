export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';

export const currencies = [
  {
    value: 'MXN',
    label: 'Pesos mexicanos',
  },
  {
    value: 'USD',
    label: 'Dolares estadounidenses',
  },
];

export default CREATE_ACCOUNT;
