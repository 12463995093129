/**
 * Company selector
 *
 * This function create an selector
 *
 */

export const selectorCompany = (state) => state.company;

export default selectorCompany;
