import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import TasksList from 'components/tasksList';
import { getTasks, resetTasks } from 'services/actions/tasksList';
import { getUserProfile } from 'services/actions/userProfile';
import { selectorTasksList } from 'services/selectors/tasksList';
import { selectorActualAccountName } from 'services/selectors/account';
import { selectorLogin } from 'services/selectors/login';
import { selectorUserProfileId } from 'services/selectors/userProfile';
import Error from 'components/error/index';
import Loading from 'components/loading/index';
import { notTask, moreTask, taskDistilledWithTimesheets } from 'constants/tasks';
import Button from '@material-ui/core/Button';


import {
  Grid,
  Typography,
} from '@material-ui/core';

const TasksListContainer = (props) => {
  const { handleClose, default: { filterByUser } } = props;
  const dispatch = useDispatch();
  const {
    isLoading,
    data,
    pagination: {
      offset,
      limit,
      orderBy,
      totalCount,
    },
    filter,
    error,
  } = useSelector(selectorTasksList);

  const actualAccountName = useSelector(selectorActualAccountName);
  const { data: { id: globalId } } = useSelector(selectorLogin.login);
  const userId = useSelector(selectorUserProfileId);

  useEffect(() => {
    const searchTasks = () => {
      if (userId) {
        dispatch(resetTasks());
        dispatch(
          getTasks(
            actualAccountName,
            0,
            limit,
            '',
            filterByUser ? { task_responsible: userId } : {},
            taskDistilledWithTimesheets,
          ),
        );
      } else {
        dispatch(getUserProfile(actualAccountName, globalId));
      }
    };
    searchTasks();
  }, [actualAccountName, userId, globalId, dispatch, filterByUser, limit]);


  useEffect(() => () => {
    dispatch(resetTasks());
  }, [dispatch]);

  const handleGetMore = () => {
    dispatch(
      getTasks(
        actualAccountName,
        offset + limit,
        limit,
        orderBy,
        filter,
        taskDistilledWithTimesheets,
      ),
    );
  };

  if (error) {
    return (
      <Error message={error.message} />
    );
  }

  return (
    <Grid container direction="column">
      <Grid item>
        <TasksList
          handleClose={handleClose}
          listItems={data}
          checkbox />
      </Grid>
      {isLoading && <Loading />}
      { !isLoading && data.length < 1
        ? (
          <Grid item>
            <center>
              <Typography color="primary">
                { notTask }
              </Typography>
            </center>
          </Grid>
        ) : null}
      { !isLoading && totalCount > offset + limit
        ? (
          <Grid item>
            <Button color="primary" onClick={handleGetMore} fullWidth>
              { moreTask }
            </Button>
          </Grid>
        ) : ''}
    </Grid>
  );
};
TasksListContainer.propTypes = {
  handleClose: PropTypes.func.isRequired,
  default: PropTypes.shape({
    filterByUser: PropTypes.bool,
  }),
};

TasksListContainer.defaultProps = {
  default: {
    filterByUser: null,
  },
};

export default TasksListContainer;
