import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { create } from 'services/actions/createAccount';
import useOpenClose from 'hooks/useOpenClose';
import { currencies } from 'constants/createAccount';
import { TransparentDialog } from 'components/transparentDialog/index';
import CreateAccount from 'components/createAccount/index';
import PersonAdd from '@material-ui/icons/PersonAdd';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { reset } from 'redux-form';

const CreateAccountContainer = () => {
  const [open, setOpen] = useState(true);
  const [currency, setCurrency] = useState('MXN');
  const [account, setNameAccount] = useState('');
  const [description, setDescAccount] = useState('');
  const [messageAfterSubmit, setMessageAfterSubmit] = useState('');
  const [titleMessageAfterSubmit, setTitleMessageAfterSubmit] = useState('');
  const [success, setSuccess] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const createAccountFun = (data) => {
    const {
      name: n,
      description: d,
      currency: c,
    } = data;
    return dispatch(create(n, d, c)).then((res) => res);
  };

  const {
    open: openDialog,
    handleClose: handleCloseDialog,
    handleOpen: handleOpenDialog,
  } = useOpenClose();

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setCurrency(event.target.value);
  };

  const handleSend = (event) => {
    event.preventDefault();
    const currencyLabel = currency === 'MXN' ? currencies[0].label : currencies[1].label;

    const dataAccount = {
      name: account,
      description,
      currency: {
        type: currency,
        value: currencyLabel,
      },
    };

    createAccountFun(dataAccount)
      .then(() => {
        dispatch(reset('formCreateAccount'));
        setSuccess(true);
        setTitleMessageAfterSubmit('Confirmación de cuenta nueva');
        setMessageAfterSubmit(
          'Tu cuenta se a registrado exitosamente y ya puedes comenzar a utilizarla.',
        );
        handleOpenDialog();
        handleClose();
      })
      .catch((err) => {
        dispatch(reset('formCreateAccount'));
        dispatch(reset('loginForm'));
        setSuccess(false);
        setTitleMessageAfterSubmit('Error');
        setMessageAfterSubmit(err);
        handleOpenDialog();
        handleClose();
      });
  };

  const handleSendToDashboard = () => {
    if (success) {
      history.push('/dashboard');
    } else {
      handleCloseDialog();
    }
  };

  return (
    <div>
      <CreateAccount
        open={open}
        currencies={currencies}
        account={account}
        currency={currency}
        description={description}
        handleInputChange={setNameAccount}
        handleChange={handleChange}
        handleSend={handleSend}
        handleClose={handleClose}
        handleDescChange={setDescAccount} />
      <TransparentDialog
        handleClose={handleCloseDialog}
        open={openDialog}
        aria-labelledby="dialog-register-user-message"
        disableBackdropClick
        fullWidth
        maxWidth="xs"
        actionText="CONTINUAR"
        buttonProps={{
          disabled: false,
          onClick: handleSendToDashboard,
        }}>
        <Grid
          container
          direction="column"
          justify="space-between"
          alignItems="stretch"
          spacing={2}>
          <Grid
            item
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={1}>
            <Grid item xs={2} sm={1}>
              <PersonAdd style={{ color: 'white' }} />
            </Grid>
            <Grid item xs={6} sm={8}>
              <Typography variant="subtitle1" style={{ color: 'white' }}>
                {titleMessageAfterSubmit}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <center>
              <Typography color="primary">
                {messageAfterSubmit}
              </Typography>
            </center>
          </Grid>
        </Grid>
      </TransparentDialog>
    </div>
  );
};

export default CreateAccountContainer;
