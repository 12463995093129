/* ---------------------------------------------------------
 * Reducer for conversations
 * --------------------------------------------------------- */
import {
  RESET_CONVERSATIONS,
  GET_CONVERSATIONS,
  STAR_CONVERSATION,
  UNSTAR_CONVERSATION,
  MAKE_PUBLIC_CONVERSATION,
  MAKE_PRIVATE_CONVERSATION,
  CREATE_CONVERSATION,
} from 'constants/conversations';


const initialState = {
  data: [],
  isLoading: false,
  pagination: {
    limit: 10,
    offset: 0,
    orderBy: null,
    totalCount: 0,
  },
  filter: {},
  error: null,
};

const conversations = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case `${GET_CONVERSATIONS}_REJECTED`:
      return {
        ...state,
        isLoading: false,
        error: {
          ...payload,
          fromAction: GET_CONVERSATIONS,
        },
      };
    case `${STAR_CONVERSATION}_REJECTED`:
      return {
        ...state,
        isLoading: false,
        error: {
          ...payload,
          fromAction: STAR_CONVERSATION,
        },
      };
    case `${UNSTAR_CONVERSATION}_REJECTED`:
      return {
        ...state,
        isLoading: false,
        error: {
          ...payload,
          fromAction: UNSTAR_CONVERSATION,
        },
      };
    case `${MAKE_PUBLIC_CONVERSATION}_REJECTED`:
      return {
        ...state,
        isLoading: false,
        error: {
          ...payload,
          fromAction: MAKE_PUBLIC_CONVERSATION,
        },
      };
    case `${MAKE_PRIVATE_CONVERSATION}_REJECTED`:
      return {
        ...state,
        isLoading: false,
        error: {
          ...payload,
          fromAction: MAKE_PRIVATE_CONVERSATION,
        },
      };
    case `${CREATE_CONVERSATION}_REJECTED`:
      return {
        ...state,
        isLoading: false,
        error: {
          ...payload,
          fromAction: CREATE_CONVERSATION,
        },
      };
    case `${GET_CONVERSATIONS}_PENDING`:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case `${GET_CONVERSATIONS}_FULFILLED`: {
      const { data: { pagination, results } } = payload;

      return {
        ...state,
        isLoading: false,
        error: null,
        pagination: {
          limit: pagination.limit,
          offset: pagination.offset,
          orderBy: pagination.orderBy,
          totalCount: pagination.totalCount,
        },
        data: [...state.data, ...results],
        filter: pagination.filter,
      };
    }
    case `${STAR_CONVERSATION}_PENDING`:
      return {
        ...state,
        error: null,
      };
    case `${STAR_CONVERSATION}_FULFILLED`:
      return {
        ...state,
        error: null,
        data: state.data.map((item) => {
          if (item.id === action.meta.conversationId) {
            return {
              ...item,
              is_starring: true,
            };
          }
          return item;
        }),
      };
    case `${UNSTAR_CONVERSATION}_PENDING`:
      return {
        ...state,
        error: null,
      };

    case `${UNSTAR_CONVERSATION}_FULFILLED`:
      return {
        ...state,
        error: null,
        data: state.data.map((item) => {
          if (item.id === action.meta.conversationId) {
            return {
              ...item,
              is_starring: false,
            };
          }
          return item;
        }),
      };
    case `${CREATE_CONVERSATION}_PENDING`:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case `${MAKE_PUBLIC_CONVERSATION}_PENDING`:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case `${MAKE_PUBLIC_CONVERSATION}_FULFILLED`:
      return {
        ...state,
        isLoading: false,
        error: null,
        data: state.data.map((item) => {
          if (item.id === action.meta.conversationId) {
            return {
              ...item,
              is_visible: true,
            };
          }
          return item;
        }),
      };
    case `${MAKE_PRIVATE_CONVERSATION}_PENDING`:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case `${MAKE_PRIVATE_CONVERSATION}_FULFILLED`:
      return {
        ...state,
        isLoading: false,
        error: null,
        data: state.data.map((item) => {
          if (item.id === action.meta.conversationId) {
            return {
              ...item,
              is_visible: false,
            };
          }
          return item;
        }),
      };
    case `${CREATE_CONVERSATION}_FULFILLED`:
      return {
        ...state,
        isLoading: false,
        error: null,
        data: [...[payload.data], ...state.data],
      };
    case RESET_CONVERSATIONS: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

export default conversations;
