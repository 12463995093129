/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import Popover from '@material-ui/core/Popover';
import { initials } from 'utils/initials';
import useStyles from './styles';

export default function SearchListItems(props) {
  const {
    placeholder,
    dataUsers,
    handleClose,
    open,
    anchor,
    withAvatar,
    changeTextAutocomplete,
    changeIdItemAutocomplete,
  } = props;
  const classes = useStyles();
  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    id: 'use-autocomplete-demo',
    options: dataUsers,
    getOptionLabel: (option) => option.name,
  });

  function handleClickEvent(item, id) {
    changeTextAutocomplete(item);
    changeIdItemAutocomplete(id);
    handleClose();
  }

  return (
    <Popover
      elevation={3}
      open={open}
      anchorEl={anchor}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      PaperProps={{
        style: { width: '260px' },
      }}>
      <center>
        <div {...getRootProps()}>
          <Input
            {...getInputProps()}
            name="search-project-items"
            className={classes.inputSearch}
            disableUnderline
            placeholder={placeholder}
            inputProps={{
              'aria-label': { placeholder },
              style: {
                paddingLeft: '20px',
              },
            }}
            endAdornment={(
              <InputAdornment position="end">
                <IconButton
                  className={classes.iconButton}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            )} />
        </div>
      </center>
      {groupedOptions.length > 0 ? (
        <List className={classes.list} dense {...getListboxProps()}>
          {groupedOptions.map((option, index) => (
            <ListItem
              {...getOptionProps({ option, index })}
              key={`option-${option.name}-${option.id}-${Math.random()}`}
              value={option.id}
              style={{
                paddingTop: '0',
                paddingBottom: '0',
                heigth: '18px',
                width: '100%',
              }}
              onClick={() => handleClickEvent(option.name, option.id)}
              button>
              { withAvatar
                && (
                <ListItemAvatar style={{ minWidth: '26px' }}>
                  <Avatar className={classes.avatar}>
                    { initials(option.name) }
                  </Avatar>
                </ListItemAvatar>
                )}
              <ListItemText
                className={classes.itemsList}
                primary={option.name} />
            </ListItem>
          ))}
        </List>
      ) : null}
    </Popover>
  );
}

SearchListItems.defaultProps = {
  anchor: () => {},
  withAvatar: false,
  dataUsers: null,
};

SearchListItems.propTypes = {
  dataUsers: PropTypes.arrayOf(PropTypes.object),
  handleClose: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  changeIdItemAutocomplete: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  changeTextAutocomplete: PropTypes.func.isRequired,
  anchor: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.any,
  ]),
  withAvatar: PropTypes.bool,
};
