import React from 'react';
import Person from '@material-ui/icons/Person';
import CardGiftcard from '@material-ui/icons/CardGiftcard';
import Group from '@material-ui/icons/Group';
import AttachMoney from '@material-ui/icons/AttachMoney';
import Search from '@material-ui/icons/Search';
import List from '@material-ui/icons/List';
import NotAvailable from 'components/notAvailable/index';
import TasksListContainer from 'containers/tasksList';
import TaskContainer from 'containers/task/index';
import createTask from 'containers/createTask/index';
import BusinessIcon from '@material-ui/icons/Business';
import CompaniesContainer from 'containers/companies/index';
import CompanyContainer from 'containers/company/index';
import ProjectsListContainer from 'containers/projectsList/index';
import CreateProjectContainer from 'containers/createProject/index';
import ProjectContainer from 'containers/project/index';
import colors from 'constants/colors';

const legendStyles = { fontSize: '20px', color: colors.secondary };

const NewNotAvailable = () => <NotAvailable legend="Creación de Empresa." showItem={false} styles={legendStyles} />;

const CreateProjectNotAvailable = () => <NotAvailable legend="Creación de Proyecto" showItem={false} styles={legendStyles} />;

export const menuWidth = 215;

export const menuItems = [
  {
    title: 'Mi espacio',
    icon: Person,
    items: [
      {
        title: 'Panel del día',
        link: '/dashboard/panel',
        componentList: TasksListContainer,
        componentDetail: TaskContainer,
        propsList: { filterByUser: true },
      },
      {
        title: 'Tareas',
        link: '/dashboard/tasks',
        componentList: TasksListContainer,
        componentDetail: TaskContainer,
      },
      {
        title: 'Gastos por Atender',
        link: '/dashboard/bills',
        componentList: NotAvailable,
        componentDetail: NotAvailable,
      },
      {
        title: 'Mis Horas',
        link: '/dashboard/timesheet',
        componentList: NotAvailable,
        componentDetail: NotAvailable,
      },
      {
        title: 'Mis Gastos',
        link: '/dashboard/myBills',
        componentList: NotAvailable,
        componentDetail: NotAvailable,
      },
    ],
  },
  {
    title: 'Nuevo',
    icon: CardGiftcard,
    items: [
      {
        title: 'Tarea',
        link: '/dashboard/task',
        componentList: createTask,
        componentDetail: NotAvailable,
      },
      {
        title: 'Empresa',
        link: '/dashboard/company/new',
        componentList: NewNotAvailable,
        componentDetail: CompanyContainer,
      },
      {
        title: 'Gasto',
        link: '/dashboard/bill',
        componentList: NotAvailable,
        componentDetail: NotAvailable,
      },
      {
        title: 'Gastos Express',
        link: '/dashboard/billExpress',
        componentList: NotAvailable,
        componentDetail: NotAvailable,
      },
      {
        title: 'Minuta',
        link: '/dashboard/note',
        componentList: NotAvailable,
        componentDetail: NotAvailable,
      },
    ],
  },
  {
    title: 'Proyectos',
    icon: List,
    items: [
      {
        title: 'Nuevo',
        link: '/dashboard/projects/new',
        componentList: CreateProjectNotAvailable,
        componentDetail: CreateProjectContainer,
      },
      {
        title: 'Explorar',
        link: '/dashboard/projects',
        componentList: ProjectsListContainer,
        componentDetail: ProjectContainer,
      },
    ],
  },
  {
    title: 'Empresas',
    icon: BusinessIcon,
    items: [
      {
        title: 'Empresas',
        link: '/dashboard/companies',
        componentList: CompaniesContainer,
        componentDetail: CompanyContainer,
      },
    ],
  },
  {
    title: 'Personas',
    icon: Group,
    items: [],
  },
  {
    title: 'Finanzas',
    icon: AttachMoney,
    items: [],
  },
  {
    title: 'Explora',
    icon: Search,
    items: [],
  },
];

export default menuWidth;
