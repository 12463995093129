export const projectStatuses = [
  {
    id: 1223,
    name: 'En Desarrollo',
  },
  {
    id: 1224,
    name: 'En Matenimiento',
  },
  {
    id: 1225,
    name: 'Prospecto',
  },
  {
    id: 1226,
    name: 'Terminado',
  },
  {
    id: 1227,
    name: 'Cancelado',
  },
];
