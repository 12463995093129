export const hours = [];
for (let hour = 0; hour < 100; hour += 1) {
  const numberFormated = String(hour).length === 1 ? `0${hour}` : hour;
  hours.push(String(numberFormated));
}

export const minutes = [];
for (let minute = 0; minute < 60; minute += 1) {
  const numberFormated = String(minute).length === 1 ? `0${minute}` : minute;
  minutes.push(String(numberFormated));
}
