import React from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import useStyles from './styles';

export const SecondaryButton = (props) => {
  const {
    fullWidth,
    size,
    variant,
    type,
    text,
    onClick,
    styles,
  } = props;
  const classes = useStyles();

  const variants = {
    outlined: {},
    contained: classes.contained,
  };

  return (
    <Button
      style={styles}
      fullWidth={fullWidth}
      variant={variant}
      size={size}
      type={type}
      color="secondary"
      className={variants[variant]}
      onClick={onClick}>
      { text }
    </Button>
  );
};

SecondaryButton.defaultProps = {
  fullWidth: true,
  size: 'small',
  type: 'button',
  variant: 'outlined',
  styles: {},
};

SecondaryButton.propTypes = {
  fullWidth: PropTypes.bool,
  type: PropTypes.string,
  size: PropTypes.string,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  variant: PropTypes.string,
  styles: PropTypes.instanceOf(Object),
};

export default SecondaryButton;
