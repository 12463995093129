import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  icon: {
    fontSize: '1.3rem',
  },
  iconFavorite: {
    fill: '#E5B624',
    fontSize: '1.3rem',
  },
  iconButton: {
    padding: '7px',
    marginTop: '-5px',
  },
}));

export default useStyles;
