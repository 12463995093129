import instanceAPI from 'services/apis/axiosconfig';

/*
 * This function makes a request GET for all timesheets by task id.
 *
 * @param {string} account - account related to user.
 * @param {number} id - Task id.
 *
 */
export function getTimesheetsTask(account, taskId) {
  const request = instanceAPI.get(`/accounts/${account}/tasks/${taskId}/timesheets`);
  return request;
}

/*
 * @typedef {Object} dataTask
 * @property {number} taskId - Task Identificator to which task the timesheet belongs.
 * @property {number} onBehalfOf - Optional - Third party user id.
 * @property {number} expenseId - Optional - Identificator to which expense the timesheet belongs.
 * @property {date} date - Date about the registry.
 * @property {number} time - How many minutes(>0) is the timesheet.
 * @property {number} invoiceId - Identificator to which invoice the timesheet belongs.
 * @property {number} invoiceDetailId - Optional -
 *                    Identificator to which invoice detail the timesheet belongs.
 *
 * This function makes a request POST for create timesheets.
 *
 * @param {string} account - account related to user.
 * @param {dataTimesheets} data - Data for creating a timesheets.
 */
export function createTimesheetsTask(account, dataTimesheets) {
  return instanceAPI.post(`/accounts/${account}/timesheets`, dataTimesheets).then((response) => response);
}

/*
 * @typedef {Object} dataTask
 * @property {number} onBehalfOf - Optional - Third party user id.
 * @property {date} date - Date about the registry.
 * @property {number} time - How many minutes(>0) is the timesheet.
 *
 * This function makes a request PUT for update a timesheets by timesheets id.
 *
 * @param {string} account - account related to user.
 * @param {number} timesheetsId - Timesheets id.
 *
 */
export function updateTimesheetsTask(account, timesheetsId, dataTimesheets) {
  return instanceAPI.put(`/accounts/${account}/timesheets/${timesheetsId}`, dataTimesheets).then((response) => response);
}

/*
 * This function makes a request DELETE for delete a timesheets by timesheets id.
 *
 * @param {string} account - account related to user.
 * @param {number} timesheetsId - Timesheets id.
 *
 */
export function deleteTimesheetsTask(account, timesheetsId) {
  const request = instanceAPI.delete(`/accounts/${account}/timesheets/${timesheetsId}`);
  return request;
}
