import { makeStyles } from '@material-ui/core';
import colors from 'constants/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: '15pt',
  },
  company: {
    width: '250px',
  },
  margin: {
    margin: theme.spacing(1),
    height: '25px',
  },
  form: {
    width: '100%',
  },
  formField: {
    width: '55%',
    [theme.breakpoints.down('sm')]: {
      width: '88%',
    },
  },
  label: {
    fontSize: '12pt',
    fontWeight: 'bold',
    color: colors.sixth,
  },
  labelTime: {
    fontSize: '12pt',
    fontWeight: 'bold',
    color: colors.sixth,
    [theme.breakpoints.down('xs')]: {
      fontSize: '10pt',
    },
  },
  radioLabels: {
    fontWeight: 'bold',
    fontSize: '13pt',
  },
  actions: {
    float: 'right',
    marginRight: '2%',
    marginTop: '12px',
  },
  formFieldAutoSize: {
    fontFamily: 'Muli',
    color: colors.primary,
    fontSize: '15px',
    width: '55%',
    [theme.breakpoints.down('sm')]: {
      width: '88%',
    },
    '&::placeholder': {
      opacity: 0.5,
      fontSize: '15px',
      color: colors.primary,
      fontFamily: 'Muli',
    },
  },
}));


export default useStyles;
