import React, { useState } from 'react';
import classNames from 'classnames';
import { ShowdownHendrix } from 'utils/showdownHendrix';
import PropTypes from 'prop-types';
import { NavLink, useParams } from 'react-router-dom';
import IconInbox from 'components/iconInbox';
import useWindowSize from 'hooks/useWindowSize';
import widthMobile from 'constants/responsive';
import stringToColor from 'string-to-color';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  IconButton,
  Avatar,
} from '@material-ui/core';
import useStyles from 'styles/list';


const ProjectsList = (props) => {
  const size = useWindowSize();
  const classes = useStyles();
  let { id } = useParams();
  id = parseInt(id, 10);
  const { listItems, checkbox } = props;
  const [checked, setChecked] = useState([0]);
  const pathname = window.location.pathname.replace(/\/[0-9]+/, '');
  const converter = new ShowdownHendrix();

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const OnlyLetter = (name) => name.charAt(0).toUpperCase();

  return (
    <List className={classes.root}>
      { listItems.map((item) => {
        const labelId = `checkbox-list-label-${item.id}`;

        return (
          <div key={`project-div-item-${item.id}`}>
            <ListItem
              className={item.id !== id ? classes.item : classes.activeItem}
              role={undefined}
              dense
              alignItems="flex-start"
              button>
              <ListItemIcon className={classes.checkStyle} key={`project-icon-${item.id}`}>
                {checkbox ? (
                  <Checkbox
                    className={classes.checkbox}
                    edge="start"
                    tabIndex={-1}
                    disableRipple
                    color="primary"
                    inputProps={{ 'aria-labelledby': labelId }}
                    onClick={handleToggle(item.id)} />
                ) : ''}
                {!id
                  ? (
                    <>
                      <IconButton aria-label="icons-tools" className={classes.avatarStyle}>
                        <Avatar
                          className={classes.small}
                          style={{ background: `${stringToColor(item.name)}` }}>
                          {OnlyLetter(item.name)}
                        </Avatar>
                      </IconButton>
                      <IconInbox
                        statusType="iconList"
                        status={item.status_id}
                        statusName={item.projects_status.name} />
                      <div className={classes.key}>{item.key}</div>
                    </>
                  ) : ''}
              </ListItemIcon>
              <NavLink
                key={`list-item-project-desk-${item.id}`}
                to={`${pathname}/${item.id}`}
                activeClassName={classes.activeLink}
                className={classes.planeLink}>
                <ListItemText
                  className={size.width > widthMobile
                    ? classes.textItem : classes.textResponsiveItem}
                  style={id ? {} : { borderLeft: '1px solid #9A9895', paddingRight: '5px' }}
                  id={labelId}>
                  <div
                    className={classNames(classes.preview, 'markdown-body')}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: converter.makeHtml(item.name),
                    }} />
                </ListItemText>
              </NavLink>
            </ListItem>
          </div>
        );
      })}
    </List>
  );
};

ProjectsList.defaultProps = {
  checkbox: false,
};

ProjectsList.propTypes = {
  listItems: PropTypes.instanceOf(Array).isRequired,
  checkbox: PropTypes.bool,
};

export default ProjectsList;
