import React from 'react';
import ReplyIcon from '@material-ui/icons/Reply';
import classNames from 'classnames';
import stringToColor from 'string-to-color';
import { ShowdownHendrix } from 'utils/showdownHendrix';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  Grid,
  Avatar,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import Favorite from 'components/favorite/index';
import Visibility from 'components/visibility/index';
import useStyles from './styles';

function Conversations(props) {
  const classes = useStyles();
  const converter = new ShowdownHendrix();
  const {
    conversations,
    hasInsideView,
    handleClickFavorite,
    handleClickVisibility,
  } = props;

  const OnlyLetter = (name) => name.charAt(0).toUpperCase();
  const date = moment().subtract(4, 'hours');

  return (
    <div>
      {conversations.map((conversation) => (
        <Grid
          container
          direction="column"
          key={`${conversation.author_name}-${conversation.id}-${Math.random()}`}>
          <Grid
            item
            container
            justify="flex-start"
            alignItems="center">
            <Grid item>
              <Avatar
                className={classes.avatar}
                alt={conversation.author.nickname}
                style={{ background: `${stringToColor(conversation.author.nickname)}` }}>
                {OnlyLetter(conversation.author.nickname)}
              </Avatar>
            </Grid>
            <Grid item>
              <span className={classes.name}>
                {conversation.author.nickname}
              </span>
              <Tooltip
                title={conversation.is_visible ? 'Hacer privado' : 'Hacer público'}
                options={{ print: false }}>
                <span>
                  <Visibility
                    conversationId={conversation.id}
                    isVisible={conversation.is_visible}
                    handleClick={handleClickVisibility}
                    hasInsideView={hasInsideView} />
                </span>
              </Tooltip>
              <Tooltip
                options={{ print: false }}
                title="Reponder a comentario">
                <span>
                  <IconButton
                    className={classes.iconButton}
                    aria-label="Reponder"
                    disabled>
                    <ReplyIcon className={classes.icon} />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip
                options={{ print: false }}
                title={
                    conversation.is_starring ? 'Quitar de favoritos' : 'Marcar como favorito'
                  }>
                <span>
                  <Favorite
                    conversationId={conversation.id}
                    isStarring={conversation.is_starring}
                    hasInsideView={hasInsideView}
                    handleClick={handleClickFavorite} />
                </span>
              </Tooltip>
              <span>
                { moment(conversation.created_at) >= date
                  ? moment(conversation.created_at).fromNow()
                  : moment(conversation.created_at).format('DD/MM/YY hh:mm a')}
              </span>
            </Grid>
          </Grid>
          <Grid
            item
            id={`grid-content-${conversation.id}`}
            xs={12}
            className={classes.conversation}>
            <div
              className={classNames(classes.preview, 'markdown-body')}
            // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: converter.makeHtml(conversation.content),
              }} />
          </Grid>
        </Grid>
      ))}
    </div>
  );
}

Conversations.propTypes = {
  conversations: PropTypes.instanceOf(Array).isRequired,
  hasInsideView: PropTypes.bool.isRequired,
  handleClickFavorite: PropTypes.func.isRequired,
  handleClickVisibility: PropTypes.func.isRequired,
};
export default Conversations;
