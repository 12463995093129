import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { TransparentDialog } from 'components/transparentDialog/index';
import { Grid, Typography, FormControlLabel } from '@material-ui/core';
import RenderFinderField from 'components/renderFinderField';
import RenderCheckbox from 'components/renderCheckbox';
import { makeStyles, withStyles } from '@material-ui/core/styles';


const StyledFinderField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#CBC9C6',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#CBC9C6',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#CBC9C6',
    },
  },
})(RenderFinderField);

const useStyles = makeStyles({
  typographyTitle: {
    color: '#FFFFFF',
  },
  buttonFilter: {
    fontWeight: 'bold',
    color: '#9A9895',
  },
});

export const FinderSection = (props) => {
  const classes = useStyles();
  const {
    handleSubmit,
    onSubmit,
    open,
    handleClose,
    pristine,
    invalid,
    submitting,
  } = props;

  const colorText = { color: '#CBC9C6' };

  return (
    <div>
      <TransparentDialog
        handleClose={handleClose}
        open={open}
        aria-labelledby="dialog-find-task"
        buttonProps={{
          fullWidth: true,
          type: 'submit',
          disabled: pristine || submitting || invalid,
          onClick: handleSubmit(onSubmit),
        }}
        disableBackdropClick
        fullWidth
        maxWidth="md"
        actionText="MOSTRAR RESULTADOS">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            direction="column"
            justify="space-between"
            alignItems="center"
            spacing={2}>
            <Grid
              item
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={1}>
              <Grid item xs={6} sm={8}>
                <Typography variant="h6" className={classes.typographyTitle}>
                  <center>
                    Buscador
                  </center>
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Field
                  name="finderField"
                  placeholder=""
                  component={StyledFinderField}
                  required
                  fullWidth
                  style={colorText}
                  className={classes.fieldText} />
              </Grid>
              <Grid item container justify="space-between" direction="row" alignItems="center" xs={8}>
                <FormControlLabel
                  control={(
                    <Field
                      style={{ color: '#E26376' }}
                      name="concept"
                      component={RenderCheckbox}
                      label="Concepto" />
                  )}
                  label={(
                    <Typography variant="caption" className={classes.buttonFilter}>
                      Concepto
                    </Typography>
                  )} />
                <FormControlLabel
                  control={(
                    <Field
                      style={{ color: '#E26376' }}
                      color={classes.buttonFilter}
                      name="description"
                      component={RenderCheckbox}
                      label="Descripción" />
                  )}
                  label={(
                    <Typography variant="caption" className={classes.buttonFilter}>
                      Descripción
                    </Typography>
                  )} />
                <FormControlLabel
                  control={(
                    <Field
                      style={{ color: '#E26376' }}
                      color={classes.buttonFilter}
                      name="comments"
                      component={RenderCheckbox}
                      label="Comentarios" />
                  )}
                  label={(
                    <Typography variant="caption" className={classes.buttonFilter}>
                      Comentarios
                    </Typography>
                  )} />
                <FormControlLabel
                  control={(
                    <Field
                      style={{ color: '#E26376' }}
                      color={classes.buttonFilter}
                      name="fileName"
                      component={RenderCheckbox}
                      label="Nombres de archivo" />
                  )}
                  label={(
                    <Typography variant="caption" className={classes.buttonFilter}>
                      Nombres de archivo
                    </Typography>
                  )} />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </TransparentDialog>
    </div>
  );
};

FinderSection.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export const Finder = (reduxForm({
  form: 'Finder',
})(FinderSection));
export default Finder;
