import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { useDispatch } from 'react-redux';
import RenderTime from 'components/timeField';
import { Grid } from '@material-ui/core';
import { SecondaryButton } from 'components/secondaryButton';
import { PrimaryButton } from 'components/primaryButton';
import useStyles from './styles';

export function CreateTimesheet(props) {
  const {
    handleSubmit,
    pristine,
    reset,
    submitting,
    onSubmit,
    timeValue,
    handleClose,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleReset = () => {
    handleClose();
    dispatch(reset('CreateTimesheetForm'));
  };

  return (
    <div>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start">
        <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
          <Grid item xs={12} sm={7} md={7}>
            <div className={classes.timeContainer}>
              <span>
                Agregar Tiempo
              </span>
              <Field
                name="addTime"
                component={RenderTime}
                label="Set Time"
                inputProps={{
                  value: timeValue,
                }} />
            </div>
          </Grid>
          <Grid item xs={12} sm={7} md={5}>
            <div className={classes.containerButtons}>
              <SecondaryButton
                styles={{
                  marginTop: '4px',
                  marginRight: '12px',
                }}
                fullWidth={false}
                size="medium"
                variant="contained"
                type="button"
                onClick={handleReset}
                text="CANCELAR" />
              <PrimaryButton
                size="medium"
                variant="contained"
                type="submit"
                disabled={pristine || submitting}
                text="GUARDAR" />
            </div>
          </Grid>
        </form>
      </Grid>
    </div>
  );
}

CreateTimesheet.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  timeValue: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};

const CreateTimesheetForm = reduxForm({
  form: 'CreateTimesheetForm',
})(CreateTimesheet);


export default CreateTimesheetForm;
