/* ---------------------------------------------------------
 * Reducer for roles
 * --------------------------------------------------------- */
import { GET_ROLES, RESET_ROLES } from 'constants/roles';


const initialState = {
  data: [],
  isLoading: false,
  pagination: {
    limit: 10,
    offset: 0,
    orderBy: null,
    totalCount: 0,
  },
  filter: {},
  error: null,
};

const roles = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case RESET_ROLES: {
      return {
        ...initialState,
      };
    }
    case `${GET_ROLES}_REJECTED`:
      return {
        ...state,
        isLoading: false,
        error: {
          ...payload,
          fromAction: GET_ROLES,
        },
      };
    case `${GET_ROLES}_PENDING`:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case `${GET_ROLES}_FULFILLED`: {
      const { data: { pagination, results } } = payload;

      return {
        ...state,
        isLoading: false,
        error: null,
        pagination: {
          limit: pagination.limit,
          offset: pagination.offset,
          orderBy: pagination.orderBy,
          totalCount: pagination.totalCount,
        },
        data: [...state.data, ...results],
        filter: pagination.filter,
      };
    }
    default:
      return state;
  }
};

export default roles;
