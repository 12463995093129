import React from 'react';
import PropTypes from 'prop-types';
import Header from 'components/header';
import useOpenClose from 'hooks/useOpenClose';
import FinderContainer from 'containers/finderContainer/index';
import { useHistory } from 'react-router-dom';

function HeaderContainer(props) {
  const {
    openMenu,
    handleClickMenu,
    openNotifications,
    handleOpenNotifications,
    haveNotifications,
    title,
  } = props;
  const history = useHistory();
  const handleRouteChange = () => {
    const path = '/dashboard/panel';
    history.push(path);
  };

  const {
    open: openFinder,
    handleOpen: handleOpenFinder,
    handleClose: handleCloseFinder,
  } = useOpenClose();

  return (
    <div>
      <Header
        title={title}
        openMenu={openMenu}
        handleClickMenu={handleClickMenu}
        handleOpenFinder={handleOpenFinder}
        handleClickLogo={handleRouteChange}
        openNotifications={openNotifications}
        handleOpenNotifications={handleOpenNotifications}
        haveNotifications={haveNotifications} />
      <FinderContainer
        open={openFinder}
        handleOpen={handleOpenFinder}
        handleClose={handleCloseFinder} />
    </div>
  );
}
HeaderContainer.propTypes = {
  title: PropTypes.string.isRequired,
  openMenu: PropTypes.bool.isRequired,
  handleClickMenu: PropTypes.func.isRequired,
  openNotifications: PropTypes.bool.isRequired,
  handleOpenNotifications: PropTypes.func.isRequired,
  haveNotifications: PropTypes.bool.isRequired,
};

export default HeaderContainer;
