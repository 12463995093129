import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { blue } from '@material-ui/core/colors';
import CenteredTabs from 'components/tabComponent/index';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'transparent',
    color: blue[600],
  },
}));

const paperClass = makeStyles((theme) => ({
  root: {
    backgroundColor: 'rgba(0,0,0, .90)',
    position: 'absolute',
    right: '3%',
    top: '3.3%',
    width: '450px',
    [theme.breakpoints.down('sm')]: {
      top: '3.4%',
      right: '0',
      width: '100%',
      marginLeft: '0',
      marginRight: '0',
    },
    [theme.breakpoints.up('sm')]: {
      width: '450px',
    },
  },
}));
export default function NotificationPanel(props) {
  const classes = useStyles();
  const paperClasses = paperClass();
  const { onClose, open, dataNotifications } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      className={classes.root}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      BackdropProps={{ style: { backgroundColor: 'transparent' } }}
      PaperProps={{
        elevation: 0,
        classes: { root: paperClasses.root },
      }}>

      <DialogTitle style={{ color: '#E26376' }} id="simple-dialog-title">
        Notificaciones (
        {dataNotifications.length}
        ) ...
        {' '}
      </DialogTitle>

      <CenteredTabs notifications={dataNotifications} />

    </Dialog>
  );
}

NotificationPanel.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  dataNotifications: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      content: PropTypes.string.isRequired,
    })).isRequired,
  })).isRequired,
};
