import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import DependencyTask from 'components/dependicyTask/';
import Loading from 'components/loading/index';
import predecessorsTasks from 'services/actions/predecessorsTasks';
import successorsTasks from 'services/actions/successorsTasks';
import { selectorTasksList } from 'services/selectors/tasksList';
import { selectorActualAccountName } from 'services/selectors/account';
import { selectorPredecessorsTaks } from 'services/selectors/dependenciesTasks/predecessors';
import { selectorSuccessorsTaks } from 'services/selectors/dependenciesTasks/successorsTasks';

function filter(arr, criteria) {
  return arr.filter((obj) => Object.keys(criteria).every((c) => obj[c] === criteria[c]));
}

const DependenciaTasksContainer = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { projectID, account } = props;
  const tasksList = useSelector(selectorTasksList).data;
  const taskFilterbyProject = filter(tasksList, { projectID });
  const actualAccountName = useSelector(selectorActualAccountName);
  const dataPredecessorsTaks = useSelector(selectorPredecessorsTaks);
  const dataSuccessorsTaks = useSelector(selectorSuccessorsTaks);
  const isLoadingPredecessors = useSelector(selectorPredecessorsTaks).isFetching;
  const isLoadingSuccessors = useSelector(selectorSuccessorsTaks).isFetching;

  useEffect(() => {
    dispatch(predecessorsTasks(actualAccountName, id));
    dispatch(successorsTasks(actualAccountName, id));
    // eslint-disable-next-line
  }, [actualAccountName, id]);

  return (
    <div>
      {isLoadingPredecessors || isLoadingSuccessors ? <Loading />
        : (
          <DependencyTask
            taskList={taskFilterbyProject}
            account={account}
            tasksPredecessors={dataPredecessorsTaks}
            tasksSuccessors={dataSuccessorsTaks} />
        ) }
    </div>
  );
};

DependenciaTasksContainer.propTypes = {
  account: PropTypes.string.isRequired,
  projectID: PropTypes.number.isRequired,
};

export default DependenciaTasksContainer;
