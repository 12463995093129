import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Tabs,
  Tab,
  TextField,
  InputAdornment,
  Typography,
  Grid,
} from '@material-ui/core';
import { SkipPrevious, SkipNext } from '@material-ui/icons';
import filter from 'assets/iconsSvgs/filter.svg';

import TabPanel from 'components/boxTabs/index';
import ListTask from './listTask';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTab-textColorInherit.Mui-selected': {
      color: '#E26376',
    },
    '& .MuiTabs-indicator': {
      backgroundColor: '#E26376',
      display: 'none',
    },
    '& .MuiBox-root': {
      paddingRight: '0 !important',
      paddingLeft: '0 !important',
    },
  },
  justify: {
    '& .MuiTab-wrapper': {
      justifyContent: 'flex-end !important',
    },
    '& .MuiSvgIcon-root': {
      marginLeft: '15px',
    },
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%',
  },
  msgEmpty: {
    textAlign: 'center',
    color: '#E16474',
    padding: '25px',
  },
  activeText: {
    textAlign: 'center',
    padding: '5px',
  },
  iconsContainer: {
    color: '#E16474',
    textAlign: 'right',
    '& .MuiSvgIcon-root': {
      fontSize: '35px',
    },
  },
}));

const DependencyTask = (props) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [list, setList] = useState([]);
  const { taskList, tasksPredecessors, tasksSuccessors } = props;
  const titlePredeccessors = tasksPredecessors.data ? `${tasksPredecessors.data.length} Tareas predecesoras` : '';
  const titleSuccessors = tasksSuccessors.data ? `${tasksSuccessors.data.length} Tareas sucesoras` : '';

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handlecChangeFilter = (event) => {
    const regex = new RegExp(event.target.value, 'gi');
    const newList = taskList.filter((t) => (
      t.text.match(regex)
        || t.id === Number(event.target.value)
        || t.responsible.nickname.match(regex)
        || t.statusName.match(regex)
    ));
    const searchList = newList.map((task) => task);
    setList(searchList);
  };

  useEffect(() => {
    setList(taskList);
  }, [taskList]);

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs task"
          variant="fullWidth"
          centered>
          <Tab
            icon={<SkipPrevious />}
            label={titlePredeccessors}
            id="simple-tab-0"
            aria-controls="simple-tabpanel-0" />
          <Tab
            className={classes.justify}
            label={titleSuccessors}
            icon={<SkipNext />}
            id="simple-tab-1"
            aria-controls="simple-tabpanel-1" />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <ListTask tasks={tasksPredecessors.data} checkbox={false} clear />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ListTask tasks={tasksSuccessors.data} checkbox={false} clear />
      </TabPanel>
      <Grid container spacing={1}>
        <Grid item xs={12} md={7}>
          <TextField
            id="outlined-basic"
            placeholder="Filtrar por ID, conceptop, responsable o estado"
            variant="outlined"
            size="small"
            className={classes.textField}
            onChange={handlecChangeFilter}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={filter} alt="filter_icon" />
                </InputAdornment>
              ),
            }} />
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography variant="subtitle1" className={classes.activeText}>
            {list.length}
            {' '}
            Tareas Activas
          </Typography>
        </Grid>
        <Grid item xs={6} md={2} className={classes.iconsContainer}>
          <SkipPrevious />
          <SkipNext />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {list.length > 0 ? <ListTask tasks={list} />
            : (
              <Typography variant="h6" className={classes.msgEmpty}>
                No hay tareas en lista
              </Typography>
            )}
        </Grid>
      </Grid>
    </div>
  );
};

DependencyTask.propTypes = {
  taskList: PropTypes.instanceOf(Array).isRequired,
  tasksPredecessors: PropTypes.instanceOf(Object).isRequired,
  tasksSuccessors: PropTypes.instanceOf(Object).isRequired,
};

export default DependencyTask;
