import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { RecoverPassword } from 'components/recoverPassword/index';
import { recoverPasswordUser } from 'services/actions/recoverPassword';
import { TransparentDialog } from 'components/transparentDialog/index';
import useOpenClose from 'hooks/useOpenClose';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { reset } from 'redux-form';

export default function RecoverPasswordContainer(props) {
  const {
    open,
    handleOpen,
    handleClose,
  } = props;
  const dispatch = useDispatch();
  const [helperTexts, setHelperTexts] = useState({
    email: '',
  });
  const [messageAfterSubmit, setMessageAfterSubmit] = useState('');

  const {
    open: OpenDialog,
    handleClose: handleCloseDialog,
    handleOpen: handleOpenDialog,
  } = useOpenClose();

  const handleSubmit = async (dataUser) => {
    const response = await dispatch(recoverPasswordUser(dataUser));
    const { action: { payload: { status } } } = response;
    if (status >= 500) {
      setMessageAfterSubmit('Hubo un error, por favor intenta más tarde.');
      dispatch(reset('RecoverPassword'));
      handleOpenDialog();
      handleClose();
    } else {
      setHelperTexts({});
      dispatch(reset('RecoverPassword'));
      setMessageAfterSubmit(
        'Si existe un usuario con ese correo electrónico te llegará un correo con un enlace para cambiar tu contraseña.',
      );
      handleOpenDialog();
      handleClose();
    }
  };

  return (
    <div>
      <RecoverPassword
        onSubmit={handleSubmit}
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        helperTexts={helperTexts}
        initialValues={{
          email: '',
        }} />
      <TransparentDialog
        handleClose={handleCloseDialog}
        open={OpenDialog}
        aria-labelledby="dialog-recover-success-password"
        buttonProps={{
          fullWidth: false,
          type: 'button',
          disabled: false,
          onClick: handleCloseDialog,
        }}
        disableBackdropClick
        fullWidth
        maxWidth="xs"
        actionText="OK">
        <Grid
          container>
          <Grid
            item
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={1}>
            <Grid item xs={6} sm={7}>
              <center>
                <Typography variant="h6" style={{ color: 'white' }}>
                  Recuperar contraseña
                </Typography>
              </center>
            </Grid>
            <Grid item>
              <center>
                <Typography color="primary">
                  {messageAfterSubmit}
                </Typography>
              </center>
            </Grid>
          </Grid>
        </Grid>
      </TransparentDialog>

    </div>
  );
}

RecoverPasswordContainer.propTypes = {
  open: PropTypes.bool.isRequired,
  handleOpen: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};
