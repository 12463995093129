import {
  getTaskAPI,
  createTaskAPI,
  updateTaskAPI,
} from 'services/apis/task';
import {
  GET_TASK,
  CREATE_TASK,
  UPDATE_TASK,
} from 'constants/task';

/*
 * Task's actions
 */

/*
 * @typedef {Object} dataTask
 * @property {string} concept - Concept task, field required.
 * @property {string} project_id - Task's project, field required.
 * @property {number} responsible - Task's responsible, field optional.
 * @property {number} petitioner - Task's petitioner, field optional.
 * @property {date} start_date - Task's start date, field optional.
 * @property {date} delevery_date - Task's delevery date, field optional.
 * @property {number} status_id - Task's status, field.
 * @property {number} priority_id - Task's priority, field optional.
 * @property {number} solicitude_type_d - Task's solicitude type, field optional.
 * @property {number} task_expense_source_id  - Task's expense source id , field optional.
 * @property {number} estimated_time optional - Task's estimated time, field optional.
 * @property {boolean} is_chargeable - Flag to identify if the task is chargeable to client,optional
 * @property {boolean} is_pagable  - Flag to identify if the task is pagable, field optional.
 * @property {boolean} is_visible - Flag,identify if the task is visible by has_inside_view users.
 * @property {number} timesheet_time - Time in minutes(>0)(>0) for dedicated time, field optional.
 * @property {date} timesheet_date - Date for dedicated time, field optional.
 * @property {number} timesheet_on_behalf_of - User id about whom was made the timesheet, optional.
 *
 *
 * This function create a task.
 *
 * @param {string} account - account related to user.
 * @param {dataTask} data - Data for creating a task.
 *
 */
export const createTask = (account, data) => ({
  type: CREATE_TASK,
  payload: createTaskAPI(account, data),
});

/*
 * @typedef {Object} dataTask
 * @property {string} concept - Concept task, field required.
 * @property {string} project_id - Task's project, field required.
 * @property {number} responsible - Task's responsible, field optional.
 * @property {number} petitioner - Task's petitioner, field optional.
 * @property {date} start_date - Task's start date, field optional.
 * @property {date} delevery_date - Task's delevery date, field optional.
 * @property {number} status_id - Task's status, field.
 * @property {number} priority_id - Task's priority, field optional.
 * @property {number} solicitude_type_d - Task's solicitude type, field optional.
 * @property {number} task_expense_source_id  - Task's expense source id , field optional.
 * @property {number} estimated_time optional - Task's estimated time, field optional.
 * @property {boolean} is_chargeable - Flag to identify if the task is chargeable to client,optional
 * @property {boolean} is_pagable  - Flag to identify if the task is pagable, field optional.
 * @property {boolean} is_visible - Flag,identify if the task is visible by has_inside_view users.
 * @property {number} timesheet_time - Time in minutes(>0)(>0) for dedicated time, field optional.
 * @property {date} timesheet_date - Date for dedicated time, field optional.
 * @property {number} timesheet_on_behalf_of - User id about whom was made the timesheet, optional.
 *
 *
 * This function update a task
 *
 * @param {string} account - account related to user.
 @param {number} id - Task's id.
 * @param {dataTask} data - Data for updating a task
 *
 */
export const updateTask = (account, id, data) => ({
  type: UPDATE_TASK,
  payload: updateTaskAPI(account, id, data),
});

/*
 *
 * This function get a task by id.
 *
 * @param {string} account - account related to user.
 * @param {number} id - Task's id.
 *
 */
export const getTask = (account, id) => ({
  type: GET_TASK,
  payload: getTaskAPI(account, id),
});

export default createTask;
