export const GET_PROJECT = 'GET_PROJECT';
export const CREATE_PROJECTS = 'CREATE_PROJECTS';
export const UPDATE_PROJECTS = 'UPDATE_PROJECTS';

// status for projects
export const idProjectDeveloping = 1223;
export const idProjectInMaintenance = 1224;
export const idProjectProspectus = 1225;
export const idProjectFinished = 1226;
export const idProjectCancelled = 1227;
