import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  autocomplete: {
    width: '100%',
  },
  inputContainer: {
    borderRadius: '5px',
    width: '100%',
  },
  avatar: {
    width: '16px',
    height: '16px',
    fontSize: '8px',
  },
  inputSearch: {
    width: 'calc(100% - 2px)',
    border: '1px solid #ccc',
    borderRadius: '5px',
    margin: '1px 0px',
    fontSize: '12px',
  },
  list: {
    padding: '0px',
    width: '100%',
  },
  itemsList: {
    color: '#CBC9C6',
    '&:hover': {
      color: '#E26376',
    },
  },
  iconButton: {
    color: '#ccc',
    width: '22px',
    height: '22px',
  },
  popOver: {
    width: '',
  },
}));

export default useStyles;
