import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  iconBadge: {
    '&:disabled': {
      color: '#CBC9C6',
    },
    '&:active': {
      color: 'rgba(111, 110, 107, .88)',
    },
    color: '#6F6E6B',
    width: '22px',
    [theme.breakpoints.down('sm')]: {
      width: '26px',
      height: '26px',
    },
  },
  iconSearch: {
    padding: '0px',
    marginRight: '15px',
  },
}));

const SearchButton = (props) => {
  const classes = useStyles();

  const {
    IconBadge,
    handleClick,
    disabled,
  } = props;

  return (
    <IconButton disabled={disabled} onClick={handleClick} className={classes.iconSearch}>
      <IconBadge className={classes.iconBadge} />
    </IconButton>
  );
};

SearchButton.defaultProps = {
  disabled: false,
  IconBadge: SearchIcon,
};

SearchButton.propTypes = {
  disabled: PropTypes.bool,
  IconBadge: PropTypes.elementType,
  handleClick: PropTypes.func.isRequired,
};

export default SearchButton;
