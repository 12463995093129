import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import StatusItem from 'components/statusItem';
import useStyles from './styles';

function StatusSelector(props) {
  const classes = useStyles();
  const { idStatus, nameStatus, disabled, setIdStatus, setStatus, input, statuses } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    if (Object.keys(input).length > 0) {
      input.onChange(idStatus);
    }
  }, [input, idStatus]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSetValues = (id, name) => {
    setIdStatus(id);
    setStatus(name);
    setAnchorEl(null);
    input.onChange(id);
  };

  return (
    <div className={classes.marginDiv}>
      <Button
        disabled={disabled}
        className={classes.root}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}>
        <StatusItem status={idStatus} statusName={nameStatus} />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {
          statuses.map((status) => (
            <MenuItem
              className={classes.listItem}
              key={`status-item-${status.name}`}
              onClick={() => handleSetValues(status.id, status.name)}>
              <StatusItem status={status.id} statusName={status.name} />
            </MenuItem>
          ))
        }
      </Menu>
    </div>
  );
}

StatusSelector.defaultProps = {
  disabled: false,
  setIdStatus: () => {},
  setStatus: () => {},
  input: {},
  statuses: [],
};

StatusSelector.propTypes = {
  idStatus: PropTypes.number.isRequired,
  nameStatus: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  setIdStatus: PropTypes.func,
  setStatus: PropTypes.func,
  input: PropTypes.instanceOf(Object),
  statuses: PropTypes.instanceOf(Array),
};

export default StatusSelector;
