import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import RenderPasswordField from 'components/renderPasswordField';
import { TransparentDialog } from 'components/transparentDialog/index';
import RenderTextField from 'components/renderTextField';

const CustomFieldPassword = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#CBC9C6',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#CBC9C6',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#CBC9C6',
    },
  },
})(RenderPasswordField);

const styles = (theme) => ({
  fieldText: {
    '& label.Mui-focused': {
      color: '#CBC9C6',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#CBC9C6',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#CBC9C6',
    },
    marginRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    width: '100%',

  },
});

const validate = (values) => {
  const errors = {};
  const minLengthPassword = 8;
  const fields = {
    password: 'Contraseña',
    confirm: 'Confirmar Contraseña',
  };
  Object.keys(fields).forEach((key) => {
    if (!values[key]) errors[key] = `El campo ${fields[key]} es requerido`;
  });

  if (values.password && values.password.length < minLengthPassword) {
    errors.password = 'La contraseña debe tener al menos 6 caracteres';
  }
  if (values.confirm && values.confirm.length < minLengthPassword) {
    errors.confirm = 'La contraseña debe tener al menos 6 caracteres';
  }
  if (values.password !== values.confirm) {
    errors.confirm = 'Las contraseñas no coinciden';
  }
  return errors;
};

export function ResetPasswordForm(props) {
  const {
    handleSubmit,
    invalid,
    pristine,
    submitting,
    onSubmit,
    open,
    handleClose,
    helperTexts,
    token,
  } = props;

  const colorText = { color: '#CBC9C6', paddingBottom: '12px' };
  return (
    <div>

      <TransparentDialog
        handleClose={handleClose}
        open={open}
        aria-labelledby="dialog-reset-password"
        buttonProps={{
          fullWidth: true,
          type: 'submit',
          disabled: invalid || pristine || submitting,
          onClick: handleSubmit(onSubmit),
        }}
        disableBackdropClick
        fullWidth
        maxWidth="xs"
        actionText="GENERAR NUEVA CONTRASEÑA">

        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            direction="column"
            justify="space-between"
            alignItems="stretch"
            spacing={2}>
            <Grid
              item
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={1}>
              <Grid item xs={12} sm={12}>
                <center>
                  <Typography variant="h6" style={{ color: 'white' }}>
                    Recuperar contraseña ingresando una nueva
                  </Typography>
                </center>
              </Grid>
            </Grid>
            <Grid item>
              <Field
                name="username"
                component={RenderTextField}
                id="username"
                value={token}
                style={{ display: 'none' }} />
              <Field
                name="password"
                placeholder="Ingresa tu contraseña"
                component={CustomFieldPassword}
                autocomplete="new-password"
                type="password"
                fullWidth
                helperText={helperTexts.password}
                style={colorText}
                id="new-password"
                required />
            </Grid>
            <Grid item>
              <Field
                name="confirm"
                placeholder="Confirma tu contraseña"
                component={CustomFieldPassword}
                autocomplete="confirm-new-password"
                type="password"
                fullWidth
                id="confirm-new-password"
                required
                style={colorText} />
            </Grid>
          </Grid>
        </form>
      </TransparentDialog>

    </div>
  );
}

ResetPasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  helperTexts: PropTypes.shape({
    password: PropTypes.string,
  }).isRequired,
  token: PropTypes.string.isRequired,
};

export const ResetPassword = withStyles(styles)(reduxForm({
  form: 'ResetPassword',
  validate,
})(ResetPasswordForm));

export default ResetPassword;
