import { makeStyles } from '@material-ui/core/styles';
import colors from 'constants/colors';

const useStyles = makeStyles(() => ({
  icon: {
    fontSize: '1.3rem',
  },
  iconVisible: {
    fill: colors.secondary,
    fontSize: '1.3rem',
  },
  iconButton: {
    padding: '7px',
    marginTop: '-5px',
  },
}));

export default useStyles;
