import {
  getProjectGroupers,
} from 'services/apis/projectsGroupers';
import {
  GET_PROJECT_GROUPERS,
} from 'constants/projectGroupers';

export const getGroupers = (account, data) => ({
  type: GET_PROJECT_GROUPERS,
  payload: getProjectGroupers(account, data),
});

export default getGroupers;
