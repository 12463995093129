import { GET_PREDECESSORS } from 'constants/task';
/*
* predecessors tasks
*/
const initialState = {
  isFetching: false,
  data: [],
  error: null,
};

const predecessorsTasks = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case `${GET_PREDECESSORS}_FULFILLED`: {
      const { data: { results } } = payload;
      const newResults = results.map((list) => ({
        id: list.id,
        text: list.predecessor.concept,
        priority: list.predecessor.priority.id,
        priorityName: list.predecessor.priority.name,
        status: list.predecessor.status.id,
        statusName: list.predecessor.status.name,
        responsible: {
          nickname: list.predecessor.responsible.nickname,
        },
      }));
      return {
        ...state,
        isFetching: false,
        data: [...newResults],
        error: null,
      };
    }
    case `${GET_PREDECESSORS}_PENDING`: {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    }
    case `${GET_PREDECESSORS}_REJECTED`: {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    }

    default:
      return state;
  }
};

export default predecessorsTasks;
