import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Menu,
  MenuItem,
} from '@material-ui/core';
import PriorityItem from 'components/priorityItem';
import { statuses } from 'constants/priorities';
import useStyles from './styles';

export default function PrioritySelector(props) {
  const { idPriority, namePriority, disabled, setIdPriority, setPriority, input } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (Object.keys(input).length > 0) {
      input.onChange(idPriority);
    }
  }, [input, idPriority]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSetValues = (id, name) => {
    setIdPriority(id);
    setPriority(name);
    setAnchorEl(null);
    input.onChange(id);
  };

  return (
    <div className={classes.marginDiv}>
      <Button disabled={disabled} className={classes.root} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <PriorityItem priority={idPriority} priorityName={namePriority} />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {
          statuses.map((status) => (
            <MenuItem key={`status-item-${status.name}`} onClick={() => handleSetValues(status.id, status.name)}>
              <PriorityItem priority={status.id} priorityName={status.name} />
            </MenuItem>
          ))
        }
      </Menu>
    </div>
  );
}

PrioritySelector.defaultProps = {
  disabled: false,
  setIdPriority: () => {},
  setPriority: () => {},
  input: {},
};

PrioritySelector.propTypes = {
  idPriority: PropTypes.number.isRequired,
  namePriority: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  setIdPriority: PropTypes.func,
  setPriority: PropTypes.func,
  input: PropTypes.instanceOf(Object),
};
