import {
  CREATE_TIMESHEETS,
  UPDATE_TIMESHEETS,
  DELETE_TIMESHEETS,
  GET_TIMESHEETS,
} from 'constants/timesheets';

/*
 *
 * Reducer timesheets
 *
 */

const initialState = {
  isLoading: false,
  data: [],
  error: null,
};

const timesheets = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case `${GET_TIMESHEETS}_REJECTED`: {
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    }
    case `${GET_TIMESHEETS}_PENDING`: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case `${GET_TIMESHEETS}_FULFILLED`: {
      const { data: { results } } = payload;
      return {
        ...state,
        isLoading: false,
        data: results,
        error: null,
      };
    }
    case `${CREATE_TIMESHEETS}_REJECTED`: {
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    }
    case `${CREATE_TIMESHEETS}_PENDING`: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case `${CREATE_TIMESHEETS}_FULFILLED`: {
      return {
        ...state,
        isLoading: false,
        data: [...state.data, payload.data],
        error: null,
      };
    }
    case `${UPDATE_TIMESHEETS}_REJECTED`: {
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    }
    case `${UPDATE_TIMESHEETS}_PENDING`: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case `${UPDATE_TIMESHEETS}_FULFILLED`: {
      const index = state.data.findIndex((time) => (time.id === payload.data.id));

      const newArray = [...state.data];

      newArray[index].time = payload.data.time;
      newArray[index].updated_at = payload.data.updated_at;

      return {
        ...state,
        isLoading: false,
        data: newArray,
        error: null,
      };
    }
    case `${DELETE_TIMESHEETS}_REJECTED`: {
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    }
    case `${DELETE_TIMESHEETS}_PENDING`: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case `${DELETE_TIMESHEETS}_FULFILLED`: {
      const newArray = state.data.filter((time) => (time.id !== action.meta.timeId));

      return {
        ...state,
        isLoading: false,
        data: newArray,
        error: null,
      };
    }
    default:
      return state;
  }
};

export default timesheets;
