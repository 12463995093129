/**
 * Successors tasks selector
 *
 * This function create an selector
 *
 */

export const selectorSuccessorsTaks = (state) => state.successorsTasks;

export default selectorSuccessorsTaks;
