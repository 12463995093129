import { makeStyles } from '@material-ui/core/styles';
import colors from 'constants/colors';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    '& .MuiLinearProgress-colorSecondary': {
      backgroundColor: '#A1C45A',
    },
    '& .MuiLinearProgress-barColorSecondary': {
      backgroundColor: '#3F90BB',
    },
    '& .MuiLinearProgress-root': {
      height: '12px',
    },
    '& .MuiLinearProgress-colorPrimary': {
      backgroundColor: '#DD2C09',
    },
  },
  textTitleLinearProgress: {
    color: colors.secondary,
  },
  textRegistedTime: {
    color: '#3F90BB',
  },
  textEstimatedTime: {
    color: '#A1C45A',
    textAlign: 'end',
  },
  textExceededTimes: {
    color: '#DD2C09',
  },
  iconSize: {
    fontSize: '20px',
  },
  titleRegister: {
    fontWeight: 600,
  },

}));

export default useStyles;
