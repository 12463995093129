import moment from 'moment';

export const convertMinsToTime = (mins) => {
  let hours = Math.floor(mins / 60);
  let minutes = mins % 60;
  hours = hours < 10 ? `0${hours}` : hours;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  const finalMinutes = minutes !== '00' ? `:${minutes}` : ':00';
  return `${hours}${finalMinutes}`;
};

export const convertHoursToMins = (hours) => {
  let minutes = 0;
  if (hours && hours !== '00:00') {
    const time = hours.split(':');
    minutes = parseInt(time[0], 10) * 60 + parseInt(time[1], 10);
  }
  return minutes;
};

export const getTotalTimeOfTask = (times) => {
  let totalMin = 0;

  times.forEach((x) => {
    totalMin += x.time;
  });

  return totalMin;
};

export const getDiferenceTimesOfTask = (estimated, register) => (
  estimated - register
);

export const normaliseValesLinearProgress = (value, max) => (
  ((value - 0) * 100) / (max - 0)
);

export const calculatePercentage = (registered, estimated) => (registered * 100) / estimated;

export const verifyTimesOfCurrentDay = (times, nickname) => {
  if (typeof times === 'undefined') {
    return false;
  }

  const today = moment().format('YYYY-MM-DD');

  const timeRegister = times.filter((time) => {
    const date = moment(time.updated_at).format('YYYY-MM-DD');
    const nickName = time.user.nickname;
    return (today === date && nickName === nickname);
  });

  if (timeRegister.length > 0) {
    return timeRegister[0];
  }

  return false;
};
