import { convertHoursToMins } from 'utils/convertMinsToTime';

const prepareDataTask = (dataUser) => {
  const data = {};
  const {
    dataConcept,
    estimatedTime,
    groupId,
    projectId,
    responsibleId,
    statusId,
    priorityId,
    petitionerId,
  } = dataUser;
  if (estimatedTime && estimatedTime !== '00:00') {
    data.estimated_time = convertHoursToMins(estimatedTime);
  }

  if (groupId !== 0) {
    data.group_id = groupId;
  }

  if (projectId !== 0) {
    data.project_id = projectId;
  }

  if (responsibleId !== 0) {
    data.responsible_id = responsibleId;
  }

  data.concept = dataConcept;
  data.status_id = statusId;
  data.priority_id = priorityId;
  data.petitioner_id = petitionerId;

  return data;
};

export default prepareDataTask;
