// Own actions/constants/components
import { LOCALE_ES } from 'constants/strings';

//-----------------------------------------------------------
// Reducers
//-----------------------------------------------------------
const initialState = {
  selected: 'ES',
  constants: LOCALE_ES,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CHANGE_LANGUAGE':
      return {
        ...state,
        selected: action.selectedLanguage,
        constants: action.constants,
      };
    default:
      return state;
  }
};
