import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

// Material UI
import { Grid, Button } from '@material-ui/core';

// Selectors and Actions
import { selectors } from 'services/selectors/language/string.selector';
import { actions } from 'services/actions/language/string.action';

const NotFound = () => {
  const languageConstants = useSelector(selectors.constants);
  const dispach = useDispatch();
  const constants = languageConstants.pages.notFound;

  return (
    <section className="not-found">
      <Grid container spacing={1} align="center" justify="center">
        <Grid item xs={12}>
          <h1>{ constants.title }</h1>
          <p>{ constants.description }</p>
          <Link to="/">{ constants.button }</Link>
        </Grid>
        <Grid item xs={12} sm={1}>
          <Button variant="contained" color="primary" onClick={(() => dispach(actions.setLanguage('EN')))}>EN</Button>
        </Grid>
        <Grid item xs={12} sm={1}>
          <Button variant="contained" color="primary" onClick={(() => dispach(actions.setLanguage('ES')))}>ES</Button>
        </Grid>
      </Grid>
    </section>
  );
};

export default NotFound;
