import React from 'react';
import { Redirect } from 'react-router';
import Dashboard from 'pages/dashboard/index';
import NotAvailable from 'components/notAvailable/index';
import { menuItems } from 'constants/menu';
import { useParams } from 'react-router-dom';

export default function DashboardContainer() {
  const session = !!localStorage.getItem('token');
  const act = !!localStorage.getItem('act');
  const { id } = useParams();
  let { pathname } = window.location;
  pathname = pathname.replace(/\/[0-9]+/, '');

  let ActualComponentList = NotAvailable;
  let ActualComponentDetail = NotAvailable;
  let propsList = {};
  let titleHeader = '';

  menuItems.forEach((item) => {
    item.items.forEach((subItem) => {
      if (pathname === subItem.link) {
        ActualComponentList = subItem.componentList;
        ActualComponentDetail = subItem.componentDetail;
        propsList = subItem.propsList ? subItem.propsList : {};
        titleHeader = subItem.title;
      }
    });
  });
  return (
    <div>
      { session && act
        ? (
          <Dashboard
            idItemDetail={id}
            titleHeader={titleHeader}
            propsList={propsList}
            componentList={ActualComponentList}
            componentDetail={ActualComponentDetail} />
        )
        : <Redirect to="/" />}
    </div>
  );
}
