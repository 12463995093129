import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import RenderTextField from 'components/renderTextField';
import { Grid, FormControlLabel, Typography, InputLabel } from '@material-ui/core';
import RenderCheckbox from 'components/renderCheckbox';
import { PrimaryButton } from 'components/primaryButton';
import { SecondaryButton } from 'components/secondaryButton';
import { alphaNumeric } from 'utils/validations';
import { useDispatch } from 'react-redux';
import colors from 'constants/colors';
import useStyles from './styles';

const validate = (values) => {
  const errors = {};
  const fields = {
    nameCompany: 'Nombre de la Empresa',
  };
  Object.keys(fields).forEach((key) => {
    if (!values[key]) errors[key] = `El campo ${fields[key]} es requerido`;
  });

  if (values.username) {
    errors.username = alphaNumeric(values.username, fields.username);
  }
  return errors;
};

export function CreateCompany(props) {
  const {
    resetEdit,
    editing,
    setName,
    name,
    setTax,
    tax,
    setIssuer,
    issuer,
    setDefault,
    isDefault,
    handleSubmit,
    pristine,
    reset,
    submitting,
    handleSend,
    helperTexts,
  } = props;

  const classes = useStyles();

  const dispatch = useDispatch();

  const handleReset = () => {
    dispatch(reset('CreateCompany'));
    resetEdit(null);
  };

  const colorText = { color: colors.primary, fontSize: '15px' };
  const display = { display: 'none' };
  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start">
        <form className={classes.form} onSubmit={handleSubmit(handleSend)}>

          { editing && (
            <div style={{ display: 'none' }}>
              <Field
                id="id"
                name="companyStatus"
                component={RenderTextField}
                inputProps={{ value: 'editing' }}
                type="hidden"
                style={display} />
            </div>
          )}
          <Grid
            item
            xs={12}>
            <InputLabel htmlFor="nameCompany" className={classes.label}>Nombre:</InputLabel>
            <Field
              id="nameCompany"
              name="nameCompany"
              placeholder="Ingresa el nombre de la empresa"
              component={RenderTextField}
              inputProps={{ value: name }}
              setTextContent={setName}
              required
              type="text"
              fullWidth
              style={colorText}
              className={classes.formField} />

          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              style={{ marginTop: '12px' }}
              control={(
                <Field
                  name="isInvoiceIssuer"
                  component={RenderCheckbox}
                  setValue={setIssuer}
                  inputProps={{
                    value: issuer,
                  }}
                  label="Emite Facturas" />
              )}
              label={(
                <Typography variant="subtitle2" className={classes.radioLabels}>
                  Emite Facturas
                </Typography>
              )} />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={(
                <Field
                  name="isDefault"
                  component={RenderCheckbox}
                  inputProps={{
                    value: isDefault,
                  }}
                  setValue={setDefault}
                  label="Es Default" />
              )}
              label={(
                <Typography variant="subtitle2" className={classes.radioLabels}>
                  Es Default
                </Typography>
              )} />
          </Grid>
          <Grid
            item
            xs={12}>
            <InputLabel
              style={{ marginTop: '12px' }}
              htmlFor="nameCompany"
              className={classes.label}>
              RFC:
            </InputLabel>
            <Field
              name="tax"
              component={RenderTextField}
              inputProps={{ value: tax }}
              placeholder="Ingresa el RFC"
              helperText={helperTexts.tax_id_number}
              setTextContent={setTax}
              fullWidth
              style={colorText}
              className={classes.formField}
              type="text" />
          </Grid>
          <Grid item xs={12}>
            <div className={classes.actions}>
              <SecondaryButton
                styles={{
                  marginTop: '4px',
                  marginRight: '12px',
                }}
                fullWidth={false}
                size="medium"
                variant="contained"
                type="button"
                onClick={handleReset}
                text="CANCELAR" />
              <PrimaryButton
                size="medium"
                variant="contained"
                type="submit"
                disabled={pristine || submitting}
                text="CREAR EMPRESA" />
            </div>
          </Grid>
        </form>

      </Grid>
    </div>
  );
}
CreateCompany.defaultProps = {
  setName: () => {},
  name: '',
  setTax: () => {},
  tax: '',
  setIssuer: () => {},
  issuer: null,
  setDefault: () => {},
  isDefault: null,
  helperTexts: {},
};


CreateCompany.propTypes = {
  resetEdit: PropTypes.func.isRequired,
  editing: PropTypes.bool.isRequired,
  setName: PropTypes.func,
  name: PropTypes.string,
  setTax: PropTypes.func,
  tax: PropTypes.string,
  setIssuer: PropTypes.func,
  issuer: PropTypes.bool,
  setDefault: PropTypes.func,
  isDefault: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  handleSend: PropTypes.func.isRequired,
  helperTexts: PropTypes.instanceOf(Object),
};


const CreateCompanyForm = reduxForm({
  form: 'CreateCompany',
  validate,
})(CreateCompany);

export default CreateCompanyForm;
