import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Tooltip, IconButton } from '@material-ui/core';
import { QueryBuilder } from '@material-ui/icons';

import { titleCustom } from 'utils/customTitleBioColor';

const useStyles = makeStyles(() => ({
  time: {
    color: '#3F90BB',
  },
  barrier: {
    borderRight: '2px solid #9A9895',
    margin: '0 5px',
  },
  icon: {
    width: '18px',
    height: '18px',
  },
  iconBtn: {
    padding: '0 7px',
    height: '20px',
    marginTop: '2px',
  },
}));

const IconTime = (props) => {
  const classes = useStyles();
  const { status, times } = props;

  const checkStatus = () => {
    switch (status) {
      case 1:
        return <Tooltip title={titleCustom('Tiempo', times)}><QueryBuilder className={`${classes.time} ${classes.icon}`} /></Tooltip>;
      case 2:
        return <Tooltip title={titleCustom('Tiempo', times)}><QueryBuilder className={`${classes.time} ${classes.icon}`} /></Tooltip>;
      case 3:
        return <Tooltip title={titleCustom('Tiempo', times)}><QueryBuilder className={`${classes.time} ${classes.icon}`} /></Tooltip>;
      case 4:
        return <Tooltip title={titleCustom('Tiempo', times)}><QueryBuilder className={`${classes.time} ${classes.icon}`} /></Tooltip>;
      default:
        return <Tooltip title={titleCustom('Tiempo', times)}><QueryBuilder className={`${classes.time} ${classes.icon}`} /></Tooltip>;
    }
  };

  return (
    <IconButton aria-label="icons-tools" className={classes.iconBtn}>
      {checkStatus()}
    </IconButton>
  );
};

IconTime.propTypes = {
  status: PropTypes.number.isRequired,
  times: PropTypes.string.isRequired,
};

export default IconTime;
