import { CREATE_PROJECTS, UPDATE_PROJECTS, GET_PROJECT } from 'constants/project';

/*
 * Reducer Projects
 */

const initialState = {
  isLoading: false,
  data: null,
  error: null,
};

const project = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case `${CREATE_PROJECTS}_REJECTED`: {
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    }
    case `${CREATE_PROJECTS}_PENDING`: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case `${CREATE_PROJECTS}_FULFILLED`: {
      return {
        ...state,
        isLoading: false,
        data: payload.data,
        error: null,
      };
    }
    case `${UPDATE_PROJECTS}_REJECTED`: {
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    }
    case `${UPDATE_PROJECTS}_PENDING`: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case `${UPDATE_PROJECTS}_FULFILLED`: {
      return {
        ...state,
        isLoading: false,
        data: payload.data,
        error: null,
      };
    }
    case `${GET_PROJECT}_REJECTED`: {
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    }
    case `${GET_PROJECT}_PENDING`: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case `${GET_PROJECT}_FULFILLED`: {
      const { data: { results } } = payload;
      const data = results.length > 0 ? results[0] : null;
      return {
        ...state,
        isLoading: false,
        data,
        error: data ? null : { status: 404, message: 'Projecto no encontrado.', data: [] },
      };
    }
    default:
      return state;
  }
};

export default project;
