import instanceAPI from 'services/apis/axiosconfig';
/*
 * This function makes a request GET for a task tags.
 *
 * @param {string} account - account related to user.
 * @param {number} taskId - Task id.
 *
 */
export const getTaskTagsAPI = (account, taskId) => {
  const request = instanceAPI.get(
    `/accounts/${account}/task/${taskId}/tags`,
  );
  return request;
};

export default getTaskTagsAPI;
