import { makeStyles } from '@material-ui/core';
import colors from 'constants/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: '15pt',
  },
  company: {
    width: '250px',
  },
  margin: {
    margin: theme.spacing(1),
    height: '25px',
  },
  form: {
    width: '100%',
  },
  formField: {
    width: '55%',
    [theme.breakpoints.down('sm')]: {
      width: '88%',
    },
  },
  label: {
    fontSize: '13pt',
    fontWeight: 'bold',
    color: colors.sixth,
  },
  radioLabels: {
    fontWeight: 'bold',
    fontSize: '13pt',
  },
  actions: {
    float: 'right',
    marginRight: '15%',
    marginTop: '12px',
  },
}));


export default useStyles;
