import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Transition } from 'react-transition-group';
import HeaderContainer from 'containers/header';
import MenuContainer from 'containers/menu/index';
import useOpenClose from 'hooks/useOpenClose';
import classNames from 'classnames';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import NotificationContainer from 'containers/notificationsContainer/index';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Button from '@material-ui/core/Button';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useHistory } from 'react-router-dom';
import Footer from 'components/footer/index';
import useStyles from './styles';

export default function Dashboard(props) {
  const classes = useStyles();
  const history = useHistory();
  const {
    idItemDetail,
    componentList: ComponentList,
    componentDetail: ComponentDetail,
    propsList,
    titleHeader,
  } = props;

  const {
    open: openMenu,
    handleClose: handleCloseMenu,
    handleOpen: handleOpenMenu,
  } = useOpenClose();

  const {
    open: openNotifications,
    handleClose: handleCloseNotifications,
    handleOpen: handleOpenNotifications,
  } = useOpenClose(false);

  const handleClickMenu = () => {
    if (openMenu) {
      handleCloseMenu();
    } else {
      handleOpenMenu();
    }
  };

  const detailsColumnContainerClasses = {
    entered: classes.detailsColumnActive,
  };

  const listColumnClasses = {
    exited: classes.listColumnInactive,
  };

  const handleReturnToList = () => {
    const { pathname } = window.location;
    const parameter = pathname.substring(pathname.lastIndexOf('/'));
    const newURL = pathname.split(parameter);
    const [url] = newURL;
    history.push(url);
  };

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  let style;
  if (!sm) {
    style = {
      display: 'flex',
      flex: 1,
    };
  } else {
    style = {
      display: 'flex',
    };
  }

  return (
    <div className={classes.root}>
      <HeaderContainer
        title={titleHeader}
        openMenu={openMenu}
        handleClickMenu={handleClickMenu}
        openNotifications={openNotifications}
        handleOpenNotifications={handleOpenNotifications}
        haveNotifications />
      <NotificationContainer
        handleClose={handleCloseNotifications}
        open={openNotifications} />
      <Grid
        className={classes.independentScrollItems}
        container
        direction="row">
        <MenuContainer
          open={openMenu}
          handleOpen={handleOpenMenu}
          handleClose={handleCloseMenu} />
        <div
          className={clsx(classes.content, {
            [classes.contentShift]: openMenu,
          })}
          style={style}>
          <Grid container direction="row">
            <Transition in={!idItemDetail} timeout={0} appear>
              { (state) => (
                <Grid
                  elevation={0}
                  item
                  style={{ paddingBottom: '0', borderRight: 'solid 1px' }}
                  xs={12}
                  md={idItemDetail ? 5 : 12}
                  className={classNames(
                    classes.listColumn,
                    listColumnClasses[state],
                  )}>
                  {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                  <ComponentList handleClose={handleCloseMenu} default={propsList} />
                </Grid>
              )}
            </Transition>
            { idItemDetail ? (
              <Transition in={Boolean(idItemDetail)} timeout={0}>
                { (state) => (
                  <Grid
                    elevation={0}
                    item
                    xs={12}
                    md={7}
                    id="scroll-container"
                    className={classNames(
                      classes.detailsColumnContainer,
                      detailsColumnContainerClasses[state],
                    )}>
                    {Boolean(idItemDetail) && (
                      <Button
                        className={classes.detailsShowReturnColumnContainer}
                        onClick={handleReturnToList}>
                        <ArrowBackIosIcon
                          className={classes.detailsShowIconReturnColumnContainer} />
                        <ArrowBackIosIcon
                          className={classes.detailsShowIconReturnColumnContainer} />
                        <span>Regresar al listado de tareas</span>
                      </Button>
                    )}
                    <ComponentDetail />
                  </Grid>
                )}
              </Transition>
            ) : null}
          </Grid>
        </div>
      </Grid>
      <Footer />
    </div>
  );
}

Dashboard.propTypes = {
  propsList: PropTypes.instanceOf(Object),
  titleHeader: PropTypes.string.isRequired,
  idItemDetail: PropTypes.string,
  componentList: PropTypes.instanceOf(Object).isRequired,
  componentDetail: PropTypes.instanceOf(Object),
};

Dashboard.defaultProps = {
  propsList: {},
  idItemDetail: null,
  componentDetail: null,
};
