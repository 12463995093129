import { createAccountAPI } from 'services/apis/createAccount';
import { CREATE_ACCOUNT } from 'constants/createAccount';

/*
 * @property {string} name - Account's name
 * @property {string} description . Account's description
 * @property {Object} currency - Account's currency
 *
 *
 * This function create an account
 *
 */

export const create = (name, description, currency) => ({
  type: CREATE_ACCOUNT,
  payload: createAccountAPI(name, description, currency),
});
