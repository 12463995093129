/* ---------------------------------------------------------
 * Reducer for conversations
 * --------------------------------------------------------- */
import {
  RESET_COMPANY,
  GET_COMPANY,
  UPDATE_COMPANY,
  CREATE_COMPANY,
  DELETE_COMPANY,
} from 'constants/companies';


const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

const company = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case `${GET_COMPANY}_REJECTED`:
      return {
        ...state,
        isLoading: false,
        error: {
          ...payload,
          fromAction: GET_COMPANY,
        },
      };
    case `${UPDATE_COMPANY}_REJECTED`:
      return {
        ...state,
        isLoading: false,
        error: {
          ...payload,
          fromAction: UPDATE_COMPANY,
        },
      };
    case `${CREATE_COMPANY}_REJECTED`:
      return {
        ...state,
        isLoading: false,
        error: {
          ...payload,
          fromAction: CREATE_COMPANY,
        },
      };
    case `${DELETE_COMPANY}_REJECTED`:
      return {
        ...state,
        isLoading: false,
        error: {
          ...payload,
          fromAction: DELETE_COMPANY,
        },
      };
    case `${GET_COMPANY}_PENDING`:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case `${GET_COMPANY}_FULFILLED`: {
      return {
        ...state,
        isLoading: false,
        error: null,
        data: payload.data,
      };
    }
    case `${UPDATE_COMPANY}_PENDING`:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case `${UPDATE_COMPANY}_FULFILLED`:
      return {
        ...state,
        isLoading: false,
        error: null,
        data: payload.data,
      };
    case `${CREATE_COMPANY}_PENDING`:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case `${CREATE_COMPANY}_FULFILLED`:
      return {
        ...state,
        isLoading: false,
        error: null,
        data: payload.data,
      };
    case `${DELETE_COMPANY}_PENDING`:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case `${DELETE_COMPANY}_FULFILLED`:
      return {
        ...state,
        isLoading: false,
        error: null,
        data: null,
      };
    case RESET_COMPANY: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

export default company;
