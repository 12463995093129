import React from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import useStyles from './styles';

export const PrimaryButton = (props) => {
  const {
    size,
    type,
    variant,
    text,
    disabled,
    onClick,
  } = props;
  const classes = useStyles();

  const variants = {
    outlined: classes.outlined,
    contained: classes.contained,
  };

  return (
    <Button
      size={size}
      variant={variant}
      type={type}
      className={variants[variant]}
      disabled={disabled}
      onClick={onClick}>
      { text }
    </Button>
  );
};

PrimaryButton.defaultProps = {
  size: 'small',
  type: 'button',
  variant: 'outlined',
  disabled: 'false',
  onClick: () => {},
};

PrimaryButton.propTypes = {
  type: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  variant: PropTypes.string,
};

export default PrimaryButton;
