import instanceAPI from 'services/apis/axiosconfig';

/*
 * @typedef {Object} dataTask
 * @property {string} concept - Concept task, field required.
 * @property {string} project_id - Task's project, field required.
 * @property {number} responsible - Task's responsible, field optional.
 * @property {number} petitioner - Task's petitioner, field optional.
 * @property {date} start_date - Task's start date, field optional.
 * @property {date} delevery_date - Task's delevery date, field optional.
 * @property {number} status_id - Task's status, field.
 * @property {number} priority_id - Task's priority, field optional.
 * @property {number} solicitude_type_d - Task's solicitude type, field optional.
 * @property {number} task_expense_source_id  - Task's expense source id , field optional.
 * @property {number} estimated_time optional - Task's estimated time, field optional.
 * @property {boolean} is_chargeable - Flag to identify if the task is chargeable to client,optional
 * @property {boolean} is_pagable  - Flag to identify if the task is pagable, field optional.
 * @property {boolean} is_visible - Flag to identify if the task is visible by has_inside_view users
 * @property {number} timesheet_time - Time in minutes(>0)(>0) for dedicated time, field optional.
 * @property {date} timesheet_date - Date for dedicated time, field optional.
 * @property {number} timesheet_on_behalf_of - User id about whom was made the timesheet,optional.
 *
 *
 * This function make request post to create a Task.
 *
 * @param {string} account - account related to user.
 * @param {dataTask} data - Data for creating a task.
 *
 */
export function createTaskAPI(account, dataTask) {
  return instanceAPI.post(
    `/accounts/${account}/tasks`,
    dataTask,
  ).then((response) => response).catch((response) => {
    const {
      data,
      status,
    } = response;

    return {
      data,
      status,
    };
  });
}

/*
 * This function makes a request GET for a specific task by id.
 *
 * @param {string} account - account related to user.
 * @param {number} id - Task's id.
 *
 */
export function getTaskAPI(account, id) {
  const request = instanceAPI.get(
    `/accounts/${account}/tasks?id=${id}&include=projects,groups,priorities,statuses,responsibles,creators,petitioners,timesheets`,
  ).then((response) => response).catch((response) => {
    const {
      data,
      status,
    } = response;

    return {
      data,
      status,
    };
  });
  return request;
}

/*
 * @typedef {Object} dataTask
 * @property {string} concept - Concept task, field required.
 * @property {string} project_id - Task's project, field required.
 * @property {number} responsible - Task's responsible, field optional.
 * @property {number} petitioner - Task's petitioner, field optional.
 * @property {date} start_date - Task's start date, field optional.
 * @property {date} delevery_date - Task's delevery date, field optional.
 * @property {number} status_id - Task's status, field.
 * @property {number} priority_id - Task's priority, field optional.
 * @property {number} solicitude_type_d - Task's solicitude type, field optional.
 * @property {number} task_expense_source_id  - Task's expense source id , field optional.
 * @property {number} estimated_time optional - Task's estimated time, field optional.
 * @property {boolean} is_chargeable - Flag to identify if the task is chargeable to client,optional
 * @property {boolean} is_pagable  - Flag to identify if the task is pagable, field optional.
 * @property {boolean} is_visible - Flag to identify if the task is visible by has_inside_view users
 * @property {number} timesheet_time - Time in minutes(>0)(>0) for dedicated time, field optional.
 * @property {date} timesheet_date - Date for dedicated time, field optional.
 * @property {number} timesheet_on_behalf_of - User id about whom was made the timesheet, optional.
 *
 *
 * This function makes a request PUT updating a specific task by id.
 *
 * @param {string} account - account related to user.
 * @param {number} id - Task's id.
 * @param {dataTask} data - Data for updating a task.
 *
 */
export function updateTaskAPI(account, id, dataTask) {
  const request = instanceAPI.put(`/accounts/${account}/tasks/${id}`, dataTask).then((response) => response);
  return request;
}

export default getTaskAPI;
