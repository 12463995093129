import { makeStyles } from '@material-ui/core/styles';
import colors from 'constants/colors';

const useStyles = makeStyles(() => ({
  timelineDot: {
    width: '40px',
    height: '40px',
    backgroundColor: colors.primary,
  },
  avatar: {
    width: '40px',
    height: '40px',
    fontSize: '12pt',
    backgroundColor: colors.secondary,
  },
  secondaryBackground: {
    backgroundColor: colors.secondary,
  },
  lastConnector: {
    backgroundColor: colors.secondary,
    height: '100%',
  },
}));

export default useStyles;
