import React from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

export default function Error(props) {
  const { message } = props;

  return (
    <Grid item>
      <center>
        <ErrorOutlineIcon color="secondary" style={{ fontSize: 40 }} />
      </center>
      <Typography variant="body1">
        {message}
      </Typography>
    </Grid>
  );
}

Error.propTypes = {
  message: PropTypes.string.isRequired,
};
