import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { PrimaryButton } from 'components/primaryButton/index';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: '#E26376',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export function TransparentDialog(props) {
  const {
    fullWidth,
    disableBackdropClick,
    maxWidth,
    children,
    handleClose,
    open,
    actionText,
    buttonProps,
  } = props;

  return (
    <div>
      <Dialog
        onClose={handleClose}
        style={{
          backgroundColor: 'rgba(0,0,0,0.8)',
        }}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        }}
        aria-labelledby="dialog-register-user"
        open={open}
        disableBackdropClick={disableBackdropClick}
        fullWidth={fullWidth}
        maxWidth={maxWidth}>
        <DialogTitle
          id="dialog-register-user-title"
          onClose={() => handleClose()} />
        <DialogContent>
          {children}
        </DialogContent>
        { (actionText && buttonProps) && (
        <DialogActions>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="stretch">
            <Grid item xs={8}>
              <center>
                <PrimaryButton
                  size="medium"
                  type={buttonProps.type}
                  disabled={buttonProps.disabled}
                  text={actionText}
                  onClick={buttonProps.onClick} />
              </center>
            </Grid>
          </Grid>
        </DialogActions>
        )}
      </Dialog>
    </div>
  );
}

TransparentDialog.defaultProps = {
  fullWidth: true,
  disableBackdropClick: true,
  maxWidth: 'sm',
  actionText: '',
  buttonProps: null,
};

TransparentDialog.propTypes = {
  fullWidth: PropTypes.bool,
  disableBackdropClick: PropTypes.bool,
  maxWidth: PropTypes.string,
  children: PropTypes.instanceOf(Object).isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  actionText: PropTypes.string,
  buttonProps: PropTypes.shape({
    fullWidth: PropTypes.bool,
    type: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
  }),
};

export default TransparentDialog;
