/**
 * Task selector
 *
 * This function create an selector
 *
 */

export const selectorTask = (state) => state.task;
export const selectorTaskCreatedAt = (state) => (
  state.task.data ? state.task.data.created_at : null
);

export default selectorTask;
