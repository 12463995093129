import React from 'react';
import PropTypes from 'prop-types';

const TaskItem = ({ entity: { concept } }) => <div>{`${concept}`}</div>;

TaskItem.propTypes = {
  entity: PropTypes.shape({
    concept: PropTypes.string.isRequired,
  }).isRequired,
};

export default TaskItem;
