import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

export default function RenderTextField(props) {
  const {
    label,
    helperText,
    style,
    input,
    inputProps: { value },
    placeholder,
    fullWidth,
    meta: { touched, invalid, error },
    className,
    variant,
    color,
    disabled,
    type,
    setTextContent,
    id,
    autocomplete,
  } = props;

  const [content, setContent] = useState(value);

  useEffect(() => {
    setContent(value);
    input.onChange(value);
  }, [input, input.onChange, value]);


  let errorText = touched && error;
  if (helperText !== '') {
    errorText = helperText;
  }

  const handleChangeContent = (e) => {
    setContent(e.target.value);
    setTextContent(e.target.value);
    input.onChange(e.target.value);
  };

  return (
    <TextField
      disabled={disabled}
      label={label}
      error={(touched && invalid) || helperText !== ''}
      variant={variant}
      color={color}
      helperText={errorText}
      name={input.name}
      onBlur={input.onBlur}
      onChange={(e) => handleChangeContent(e)}
      onDragStart={input.onDragStart}
      onDrop={input.onDrop}
      onFocus={input.onFocus}
      value={content || ''}
      placeholder={placeholder}
      inputProps={{
        style,
      }}
      fullWidth={fullWidth}
      className={className}
      type={type}
      id={id}
      autoComplete={autocomplete} />
  );
}

RenderTextField.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  style: PropTypes.shape({}),
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onDragStart: PropTypes.func.isRequired,
    onDrop: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    value: PropTypes.string,
  }).isRequired,
  inputProps: PropTypes.shape({
    value: PropTypes.string,
  }),
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    invalid: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
  type: PropTypes.string,
  setTextContent: PropTypes.func,
  id: PropTypes.string,
  autocomplete: PropTypes.string,
};

RenderTextField.defaultProps = {
  disabled: false,
  label: '',
  style: {},
  fullWidth: true,
  placeholder: '',
  helperText: '',
  variant: 'standard',
  color: 'primary',
  className: '',
  inputProps: {
    value: '',
  },
  type: 'text',
  setTextContent: () => {},
  id: '',
  autocomplete: '',
};
