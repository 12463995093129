import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { reset } from 'redux-form';
import { selectorGetRoles } from 'services/selectors/roles';
import { selectorActualAccountName } from 'services/selectors/account';
import { selectorGetCompanies } from 'services/selectors/companies';
import { selectorProject } from 'services/selectors/project';
import { selectorProjectRoleOfUser } from 'services/selectors/projectPrivileges';
import { selectorUserProfileId } from 'services/selectors/userProfile';
import { getCompanies, resetCompanies } from 'services/actions/companies';
import { getRoles, resetRoles } from 'services/actions/roles';
import { projectStatuses } from 'constants/projectStatusData';
import UpdateProjectForm from 'components/updateProjectForm/index';

const UpdateProjectContainer = (props) => {
  const { optionMenu, handleSubmit, helperTexts } = props;

  const account = useSelector(selectorActualAccountName);
  const dispatch = useDispatch();
  const project = useSelector(selectorProject);
  const companies = useSelector(selectorGetCompanies);
  const roles = useSelector(selectorGetRoles);
  const profileId = useSelector(selectorUserProfileId);
  const userRole = useSelector((state) => selectorProjectRoleOfUser(state, profileId));
  const startDateFormat = moment(project.data.start_date).format('YYYY-MM-DD');
  const endDateFormat = moment(project.data.end_date).format('YYYY-MM-DD');

  const [projectName, setProjectName] = useState(project.data.name);
  const [startDate, setStartDate] = useState(startDateFormat);
  const [endDate, setEndDate] = useState(endDateFormat);
  const [statusProject, setStatusProject] = useState('');
  const [idStatus, setIdStatus] = useState(0);
  const [projectKey, setProjectKey] = useState(project.data.key);
  const [requirements, setRequirements] = useState(project.data.conclusion_requirements);
  const [companyAutocomplete, setCompanyAutocomplete] = useState('');
  const [idCompanyAutocomplete, setIdCompanyAutocomplete] = useState(0);
  const [defaultTicket, setDefaultTicket] = useState(project.data.default_ticket);
  const [projectDescription, setProjectDescription] = useState(project.data.description);
  const [rolAutocomplete, setRolAutocomplete] = useState('');
  const [idRolAutocomplete, setIdRolAutocomplete] = useState(0);

  useEffect(() => {
    dispatch(reset('UpdateProjectForm'));
    setIdStatus(project.data.status_id);
    setStatusProject(project.data.projects_status.name);
    setIdRolAutocomplete(userRole.id);
    setRolAutocomplete(userRole.name);
    setCompanyAutocomplete(project.data.company.name);
    setIdCompanyAutocomplete(project.data.invoiced_by);
    dispatch(getCompanies(account, 0, 500));
    dispatch(getRoles(account, 0, 500));
  }, [dispatch, account, project, userRole]);

  useEffect(() => {
    dispatch(resetRoles());
    dispatch(resetCompanies());
    dispatch(reset('UpdateProjectForm'));
  }, [dispatch]);

  const handleReset = () => {
    dispatch(reset('UpdateProjectForm'));
    optionMenu(null);
  };

  return (
    <div>
      <UpdateProjectForm
        handleSend={handleSubmit}
        helperTexts={helperTexts}
        handleReset={handleReset}
        idStatus={idStatus}
        setIdStatus={setIdStatus}
        setStatus={setStatusProject}
        status={statusProject}
        projectStatuses={projectStatuses}
        companyLabel={companyAutocomplete}
        changeCompanyLabel={setCompanyAutocomplete}
        idCompany={idCompanyAutocomplete}
        setIdCompany={setIdCompanyAutocomplete}
        companies={companies}
        roles={roles}
        rolLabel={rolAutocomplete}
        changeRolLabel={setRolAutocomplete}
        idRol={idRolAutocomplete}
        setIdRol={setIdRolAutocomplete}
        nameProject={projectName}
        setNameProject={setProjectName}
        projectDescription={projectDescription}
        setProjectDescription={setProjectDescription}
        projectKey={projectKey}
        setProjectKey={setProjectKey}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        requirements={requirements}
        setRequirements={setRequirements}
        defaultTicket={defaultTicket}
        setDefaultTicket={setDefaultTicket} />
    </div>
  );
};

UpdateProjectContainer.defaultProps = {
  optionMenu: () => { },
};

UpdateProjectContainer.propTypes = {
  optionMenu: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  helperTexts: PropTypes.instanceOf(Object).isRequired,
};

export default UpdateProjectContainer;
