import { GET_TASK, UPDATE_TASK, CREATE_TASK } from 'constants/task';
/*
* Reducer Task
*/
const initialState = {
  isLoading: false,
  data: null,
  error: null,
};

const task = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case `${CREATE_TASK}_REJECTED`: {
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    }
    case `${CREATE_TASK}_PENDING`: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case `${CREATE_TASK}_FULFILLED`: {
      return {
        ...state,
        isLoading: false,
        data: payload.data,
        error: null,
      };
    }
    case `${UPDATE_TASK}_REJECTED`: {
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    }
    case `${UPDATE_TASK}_PENDING`: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case `${UPDATE_TASK}_FULFILLED`: {
      return {
        ...state,
        isLoading: false,
        data: payload.data,
        error: null,
      };
    }
    case `${GET_TASK}_REJECTED`: {
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    }
    case `${GET_TASK}_PENDING`: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case `${GET_TASK}_FULFILLED`: {
      const { data: { results } } = payload;
      const data = results.length > 0 ? results[0] : null;
      return {
        ...state,
        isLoading: false,
        data,
        error: data ? null : { status: 404, message: 'Tarea no encontrada.', data: [] },
      };
    }
    default:
      return state;
  }
};

export default task;
