import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { Grid, Typography } from '@material-ui/core';


import { selectorActualAccountName } from 'services/selectors/account';
import { selectorCompany } from 'services/selectors/company';
import { getCompanies, createCompany, getCompany, resetCompanies, resetCompany, updateCompany } from 'services/actions/companies';

import Error from 'components/error/index';
import Loading from 'components/loading/index';
import FloatingMenu from 'components/floatingMenu/index';
import CreateCompanyContainer from 'containers/createCompany';
import SnackbarHendrix from 'components/snackbarHendrix/index';

import actionsCompanyMenu from 'constants/companyMenu';
import useBeforeUnload from 'hooks/useBeforeUnload';
import useOpenClose from 'hooks/useOpenClose';
import { Prompt } from 'react-router';
import Company from 'components/company/index';
import { reset } from 'redux-form';
import { selectorCompanies } from 'services/selectors/companies';

function CompanyContainer() {
  const { askBeforeUnload, removeAskBeforeUnload, setAskBeforeUnload } = useBeforeUnload(false);
  const { id } = useParams();
  const dispatch = useDispatch();

  const [activeOptionMenu, setActiveOptionMenu] = useState(null);

  const company = useSelector(selectorCompany);

  const actualAccountName = useSelector(selectorActualAccountName);

  const {
    pagination: {
      limit,
    },
    filter,
  } = useSelector(selectorCompanies);

  const [helperTexts, setHelperTexts] = useState({
    name: '',
    tax_id_number: '',
  });
  const [messageSubmit, setMessageSubmit] = useState();

  const {
    open: openSnackbar,
    handleClose: handleCloseSnackbar,
    handleOpen: handleOpenSnackbar,
  } = useOpenClose();

  const handleOptionMenu = (option) => {
    setActiveOptionMenu(option);
    if (option) {
      setAskBeforeUnload();
    } else {
      removeAskBeforeUnload();
    }
  };

  useEffect(() => {
    if (id !== 'new') {
      dispatch(getCompany(actualAccountName, id));
    }
    setActiveOptionMenu(null);
  }, [actualAccountName, id, dispatch]);

  useEffect(() => {
    removeAskBeforeUnload();
    setActiveOptionMenu(null);
    dispatch(resetCompany());
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [id]);

  if ((!company.data || company.isLoading) && id !== 'new') {
    return <Loading />;
  }

  if (company.error && company.error.status >= 500) {
    return (
      <Error message={company.error.message} />
    );
  }

  const handleReloadCompanies = () => {
    dispatch(resetCompanies());
    dispatch(
      getCompanies(
        actualAccountName,
        0,
        limit,
        '',
        filter,
      ),
    );
  };

  const handleSend = async (dataCompany) => {
    let data = {};
    let response;
    let status;

    if (dataCompany.companyStatus === 'editing') {
      data.id = id;
    }
    data.name = dataCompany.nameCompany;
    data.tax_id_number = dataCompany.tax;
    data.is_invoice_issuer = dataCompany.isInvoiceIssuer;
    data.is_default = dataCompany.isDefault;

    try {
      if (dataCompany.companyStatus === 'editing') {
        response = await dispatch(updateCompany(actualAccountName, data));
      } else {
        response = await dispatch(createCompany(actualAccountName, data));
      }
      status = response.action.payload.status;
    } catch (error) {
      status = error.status;
      data = error.data;
    }

    if (status >= 500 || status === 404) {
      dispatch(reset('CreateCompany'));
      setMessageSubmit('Hubo un error, por favor intenta más tarde.');
      handleOpenSnackbar();
    } else if (status > 399) {
      const errors = {};
      data.forEach((item) => {
        errors[item.param] = item.message;
      });
      setHelperTexts(errors);
    } else {
      setHelperTexts({});
      dispatch(reset('CreateCompany'));
      handleReloadCompanies();
      setMessageSubmit('La información de la empresa se ha registrado correctamente.');
      handleOpenSnackbar();
      setActiveOptionMenu(null);
    }
  };

  return (
    <>
      <Prompt
        when={askBeforeUnload}
        message="Tienes cambios sin guardar, ¿estás seguro de salir?" />
      <Grid container direction="column">
        { id !== 'new'
          && (
          <FloatingMenu
            onAction={handleOptionMenu}
            actions={actionsCompanyMenu}
            disabled={activeOptionMenu !== null} />
          )}

        { activeOptionMenu === 'Editar empresa' && id !== 'new' ? (
          <CreateCompanyContainer
            handleSend={handleSend}
            editing
            helperTexts={helperTexts}
            resetEdit={setActiveOptionMenu} />
        ) : ''}

        { activeOptionMenu === null && id !== 'new' ? (
          <Company data={company.data} />
        ) : ''}
        { id === 'new' ? (
          <CreateCompanyContainer
            handleSend={handleSend}
            editing={false}
            helperTexts={helperTexts}
            resetEdit={setActiveOptionMenu} />
        ) : ''}

      </Grid>
      <SnackbarHendrix
        open={openSnackbar}
        handleClose={handleCloseSnackbar}>
        <Typography variant="body2" styles={{ color: '#FFFFFF' }}>
          {messageSubmit}
        </Typography>
      </SnackbarHendrix>
    </>
  );
}

export default CompanyContainer;
