import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

// Own constants/actions/components
import LoginContainer from 'containers/loginContainer/index';
import NotFound from 'components/notFound/index';
import DashboardContainer from 'containers/dashboard';

const Router = () => (
  <BrowserRouter>
    <Switch>
      <Redirect from="/" to="/login" exact />
      <Route path="/login/:token?">
        <LoginContainer />
      </Route>
      <Route path="/register/:registerToken?">
        <LoginContainer />
      </Route>
      <Route exact path="/dashboard/*/:id">
        <DashboardContainer />
      </Route>
      <Route exact path="/dashboard/*">
        <DashboardContainer />
      </Route>
      <Route component={NotFound} />
    </Switch>
  </BrowserRouter>
);

export default Router;
