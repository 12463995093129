import instanceAPI from 'services/apis/axiosconfig';

/*
 * This function makes a request POST a project.
 *
 * @typedef {Object} dataProject
 * @property {string} name - Name (unique) for the project, field required.
 * @property {string} key - Key (unique) for the project, field required.
 * @property {date} start_date - Date when start the project, field required.
 * @property {date} end_date - Date when finish the project, field required.
 * @property {integer} status_id - Status for project, field required.
 * @property {string} conclusion_requirements - Specif. to terminate the project, field optional.
 * @property {integer} invoiced_by - Company who paid this project, field required.
 * @property {string} default_ticket - Text for default description ticket, field optional.
 * @property {string} description - Text for describing the project, field optional.
 * @property {integer} role_id  - Role to take the user in this project, field required.
 *
 *
 * This function make request post to create a Project.
 *
 * @param {string} account - account related to user.
 * @param {data} dataProject - Data for creating a project.
 *
 */

export const createProjectAPI = (account, dataProject) => {
  const request = instanceAPI.post(
    `/accounts/${account}/projects`, dataProject,
  );
  return request;
};

/*
 * This function makes a request PUT a project.
 *
 * @typedef {Object} dataProject
 * @property {string} name - Name (unique) for the project, field required.
 * @property {string} key - Key (unique) for the project, field required.
 * @property {date} start_date - Date when start the project, field required.
 * @property {date} end_date - Date when finish the project, field required.
 * @property {integer} status_id - Status for project, field required.
 * @property {string} conclusion_requirements - Specif. to terminate the project, field optional.
 * @property {integer} invoiced_by - Company who paid this project, field required.
 * @property {string} default_ticket - Text for default description ticket, field optional.
 * @property {string} description - Text for describing the project, field optional.
 * @property {integer} role_id  - Role to take the user in this project, field required.
 * @property {string} message - When the project changes its status to inactive,
 * this is used for notificate to all stake holder that the project is inactive.
 *
 *
 * This function make request put to update a Project.
 *
 * @param {string} account - account related to user.
 * @param {data} dataProject - Data for updating a project.
 *
 */

export const updateProjectAPI = (account, projectId, dataTask) => {
  const request = instanceAPI.put(
    `/accounts/${account}/projects/${projectId}?include=companies,projects_statuses`, dataTask,
  )
    .then((response) => response);
  return request;
};

/*
 * This function makes a request GET for a specific project by id.
 *
 * @param {string} account - account related to user.
 * @param {number} id - Project id.
 *
 */

export function getProjectAPI(account, id) {
  const request = instanceAPI.get(
    `/accounts/${account}/projects?id=${id}&include=companies,projects_statuses`,
  );
  return request;
}
