import React, { useState } from 'react';
import { withStyles, Grid, Button, FormControlLabel, Typography } from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';

import { required, userAccount } from 'utils/formValidation';

import RenderTextField from 'components/renderTextField';
import RenderPasswordField from 'components/renderPasswordField';
import RenderCheckbox from 'components/renderCheckbox';

import logoLogin from 'assets/img/md_vo_login.png';

const CustomFieldPassword = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#6F6E6B',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#9A9895',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#9A9895',
    },
    color: '#9A9895',
  },
})(RenderPasswordField);

const styles = () => ({
  root: {
    flexGrow: 1,
  },
  container: {
    paddingTop: '180px',
  },
  gridItemRight: {
    display: 'flex',
  },
  buttonAlignRight: {
    marginLeft: 'auto',
  },
  fieldText: {
    '& label.Mui-focused': {
      color: '#6F6E6B',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#9A9895',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#9A9895',
    },
    paddingTop: '15px',
    paddingBottom: '15px',
  },
  lockIcon: {
    color: '#9A9895',
    marginLeft: '140px',
  },
  form: {
    width: '300px',
  },
  buttonRecoverPassword: {
    fontWeight: 'bold',
    color: '#9A9895',
    fontSize: '11px',
    paddingBottom: '0px',
    paddingRight: '0px',
  },
  buttonSession: {
    fontWeight: 'bold',
    color: '#9A9895',
  },
  logo: {
    width: '235px',
    height: '45px',
    paddingTop: '20px',
  },
  buttonLogin: {
    fontSize: '13px',
    marginTop: '30px',
    marginLeft: '20px',
    color: '#E26376',
    border: '1px solid #E26376',
    fontWeight: 'bold',
    paddingRight: '16px',
    paddingLeft: '16px',
    '& .MuiButton-outlined .Mui-disabled': {
      color: '#9A9895',
      border: '1px solid #9A9895',
    },
    '&:hover': {
      backgroundColor: 'rgba(198,99,117,0.04)',
    },
  },
  buttonRegisterUser: {
    marginTop: '30px',
    width: '100%',
    color: '#FFFFFF',
    backgroundColor: '#CBC9C6',
    fontWeight: 'bold',
    fontSize: '13px',
  },
});

const Login = (props) => {
  const colorText = { color: '#6F6E6B' };

  const {
    handleSubmit,
    pristine,
    submitting,
    classes,
    onSubmit,
    handleOpenRecoverPassword,
    handleOpenCreateUser,
    checkboxStatus,
    setCheckbox,
  } = props;

  const [username, setUsername] = useState('');

  return (
    <div>
      <Grid
        container
        spacing={0}
        alignItems="center"
        justify="center"
        alignContent="center"
        className={classes.container}>
        <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
          <Grid item xs={12}>
            <Grid item xs={12} className={classes.lockIcon}>
              <span className="material-icons-outlined">lock</span>
            </Grid>
            <Grid item xs={12}>
              <Field
                name="usernameEmail"
                id="usernameEmail"
                component={RenderTextField}
                placeholder="Correo electrónico"
                validate={[required, userAccount]}
                className={classes.fieldText}
                inputProps={{ value: username }}
                setTextContent={setUsername}
                style={colorText}
                autocomplete="username" />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <Field
                name="password"
                type="password"
                id="pasword"
                component={CustomFieldPassword}
                autocomplete="current-password"
                placeholder="Contraseña"
                validate={[required]}
                style={colorText} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={(
                <Field
                  name="shouldRemember"
                  component={RenderCheckbox}
                  label="Recordar sesión"
                  setValue={setCheckbox}
                  inputProps={{ value: checkboxStatus }} />
                )}
              label={(
                <Typography variant="caption" className={classes.buttonSession}>
                  Recordar sesión
                </Typography>
                )} />
            <Button
              disableFocusRipple
              disableRipple
              variant="text"
              onClick={handleOpenRecoverPassword}
              className={classes.buttonRecoverPassword}>
              RECUPERAR CONTRASEÑA
            </Button>
          </Grid>
          <Grid
            container
            alignItems="center"
            justify="center"
            alignContent="center">
            <Grid item xs={6}>
              <Button
                variant="outlined"
                type="submit"
                className={classes.buttonLogin}
                disabled={
                    submitting || pristine
                  }>
                INGRESAR
              </Button>
            </Grid>
            <Grid item xs={9}>
              <Button
                variant="contained"
                className={classes.buttonRegisterUser}
                onClick={handleOpenCreateUser}
                fullWidth>
                Registrar usuario nuevo
              </Button>
            </Grid>
            <Grid item xs={9}>
              <img src={logoLogin} className={classes.logo} alt="manoderecha vincoorbis" />
            </Grid>
          </Grid>
        </form>
      </Grid>
    </div>
  );
};

Login.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loginUser: PropTypes.func, // eslint-disable-line
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  classes: PropTypes.instanceOf(Object).isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleOpenRecoverPassword: PropTypes.func.isRequired,
  handleOpenCreateUser: PropTypes.func.isRequired,
  checkboxStatus: PropTypes.bool.isRequired,
  setCheckbox: PropTypes.func.isRequired,
};

export default withStyles(styles)(reduxForm({
  form: 'loginForm',
})(Login));
