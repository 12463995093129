import { GET_USER_PROFILE, RESET_USER_PROFILE } from 'constants/userProfile';
/*
* Reducer user profile
*/
const initialState = {
  isLoading: false,
  data: null,
  error: null,
};

const userProfile = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case `${RESET_USER_PROFILE}`:
      return { ...initialState };
    case `${GET_USER_PROFILE}_REJECTED`: {
      return {
        ...state,
        isLoading: false,
        error: payload.data,
      };
    }
    case `${GET_USER_PROFILE}_PENDING`: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case `${GET_USER_PROFILE}_FULFILLED`: {
      return {
        ...state,
        isLoading: false,
        data: payload.data,
        error: null,
      };
    }
    default:
      return state;
  }
};

export default userProfile;
