import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      padding: '20px',
    },

    [theme.breakpoints.down('sm')]: {
      padding: '10px',
    },
  },
  name: {
    paddingRight: '10px',
    paddingLeft: '15px',
  },
  active: {
    background: '#eaeaea',
    padding: '20px',
  },
  avatar: {
    width: '32px',
    height: '32px',
    fontSize: '15pt',
  },
  conversation: {
    padding: '0px 0px 20px 0px',
    marginTop: '0px',
  },
  preview: {
    '& .MuiBox-root': {
      paddingBottom: '15',
    },
    '& h1': {
      fontSize: '1.2em',
    },
    '& img': { maxWidth: '100%', display: 'block' },
    '& h2': {
      fontSize: '1.2em',
      color: '#595454',
    },
    '& h3': {
      fontSize: '1em',
    },
    '& blockquote': {
      padding: '10px',
      fontSize: '1.2em',
      fontStyle: 'italic',
      background: '#e8e8e8',
    },
    '& a': {
      textDecoration: 'underline',
      color: '#0000ff',
    },
    '& ol': {
      fontSize: '1.2em',
      color: '#595454',
    },
    '& table': {
      padding: '10px',
      borderCollapse: 'collapse',
      border: '1px solid',
      borderspacing: '10px',
      fontSize: '0.8em',
      background: '#ffffff',
    },
    '& tr': {
      border: 'solid',
      borderWidth: '1px 0',
    },
    '& th': {
      textAlign: 'left',
    },
    '& td': {
      paddingRight: '15px',
      textAlign: 'left',
    },
    '& code': {
      fontSize: '1.2em',
      fontWeight: 'normal',
      fontFamily: 'Andale Mono,AndaleMono,monospace;',
      textAlign: 'left',
      color: '#595454',
      whiteSpace: 'nowrap',
      overflow: 'scroll',
      display: 'block',
      width: '100%',
    },
  },
  iconButton: {
    padding: '7px',
    marginTop: '-5px',
  },
  icon: {
    fontSize: '1.3rem',
  },
}));

export default useStyles;
