import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import PersonIcon from '@material-ui/icons/Person';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Radio from '@material-ui/core/Radio';
import { blue } from '@material-ui/core/colors';

const useStyles = makeStyles(() => ({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  rootRadio: {
    color: '#CBC9C6',
  },
  body1: {
    fontWeight: '500',
    marginLeft: '10px',
    color: '#6F6E6B',
  },
}));

const ListItemsAccount = (props) => {
  const classes = useStyles();
  const { data, valueRadio, handleToggle } = props;

  return (
    <div>
      <List>
        {data && data.map((account) => (
          <ListItem key={account.id} dense button onClick={handleToggle(account.id)}>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <PersonIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText id={account.id} primary={account.name} className={classes.body1} />
            <ListItemSecondaryAction>
              <Radio
                id={account.id}
                checked={valueRadio === account.id}
                value={account.id}
                onClick={handleToggle(account.id)}
                name="radio-button"
                inputProps={{ 'aria-label': account.id }}
                className={classes.rootRadio} />
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </div>
  );
};

ListItemsAccount.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  valueRadio: PropTypes.string.isRequired,
  handleToggle: PropTypes.func.isRequired,
};

export default ListItemsAccount;
