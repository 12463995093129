import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    '& .MuiTableCell-head': {
      fontWeight: 700,
    },
    '& .MuiTableCell-root': {
      paddingTop: '5px',
      paddingRight: '5px',
      paddingBottom: '5px',
      paddingLeft: '5px',
      fontSize: '12px',
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: 0,
    },
  },
}));

function TableComponent(props) {
  const classes = useStyles();
  const { headers, children } = props;

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {headers.map((x) => (
              <TableCell key={x} align="left">{x}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {children}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

TableComponent.propTypes = {
  children: PropTypes.node.isRequired,
  headers: PropTypes.instanceOf(Object).isRequired,
};

export default TableComponent;
