import { makeStyles } from '@material-ui/core/styles';
import colors from 'constants/colors';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    zIndex: 3000,
    right: '20px',
    top: '70px',
    position: 'fixed',
    [theme.breakpoints.down('sm')]: {
      right: '0px',
    },
    '@media (max-width: 400px)': {
      right: '0px',
    },
  },
  actionItem: {
    backgroundColor: colors.fourth,
    color: 'white',
    '&.MuiFab-root.Mui-disabled': {
      color: 'white',
    },
  },
  activeItem: {
    backgroundColor: colors.primary,
    color: 'white',
    '&:hover': {
      backgroundColor: '#9E4552',
    },
  },
  speedDial: {
    position: 'sticky',
    '&.MuiSpeedDial-directionDown': {
      top: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
}));

export default useStyles;
