import colors from 'constants/colors';

const styles = (theme) => ({
  wrapper: {
    padding: '5px 2px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '@media (max-width: 400px)': {
      padding: '5px',
      width: '353px',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
    },
  },
  header: {
    fontFamily: 'Muli',
    background: '#ffffff',
    borderRadius: '3px 3px 0 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    [theme.breakpoints.up('xs')]: {
      marginTop: 20,
    },
  },

  headerTransparent: {
    background: 'transparent',
  },
  textareaContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',

    [theme.breakpoints.down('xs')]: {
      marginTop: 20,
    },
  },
  textarea: {
    fontFamily: 'Muli',
    fontSize: '14px',
    width: '100%',
    color: '#6F6E6B',
    lineHeight: '22px',
    border: '1px solid #d1d5da',
    borderBottomStyle: 'none',
    borderRadius: '3px',
    outline: 'none',
    resize: 'vertical',
    padding: '20px',
    boxShadow: 'inset 0 1px 2px rgba(27,31,35,0.075)',
    boxSizing: 'border-box',
    maxWidth: '100%',
    '&::placeholder': {
      fontSize: '14px',
      color: colors.primary,
      textDecoration: 'underline',
      fontWeight: '600',
    },
  },
  navButton: {
    fontFamily: 'Muli',
    display: 'inline-block',
    padding: '8px 12px',
    fontSize: '12px',
    lineHeight: '20px',
    color: colors.secondary,
    textDecoration: 'none',
    backgroundColor: 'transparent',
    border: '1px solid transparent',
    borderBottom: '0',

    '&.navButton-selected': {
      background: 'white',
      borderColor: '#d1d5da',
      borderRadius: '3px 3px 0 0',
    },
  },
  dropdown: {
    position: 'absolute',
    display: 'block',
    top: '43px !important',
    left: '21px',
  },
  autocompleteList: {
    padding: 0,
    minWidth: '200px',
    margin: '0',
    background: '#fff',
    border: '1px solid #dfe2e5',
    borderRadius: '3px',
    boxShadow: '0 0 5px rgba(27, 31, 35, 0.1)',
    listStyle: 'none',
    maxHeight: '200px',
    overflow: 'scroll',
  },
  autocompleteItem: {
    borderBottom: '1px solid #dfe2e5',

    '& .rta__entity': {
      margin: '0',
      padding: '12px',
      fontSize: '14px',
      lineHeight: '14px',
      borderBottom: '1px',
    },

    '& .rta__entity--selected': {
      color: '#fff',
      textDecoration: 'none',
      background: '#0366d6',
    },
  },
  preview: {
    padding: '20px',
    backgroundColor: '#fafafa',
    borderBottom: '1px solid #e1e4e8',
    '& h1': {
      fontSize: '1.2em',
    },
    '& img': { maxWidth: '100%', display: 'block' },
    '& h2': {
      fontSize: '1.2em',
      color: '#595454',
    },
    '& h3': {
      fontSize: '1em',
    },
    '& blockquote': {
      padding: '10px',
      fontSize: '1.2em',
      fontStyle: 'italic',
      background: '#e8e8e8',
    },
    '& a': {
      fontSize: '1.2em',
      textDecoration: 'underline',
      color: '#0000ff',
    },
    '& ol': {
      fontSize: '1.2em',
      color: '#595454',
    },
    '& table': {
      padding: '10px',
      borderCollapse: 'collapse',
      border: '1px solid',
      borderspacing: '10px',
      fontSize: '0.8em',
      background: '#ffffff',
    },
    '& tr': {
      border: 'solid',
      borderWidth: '1px 0',
    },
    '& th': {
      textAlign: 'left',
    },
    '& td': {
      paddingRight: '15px',
      textAlign: 'left',
    },
    '& code': {
      fontSize: '1.2em',
      fontWeight: 'normal',
      fontFamily: 'Andale Mono,AndaleMono,monospace;',
      textAlign: 'left',
      color: '#595454',
      whiteSpace: 'nowrap',
      overflow: 'scroll',
      display: 'block',
      width: '100%',
    },
  },
  dropzone: {
    fontFamily: 'Muli',
    cursor: 'pointer',
    padding: '5px',
    fontSize: '12px',
    border: '1px solid rgba(0,0,0,.2)',
    borderTopStyle: 'none',
    marginTop: '-10px',
    borderRadius: '3px',
    color: 'rgba(0,0,0,0.5)',
    backgroundColor: '#fafafa',
    alignItems: 'center',

    '& > p': {
      margin: 0,
    },
  },
  commentButtonContainer: {
    marginTop: '10px',
    textAlign: 'right',
  },
  errorMessage: {
    color: 'red',
  },
  textDropZone: {
    paddingLeft: '15px',
    color: colors.primary,
    fontWeight: '600',
    fontFamily: 'Muli',

  },
  uploadIcon: {
    fill: colors.secondary,
  },
  arrowIcon: {
    marginTop: '1px',
    fill: colors.primary,
    width: 18,
    height: 18,
  },
  aIcon: {
    marginBottom: '16px',
    fontWeight: '600',
    fontFamily: 'Muli',
  },
  buttonCancel: {
    color: colors.fifth,
    backgroundColor: colors.fourth,
  },
  visibleButton: {
    padding: '5px 5px 5px 5px',
  },
  visibleText: {
    padding: '5px 5px 5px 5px',
  },
  showToolbar: {
    [theme.breakpoints.down('xs')]: {
    },
  },
  timeContainer: {
    marginTop: '20px',
  },
  containerButtons: {
    flexDirection: 'row-reverse',
    marginTop: '20px',
    marginRight: '-4px',
    float: 'right',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'right',
      marginBottom: '10px',
      marginTop: '10px',
    },
  },
  form: {
    display: 'inline-flex',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
});

export default styles;
