import React from 'react';
import EditIcon from '@material-ui/icons/Edit';
import ForumIcon from '@material-ui/icons/Forum';
import moreTime from 'assets/iconsSvgs/moreTime.svg';

const actionsTaskMenu = [
  {
    icon: <ForumIcon />,
    name: 'Comentar',
  },
  {
    icon: <EditIcon />,
    name: 'Editar tarea',
  },
  {
    icon: (
      <img src={moreTime} alt="time" style={{ width: '1.5rem', height: '1.5rem' }} />
    ),
    name: 'Agregar Tiempos',
  },
];
export default actionsTaskMenu;
