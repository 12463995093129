import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reset } from 'redux-form';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Loading from 'components/loading/index';
import CreateTaskForm from 'components/createTask/index';

import { createTask } from 'services/actions/task';
import { getUserProfile } from 'services/actions/userProfile';
import { getProjects } from 'services/actions/projectsOfUser';
import { getGroupers } from 'services/actions/projectGroupers';
import { usersOfProject } from 'services/actions/usersOfProject';
import { createTimesheets } from 'services/actions/timesheetsTask';

import { selectorProjectsOfUser } from 'services/selectors/projectsOfUser';
import { selectorActualAccountName } from 'services/selectors/account';
import { selectorLogin } from 'services/selectors/login';
import { selectorUserProfileId } from 'services/selectors/userProfile';
import { projectGroupers } from 'services/selectors/projectGroupers';
import { usersOfProjectSelector } from 'services/selectors/usersOfProject';

import useOpenClose from 'hooks/useOpenClose';
import { statuses, statusesForResponsible } from 'constants/status';
import prepareDataTask from 'utils/prepareDataTask';
import { convertHoursToMins } from 'utils/convertMinsToTime';

function CreateTask() {
  const dispatch = useDispatch();

  const {
    open,
    handleClose,
    handleOpen,
  } = useOpenClose(false);

  const [concept, setConcept] = useState('');
  const [characters, setCharacters] = useState(0);
  const [messageAfterSubmit, setMessageAfterSubmit] = useState('');
  const [helperTexts, setHelperTexts] = useState({});

  const { data: { id: globalId, username } } = useSelector(selectorLogin.login);
  const account = useSelector(selectorActualAccountName);
  const profileId = useSelector(selectorUserProfileId);

  const [showProject, setShowProject] = useState(false);
  const [projectAutocomplete, setProjectAutocomplete] = useState('proyecto');
  const [idProjectAutocomplete, setIdProjectAutocomplete] = useState(0);

  const [groupersAutocomplete, setGroupersAutocomplete] = useState('nombre del agrupador');
  const [idGroupersAutocomplete, setIdGroupersAutocomplete] = useState(0);

  const [showPetitioner, setShowPetitioner] = useState(false);
  const [petitionerAutocomplete, setPetitionerAutocomplete] = useState(username);
  const [idPetitionerAutocomplete, setIdPetitionerAutocomplete] = useState(profileId);

  const [responsibleAutocomplete, setResponsibleAutocomplete] = useState('usuario responsable');
  const [idResponsibleAutocomplete, setIdResponsibleAutocomplete] = useState(0);

  const [priority, setPriority] = useState('Normal');
  const [idPriority, setIdPriority] = useState(4);

  const [statusTask, setStatus] = useState('Bandeja');
  const [idStatus, setIdStatus] = useState(8);

  const [showPriority, setShowPriority] = useState(false);

  useEffect(() => {
    dispatch(reset('formCreateTask'));
  }, [dispatch]);

  useEffect(() => {
    if (profileId != null) {
      dispatch(getProjects(account, profileId));
    }
    dispatch(getUserProfile(account, globalId));
  }, [account, globalId, profileId, dispatch]);

  const projects = useSelector(selectorProjectsOfUser);

  useEffect(() => {
    if (idProjectAutocomplete !== 0) {
      setGroupersAutocomplete('nombre de agrupador');
      setIdGroupersAutocomplete(0);
      setResponsibleAutocomplete('usuario responsable');
      setIdResponsibleAutocomplete(0);
      dispatch(getGroupers(account, idProjectAutocomplete));
      dispatch(usersOfProject(account, idProjectAutocomplete));
    }
  }, [dispatch, idProjectAutocomplete, account]);

  const groupers = useSelector(projectGroupers);
  const userProject = useSelector(usersOfProjectSelector);

  const handleShowProjectGrid = () => {
    setShowProject(!showProject);
  };

  const handleShowPetitionerGrid = () => {
    setShowPetitioner(!showPetitioner);
  };

  const handleShowPriorityGrid = () => {
    setShowPriority(!showPriority);
  };

  const handleReset = () => {
    setShowProject(false);
    setShowPetitioner(false);
    setShowPriority(false);
    setCharacters(0);
    setProjectAutocomplete('proyecto');
    setIdProjectAutocomplete(0);
    setGroupersAutocomplete('nombre de agrupador');
    setIdGroupersAutocomplete(0);
    setPetitionerAutocomplete(username);
    setIdPetitionerAutocomplete(profileId);
    setResponsibleAutocomplete('usuario responsable');
    setIdResponsibleAutocomplete(0);
    setIdPriority(4);
    setPriority('Normal');
    setStatus('Bandeja');
    setIdStatus(8);
    setConcept('');
    dispatch(reset('formCreateTask'));
  };

  const handleSend = async (dataUser) => {
    const { projectId, responsibleId } = dataUser;
    if (projectId !== 0 && responsibleId !== 0) {
      const formattedData = prepareDataTask(dataUser);
      const response = await dispatch(createTask(account, formattedData));
      const {
        action: {
          payload: {
            status,
            data,
          },
        },
      } = response;

      if (status >= 500 || status === 404) {
        setMessageAfterSubmit('Hubo un error, por favor intenta más tarde.');
        handleReset();
        handleOpen();
      } else if (status > 399) {
        const errors = {};
        data.forEach((item) => {
          errors[item.param] = item.message;
        });
        setHelperTexts(errors);
        handleOpen();
      } else {
        const { timesheets } = dataUser;
        if (timesheets && timesheets !== '00:00') {
          const dateFormat = moment().format('YYYY-MM-DD');
          const info = {
            task_id: data.id,
            time: convertHoursToMins(timesheets),
            on_behalf_of: profileId,
            date: dateFormat,
          };
          await dispatch(createTimesheets(account, info));
        }
        setMessageAfterSubmit('Se ha creado la tarea correctamente.');
        handleReset();
        handleOpen();
      }
    } else {
      if (projectId === 0) {
        setMessageAfterSubmit('El proyecto es requerido.');
        handleOpen();
      }

      if (projectId !== 0 && responsibleId === 0) {
        setMessageAfterSubmit('El responsable es requerido.');
        handleOpen();
      }
    }
  };

  if (projects == null || profileId == null
    || account == null || username == null) {
    return <Loading />;
  }

  return (
    <Grid container>
      <CreateTaskForm
        open={open}
        handleClose={handleClose}
        handleOpen={handleOpen}
        setMessageAfterSubmit={setMessageAfterSubmit}
        messageAfterSubmit={messageAfterSubmit}
        setHelperTexts={setHelperTexts}
        helperTexts={helperTexts}
        onSubmit={handleSend}
        projects={projects && projects}
        groupers={groupers && groupers}
        responsible={userProject && userProject}
        petitioner={userProject && userProject}
        showProject={showProject}
        showPetitioner={showPetitioner}
        showPriority={showPriority}
        handleShowProjectGrid={handleShowProjectGrid}
        handleShowPriorityGrid={handleShowPriorityGrid}
        handleShowPetitionerGrid={handleShowPetitionerGrid}
        handleReset={handleReset}
        concept={concept}
        setCharacters={setCharacters}
        setConcept={setConcept}
        characters={characters}
        projectLabel={projectAutocomplete}
        changeProjectLabel={setProjectAutocomplete}
        groupersLabel={groupersAutocomplete}
        changeGroupersLabel={setGroupersAutocomplete}
        petitionerLabel={petitionerAutocomplete}
        changePetitionerLabel={setPetitionerAutocomplete}
        responsibleLabel={responsibleAutocomplete}
        changeResponsibleLabel={setResponsibleAutocomplete}
        idProject={idProjectAutocomplete}
        setIdProject={setIdProjectAutocomplete}
        idGroupers={idGroupersAutocomplete}
        setIdGroupers={setIdGroupersAutocomplete}
        idPetitioner={!idPetitionerAutocomplete ? profileId : idPetitionerAutocomplete}
        setIdPetitioner={setIdPetitionerAutocomplete}
        idResponsible={idResponsibleAutocomplete}
        setIdResponsible={setIdResponsibleAutocomplete}
        priority={priority}
        idPriority={idPriority}
        setIdPriority={setIdPriority}
        status={statusTask}
        statusesTaks={idResponsibleAutocomplete !== profileId ? statusesForResponsible : statuses}
        idStatus={idStatus}
        setIdStatus={setIdStatus}
        setPriority={setPriority}
        setStatus={setStatus} />
    </Grid>
  );
}

export default CreateTask;
