import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import Chip from '@material-ui/core/Chip';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import SearchListItems from 'components/autocompleteSelect';
import useOpenClose from 'hooks/useOpenClose';
import { initials } from 'utils/initials';
import colors from 'constants/colors';
import Tooltip from '@material-ui/core/Tooltip';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useStyles from './styles';

function TaskDetailSelector(props) {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    input,
    placeholder,
    data,
    haveAvatar,
    haveLabel,
    label,
    changeLabel,
    idItem,
    changeIdItem,
    disabled,
    withDeleteIcon,
    meta: { invalid, error },
  } = props;

  useEffect(() => {
    if (input && Object.keys(input).length > 0) {
      input.onChange(idItem);
    }
  }, [input, idItem]);

  const {
    open: openAutocomplete,
    handleOpen: handleOpenAutoComplete,
    handleClose: handleCloseAutoComplete,
  } = useOpenClose();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    if (openAutocomplete) {
      handleCloseAutoComplete();
    } else {
      handleOpenAutoComplete();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    handleCloseAutoComplete();
  };

  const handleClickEvent = () => {
    changeLabel('nombre del agrupador');
    changeIdItem(0);
    input.onChange(0);
  };

  const colorDisable = data && data.length > 0
    ? { color: colors.primary }
    : { color: colors.secondary };

  return (
    <div className={classes.marginDiv}>
      <List className={classes.list}>
        <ListItem
          className={classes.listItem}
          button={!disabled}
          alignItems="flex-start"
          onClick={(e) => handleClick(e)}
          value={idItem}>
          { haveAvatar
              && (
              <ListItemAvatar style={{ minWidth: '26px', marginTop: '0px', marginLeft: '5px' }}>
                <Tooltip
                  placement={matches ? 'right-start' : 'bottom-end'}
                  open={invalid && error !== ''}
                  title={invalid && error ? error : 'Campo obligatorio'}
                  options={{ print: false }}>
                  <Avatar className={classes.avatar}>
                    { initials(label) }
                  </Avatar>
                </Tooltip>
              </ListItemAvatar>
              )}
          { haveLabel
            && (
            <ListItemText
              className={classes.listItemText}
              style={colorDisable}
              primary={label}
              value={idItem} />
            )}
        </ListItem>
        { withDeleteIcon && idItem !== 0 && (
        <Chip
          size="small"
          onDelete={() => handleClickEvent()}
          className={classes.iconChip}
          variant="outlined" />
        )}
      </List>

      { !disabled
      && (
      <SearchListItems
        anchor={anchorEl}
        dataUsers={data}
        handleClose={handleClose}
        open={openAutocomplete}
        changeTextAutocomplete={changeLabel}
        changeIdItemAutocomplete={changeIdItem}
        withAvatar={haveAvatar}
        placeholder={placeholder}
        input={input} />
      )}
    </div>
  );
}

TaskDetailSelector.defaultProps = {
  data: null,
  idItem: null,
  disabled: false,
  input: {},
  placeholder: '',
  changeLabel: () => {},
  changeIdItem: () => {},
  withDeleteIcon: false,
  meta: {},
};

TaskDetailSelector.propTypes = {
  placeholder: PropTypes.string,
  input: PropTypes.instanceOf(Object),
  data: PropTypes.arrayOf(PropTypes.object),
  haveAvatar: PropTypes.bool.isRequired,
  haveLabel: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  changeLabel: PropTypes.func,
  idItem: PropTypes.number,
  changeIdItem: PropTypes.func,
  disabled: PropTypes.bool,
  withDeleteIcon: PropTypes.bool,
  meta: PropTypes.instanceOf(Object),
};

export default TaskDetailSelector;
