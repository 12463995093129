export const notificationsData = [
  {
    title: 'Leídas',
    items: [
      {
        id: 1,
        content: 'Todas las Notificaciones Leídas',
      },
    ],
  },
  {
    title: 'No leídas',
    items: [
      {
        id: 2,
        content: 'Todas las Notificaciones No Leídas',
      },
    ],
  },
];
