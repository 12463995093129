/**
 * Roles selector
 *
 * This function create an selector
 *
 */

export const selectorGetRoles = (state) => {
  const { roles: { data } } = state;
  return !data ? null : data;
};
