import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import LogoMD from 'assets/img/logo_mano_derecha.svg';
import LogoVO from 'assets/img/logo_vo.svg';
import useStyles from './styles';

const Footer = () => {
  const classes = useStyles();

  return (
    <div>
      <Grid item xs={12} container className={classes.footer} justify="space-evenly" direction="row">
        <a href="https://manoderecha.net/" className={classes.logoMD}>
          <img src={LogoMD} alt="logo" />
        </a>
        <a href="https://vincoorbis.com/" className={classes.logoVO}><img src={LogoVO} alt="logo" /></a>
        <Typography variant="body2" className={classes.title}>
          © Copyright
          {' '}
          {new Date().getFullYear()}
          . Todos los derechos reservados
        </Typography>
      </Grid>
    </div>
  );
};

export default Footer;
