import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Companies from 'components/companies';
import { getCompanies, resetCompanies } from 'services/actions/companies';
import { selectorCompanies } from 'services/selectors/companies';
import { moreCompanies, noCompanies } from 'constants/companies';
import { selectorActualAccountName } from 'services/selectors/account';
import Error from 'components/error/index';
import Loading from 'components/loading/index';
import Button from '@material-ui/core/Button';

import {
  Grid,
  Typography,
} from '@material-ui/core';

const CompaniesContainer = (props) => {
  const { handleClose } = props;
  const dispatch = useDispatch();
  const {
    isLoading,
    data,
    pagination: {
      offset,
      limit,
      orderBy,
      totalCount,
    },
    filter,
    error,
  } = useSelector(selectorCompanies);

  const actualAccountName = useSelector(selectorActualAccountName);

  useEffect(() => {
    const searchTasks = () => {
      dispatch(resetCompanies());
      dispatch(
        getCompanies(
          actualAccountName,
          0,
          limit,
          '',
          filter,
        ),
      );
    };
    searchTasks();
  }, [actualAccountName, dispatch, filter, limit]);


  useEffect(() => () => {
    dispatch(resetCompanies());
  }, [dispatch]);

  const handleGetMore = () => {
    dispatch(
      getCompanies(
        actualAccountName,
        offset + limit,
        limit,
        orderBy,
        filter,
      ),
    );
  };

  if (error) {
    return (
      <Error message={error.message} />
    );
  }

  return (
    <Grid container direction="column">
      <Grid item>
        <Companies
          handleClose={handleClose}
          listItems={data}
          checkbox />
      </Grid>
      {isLoading && <Loading />}
      { !isLoading && data.length < 1
        ? (
          <Grid item>
            <center>
              <Typography color="primary">
                { noCompanies }
              </Typography>
            </center>
          </Grid>
        ) : null}
      { !isLoading && totalCount > offset + limit
        ? (
          <Grid item>
            <Button color="primary" onClick={handleGetMore} fullWidth>
              { moreCompanies }
            </Button>
          </Grid>
        ) : ''}
    </Grid>
  );
};

CompaniesContainer.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default CompaniesContainer;
