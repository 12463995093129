import axios from 'axios';
import { formatResponse, formatError } from './formatResponse';

const instanceAPI = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: { 'Content-type': 'application/json' },
});

// Interceptor for when token is available, add it on request headers
// eslint-disable-next-line
instanceAPI.interceptors.request.use(config => {
  const token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
  // eslint-disable-next-line
  config.headers.common.Authorization = `Bearer ${token}`;
  return config;
});

instanceAPI.interceptors.response.use(
  (response) => formatResponse(response),
  (error) => formatError(error),
);

export default instanceAPI;
