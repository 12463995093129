import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    paddingLeft: '20px',
  },
  gridText: {
    paddingLeft: '10px',
    paddingBottom: '25px',
  },
  gridCheckbox: '10px',
  textData: {
    fontWeight: 'bold',
    color: '#59574E',
  },
}));

export default useStyles;
