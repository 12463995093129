import React from 'react';
import IconInbox from 'components/iconInbox';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import useStyles from './styles';

function Project(props) {
  const { data, userRole } = props;
  const classes = useStyles();
  return (
    <Grid container direction="column">
      <Grid item className={classes.gridText}>
        <Typography variant="body2">
          Nombre:
        </Typography>
        <Typography
          variant="h6"
          className={classes.textData}>
          {data.name}
        </Typography>
      </Grid>
      <Grid
        container
        item
        direction="row"
        className={classes.gridText}>
        <Grid item>
          <Typography
            className={classes.textData}
            variant="body2">
            Fecha de inicio
          </Typography>
          <Typography
            variant="body1">
            {moment(data.start_date).format('MMMM D, YYYY')}
          </Typography>
        </Grid>
        <Grid item style={{ paddingLeft: '40px' }}>
          <Typography
            className={classes.textData}
            variant="body2">
            Fecha de entrega
          </Typography>
          <Typography

            variant="body1">
            {moment(data.end_date).format('MMMM D, YYYY')}
          </Typography>
        </Grid>
      </Grid>
      <Grid item className={classes.gridText}>
        <Typography
          className={classes.textData}
          variant="body2">
          Estado:
          <IconInbox
            statusType="iconName"
            status={data.status_id}
            statusName={data.projects_status.name} />
        </Typography>
      </Grid>
      <Grid item className={classes.gridText}>
        <Typography
          className={classes.textData}
          variant="body2">
          Clave:
        </Typography>
        <Typography
          variant="body1">
          {data.key}
        </Typography>
      </Grid>
      <Grid item className={classes.gridText}>
        <Typography
          className={classes.textData}
          variant="body2">
          Descripción de la conclusión:
        </Typography>
        <Typography
          variant="body1">
          {data.description}
        </Typography>
      </Grid>
      <Grid item className={classes.gridText}>
        <Typography
          className={classes.textData}
          variant="body2">
          Compañía que facturó el proyecto:
        </Typography>
        <Typography
          variant="body1">
          {data.company.name}
        </Typography>
      </Grid>
      <Grid item className={classes.gridText}>
        <Typography
          className={classes.textData}
          variant="body2">
          Ticket default:
        </Typography>
        <Typography
          variant="body1">
          {data.default_ticket}
        </Typography>
      </Grid>
      <Grid item className={classes.gridText}>
        <Typography
          className={classes.textData}
          variant="body2">
          Descripción del proyecto:
        </Typography>
        <Typography
          variant="body1">
          {data.description}
        </Typography>
      </Grid>
      <Grid item className={classes.gridText}>
        <Typography
          className={classes.textData}
          variant="body2">
          Rol en el proyecto:
        </Typography>
        <Typography
          variant="body1">
          {userRole ? userRole.name : '--'}
        </Typography>
      </Grid>
    </Grid>
  );
}

Project.propTypes = {
  data: PropTypes.shape({
    description: PropTypes.string.isRequired,
    default_ticket: PropTypes.string,
    projects_status: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    status_id: PropTypes.number.isRequired,
    key: PropTypes.string.isRequired,
    start_date: PropTypes.string.isRequired,
    end_date: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    company: PropTypes.shape({
      name: PropTypes.string,
    }).isRequired,
  }),
  userRole: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
};

Project.defaultProps = {
  userRole: null,
  data: {
    default_ticket: '',
    company: {
      name: '',
    },
  },
};

export default Project;
