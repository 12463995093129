import React from 'react';
import { useSelector } from 'react-redux';
import { AppBar, Toolbar, Avatar, Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import NotificationImportantOutlinedIcon from '@material-ui/icons/NotificationImportantOutlined';
import HendrixLogoButton from 'components/hendrixIconButton/index';
import useOpenClose from 'hooks/useOpenClose';
import DialogDataUser from 'components/dialogDataUser';
import { selectorLogin } from 'services/selectors/login/index';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import PrimaryIconBadge from 'components/primaryIconBadge/index';
import SecondaryIconBadge from 'components/secondaryIconBadge/index';
import SearchButton from 'components/searchIconButton/index';
import { initials } from 'utils/initials';
import useStyles from './styles';

export default function Header(props) {
  const classes = useStyles();
  const {
    handleClickMenu,
    openMenu,
    haveNotifications,
    handleOpenNotifications,
    handleOpenFinder,
    handleClickLogo,
    title,
  } = props;

  const dataUser = useSelector(selectorLogin.login);
  const { data } = dataUser;

  const {
    open,
    handleClose,
    handleOpen,
  } = useOpenClose(false);

  const handleRemoveToken = () => {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('act');
    window.localStorage.removeItem('persist:root');
    window.location.href = '/';
  };

  const handleClickOpen = () => {
    handleOpen(true);
  };


  return (
    <div className={classes.grow}>
      <AppBar elevation={1} position="fixed" color="inherit" className={classes.appBar}>
        <Toolbar>
          <Box display="flex" flexGrow={1}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleClickMenu}
              edge="start"
              className={classes.menuButton}>
              <MenuIcon className={openMenu ? classes.menuActive : classes.menuInactive} />
            </IconButton>
            <HendrixLogoButton handleClick={handleClickLogo} />
            <Typography variant="h6" className={classes.title}>
              {title}
            </Typography>
          </Box>
          <SearchButton handleClick={handleOpenFinder} />
          { !haveNotifications ? (
            <PrimaryIconBadge
              disabled={false}
              handleClick={handleOpenNotifications}
              IconBadge={NotificationImportantOutlinedIcon} />
          ) : (
            <SecondaryIconBadge
              disabled={false}
              handleClick={handleOpenNotifications}
              IconBadge={NotificationImportantOutlinedIcon} />
          )}
          <Avatar
            className={classes.avatar}
            onClick={handleClickOpen}>
            {data && initials(data.username)}
          </Avatar>
          <DialogDataUser
            classes={classes}
            open={open}
            onClose={handleClose}
            logout={handleRemoveToken} />
        </Toolbar>
      </AppBar>
    </div>
  );
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
  openMenu: PropTypes.bool.isRequired,
  handleClickMenu: PropTypes.func.isRequired,
  haveNotifications: PropTypes.bool.isRequired,
  handleOpenNotifications: PropTypes.func.isRequired,
  handleOpenFinder: PropTypes.func.isRequired,
  handleClickLogo: PropTypes.func.isRequired,
};
