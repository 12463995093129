import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { ShowdownHendrix } from 'utils/showdownHendrix';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import SwapCallsIcon from '@material-ui/icons/SwapCalls';

import GraphCircle from 'components/graphCircle/index';
import { PrimaryButton } from 'components/primaryButton/index';
import { SecondaryButton } from 'components/secondaryButton/index';
import RenderTime from 'components/timeField/index';
import RenderTextAreaAutosize from 'components/renderTextAreaAutosize/index';
import WhiteModalComponent from 'components/whiteModalComponent/index';

import TaskDetailSelector from 'containers/taskUserSelector';
import PrioritySelector from 'containers/priorityBadge/index';
import StatusSelector from 'containers/statusBagde';

import useOpenClose from 'hooks/useOpenClose';
import useStyles from './styles';

const validate = (values) => {
  const errors = {};
  const fields = {
    dataConcept: 'Concepto',
    responsibleId: 'Responsable',
  };
  Object.keys(fields).forEach((key) => {
    if (!values[key]) errors[key] = `${fields[key]} obligatorio`;
  });
  return errors;
};

function UpdateTaskForm(props) {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    open: openModal,
    handleClose: handleCloseModal,
    handleOpen: handleOpenModal,
  } = useOpenClose(false);

  const {
    editing,
    task,
    pristine,
    invalid,
    submitting,
    onSubmit,
    handleSubmit,
    projects,
    groupers,
    responsible,
    petitioner,
    characters,
    projectLabel,
    groupersLabel,
    petitionerLabel,
    responsibleLabel,
    changeProjectLabel,
    changeGroupersLabel,
    changePetitionerLabel,
    changeResponsibleLabel,
    idProject,
    idGroupers,
    idPetitioner,
    idResponsible,
    setIdProject,
    setIdGroupers,
    setIdPetitioner,
    setIdResponsible,
    priority,
    setPriority,
    idPriority,
    setIdPriority,
    status,
    setStatus,
    idStatus,
    setIdStatus,
    statusesTaks,
    estimateColor,
    registerTime,
    estimatedTime,
    totalTime,
    setCharacters,
    handleCancel,
    handleInvestPR,
  } = props;

  const readOnly = !editing;
  const styleCharacters = characters > 250
    ? {
      color: 'red',
    }
    : {
      color: '#9A9895',
    };
  const styleEstimated = editing && matches
    ? {
      marginLeft: '8px',
      marginBottom: '8px',
      fontSize: '14px',
      color: estimateColor > 100 ? '#DD2C09' : '#3F90BB',
    } : {
      fontSize: '14px',
      color: estimateColor > 100 ? '#DD2C09' : '#3F90BB',
      marginLeft: '8px',
      paddingBottom: '4px',
    };
  const styleRowProject = editing ? { marginTop: '-8px' } : {};
  const converter = new ShowdownHendrix();

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" name="formUpdateTask">
      <Grid container>
        <Grid item xs={12}>
          {!editing
            ? (
              <div
                className={classes.concept}
              // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: converter.makeHtml(task.concept),
                }} />
            ) : (
              <>
                <Field
                  name="dataConcept"
                  id="dataConcept"
                  component={RenderTextAreaAutosize}
                  className={classes.conceptUpdate}
                  setCharacters={setCharacters}
                  withIconNext={false}
                  inputProps={{ value: task.concept }} />
                <Grid
                  container
                  item
                  className={classes.styleCharactersGrid}
                  justify="flex-end"
                  alignItems="flex-end">
                  <Typography variant="caption" display="inline" gutterBottom style={styleCharacters}>
                    {characters}
                    /
                  </Typography>
                  <Typography variant="caption" display="inline" gutterBottom style={styleCharacters}>
                    250
                  </Typography>
                </Grid>
              </>
            )}
        </Grid>
        <div className={`${classes.grid} ${classes.wrap}`} style={styleRowProject}>
          {!editing
            ? (
              <>
                <p className={`${classes.fontStyle} ${classes.spacing}`}>
                  {task.project.name}
                </p>
                <Divider style={{ margin: '0 7px' }} orientation="vertical" flexItem />
                <>
                  { task.group
                && (
                <p className={`${classes.fontStyle} ${classes.spacing}`}>
                  {task.group.name}
                </p>
                )}
                </>
              </>
            )
            : (
              <>
                <Field
                  id="projectId"
                  name="projectId"
                  placeholder="elige un proyecto"
                  haveLabel
                  haveAvatar={false}
                  data={projects}
                  label={projectLabel}
                  changeLabel={changeProjectLabel}
                  withDeleteIcon={false}
                  idItem={idProject}
                  changeIdItem={setIdProject}
                  component={TaskDetailSelector} />
                <Divider style={{ margin: '0 7px' }} orientation="vertical" flexItem />
                <Field
                  id="groupId"
                  name="groupId"
                  placeholder="elige un agrupador"
                  haveLabel
                  withDeleteIcon
                  disabled={!(groupers && groupers.length > 0)}
                  haveAvatar={false}
                  data={groupers}
                  label={groupersLabel}
                  changeLabel={changeGroupersLabel}
                  idItem={idGroupers}
                  changeIdItem={setIdGroupers}
                  component={TaskDetailSelector} />
              </>
            )}
        </div>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.grid}>
            <div className={classes.userResponsibles} style={{ display: 'flex', alignItems: 'center' }}>
              <Field
                id="petitionerId"
                name="petitionerId"
                placeholder="elige un usuario"
                data={petitioner}
                disabled={readOnly}
                label={petitionerLabel}
                haveLabel={false}
                haveAvatar
                withDeleteIcon={false}
                changeLabel={changePetitionerLabel}
                idItem={idPetitioner}
                changeIdItem={setIdPetitioner}
                component={TaskDetailSelector} />
              <IconButton
                disabled={readOnly}
                style={{ padding: '7px', width: '26px', height: '26px' }}
                color="secondary"
                onClick={() => handleInvestPR(
                  idPetitioner,
                  petitionerLabel,
                  idResponsible,
                  responsibleLabel,
                )}>
                <SwapCallsIcon style={{ transform: 'rotate(90deg)', width: '15px', height: '17px' }} />
              </IconButton>
              <Field
                id="responsibleId"
                name="responsibleId"
                placeholder="elige un usuario"
                data={responsible}
                label={responsibleLabel}
                disabled={readOnly}
                haveLabel={false}
                haveAvatar
                withDeleteIcon={false}
                changeLabel={changeResponsibleLabel}
                idItem={idResponsible}
                changeIdItem={setIdResponsible}
                component={TaskDetailSelector}
                style={{ paddingRight: '7px' }} />
            </div>

            <Field
              id="priorityId"
              name="priorityId"
              idPriority={idPriority}
              namePriority={priority}
              disabled={readOnly}
              setIdPriority={setIdPriority}
              setPriority={setPriority}
              component={PrioritySelector} />
            <Divider orientation="vertical" flexItem />
            <Field
              id="statusId"
              name="statusId"
              idStatus={idStatus}
              nameStatus={status}
              disabled={readOnly}
              setIdStatus={setIdStatus}
              setStatus={setStatus}
              statuses={statusesTaks}
              component={StatusSelector} />

            <Divider orientation="vertical" flexItem />

            <div className={`${classes.grid} ${classes.timeZone}`}>
              <Typography
                style={styleEstimated}
                className={!editing && !matches ? classes.registeredColor : ''}
                component="span"
                variant="body1">
                Registrado:
                {' '}
                {registerTime}
                {' '}
                /
              </Typography>
              {!editing
                ? (
                  <Typography className={classes.estimatedColor} component="span" variant="body1">
                    Estimado:
                    {' '}
                    {estimatedTime}
                  </Typography>
                ) : (
                  <>
                    <Typography className={classes.estimatedColor} component="span" variant="body1">
                      Estimado:
                    </Typography>
                    <Field
                      id="estimatedTime"
                      name="estimatedTime"
                      component={RenderTime}
                      inputProps={{
                        value: estimatedTime,
                      }} />
                  </>
                )}
              <GraphCircle timeRegistered={totalTime} timeEstimated={task.estimated_time} />
            </div>
          </div>
        </Grid>
        {editing
        && (
        <Grid
          container
          item
          xs={12}
          sm={12}
          justify="flex-end"
          alignItems="flex-end"
          className={classes.gridPaddingButton}>
          <SecondaryButton
            size={!matches ? 'medium' : 'small'}
            variant="contained"
            text="Cancelar"
            className={classes.buttonCancel}
            fullWidth={false}
            onClick={handleCancel} />
          <PrimaryButton
            size={!matches ? 'medium' : 'small'}
            variant="contained"
            text="Guardar"
            disabled={pristine || submitting || invalid}
            onClick={handleOpenModal} />
        </Grid>
        )}
      </Grid>
      <WhiteModalComponent
        open={openModal}
        onClose={handleCloseModal}
        valueRadio="aceptar"
        variantTypography="subtitle1"
        textTypography="¿Deseas continuar?"
        textButtonRight="ACEPTAR"
        textButtonLeft="CANCELAR"
        handleSubmitRight={handleSubmit(onSubmit)}
        handleSubmitLeft={handleCloseModal}
        styleTitle={{ paddingBottom: '1px' }}>
        <>Se guardaran los cambios en la información de la tarea.</>
      </WhiteModalComponent>
    </form>
  );
}

UpdateTaskForm.defaultProps = {
  projects: {},
  groupers: {},
  responsible: {},
  petitioner: {},
  idPetitioner: null,
  statusesTaks: [],
};

UpdateTaskForm.propTypes = {
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  projects: PropTypes.instanceOf(Array),
  groupers: PropTypes.instanceOf(Array),
  responsible: PropTypes.instanceOf(Array),
  petitioner: PropTypes.instanceOf(Array),
  handleSubmit: PropTypes.func.isRequired,
  characters: PropTypes.number.isRequired,
  projectLabel: PropTypes.string.isRequired,
  changeProjectLabel: PropTypes.func.isRequired,
  groupersLabel: PropTypes.string.isRequired,
  changeGroupersLabel: PropTypes.func.isRequired,
  petitionerLabel: PropTypes.string.isRequired,
  changePetitionerLabel: PropTypes.func.isRequired,
  responsibleLabel: PropTypes.string.isRequired,
  changeResponsibleLabel: PropTypes.func.isRequired,
  idProject: PropTypes.number.isRequired,
  setIdProject: PropTypes.func.isRequired,
  idGroupers: PropTypes.number.isRequired,
  setIdGroupers: PropTypes.func.isRequired,
  idPetitioner: PropTypes.number,
  setIdPetitioner: PropTypes.func.isRequired,
  idResponsible: PropTypes.number.isRequired,
  setIdResponsible: PropTypes.func.isRequired,
  priority: PropTypes.string.isRequired,
  setPriority: PropTypes.func.isRequired,
  idPriority: PropTypes.number.isRequired,
  setIdPriority: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  setStatus: PropTypes.func.isRequired,
  idStatus: PropTypes.number.isRequired,
  setIdStatus: PropTypes.func.isRequired,
  statusesTaks: PropTypes.instanceOf(Array),
  setCharacters: PropTypes.func.isRequired,
  editing: PropTypes.bool.isRequired,
  task: PropTypes.instanceOf(Object).isRequired,
  estimateColor: PropTypes.number.isRequired,
  registerTime: PropTypes.string.isRequired,
  estimatedTime: PropTypes.string.isRequired,
  totalTime: PropTypes.number.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleInvestPR: PropTypes.func.isRequired,
};

export default (reduxForm({
  form: 'formUpdateTask',
  validate,
}))(UpdateTaskForm);
