import React from 'react';
import Input from '@material-ui/core/Input';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import PropTypes from 'prop-types';

const RenderFinderField = (props) => {
  const {
    style,
    fullWidth,
    placeholder,
    input,
    classes,
  } = props;

  return (
    <div className={classes && classes.root ? classes.root : ''}>
      <Input
        startAdornment={(
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        )}
        id="standard-adornment-finder-field"
        margin="dense"
        type="text"
        onChange={input.onChange}
        onDragStart={input.onDragStart}
        onDrop={input.onDrop}
        onFocus={input.onFocus}
        onBlur={input.onBlur}
        style={style}
        name={input.name}
        fullWidth={fullWidth}
        placeholder={placeholder} />
    </div>
  );
};

RenderFinderField.propTypes = {
  style: PropTypes.shape({}),
  fullWidth: PropTypes.bool,
  placeholder: PropTypes.string.isRequired,
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onBlur: PropTypes.func.isRequired,
    onDragStart: PropTypes.func.isRequired,
    onDrop: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
  }).isRequired,
  classes: PropTypes.shape({
    root: PropTypes.string,
  }),
};

RenderFinderField.defaultProps = {
  style: {},
  fullWidth: true,
  classes: {},
};

export default RenderFinderField;
