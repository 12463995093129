/**
 * Accounts´s selector
 *
 * This function create an selector
 *
 */

const accounts = (state) => state.account;

export const selectors = { accounts };
