import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import RenderTextField from 'components/renderTextField';
import { TransparentDialog } from 'components/transparentDialog/index';
import { email } from 'utils/validations';

const styles = (theme) => ({
  fieldText: {
    '& label.Mui-focused': {
      color: '#CBC9C6',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#CBC9C6',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#CBC9C6',
    },
    marginRight: theme.spacing(1),
    width: '100%',

  },
});

const validate = (values) => {
  const errors = {};

  if (!values.email) errors.email = 'El campo Correo Electrónico es requerido';

  if (values.email) errors.email = email(values.email);

  return errors;
};

export function RecoverPasswordForm(props) {
  const {
    handleSubmit,
    pristine,
    invalid,
    submitting,
    classes,
    onSubmit,
    open,
    handleClose,
    helperTexts,
  } = props;

  const colorText = { color: '#CBC9C6', paddingBottom: '12px' };
  return (
    <div>
      <TransparentDialog
        handleClose={handleClose}
        open={open}
        aria-labelledby="dialog-recover-password"
        buttonProps={{
          fullWidth: true,
          type: 'submit',
          disabled: pristine || submitting || invalid,
          onClick: handleSubmit(onSubmit),
        }}
        disableBackdropClick
        fullWidth
        maxWidth="xs"
        actionText="Enviar Correo">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            direction="column"
            justify="space-between"
            alignItems="stretch"
            spacing={2}>
            <Grid
              item
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={1}>
              <Grid item xs={6} sm={7}>
                <Typography variant="h6" style={{ color: 'white' }}>
                  Recuperar contraseña
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Field
                name="email"
                placeholder="Ingresa tu correo electrónico"
                component={RenderTextField}
                type="text"
                fullWidth
                helperText={helperTexts.email}
                className={classes.fieldText}
                style={colorText}
                id="recover-password"
                required />
            </Grid>
          </Grid>
        </form>
      </TransparentDialog>

    </div>
  );
}

RecoverPasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  classes: PropTypes.shape({ fieldText: PropTypes.string }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  helperTexts: PropTypes.shape({
    email: PropTypes.string,
  }).isRequired,
};

export const RecoverPassword = withStyles(styles)(reduxForm({
  form: 'RecoverPassword',
  validate,
})(RecoverPasswordForm));

export default RecoverPassword;
