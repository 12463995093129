import { useEffect, useState } from 'react';

function handleUnload(event) {
  event.preventDefault();
  if (event.defaultPrevented) {
    /* eslint-disable-next-line no-param-reassign */
    event.returnValue = '';
  }
  return 'ask unload';
}

function useBeforeUnload(initial = false) {
  const [ask, setAsk] = useState(initial);
  const setAskBeforeUnload = () => {
    if (!ask) {
      window.addEventListener('beforeunload', handleUnload);
      setAsk(true);
    }
  };

  const removeAskBeforeUnload = () => {
    if (ask) {
      window.removeEventListener('beforeunload', handleUnload);
      setAsk(false);
    }
  };

  useEffect(() => {
    if (initial) {
      window.addEventListener('beforeunload', handleUnload);
    }
    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, [initial]);

  return { askBeforeUnload: ask, removeAskBeforeUnload, setAskBeforeUnload };
}

export default useBeforeUnload;
