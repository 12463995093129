/**
 * Project privileges selector
 *
 * This function create an selector
 *
 */

export const selectorProjectPrivileges = (state) => state.projectPrivileges;
export const selectorProjectPrivilegesOfUser = (state, userId) => (
  state.projectPrivileges.data.find((item) => item.user.id === userId)
);

export const selectorProjectRoleOfUser = (state, userId) => {
  const privileges = state.projectPrivileges.data.find((item) => item.user.id === userId);
  return privileges ? privileges.role : null;
};


export default selectorProjectPrivileges;
