import React from 'react';
import PropTypes from 'prop-types';
import Badge from '@material-ui/core/Badge';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

import PersonIcon from '@material-ui/icons/Person';

const StyledBadge = withStyles(() => ({
  badge: {
    right: 1,
    top: 2,
    padding: '0 3px',
  },
}))(Badge);

const useStyles = makeStyles((theme) => ({
  iconButton: {
    marginRight: '15px',
    padding: '0px',
  },
  iconBadge: {
    '&:disabled': {
      color: '#CBC9C6',
    },
    '&:active': {
      color: 'rgba(111, 110, 107, .88)',
    },
    color: '#E6F6E6B',
    width: '26px',
    height: '26px',
    [theme.breakpoints.down('sm')]: {
      width: '22px',
      height: '26px',
    },
  },
}));

export default function PrimaryIconBadge(props) {
  const classes = useStyles();
  const { numNotify,
    IconBadge,
    handleClick,
    disabled } = props;

  return (
    <IconButton className={classes.iconButton} disabled={disabled} onClick={handleClick}>
      <StyledBadge
        badgeContent={numNotify}
        max={100}
        showZero={false}
        color="secondary">
        <IconBadge className={classes.iconBadge} />
      </StyledBadge>
    </IconButton>
  );
}

PrimaryIconBadge.defaultProps = {
  numNotify: 0,
  disabled: false,
  IconBadge: PersonIcon,
};

PrimaryIconBadge.propTypes = {
  numNotify: PropTypes.number,
  disabled: PropTypes.bool,
  IconBadge: PropTypes.elementType,
  handleClick: PropTypes.func.isRequired,
};
