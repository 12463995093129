import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0 8px',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '0px',
    },
  },
  marginDiv: {
    margin: 0,
    display: 'inline-block',
  },
}));

export default useStyles;
