import { fade, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    height: '55px',
  },
  grow: {
    flexGrow: 1,
    borderBottom: '1px solid',
    borderBottomColor: 'rgba(111, 110, 107, 0.15)',
  },
  avatar: {
    marginLeft: 'auto',
    width: '32px',
    height: '32px',
    border: '1px solid #6F6E6B',
    [theme.breakpoints.down('sm')]: {
      width: '26px',
      height: '26px',
    },
  },
  modalAvatar: {
    marginLeft: 'auto',
    '& .MuiPaper-elevation24': {
      boxShadow: '-5px 10px 10px 1px rgba(154,152,149,0.2), -1px 1px 10px 1px rgba(154,152,149,0.14), 1px 5px 10px 1px rgba(154,152,149,0.12)',
    },
    '& .MuiDialog-paper': {
      position: 'absolute',
      top: '50px',
      right: '1px',
      margin: '0px',
    },
    [theme.breakpoints.down('md')]: {
      '& .MuiDialog-paper': {
        position: 'absolute',
        top: '200px',
        right: '30px',
        left: '10px',
        minWidth: '300px',
      },
      '& .MuiBackdrop-root': {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
      },
    },
    [theme.breakpoints.up('md')]: {
      '& .MuiBackdrop-root': {
        backgroundColor: 'transparent',
      },
    },
  },
  itemsList: {
    '& .MuiListItem-gutters': {
      paddingTop: '5px',
      paddingBottom: '0px',
    },
  },
  divider: {
    height: '3px',
  },
  account: {
    color: '#E26376',
    fontFamily: 'Muli',
    fontSize: '10pt',
  },
  text: {
    color: '#6E6E6B',
    fontFamily: 'Muli',
    fontSize: '10pt',
    fontWeight: '700',
  },
  menuButton: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0',
      padding: '0',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  adorment: {
    marginRight: theme.spacing(2),
    color: fade(theme.palette.common.black, 0.15),
  },
  logo: {
    width: 20,
    height: 20,
    marginLeft: theme.spacing(2),
    [theme.breakpoints.up('xs')]: {
      marginLeft: theme.spacing(0),
    },
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('xs')]: {
      marginLeft: theme.spacing(1),
    },
  },
  menuActive: {
    color: '#E57586',
  },
  menuInactive: {
    color: '#6F6E6B',
    [theme.breakpoints.down('sm')]: {
      padding: '0px',
    },
  },
  title: {
    color: '#6F6E6B',
    paddingTop: '10px',
    fontWeight: '600',
  },
}));

export default useStyles;
