import { useState } from 'react';

export default function useCreateUser() {
  const [openCreateUser, setOpenCreateUser] = useState(false);
  const handleOpenCreateUser = () => {
    setOpenCreateUser(true);
  };

  const handleCloseCreateUser = () => {
    setOpenCreateUser(false);
  };

  return {
    openCreateUser,
    handleOpenCreateUser,
    handleCloseCreateUser,
  };
}
