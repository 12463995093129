/**
 * Login selector
 *
 * This function create an selector
 *
 */

export const selectorActualAccountName = (state) => {
  const { account: { accounts } } = state;
  return (
    accounts ? accounts.name : null
  );
};

export default selectorActualAccountName;
