/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { Redirect } from 'react-router';

import CreateUserContainer from 'containers/createUser/';
import RecoverPasswordContainer from 'containers/recoverPassword/';
import ResetPasswordContainer from 'containers/resetPassword/';
import Accounts from 'containers/selectAccount/index';
import CreateAccountContainer from 'containers/createAccount/index';

import Login from 'pages/login/index';
import SnackbarHendrix from 'components/snackbarHendrix/index';
import DialogWrongCredentials from 'components/dialogWrongCredentials';

import { createLogin } from 'services/actions/login/index';
import { registerUser } from 'services/actions/user/index';
import { actions } from 'services/actions/searchAccount';

import useOpenClose from 'hooks/useOpenClose';
import useCreateUser from 'hooks/useCreateUser';

import { loginError } from 'constants/strings';

const LoginContainer = () => {
  const { token } = useParams();
  const hasErrors = typeof token !== 'undefined';
  const { registerToken } = useParams();
  const [messageRegister, setMessageRegister] = useState('');
  const [error, setError] = useState('');
  const [dataAccount, setDataAccounts] = useState({});
  const dispatch = useDispatch();

  const [checkboxStatus, setCheckboxStatus] = useState(false);

  const {
    open,
    handleClose,
    handleOpen,
  } = useOpenClose(false);

  const {
    openCreateUser,
    handleOpenCreateUser,
    handleCloseCreateUser,
  } = useCreateUser();

  const {
    open: openRecoverPassword,
    handleOpen: handleOpenRecoverPassword,
    handleClose: handleCloseRecoverPassword,
  } = useOpenClose();

  const {
    open: openResetPassword,
    handleOpen: handleOpenResetPassword,
  } = useOpenClose();

  const {
    open: openAccounts,
    handleClose: closeAccounts,
  } = useOpenClose(true);

  const register = async () => {
    const response = await dispatch(registerUser(registerToken));
    let message = (
      <>
        Tu usuario se ha registrado
        <span style={{ color: '#E26376' }}> exitosamente</span>
        .
      </>
    );
    const { action: { payload: { data, status } } } = response;

    if (status >= 500) {
      message = (
        <span style={{ color: '#E26376' }}>Error en el servidor, intentalo más tarde.</span>
      );
    } else if (status >= 299) {
      message = (
        <>
          Error al completar registro.
          <span style={{ color: '#E26376' }}>
            {' '}
            {data.message}
          </span>
        </>
      );
    }

    setMessageRegister(message);
    handleOpen();
  };

  if (!messageRegister && registerToken) {
    register();
  }

  const handleSubmit = async (dataUser) => {
    setError('');
    const response = await dispatch(createLogin(dataUser));
    const {
      action: {
        payload: {
          data,
          status,
          message,
        },
      },
    } = response;

    if (status >= 200 && status < 300) {
      localStorage.setItem('token', data.access_token);
      const { accounts } = data;
      if (accounts.length === 1) {
        localStorage.setItem('act', accounts[0].id);
        const { hasAccount } = actions;
        dispatch(hasAccount(accounts[0]));
      }
      setDataAccounts(accounts);
    } else {
      setError(message);
    }
  };

  const session = !!localStorage.getItem('token');
  const act = !!localStorage.getItem('act');

  return (
    <div>
      <CreateUserContainer
        open={openCreateUser}
        handleOpen={handleOpenCreateUser}
        handleClose={handleCloseCreateUser} />
      <RecoverPasswordContainer
        open={openRecoverPassword}
        handleOpen={handleOpenRecoverPassword}
        handleClose={handleCloseRecoverPassword} />
      <ResetPasswordContainer
        token={hasErrors ? token : ''}
        open={hasErrors && !openResetPassword}
        handleClose={handleOpenResetPassword} />
      { session && act
        ? <Redirect to="/dashboard/panel" />
        : '' }
      { dataAccount.length === 0 && session && !act ? (
        <CreateAccountContainer />
      ) : '' }
      { dataAccount.length > 1 && session && !act ? (
        <Accounts open={openAccounts} handleCloseAccounts={closeAccounts} acts={dataAccount} />
      ) : '' }
      { dataAccount.length === 1 && session && act
        ? <Redirect to="/dashboard/panel" />
        : (
          <Login
            checkboxStatus={checkboxStatus}
            setCheckbox={setCheckboxStatus}
            onSubmit={handleSubmit}
            handleOpenCreateUser={handleOpenCreateUser}
            handleOpenRecoverPassword={handleOpenRecoverPassword} />
        ) }
      {error && (
        <DialogWrongCredentials
          opn
          reset={() => {}}
          data={loginError}
          errorApi={error}
          handleOpenCreateUser={handleOpenCreateUser}
          handleOpenRecoverPassword={handleOpenRecoverPassword} />
      )}
      <SnackbarHendrix
        open={open}
        handleClose={handleClose}>
        <Typography variant="body2" color="secondary">
          {messageRegister}
        </Typography>
      </SnackbarHendrix>
    </div>
  );
};

export default LoginContainer;
