import { getTaskTagsAPI } from 'services/apis/taskTag';
import { GET_TASK_TAGS } from 'constants/taskTag';

/*
 *
 * This function get a task tags by task id.
 *
 * @param {string} account - account related to user.
 * @param {number} taskId - Task id.
 *
 */
export const getTaskTags = (account, taskId) => ({
  type: GET_TASK_TAGS,
  payload: getTaskTagsAPI(account, taskId),
});
