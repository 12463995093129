import React from 'react';
import PropTypes from 'prop-types';
import dateformat from 'dateformat';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  convertMinsToTime,
  getTotalTimeOfTask,
  getDiferenceTimesOfTask,
  normaliseValesLinearProgress,
} from 'utils/convertMinsToTime';
import Table from 'components/table/index';
import Headers from 'constants/headersTableTimesheets';
import useStyles from './styles';

function Timesheets(props) {
  const classes = useStyles();

  const { timesheets, estimated } = props;

  const estimatedTime = convertMinsToTime(estimated);

  const totalTime = getTotalTimeOfTask(timesheets);
  const registerTime = convertMinsToTime(totalTime);

  const diferenceTime = convertMinsToTime(
    getDiferenceTimesOfTask(estimated, totalTime),
  );

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          sm={12}>
          <Typography
            align="center"
            variant="body2"
            className={classes.textTitleLinearProgress}>
            Tiempo registrado / estimado
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          style={{ textAlign: 'end', paddingBottom: '20px' }}>
          <Typography
            variant="subtitle2"
            className={totalTime > estimated ? classes.textExceededTimes : ''}>
            Restante
            {' '}
            {diferenceTime}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}>
          <LinearProgress
            variant="determinate"
            color={totalTime > estimated ? 'primary' : 'secondary'}
            value={
              totalTime < estimated
                ? normaliseValesLinearProgress(totalTime, estimated)
                : totalTime
            } />
        </Grid>
        <Grid
          item
          xs={6}
          sm={6}
          style={{ textAlign: 'center', paddingTop: '20px' }}>
          <Typography
            variant="body2"
            display="inline"
            className={
              totalTime > estimated ? classes.textExceededTimes : classes.textRegistedTime
            }>
            Registrado
            {' '}
            {registerTime}
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          sm={6}
          style={{ textAlign: 'center', paddingTop: '20px' }}>
          <Typography
            variant="body2"
            display="inline"
            className={
              totalTime > estimated
                ? classes.textExceededTimes
                : classes.textEstimatedTime
            }>
            Estimado
            {' '}
            {estimatedTime}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}>
          <Typography variant="body2" display="inline" className={classes.titleRegister}>
            <br />
            Registro de tiempos
            <br />
            <br />
          </Typography>
          <Typography variant="body2" display="inline" className={classes.titleRegister}>
            Mostrar
          </Typography>
          <Typography variant="body2" display="inline" className={classes.titleRegister}>
            <span
              className="material-icons"
              style={{ paddingLeft: '5px', paddingRight: '5px', fontSize: '16px', verticalAlign: 'middle' }}>
              filter_alt
            </span>
          </Typography>
          <Typography variant="body2" display="inline" className={classes.textTitleLinearProgress}>
            Usuarios
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}>
          <Table
            headers={Headers}>
            {timesheets.map((row) => (
              <TableRow key={row.id}>
                <TableCell align="left">{row.user.nickname}</TableCell>
                <TableCell align="left">{convertMinsToTime(row.time)}</TableCell>
                <TableCell align="left">{dateformat(row.date, 'yy/m/d')}</TableCell>
                <TableCell align="left">{dateformat(row.date, 'HH:MM')}</TableCell>
                <TableCell align="left">{row.on_behalf_of_full.nickname}</TableCell>
                <TableCell align="center">
                  <CreateIcon className={classes.iconSize} />
                </TableCell>
                <TableCell align="center">
                  <DeleteIcon className={classes.iconSize} />
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </Grid>
      </Grid>
    </div>
  );
}

Timesheets.propTypes = {
  estimated: PropTypes.number.isRequired,
  timesheets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      time: PropTypes.number.isRequired,
      user_id: PropTypes.number.isRequired,
      on_behalf_of: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

export default Timesheets;
