import { makeStyles } from '@material-ui/core/styles';
import colors from 'constants/colors';

const useStyles = makeStyles({
  contained: {
    backgroundColor: colors.fourth,
    boxShadow: 'none',
    color: '#fff',
    fontWeight: 'bold',
    marginTop: '4px',
    marginRight: '12pt',
  },
});

export default useStyles;
