import colors from 'constants/colors';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  icon: {
    transform: 'rotate(360deg)',
    width: 20,
    height: 20,
  },
  remove: {
    fill: colors.primary,
    width: 20,
    height: 20,
  },
  textCount: {
    marginLeft: '5px',
    transform: 'rotate(180deg)',
    fontSize: '12px',
    fontWeight: '600',
    color: colors.secondary,
  },
}));

export default useStyles;
