/**
 * Project groupers selector
 *
 * This function create an selector
 *
 */

export const projectGroupers = (state) => {
  const { projectGroupers: { data } } = state;
  return !data ? null : data;
};

export default projectGroupers;
