export const GET_TASKS = 'GET_TASKS';
export const RESET_TASKS = 'RESET_TASKS';

export const notTask = 'No hay tareas';
export const moreTask = 'VER 10 TAREAS MÁS';

// Constants for resource_view endpoint finder

export const taskStandard = 'task_standard';
export const taskDistilled = 'task_distilled';
export const taskDistilledWithTimesheets = 'task_distilled_with_timesheets';
export const taskDistilledWithConversations = 'task_distilled_with_conversations';
export const taskDistilledWithSuccessors = 'task_distilled_with_successors';
export const taskMinimal = 'task_minimal';
