import {
  createTimesheetsTask,
  getTimesheetsTask,
  updateTimesheetsTask,
  deleteTimesheetsTask,
} from 'services/apis/timesheets';
import {
  CREATE_TIMESHEETS,
  UPDATE_TIMESHEETS,
  DELETE_TIMESHEETS,
  GET_TIMESHEETS,
} from 'constants/timesheets';


export const getTimesheets = (account, data) => ({
  type: GET_TIMESHEETS,
  payload: getTimesheetsTask(account, data),
});

/*
 * @typedef {Object} dataTask
 * @property {number} taskId - Task Identificator to which task the timesheet belongs.
 * @property {number} onBehalfOf - Optional - Third party user id.
 * @property {number} expenseId - Optional - Identificator to which expense the timesheet belongs.
 * @property {date} date - Date about the registry.
 * @property {number} time - How many minutes(>0) is the timesheet.
 * @property {number} invoiceId - Identificator to which invoice the timesheet belongs.
 * @property {number} invoiceDetailId - Optional -
 *                    Identificator to which invoice detail the timesheet belongs.
 *
 * This function create a timesheets.
 *
 * @param {string} account - account related to user.
 * @param {dataTimesheets} data - Data for creating a timesheets.
 */
export const createTimesheets = (account, data) => ({
  type: CREATE_TIMESHEETS,
  payload: createTimesheetsTask(account, data),
});

/*
 * @typedef {Object} dataTask
 * @property {number} onBehalfOf - Optional - Third party user id.
 * @property {date} date - Date about the registry.
 * @property {number} time - How many minutes(>0) is the timesheet.
 *
 * This function makes a request PUT for update a timesheets by timesheets id.
 *
 * @param {string} account - account related to user.
 * @param {number} timesheetsId - Timesheets id.
 *
 */
export const updateTimesheets = (account, id, data) => ({
  type: UPDATE_TIMESHEETS,
  payload: updateTimesheetsTask(account, id, data),
});

/*
 * This function makes a request DELETE for delete a timesheets by timesheets id.
 *
 * @param {string} account - account related to user.
 * @param {number} timesheetsId - Timesheets id.
 *
 */
export const deleteTimesheets = (account, id) => ({
  type: DELETE_TIMESHEETS,
  meta: {
    timeId: id,
  },
  payload: deleteTimesheetsTask(account, id),
});
