import React from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Typography from '@material-ui/core/Typography';

import { PrimaryButton } from 'components/primaryButton/index';
import SnackbarHendrix from 'components/snackbarHendrix/index';
import RenderTime from 'components/timeField/index';
import RenderTextAreaAutosize from 'components/renderTextAreaAutosize/index';

import TaskDetailSelector from 'containers/taskUserSelector';
import PrioritySelector from 'containers/priorityBadge/index';
import StatusSelector from 'containers/statusBagde';

import useStyles from './styles';

const validate = (values) => {
  const errors = {};
  const fields = {
    dataConcept: 'Concepto',
  };
  Object.keys(fields).forEach((key) => {
    if (!values[key]) errors[key] = `El campo ${fields[key]} es requerido`;
  });
  return errors;
};

function CreateTaskForm(props) {
  const classes = useStyles();

  const {
    open,
    handleClose,
    messageAfterSubmit,
    setMessageAfterSubmit,
    helperTexts,
    pristine,
    submitting,
    onSubmit,
    handleSubmit,
    projects,
    groupers,
    responsible,
    petitioner,
    handleShowProjectGrid,
    handleShowPetitionerGrid,
    handleShowPriorityGrid,
    handleReset,
    showProject,
    showPetitioner,
    showPriority,
    concept,
    characters,
    projectLabel,
    groupersLabel,
    petitionerLabel,
    responsibleLabel,
    changeProjectLabel,
    changeGroupersLabel,
    changePetitionerLabel,
    changeResponsibleLabel,
    idProject,
    idGroupers,
    idPetitioner,
    idResponsible,
    setIdProject,
    setIdGroupers,
    setIdPetitioner,
    setIdResponsible,
    priority,
    setPriority,
    idPriority,
    setIdPriority,
    status,
    setStatus,
    idStatus,
    setIdStatus,
    statusesTaks,
    setHelperTexts,
    setCharacters,
    setConcept,
  } = props;

  const readOnly = false;
  const style = characters > 250 ? { color: 'red' } : { color: '#9A9895' };

  const handleCloseSnackbarHendrix = () => {
    setMessageAfterSubmit('');
    handleClose();
    setHelperTexts({});
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" name="formCreateTask">
        <Grid item xs={12}>
          <Field
            name="dataConcept"
            id="dataConcept"
            placeholder="Escribe aquí tu tarea"
            component={RenderTextAreaAutosize}
            inputProps={{ value: concept }}
            fullWidth={false}
            className={classes.field}
            setCharacters={setCharacters}
            changeConcept={setConcept}
            showProject={showProject}
            handleShowProjectGrid={handleShowProjectGrid}
            withIconNext
            styleIcon={classes.iconNextConcept} />
        </Grid>
        <Grid
          container
          item
          xs={12}
          justify="flex-end"
          alignItems="flex-end">
          <Typography variant="caption" display="inline" gutterBottom style={style}>
            {characters}
            /
          </Typography>
          <Typography variant="caption" display="inline" gutterBottom style={style}>
            250
          </Typography>
        </Grid>
        {showProject && (
          <Grid item xs={12} sm={12} className={classes.gridPadding}>
            <Typography variant="caption" display="inline" gutterBottom className={classes.text}>
              para el
            </Typography>
            <Field
              id="projectId"
              name="projectId"
              placeholder="elige un proyecto"
              haveLabel
              haveAvatar={false}
              data={projects}
              label={projectLabel}
              changeLabel={changeProjectLabel}
              withDeleteIcon={false}
              idItem={idProject}
              changeIdItem={setIdProject}
              component={TaskDetailSelector} />
            <Typography variant="caption" display="inline" gutterBottom className={classes.text}>
              <br />
              agrupada en
            </Typography>
            <Field
              id="groupId"
              name="groupId"
              placeholder="elige un agrupador"
              haveLabel
              withDeleteIcon
              disabled={!(groupers && groupers.length > 0)}
              haveAvatar={false}
              data={groupers}
              label={groupersLabel}
              changeLabel={changeGroupersLabel}
              idItem={idGroupers}
              changeIdItem={setIdGroupers}
              component={TaskDetailSelector} />
            {!showPetitioner && (
              <NavigateNextIcon
                className={classes.iconNext}
                onClick={handleShowPetitionerGrid} />
            )}
          </Grid>
        )}
        {showPetitioner && (
          <Grid item xs={12} sm={12} className={classes.gridPadding}>
            <Typography variant="caption" display="inline" gutterBottom className={classes.text}>
              solicitada por
            </Typography>
            <Field
              id="petitionerId"
              name="petitionerId"
              placeholder="elige un usuario"
              data={petitioner}
              disabled={!(petitioner && petitioner.length > 0)}
              label={petitionerLabel}
              haveLabel
              haveAvatar
              withDeleteIcon={false}
              changeLabel={changePetitionerLabel}
              idItem={idPetitioner}
              changeIdItem={setIdPetitioner}
              component={TaskDetailSelector} />
            <Typography variant="caption" display="inline" gutterBottom className={classes.text}>
              <br />
              que debe ser realizada por
            </Typography>
            <Field
              id="responsibleId"
              name="responsibleId"
              placeholder="elige un usuario"
              data={responsible}
              label={responsibleLabel}
              disabled={!(responsible && responsible.length > 0)}
              haveLabel
              haveAvatar
              withDeleteIcon={false}
              changeLabel={changeResponsibleLabel}
              idItem={idResponsible}
              changeIdItem={setIdResponsible}
              component={TaskDetailSelector} />
            {!showPriority && (
            <NavigateNextIcon
              className={classes.iconNext}
              onClick={handleShowPriorityGrid} />
            )}
          </Grid>
        )}
        {showPriority && (
          <>
            <Grid item xs={12} sm={12} className={classes.gridPadding}>
              <Typography variant="caption" display="inline" gutterBottom className={classes.text}>
                con una prioridad
              </Typography>
              <Field
                id="priorityId"
                name="priorityId"
                idPriority={idPriority}
                namePriority={priority}
                disabled={readOnly}
                setIdPriority={setIdPriority}
                setPriority={setPriority}
                component={PrioritySelector} />
              <Typography variant="caption" display="inline" gutterBottom className={classes.text}>
                <br />
                en estado
              </Typography>
              <Field
                id="statusId"
                name="statusId"
                idStatus={idStatus}
                nameStatus={status}
                disabled={readOnly}
                setIdStatus={setIdStatus}
                setStatus={setStatus}
                statuses={statusesTaks}
                component={StatusSelector} />
            </Grid>
            <Grid item xs={12} sm={12} className={classes.gridPadding}>
              <Typography variant="caption" display="inline" gutterBottom className={classes.text}>
                con un tiempo estimado de
              </Typography>
              <Field
                id="estimatedTime"
                name="estimatedTime"
                component={RenderTime}
                inputProps={{
                  value: '00:00',
                }} />
              <Typography variant="caption" display="inline" gutterBottom className={classes.text}>
                <br />
                del cual se ha invertido
              </Typography>
              <Field
                id="timesheets"
                name="timesheets"
                component={RenderTime}
                inputProps={{
                  value: '00:00',
                }} />
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={12}
              justify="flex-end"
              alignItems="flex-end"
              className={classes.gridPadding}>
              <Button
                variant="contained"
                className={classes.buttonCancel}
                onClick={handleReset}>
                Cancelar
              </Button>
              <PrimaryButton
                size="medium"
                text="Crear Tarea"
                type="submit"
                disabled={pristine || submitting}
                onClick={handleSubmit(onSubmit)} />
            </Grid>
          </>
        )}
      </form>
      <SnackbarHendrix
        open={open}
        handleClose={handleCloseSnackbarHendrix}>
        {helperTexts && Object.keys(helperTexts).length > 0
          ? Object.values(helperTexts).map((x) => (
            <Typography variant="body2" color="error" key={x}>
              {x}
              <br />
            </Typography>
          )) : (
            <Typography variant="body2" color="primary">
              {messageAfterSubmit}
            </Typography>
          )}
      </SnackbarHendrix>
    </div>
  );
}

CreateTaskForm.defaultProps = {
  projects: {},
  groupers: {},
  responsible: {},
  petitioner: {},
  idPetitioner: null,
  statusesTaks: [],
  setCharacters: () => {},
  setConcept: () => {},
};

CreateTaskForm.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  messageAfterSubmit: PropTypes.string.isRequired,
  helperTexts: PropTypes.instanceOf(Object).isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  projects: PropTypes.instanceOf(Array),
  groupers: PropTypes.instanceOf(Array),
  responsible: PropTypes.instanceOf(Array),
  petitioner: PropTypes.instanceOf(Array),
  showProject: PropTypes.bool.isRequired,
  showPetitioner: PropTypes.bool.isRequired,
  showPriority: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleShowProjectGrid: PropTypes.func.isRequired,
  handleShowPriorityGrid: PropTypes.func.isRequired,
  handleShowPetitionerGrid: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  concept: PropTypes.string.isRequired,
  characters: PropTypes.number.isRequired,
  projectLabel: PropTypes.string.isRequired,
  changeProjectLabel: PropTypes.func.isRequired,
  groupersLabel: PropTypes.string.isRequired,
  changeGroupersLabel: PropTypes.func.isRequired,
  petitionerLabel: PropTypes.string.isRequired,
  changePetitionerLabel: PropTypes.func.isRequired,
  responsibleLabel: PropTypes.string.isRequired,
  changeResponsibleLabel: PropTypes.func.isRequired,
  idProject: PropTypes.number.isRequired,
  setIdProject: PropTypes.func.isRequired,
  idGroupers: PropTypes.number.isRequired,
  setIdGroupers: PropTypes.func.isRequired,
  idPetitioner: PropTypes.number,
  setIdPetitioner: PropTypes.func.isRequired,
  idResponsible: PropTypes.number.isRequired,
  setIdResponsible: PropTypes.func.isRequired,
  priority: PropTypes.string.isRequired,
  setPriority: PropTypes.func.isRequired,
  idPriority: PropTypes.number.isRequired,
  setIdPriority: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  setStatus: PropTypes.func.isRequired,
  idStatus: PropTypes.number.isRequired,
  setIdStatus: PropTypes.func.isRequired,
  statusesTaks: PropTypes.instanceOf(Array),
  setMessageAfterSubmit: PropTypes.func.isRequired,
  setHelperTexts: PropTypes.func.isRequired,
  setCharacters: PropTypes.func,
  setConcept: PropTypes.func,
};

export default (reduxForm({
  form: 'formCreateTask',
  validate,
}))(CreateTaskForm);
