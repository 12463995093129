import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { CreateUser } from 'components/createUser/index';
import { createUser } from 'services/actions/user/index';
import { TransparentDialog } from 'components/transparentDialog/index';
import Typography from '@material-ui/core/Typography';
import PersonAdd from '@material-ui/icons/PersonAdd';
import Grid from '@material-ui/core/Grid';
import useOpenClose from 'hooks/useOpenClose';
import PropTypes from 'prop-types';
import { reset } from 'redux-form';

export default function CreateUserContainer(props) {
  const {
    open,
    handleOpen,
    handleClose,
  } = props;
  const dispatch = useDispatch();
  const [helperTexts, setHelperTexts] = useState({
    username: '',
    email: '',
    password: '',
    confirm: '',
  });

  const [messageAfterSubmit, setMessageAfterSubmit] = useState('');

  const {
    open: openDialog,
    handleClose: handleCloseDialog,
    handleOpen: handleOpenDialog,
  } = useOpenClose();

  const handleSubmit = async (dataUser) => {
    try {
      await dispatch(createUser(dataUser));
      setHelperTexts({});
      dispatch(reset('CreateUser'));
      setMessageAfterSubmit(
        'Te hemos enviado un correo electrónico donde puedes confirmar el registro de tu usuario.',
      );
      handleOpenDialog();
      handleClose();
    } catch (e) {
      const {
        data,
        status,
      } = e;
      if (status >= 500 || status === 404) {
        setMessageAfterSubmit('Hubo un error, por favor intenta más tarde.');
        dispatch(reset('CreateUser'));
        handleOpenDialog();
        handleClose();
      } else {
        const errors = {};
        data.forEach((item) => {
          errors[item.param] = item.message;
        });
        setHelperTexts(errors);
      }
    }
  };

  return (
    <div>
      <CreateUser
        onSubmit={handleSubmit}
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        helperTexts={helperTexts}
        initialValues={{
          username: '',
          email: '',
          password: '',
          confirm: '',
        }} />
      <TransparentDialog
        handleClose={handleCloseDialog}
        open={openDialog}
        aria-labelledby="dialog-register-user-message"
        disableBackdropClick
        fullWidth
        maxWidth="xs">
        <Grid
          container
          direction="column"
          justify="space-between"
          alignItems="stretch"
          spacing={2}>
          <Grid
            item
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={1}>
            <Grid item xs={2} sm={1}>
              <PersonAdd style={{ color: 'white' }} />
            </Grid>
            <Grid item xs={6} sm={8}>
              <Typography variant="h6" style={{ color: 'white' }}>
                Registrar un usuario nuevo
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <center>
              <Typography color="primary">
                {messageAfterSubmit}
              </Typography>
            </center>
          </Grid>
        </Grid>
      </TransparentDialog>
    </div>
  );
}

CreateUserContainer.propTypes = {
  open: PropTypes.bool.isRequired,
  handleOpen: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};
