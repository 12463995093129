import {
  GET_PROJECT_GROUPERS,
} from 'constants/projectGroupers';

/*
 *
 * Reducer of project groupers
 *
 */

const initialState = {
  isLoading: false,
  data: [],
  error: null,
};

const projectGroupers = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case `${GET_PROJECT_GROUPERS}_REJECTED`: {
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    }
    case `${GET_PROJECT_GROUPERS}_PENDING`: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case `${GET_PROJECT_GROUPERS}_FULFILLED`: {
      const { data: { results } } = payload;
      return {
        ...state,
        isLoading: false,
        data: results,
        error: null,
      };
    }
    default:
      return state;
  }
};

export default projectGroupers;
