import instanceAPI from 'services/apis/axiosconfig';

/*
 * This function makes a request GET for a projects.
 *
 * @param {string} account - account related to user.
 */

export const getProjectsAPI = (account, offset, limit, orderBy, filter) => {
  let params = '';

  Object.keys(filter).forEach((key) => {
    if (filter[key] !== undefined) params += `${key}=${filter[key]}&`;
  });

  params = `${orderBy ? `&order_by=${orderBy}` : ''}${params ? `&${params}` : ''
  }`;

  const request = instanceAPI.get(
    `/accounts/${account}/projects?offset=${offset}&limit=${limit}&include=companies,projects_statuses${params}`,
  );
  return request
    .then((res) => ({
      ...res,
      data: {
        results: res.data.results,
        pagination: {
          offset,
          limit,
          orderBy,
          totalCount: res.data.x_total_count,
          filter,
        },
        filter,
      },
    }));
};
