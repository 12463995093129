import React from 'react';
import PropTypes from 'prop-types';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import IconButton from '@material-ui/core/IconButton';
import useStyles from './styles';

export default function Favorite(props) {
  const classes = useStyles();
  const { isStarring, hasInsideView, handleClick, conversationId } = props;
  const disabled = !hasInsideView;

  const icon = isStarring ? (
    <StarIcon className={classes.iconFavorite} />
  ) : (
    <StarBorderIcon className={classes.icon} />
  );

  return (
    <IconButton
      className={classes.iconButton}
      aria-label="Favorito"
      disabled={disabled}
      onClick={() => handleClick(conversationId, isStarring)}>
      {icon}
    </IconButton>
  );
}
Favorite.propTypes = {
  isStarring: PropTypes.bool.isRequired,
  hasInsideView: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  conversationId: PropTypes.number.isRequired,
};
