/**
 * Conversations selector
 *
 * This function create an selector
 *
 */

export const selectorConversations = (state) => state.conversations;

export default selectorConversations;
