/* ---------------------------------------------------------
 * Actions task conversation
 * ---------------------------------------------------------- */
import {
  RESET_CONVERSATIONS,
  GET_CONVERSATIONS,
  STAR_CONVERSATION,
  UNSTAR_CONVERSATION,
  MAKE_PUBLIC_CONVERSATION,
  MAKE_PRIVATE_CONVERSATION,
  CREATE_CONVERSATION,
} from 'constants/conversations';

import {
  getConversationsAPI,
  starConversationAPI,
  unstarConversationAPI,
  makePublicConversationAPI,
  makePrivateConversationAPI,
  createConversationAPI,
} from 'services/apis/conversations';

/**
 * Action creator to get the conversations for a task
 * API Doc: https://hendrix.vincoorbisdev.com/docs/api/#api-conversations-GetConversations
 * @param {string} accountName - User's account name of the desired task stream
 * @param {Number} TaskId - Identifier Task
 *
 */
export const getConversations = (
  accountName,
  taskId,
  offset = 0,
  limit = 10,
  orderBy = '',
  filter = {},
) => ({
  type: GET_CONVERSATIONS,
  payload: getConversationsAPI(accountName, taskId, offset, limit, orderBy, filter),
});

/**
 * Action creator to star a conversation for a task
 * API Doc: https://hendrix.vincoorbisdev.com/docs/api/#api-conversations-StarConversation
 * @param {string} accountName - User's account name of the desired task stream
 * @param {Number} conversationId - Identifier Conversation
 *
 */
export const starConversation = (accountName, conversationId) => ({
  type: STAR_CONVERSATION,
  meta: { conversationId },
  payload: starConversationAPI(accountName, conversationId),
});

/**
 * action creator to unstar a conversation for a task
 * api doc: https://hendrix.vincoorbisdev.com/docs/api/#api-conversations-unstarconversation
 * @param {string} accountname - user's account name of the desired task stream
 * @param {number} conversationid - identifier conversation
 *
 */
export const unstarConversation = (accountName, conversationId) => ({
  type: UNSTAR_CONVERSATION,
  meta: { conversationId },
  payload: unstarConversationAPI(accountName, conversationId),
});

/**
 * Action creator to make public a conversation for a task
 * API Doc: https://hendrix.vincoorbisdev.com/docs/api/#api-conversations-MakePublicConversation
 * @param {string} accountName - User's account name of the desired task stream
 * @param {Number} conversationId - Identifier Conversation
 * @param {Number} taskId - Identifier Task
 *
 */
export const makePublicConversation = (
  accountName,
  conversationId,
) => ({
  type: MAKE_PUBLIC_CONVERSATION,
  meta: { conversationId },
  payload: makePublicConversationAPI(accountName, conversationId),
});

/**
 * Action creator to make private a conversation for a task
 * API Doc: https://hendrix.vincoorbisdev.com/docs/api/#api-conversations-MakePrivateConversation
 * @param {string} accountName - User's account name of the desired task stream
 * @param {Number} conversationId - Identifier Conversation
 * @param {Number} taskId - Identifier Task
 *
 */
export const makePrivateConversation = (
  accountName,
  conversationId,
) => ({
  type: MAKE_PRIVATE_CONVERSATION,
  meta: { conversationId },
  payload: makePrivateConversationAPI(accountName, conversationId),
});

/*
 *
 * @typedef {Object} dataConversation
 * @property {integer} taskId - Task identifier
 * @property {integer} statusId - Status identifier
 * @property {integer} operationId - Operation identifier
 * @property {integer} roleId(optional) - Role identifier
 * @property {integer} parentId - patent identifier
 * @property {string} content - Content of the comment
 * @property {boolean} isVisible - flag to indicate if the conversation is public or private
 *
 * Action creator to create a conversation for a task
 * API Doc: https://hendrix.vincoorbisdev.com/docs/api/#api-conversations-PostConversation
 * @param {string} accountName - User's account name of the desired task stream
 * @typedef {dataConversation} data - Object with the data to create a conversation
 *
 */
export const createConversation = (accountName, data) => ({
  type: CREATE_CONVERSATION,
  payload: createConversationAPI(accountName, data),
});

export const resetConversations = () => ({
  type: RESET_CONVERSATIONS,
  payload: null,
});

export default getConversations;
