import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Prompt } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { reset } from 'redux-form';
import { selectorActualAccountName } from 'services/selectors/account';
import { selectorUserProfileId } from 'services/selectors/userProfile';
import { selectorProjectsList } from 'services/selectors/projectsList';
import { selectorProjectRoleOfUser } from 'services/selectors/projectPrivileges';
import { selectorProject } from 'services/selectors/project';
import { selectorLogin } from 'services/selectors/login';
import { getUserProfile } from 'services/actions/userProfile';
import { getProject, updateProject } from 'services/actions/project';
import { getProjectPrivileges, resetProjectPrivileges } from 'services/actions/projectPrivileges';
import { getProjectsList, resetProjects } from 'services/actions/projectsList';
import UpdateProjectContainer from 'containers/updateProject/index';
import Project from 'components/project/index';
import SnackbarHendrix from 'components/snackbarHendrix/index';
import Error from 'components/error/index';
import Loading from 'components/loading/index';
import FloatingMenu from 'components/floatingMenu/index';
import { Grid, Typography } from '@material-ui/core';
import actionsProjectMenu from 'constants/projectMenu';
import useBeforeUnload from 'hooks/useBeforeUnload';
import useOpenClose from 'hooks/useOpenClose';

function ProjectContainer() {
  const { askBeforeUnload, removeAskBeforeUnload, setAskBeforeUnload } = useBeforeUnload(false);
  const { id } = useParams();
  const dispatch = useDispatch();

  const {
    open: openSnackbar,
    handleClose: handleCloseSnackbar,
    handleOpen: handleOpenSnackbar,
  } = useOpenClose();

  const { data: { id: globalId } } = useSelector(selectorLogin.login);
  const [activeOptionMenu, setActiveOptionMenu] = useState(null);
  const [messageSubmit, setMessageSubmit] = useState('');
  const [helperTexts, setHelperTexts] = useState({
    nameProject: '',
    key: '',
    startDate: '',
    endDate: '',
    description: '',
  });

  const project = useSelector(selectorProject);
  const actualAccountName = useSelector(selectorActualAccountName);
  const profileId = useSelector(selectorUserProfileId);
  const userRole = useSelector((state) => selectorProjectRoleOfUser(state, profileId));
  const {
    pagination: {
      limit,
    },
  } = useSelector(selectorProjectsList);

  const handleOptionMenu = (option) => {
    setActiveOptionMenu(option);
    if (option) {
      setAskBeforeUnload();
    } else {
      removeAskBeforeUnload();
    }
  };

  useEffect(() => {
    dispatch(getUserProfile(actualAccountName, globalId));
    dispatch(getProject(actualAccountName, id));
    setActiveOptionMenu(null);
  }, [actualAccountName, id, dispatch, globalId]);


  useEffect(() => {
    removeAskBeforeUnload();
    setActiveOptionMenu(null);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [id]);


  useEffect(() => {
    if (id && profileId) {
      dispatch(resetProjectPrivileges());
      dispatch(getProjectPrivileges(actualAccountName, id));
    }
  }, [actualAccountName, dispatch, id, profileId]);

  useEffect(() => {
    dispatch(getProject(actualAccountName, id));
    setActiveOptionMenu(null);
  }, [actualAccountName, id, dispatch]);

  const handleReloadProjectList = () => {
    dispatch(resetProjects());
    dispatch(
      getProjectsList(
        actualAccountName,
        0,
        limit,
        '',
        {},
      ),
    );
  };

  const handleSubmit = async (dataProject) => {
    let data = {};
    let response;
    let status;

    data.name = dataProject.nameProject;
    data.key = dataProject.key;
    data.start_date = dataProject.startDate;
    data.end_date = dataProject.endDate;
    data.status_id = dataProject.statusId;
    data.conclusion_requirements = dataProject.conclusionRequirements;
    data.invoiced_by = dataProject.invoicedBy;
    data.default_ticket = dataProject.defaultTicket;
    data.description = dataProject.description;
    data.role_id = dataProject.roleId;

    try {
      response = await dispatch(updateProject(actualAccountName, id, data));
      status = response.action.payload.status;
    } catch (error) {
      status = error.status;
      data = error.data;
      setMessageSubmit(error.message);
    }

    if (status >= 500 || status === 404) {
      dispatch(reset('UpdateProjectForm'));
      setMessageSubmit('Hubo un error, por favor intenta más tarde.');
    } else if (status === 409) {
      setHelperTexts({});
      setMessageSubmit('Ya existe un proyecto con el mismo nombre. Por favor inténtalo de nuevo.');
      handleOpenSnackbar();
    } else if (status === 400) {
      const errors = {};
      data.forEach((item) => {
        errors[item.param] = item.message;
      });
      setHelperTexts(errors);
      handleOpenSnackbar();
    } else {
      dispatch(reset('UpdateProjectForm'));
      handleReloadProjectList();
      setHelperTexts({});
      setMessageSubmit('Se actualizó el proyecto correctamente.');
      handleOpenSnackbar();
      removeAskBeforeUnload();
      setActiveOptionMenu(null);
    }
  };


  if (!project.data || project.isLoading) {
    return <Loading />;
  }

  if (project.error && project.error.status >= 500) {
    return (
      <Error message={project.error.message} />
    );
  }
  return (
    <>
      <Prompt
        when={askBeforeUnload}
        message="Tienes cambios sin guardar, ¿estás seguro de salir?" />
      <Grid container direction="column">
        <FloatingMenu
          onAction={handleOptionMenu}
          actions={actionsProjectMenu}
          disabled={activeOptionMenu !== null} />
        <Grid item>
          { activeOptionMenu === 'Editar proyecto' && id !== 'new' ? (
            <UpdateProjectContainer
              optionMenu={setActiveOptionMenu}
              handleSubmit={handleSubmit}
              helperTexts={helperTexts} />
          ) : ''}
          {activeOptionMenu === null && project.data.projects_status
            ? (<Project data={project.data} userRole={userRole} />) : ''}
        </Grid>
      </Grid>
      <SnackbarHendrix
        open={openSnackbar}
        handleClose={handleCloseSnackbar}>
        {helperTexts && Object.keys(helperTexts).length > 0
          ? Object.values(helperTexts).map((x) => (
            <Typography variant="body2" styles={{ color: '#FFFFFF' }} key={x}>
              {x}
              <br />
            </Typography>
          )) : (
            <Typography variant="body2" styles={{ color: '#FFFFFF' }}>
              {messageSubmit}
            </Typography>
          )}
      </SnackbarHendrix>
    </>
  );
}

export default ProjectContainer;
