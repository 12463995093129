import { makeStyles } from '@material-ui/core/styles';
import colors from 'constants/colors';
import { menuWidth as drawerWidth } from 'constants/menu';

const widthItemList = `${drawerWidth}px`;

const useStyles = makeStyles((theme) => ({
  drawerOpen: {
    width: drawerWidth,
    borderRight: 'none',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    borderRight: 'none',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7) + 1,
    },
    [theme.breakpoints.down('sm')]: {
      width: '0px',
    },
  },
  drawerPaper: {
    borderRight: 'none',
    width: drawerWidth,
  },
  drawer: {
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  toolbar: theme.mixins.toolbar,
  nested: {
    paddingLeft: theme.spacing(9),
    width: widthItemList,
    '&:hover': {
      backgroundColor: colors.fifth,
      color: colors.primary,
    },
  },
  activeItem: {
    backgroundColor: colors.primary,
    color: colors.fifth,
    '& *': {
      color: colors.fifth,
    },
    '&:hover': {
      backgroundColor: colors.primary,
      color: colors.fifth,
    },
    width: widthItemList,
    padding: '4px 10px 4px 0',
    borderRadius: '1px',
  },
  item: {
    '&:hover': {
      backgroundColor: colors.primary,
      color: colors.fifth,
      '& *': {
        color: colors.fifth,
      },
    },
    width: widthItemList,
    borderRadius: '0.5px',
    padding: '4px 10px 4px 0',
  },
  font: {
    fontSize: '10pt',
  },
  icon: {
    margin: '0 16px 0 20px',
    color: colors.seconday,
  },
  planeLink: {
    textDecoration: 'none',
    color: colors.secondary,
  },
  activeLink: {
    visited: { color: colors.secondary },
    color: colors.primary,
  },
}));

export default useStyles;
