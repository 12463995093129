import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  timeContainer: {
    marginTop: '20px',
  },
  containerButtons: {
    float: 'right',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'right',
      marginBottom: '10px',
      marginTop: '10px',
    },
  },
}));

export default useStyles;
