import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import Build from '@material-ui/icons/Build';


const NotAvailable = (props) => {
  const {
    legend,
    showItem,
    styles,
  } = props;
  return (
    <Grid container align="center" justify="center">
      <Grid item>
        <h1 style={styles}>{legend}</h1>
        { showItem ? <Build style={{ fontSize: 80 }} /> : '' }
      </Grid>
    </Grid>
  );
};

NotAvailable.defaultProps = {
  legend: 'Be patient, this will be done soon.',
  showItem: true,
  styles: {},
};

NotAvailable.propTypes = {
  legend: PropTypes.string,
  showItem: PropTypes.bool,
  styles: PropTypes.instanceOf(Object),
};

export default NotAvailable;
