import React from 'react';
import PropTypes from 'prop-types';
import NotificationPanel from 'components/notificationPanel/index';
import { notificationsData } from 'constants/notification';

export default function NotificationContainer(props) {
  const {
    handleClose,
    open,
  } = props;

  return (
    <NotificationPanel
      onClose={handleClose}
      open={open}
      dataNotifications={notificationsData} />
  );
}

NotificationContainer.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
