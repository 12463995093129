import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { getTimesheets } from 'services/actions/timesheetsTask';
import TimesheetsRegister from 'components/timesheets/index';
import Loading from 'components/loading/index';
import { timesheetsTask, estimatedTimeTask } from 'services/selectors/timesheetsTask';

function Timesheets(props) {
  const dispatch = useDispatch();

  const {
    account,
    taskId,
  } = props;

  useEffect(() => {
    const timesheets = () => {
      dispatch(getTimesheets(account, taskId));
    };
    return timesheets();
  }, [account, taskId, dispatch]);

  const timesheets = useSelector(timesheetsTask);
  const estimated = useSelector(estimatedTimeTask);

  if (timesheets.isLoading) {
    return <Loading />;
  }

  return (
    <TimesheetsRegister
      timesheets={timesheets}
      estimated={estimated} />
  );
}

Timesheets.propTypes = {
  account: PropTypes.string.isRequired,
  taskId: PropTypes.string.isRequired,
};

export default Timesheets;
