import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Tooltip, IconButton } from '@material-ui/core';

import {
  Remove,
  Whatshot,
} from '@material-ui/icons';

import { titleCustom } from 'utils/customTitleBioColor';

import veryHigh from 'assets/iconsSvgs/muyAlta.svg';
import high from 'assets/iconsSvgs/alta.svg';
import low from 'assets/iconsSvgs/baja.svg';
import veryLow from 'assets/iconsSvgs/muyBaja.svg';

const useStyles = makeStyles(() => ({
  urgent: {
    color: '#E01731',
  },
  normal: {
    color: '#2AAB7E',
  },
  MuiIconButtonLabel: {
    borderRight: '2px solid #9A9895',
    paddingRight: ' 7px',
  },
  icon: {
    width: '18px',
    height: '18px',
  },
  iconBtn: {
    padding: '0 7px',
    borderLeft: '1px solid #9A9895',
    borderRight: '1px solid #9A9895',
    borderRadius: 0,
    height: '20px',
    marginTop: '2px',
  },
}));

const IconMD = (props) => {
  const classes = useStyles();
  const { priority, priorityName } = props;

  const checkPriority = () => {
    switch (priority) {
      case 1:
        return <Tooltip title={titleCustom('Prioridad', priorityName)}><Whatshot className={`${classes.urgent} ${classes.icon}`} /></Tooltip>;
      case 2:
        return <Tooltip title={titleCustom('Prioridad', priorityName)}><img src={veryHigh} alt="very high" className={`${classes.icon}`} /></Tooltip>;
      case 3:
        return <Tooltip title={titleCustom('Prioridad', priorityName)}><img src={high} alt="high" className={`${classes.icon}`} /></Tooltip>;
      case 4:
        return <Tooltip title={titleCustom('Prioridad', priorityName)}><Remove className={`${classes.normal} ${classes.icon}`} /></Tooltip>;
      case 5:
        return <Tooltip title={titleCustom('Prioridad', priorityName)}><img src={low} alt="low" className={`${classes.icon}`} /></Tooltip>;
      case 6:
        return <Tooltip title={titleCustom('Prioridad', priorityName)}><img src={veryLow} alt="very low" className={`${classes.icon}`} /></Tooltip>;
      default:
        return '';
    }
  };

  return (
    <IconButton aria-label="icons-tools" className={classes.iconBtn}>
      {checkPriority()}
    </IconButton>
  );
};

IconMD.propTypes = {
  priority: PropTypes.number.isRequired,
  priorityName: PropTypes.string.isRequired,
};

export default IconMD;
