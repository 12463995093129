export const alphaNumeric = (value, field) => (
  value && !/^[a-zA-Z0-9]+$/i.test(value)
    ? `El ${field} es inválido`
    : undefined);

export const email = (value) => (
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'El correo electrónico es inválido'
    : undefined);

export default email;
