import React from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import { Grid, Typography } from '@material-ui/core';
import LabelIcon from '@material-ui/icons/Label';
import useStyles from './styles';

const ExpandAddIcon = (props) => {
  const classes = useStyles();
  const { totalTags } = props;

  return (
    <div>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center">
        <Typography className={classes.textCount}>{`+${totalTags}`}</Typography>
        <LabelIcon className={classes.iconLabel} />
        <AddIcon className={classes.iconAdd} />
      </Grid>
    </div>
  );
};

ExpandAddIcon.propTypes = {
  totalTags: PropTypes.number.isRequired,
};

export default ExpandAddIcon;
