import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import useStyles from './styles';

function Company(props) {
  const {
    data,
  } = props;
  const classes = useStyles();

  return (
    <Grid container direction="column">
      <Grid item className={classes.gridText}>
        <Typography variant="body2">
          Nombre:
        </Typography>
      </Grid>
      <Grid item className={classes.gridText}>
        <Typography variant="h6">
          {data.name}
        </Typography>
      </Grid>
      <Grid item className={classes.gridCheckbox}>
        <Typography
          variant="subtitle1"
          style={{ fontWeight: 'bold' }}>
          <Checkbox
            disabled
            color="primary"
            defaultChecked={data.is_invoice_issuer} />
          Emite Facturas
        </Typography>
      </Grid>
      <Grid item className={classes.gridCheckbox}>
        <Typography
          variant="subtitle1"
          style={{ fontWeight: 'bold' }}>
          <Checkbox
            disabled
            color="primary"
            defaultChecked={data.is_default} />
          Default
        </Typography>
      </Grid>
      <Grid item className={classes.gridText}>
        <Typography variant="body2">
          RFC:
        </Typography>
      </Grid>
      <Grid item className={classes.gridText}>
        <Typography variant="h6">
          {data.tax_id_number ? data.tax_id_number : 'Sin RFC'}
        </Typography>
      </Grid>
    </Grid>
  );
}

Company.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default Company;
