/* ---------------------------------------------------------
 * Reducer for companies
 * --------------------------------------------------------- */
import {
  RESET_COMPANIES,
  GET_COMPANIES,
} from 'constants/companies';


const initialState = {
  data: [],
  isLoading: false,
  pagination: {
    limit: 10,
    offset: 0,
    orderBy: null,
    totalCount: 0,
  },
  filter: {},
  error: null,
};

const companies = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case `${GET_COMPANIES}_REJECTED`:
      return {
        ...state,
        isLoading: false,
        error: {
          ...payload,
          fromAction: GET_COMPANIES,
        },
      };
    case `${GET_COMPANIES}_PENDING`:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case `${GET_COMPANIES}_FULFILLED`: {
      const { data: { pagination, results } } = payload;

      return {
        ...state,
        isLoading: false,
        error: null,
        pagination: {
          limit: pagination.limit,
          offset: pagination.offset,
          orderBy: pagination.orderBy,
          totalCount: pagination.totalCount,
        },
        data: [...state.data, ...results],
        filter: pagination.filter,
      };
    }
    case `${RESET_COMPANIES}`:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default companies;
