import React from 'react';
import PropTypes from 'prop-types';
import RemoveIcon from '@material-ui/icons/Remove';
import { Grid, Typography } from '@material-ui/core';
import LabelIcon from '@material-ui/icons/Label';
import useStyles from './styles';

const ExpandRemoveIcon = (props) => {
  const { totalTags } = props;
  const classes = useStyles();

  return (
    <div>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center">

        <RemoveIcon className={classes.remove} />
        <LabelIcon className={classes.icon} />
        <Typography className={classes.textCount}>{`+${totalTags}`}</Typography>
      </Grid>
    </div>
  );
};

ExpandRemoveIcon.propTypes = {
  totalTags: PropTypes.number.isRequired,
};

export default ExpandRemoveIcon;
