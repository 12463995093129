/**
 * Predecessors tasks selector
 *
 * This function create an selector
 *
 */

export const selectorPredecessorsTaks = (state) => state.predecessorsTasks;

export default selectorPredecessorsTaks;
