import { makeStyles } from '@material-ui/core/styles';
import colors from 'constants/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '16px',
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    '& .MuiAppBar-colorDefault': {
      backgroundColor: '#FFFFFF',
      color: colors.secondary,
    },
    '& .MuiTabs-root': {
      minHeight: '1px',
    },
    '& .MuiTab-root': {
      textTransform: 'capitalize',
      fontFamily: 'Muli',
      fontSize: '10pt',
      minHeight: '1px',
      padding: '5px',
    },
    '& .MuiTab-textColorInherit.Mui-selected': {
      color: colors.secondary,
    },
    '& .MuiTab-labelIcon .MuiTab-wrapper > *:first-child': {
      width: '18px',
      height: '19px',
      marginRight: '18px',
      marginBottom: '0px',
      [theme.breakpoints.down('sm')]: {
        marginRight: '0px',
      },
    },
    '& .MuiTab-wrapper': {
      flexDirection: 'initial',
      justifyContent: 'initial',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    '& .MuiPaper-elevation4': {
      boxShadow: '0px 0px 0px 0px rgba(0,0,0,0)',
    },
    '& .MuiTabs-indicator': {
      backgroundColor: colors.secondary,
    },
    '& .MuiBox-root': {
      paddingTop: '20px',
      paddingBottom: '25px',
      paddingRight: '10px',
      paddingLeft: '0px',
    },
  },
}));

export default useStyles;
