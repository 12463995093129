/**
 * Task selector
 *
 * This function create an selector
 *
 */

export const selectorTasksList = (state) => state.tasksList;

export default selectorTasksList;
