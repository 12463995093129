import instanceAPI from 'services/apis/axiosconfig';

/*
 * Returns a list of project groupers and the total count of rows.
 *
 * @param {account} account - Account related to user
 * @param {projectId} projectId - projectId related to user
 *
 * @return {object} - data information about projects objects and the total count of rows.
 */
export function getProjectGroupers(account, projectId) {
  const request = instanceAPI.get(`/accounts/${account}/projects/${projectId}/groups`);
  return request;
}

export default getProjectGroupers;
