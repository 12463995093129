/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useRef, useEffect } from 'react';
import 'showdown-prettify';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import emoji from '@jukben/emoji-search';
import { withStyles } from '@material-ui/core/styles';
import ReactTextareaAutocomplete from '@webscopeio/react-textarea-autocomplete';
import { ShowdownHendrix } from 'utils/showdownHendrix';
import Loading from 'components/loading/index';
import ToolbarMDButton from 'components/toolbarButton/index';
import { Typography, Grid, IconButton } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { PrimaryButton } from 'components/primaryButton/index';
import { SecondaryButton } from 'components/secondaryButton/index';
import RenderTime from 'components/timeField';
import styles from './styles';
import { insertSymbol, wrapSelectedRangeWithSymbols } from '../../utils/helpersMDEditor';
import Toolbar from './Toolbar';
import TaskItem from './TaskItem';
import EmojiItem from './EmojiItem';
import UserItem from './UserItem';

function MDEditor(props) {
  const [showToolbar, setShowToolbar] = useState(false);
  const [preview, setPreview] = useState(false);
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [errorFile, setErrorFile] = useState('Ocurrió un error al subir tus archivos, intenta de nuevo más tarde.');
  const converter = new ShowdownHendrix();
  const rta = useRef(null);
  const {
    success,
    disabledAction,
    withEmoji,
    withUsers,
    withTasks,
    tasks,
    users,
    onSubmit,
    defaultValue,
    classes,
    actionText,
    placeholder,
    errorMessage,
    transparent,
    isToggleVisibility,
    handleToggleVisibility,
    handleCancelComment,
    handleValueTimeChange,
    valueTime,
  } = props;

  useEffect(() => {
    if (disabledAction && success) {
      setValue('');
    }
  }, [disabledAction, success]);

  const getSelectedRange = () => {
    const { selectionStart, selectionEnd } = rta.current.getSelectionPosition();
    return {
      start: selectionStart,
      end: selectionEnd,
    };
  };

  const setCaretPosition = (position) => { rta.current.setCaretPosition(position); };

  const getAvailableTriggers = () => {
    let triggers;
    if (withEmoji) {
      triggers = {
        ...triggers,
        ':': {
          dataProvider: (token) => emoji(token)
            .slice(0, 10)
            .map(({ name, char }) => ({ name, char })),
          component: EmojiItem,
          output: (item) => item.char,
        },
      };
    }

    if (withUsers) {
      triggers = {
        ...triggers,
        '@': {
          dataProvider: (token) => users.filter(
            (item) => item.fullName.toLowerCase().includes(token.toLowerCase())
              || item.nickname.toLowerCase().includes(token.toLowerCase()),
          ),
          component: UserItem,
          output: (item) => `@${item.nickname}`,
        },
      };
    }

    if (withTasks) {
      triggers = {
        ...triggers,
        '#': {
          dataProvider: (token) => tasks.filter((item) => item.concept
            .toLowerCase()
            .includes(
              token.toLowerCase()
              || item.concept.toLowerCase().includes(token.toLowerCase()),
            )),
          component: TaskItem,
          output: (item) => `#id:${item.id}&slug:${item.slug}`,
        },
      };
    }
    return triggers;
  };

  const handleValueChange = (e) => {
    const newValue = e.target.value !== null && e.target.value !== undefined
      ? e.target.value
      : e.currentTarget.value;

    setValue(newValue);
  };

  const handleAction = () => {
    const content = defaultValue === '' ? value : `${defaultValue} <br/> ${value}`;

    onSubmit(content);
    setValue('');
  };

  const uploadFile = async (file) => {
    setLoading(true);
    const data = new FormData();
    const fileName = file[0].name
      .split('.')
      .slice(0, -1)
      .join('.');

    data.append('file', file[0]);
    data.append('upload_preset', 'comments');

    try {
      const res = await fetch(process.env.REACT_APP_CLOUDINARY_CONTAINER, {
        method: 'POST',
        body: data,
      });

      const fileResponse = await res.json();

      if (fileResponse.error) {
        setLoading(false);
        setErrorFile('El Formato del archivo que acabas de subir no es valido. (solo es compatible imagenes y archivos PDF)');
        setUploadError(true);
      } else {
        setUploadError(false);
      }

      const action = {
        prefix: `![${fileName}](${fileResponse.secure_url})`,
        suffix: '',
      };

      if (fileResponse.secure_url) {
        setLoading(false);
        const range = getSelectedRange();
        const { start, end } = range;

        if (start === end) {
          const caretPosition = start;
          const { text: newValue, added } = insertSymbol(
            value,
            caretPosition,
            action,
          );
          setValue(newValue);
          setTimeout(() => {
            const newPosition = added
              ? caretPosition + action.prefix.length
              : caretPosition - action.prefix.length;
            setCaretPosition(newPosition);
          }, 1000);
        } else {
          const { text: newValue } = wrapSelectedRangeWithSymbols(
            value,
            range,
            action,
          );
          setValue(newValue);
        }
      }
    } catch (err) {
      setUploadError(true);
      setLoading(false);
    }
  };


  const availableTriggers = getAvailableTriggers();
  return (
    <div className={classes.wrapper}>
      <div
        className={classNames(
          classes.header,
          transparent ? classes.headerTransparent : null,
        )}>
        <nav>
          <button
            type="button"
            className={classNames(classes.navButton, {
              'navButton-selected': !preview,
            })}
            onClick={() => setPreview(false)}>
            Escribir
          </button>
          <button
            type="button"
            className={classNames(classes.navButton, {
              'navButton-selected': preview,
            })}
            onClick={() => setPreview(true)}>
            Vista Previa
          </button>
        </nav>

        {!preview && (
          <ToolbarMDButton handleClick={() => setShowToolbar(!showToolbar)} active={showToolbar} />
        )}
      </div>
      {(!preview && showToolbar) && (
      <Toolbar
        getSelectedRange={getSelectedRange}
        setCaretPosition={setCaretPosition}
        value={value}
        className={classes.showToolbar}
        onChange={handleValueChange} />
      )}
      {preview ? (
        <div>
          <article
            className={classNames(classes.preview, 'markdown-body')}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: converter.makeHtml(
                `${defaultValue} <br/>
                ${value}
                `,
              ),
            }} />
        </div>
      ) : (
        <>
          <ReactTextareaAutocomplete
            className={classes.textarea}
            loadingComponent={Loading}
            placeholder={placeholder}
            ref={rta}
            containerClassName={classes.textareaContainer}
            dropdownClassName={classes.dropdown}
            listClassName={classes.autocompleteList}
            itemClassName={classes.autocompleteItem}
            minChar={0}
            trigger={availableTriggers}
            value={value}
            onChange={handleValueChange} />
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start" />
          <Dropzone onDrop={(file) => uploadFile(file)}>
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} className={classes.dropzone}>
                <input {...getInputProps()} />
                {loading && <Loading />}
                {uploadError && !loading && (
                <p className={classes.textDropZone}>
                  {errorFile}
                </p>
                )}

                {!uploadError && !loading && (
                <p className={classes.textDropZone}>
                  Arrastra y suelta un archivo / imagen o haz click aqui.
                </p>
                )}
              </div>
            )}
          </Dropzone>

          {errorMessage && (
          <p className={classes.errorMessage}>{errorMessage}</p>
          )}
        </>
      )}
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start">
        <div className={classes.form}>
          <Grid item xs={12} sm={7} md={7}>
            <div className={classes.timeContainer}>
              <Typography variant="caption" className={classes.visibleText}>
                Agregar tiempo
              </Typography>
              <RenderTime
                input={{ onChange: handleValueTimeChange }}
                inputProps={{ value: valueTime }} />
              <Typography variant="caption" className={classes.visibleText}>
                Visibilidad
              </Typography>
              <IconButton className={classes.visibleButton} onClick={handleToggleVisibility}>
                {isToggleVisibility ? (<VisibilityIcon />) : (<VisibilityOffIcon />)}
              </IconButton>
            </div>
          </Grid>
          <Grid item xs={12} sm={7} md={7}>
            <div className={classes.containerButtons}>
              <SecondaryButton
                size="small"
                variant="contained"
                text="Cancelar"
                fullWidth={false}
                onClick={handleCancelComment} />
              <PrimaryButton
                size="small"
                variant="contained"
                disabled={value.replace(/\s/g, '') === '' || disabledAction}
                text={actionText}
                onClick={handleAction} />
            </div>
          </Grid>
        </div>
      </Grid>
    </div>
  );
}

MDEditor.propTypes = {
  withEmoji: PropTypes.bool,
  withUsers: PropTypes.bool,
  withTasks: PropTypes.bool,
  classes: PropTypes.instanceOf(Object).isRequired,
  actionText: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  success: PropTypes.bool,
  errorMessage: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      concept: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      slug: PropTypes.string.isRequired,
    }),
  ),
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      nickname: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  transparent: PropTypes.bool,
  defaultValue: PropTypes.string,
  disabledAction: PropTypes.bool,
  isToggleVisibility: PropTypes.bool.isRequired,
  handleToggleVisibility: PropTypes.func.isRequired,
  handleCancelComment: PropTypes.func.isRequired,
  handleValueTimeChange: PropTypes.func.isRequired,
  valueTime: PropTypes.string.isRequired,
};

MDEditor.defaultProps = {
  withEmoji: true,
  withUsers: true,
  withTasks: true,
  placeholder: '',
  success: null,
  errorMessage: null,
  tasks: null,
  users: null,
  transparent: false,
  defaultValue: '',
  disabledAction: false,
};

export const MDEditorComponent = MDEditor;
export default withStyles(styles)(MDEditor);
