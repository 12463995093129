import { createUserAPI, registerUserAPI } from 'services/apis/users';
import { CREATE_USER, REGISTER_USER } from 'constants/user';

/*
 * User's actions
 */

/*
 * @typedef {Object} dataUser
 * @property {string} username - User's username
 * @property {string} password . User's password
 * @property {confirm} confirm - Confirm user's password
 * @property {email} email - User's email
 *
 *
 * This function create an user
 *
 * @param {dataUser} data - Data for creating a user
 *
 */
export const createUser = (data) => ({
  type: CREATE_USER,
  payload: createUserAPI(data),
});

/*
 * This function register an user
 *
 * @param {string} token - Token to finish the register for an user.
 *
 */
export const registerUser = (token) => ({
  type: REGISTER_USER,
  payload: registerUserAPI(token),
});

export default createUser;
