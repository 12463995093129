import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import logo from 'assets/img/safari-pinned-tab.svg';

const useStyles = makeStyles({
  iconBadge: {
    '&:disabled': {
      color: '#CBC9C6',
    },
    '&:active': {
      color: 'rgba(111, 110, 107, .88)',
    },
    color: '#E6F6E6B',
    width: '22px',
  },
});

const HendrixLogoButton = (props) => {
  const classes = useStyles();

  const {
    handleClick,
    disabled,
  } = props;

  return (
    <IconButton disabled={disabled} onClick={handleClick}>
      <img src={logo} alt="logo" className={classes.iconBadge} />
    </IconButton>
  );
};

HendrixLogoButton.defaultProps = {
  disabled: false,
};

HendrixLogoButton.propTypes = {
  disabled: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
};

export default HendrixLogoButton;
