import React from 'react';
import { makeStyles } from '@material-ui/core';

import {
  ZoomIn,
  Delete,
  Inbox,
  BeachAccess,
  Done,
  CollectionsBookmark,
  PlayCircleFilledWhite,
  CheckCircle,
  DirectionsBike,
  TrackChanges,
  Cancel,

} from '@material-ui/icons';
import buildIcon from 'assets/iconsSvgs/construction.svg';
import {
  idProjectDeveloping,
  idProjectInMaintenance,
  idProjectProspectus,
  idProjectFinished,
  idProjectCancelled,
} from 'constants/project';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  reference: {
    color: '#9F9D9C',
  },
  trash: {
    color: '#9F9D9C',
  },
  someday: {
    color: '#9F9D9C',
  },
  accepted: {
    color: '#3F90BB',
  },
  finished: {
    color: '#88B572',
  },
  inbox: {
    color: '#C86375',
  },
  review: {
    color: '#FCD11E',
  },
  validated: {
    color: '#88B572',
  },
  developing: {
    color: '#3F90BB',
  },
  developingProject: {
    color: '#4899D1',
  },
  maintenance: {
    color: '#E2AB3D',
  },
  prospect: {
    color: '#84658C',
  },
  cancel: {
    color: '#9F9D9C',
  },
  icon: {
    width: '18px',
    height: '18px',
  },
  iconContainer: {
    marginTop: '5px',
    marginRight: '11px',
    paddingBottom: '0px',
    [theme.breakpoints.down('sm')]: {
      marginRight: '7px',
    },
  },
  statusName: {
    textTransform: 'capitalize',
    fontWeight: '300',
    fontSize: '12pt',
    margin: '0',
    paddingBottom: '5px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
}));

const StatusItem = (props) => {
  const classes = useStyles();
  const { status, statusName } = props;

  const checkStatus = () => {
    switch (status) {
      case 1:
        return (
          <div className={classes.root}>
            <div className={classes.iconContainer}>
              <CollectionsBookmark className={`${classes.reference} ${classes.icon}`} />
            </div>
            <p className={`${classes.statusName} ${classes.reference}`}>{statusName}</p>
          </div>
        );
      case 2:
        return (
          <div className={classes.root}>
            <div className={classes.iconContainer}>
              <Delete className={`${classes.trash} ${classes.icon}`} />
            </div>
            <p className={`${classes.statusName} ${classes.trash}`}>{statusName}</p>
          </div>
        );
      case 3:
        return (
          <div className={classes.root}>
            <div className={classes.iconContainer}>
              <BeachAccess className={`${classes.someday} ${classes.icon}`} />
            </div>
            <p className={`${classes.statusName} ${classes.someday}`}>{statusName}</p>
          </div>
        );
      case 4:
        return (
          <div className={classes.root}>
            <div className={classes.iconContainer}>
              <PlayCircleFilledWhite className={`${classes.accepted} ${classes.icon}`} />
            </div>
            <p className={`${classes.statusName} ${classes.accepted}`}>{statusName}</p>
          </div>
        );
      case 7:
        return (
          <div className={classes.root}>
            <div className={classes.iconContainer}>
              <CheckCircle className={`${classes.finished} ${classes.icon}`} />
            </div>
            <p className={`${classes.statusName} ${classes.finished}`}>{statusName}</p>
          </div>
        );
      case 8:
        return (
          <div className={classes.root}>
            <div className={classes.iconContainer}>
              <Inbox className={`${classes.inbox} ${classes.icon}`} />
            </div>
            <p className={`${classes.statusName} ${classes.inbox}`}>{statusName}</p>
          </div>
        );
      case 10:
        return (
          <div className={classes.root}>
            <div className={classes.iconContainer}>
              <ZoomIn className={`${classes.review} ${classes.icon}`} />
            </div>
            <p className={`${classes.statusName} ${classes.review}`}>{statusName}</p>
          </div>
        );
      case 11:
        return (
          <div className={classes.root}>
            <div className={classes.iconContainer}>
              <Done className={`${classes.validated} ${classes.icon}`} />
            </div>
            <p className={`${classes.statusName} ${classes.validated}`}>{statusName}</p>
          </div>
        );
      case 12:
        return (
          <div className={classes.root}>
            <div className={classes.iconContainer}>
              <DirectionsBike className={`${classes.developing} ${classes.icon}`} />
            </div>
            <p className={`${classes.statusName} ${classes.developing}`}>{statusName}</p>
          </div>
        );
      case idProjectDeveloping:
        return (
          <div className={classes.root}>
            <div className={classes.iconContainer}>
              <DirectionsBike className={`${classes.developing} ${classes.icon}`} />
            </div>
            <p className={`${classes.statusName} ${classes.developing}`}>{statusName}</p>
          </div>
        );
      case idProjectInMaintenance:
        return (
          <div className={classes.root}>
            <div className={classes.iconContainer}>
              <img src={buildIcon} alt="build icon" className={`${classes.icon}`} />
            </div>
            <p className={`${classes.statusName} ${classes.maintenance}`}>{statusName}</p>
          </div>
        );
      case idProjectProspectus:
        return (
          <div className={classes.root}>
            <div className={classes.iconContainer}>
              <TrackChanges className={`${classes.prospect} ${classes.icon}`} />
            </div>
            <p className={`${classes.statusName} ${classes.prospect}`}>{statusName}</p>
          </div>
        );
      case idProjectFinished:
        return (
          <div className={classes.root}>
            <div className={classes.iconContainer}>
              <CheckCircle className={`${classes.finished} ${classes.icon}`} />
            </div>
            <p className={`${classes.statusName} ${classes.finished}`}>{statusName}</p>
          </div>
        );
      case idProjectCancelled:
        return (
          <div className={classes.root}>
            <div className={classes.iconContainer}>
              <Cancel className={`${classes.cancel} ${classes.icon}`} />
            </div>
            <p className={`${classes.statusName} ${classes.cancel}`}>{statusName}</p>
          </div>
        );
      default:
        return '';
    }
  };

  return checkStatus();
};

export default StatusItem;
