import { useState } from 'react';

export default function useOpenClose(initialValue = false) {
  const [open, setOpen] = useState(initialValue);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return {
    open,
    handleOpen,
    handleClose,
  };
}
