import { makeStyles } from '@material-ui/core/styles';
import colors from 'constants/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    color: colors.primary,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(0),
    top: theme.spacing(0),
    color: theme.palette.grey[500],
  },
  margin: {
    margin: theme.spacing(2),
    marginRight: '40px',
    color: colors.primary,
  },
  buttonLeft: {
    margin: theme.spacing(2),
    marginRight: '40px',
    color: colors.secondary,
  },
}));

export default useStyles;
