import { GET_TASK_TAGS } from 'constants/taskTag';
/*
* Reducer Task Tags
*/
export const initialState = {
  isLoading: false,
  data: [],
  error: null,
};

const taskTag = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case `${GET_TASK_TAGS}_REJECTED`: {
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    }
    case `${GET_TASK_TAGS}_PENDING`: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case `${GET_TASK_TAGS}_FULFILLED`: {
      const { data: { results } } = payload;
      return {
        ...state,
        isLoading: false,
        data: results,
        error: null,
      };
    }
    default:
      return state;
  }
};

export default taskTag;
