/**
 * Companies selector
 *
 * This function create an selector
 *
 */

export const selectorCompanies = (state) => state.companies;

export const selectorGetCompanies = (state) => {
  const { companies: { data } } = state;
  return !data ? null : data;
};

export default selectorCompanies;
