/**
 * Task tags selector
 *
 * This function create an selector
 *
 */

export const selectorTags = (state) => state.taskTag;

export default selectorTags;
