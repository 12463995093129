import instanceAPI from 'services/apis/axiosconfig';

export function createAccountAPI(name, description, currency) {
  const request = instanceAPI.post('/accounts', {
    name,
    description,
    currency,
  });
  return request.then((res) => res).catch((err) => err);
}

export default createAccountAPI;
