const limit = (val, max) => {
  let limitMinutes = '';
  if (val.length === 1 && val[0] > max[0]) {
    limitMinutes = 5;
  }

  if (val.length === 2) {
    if (Number(val) === 0) {
      limitMinutes = '00';
    } else if (val > max) {
      limitMinutes = max;
    }
  }
  return limitMinutes !== '' ? limitMinutes : val;
};

const timeFormat = (val) => {
  let hours = '';
  let minutes = '';

  hours = val.substring(0, 2);
  minutes = limit(val.substring(2, 4), '59');

  return hours + (minutes.length ? `:${minutes}` : '');
};

export default timeFormat;
