import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { calculatePercentage } from 'utils/convertMinsToTime';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    marginLeft: '8px',
  },
  bottom: {
    color: '#A1C45A',
  },
  top: {
    position: 'absolute',
    left: 0,
  },
}));

function GraphCircle(props) {
  const {
    timeRegistered,
    timeEstimated,
  } = props;
  const classes = useStyles();
  const estimated = calculatePercentage(timeRegistered, timeEstimated);
  const currentColor = estimated > 100 ? '#DD2C09' : '#3F90BB';
  return (
    <div className={classes.root}>
      <CircularProgress
        style={{ width: '26px', height: '26px' }}
        variant="determinate"
        className={classes.bottom}
        size={40}
        thickness={4}
        value={100} />
      <CircularProgress
        style={{ width: '26px', height: '26px', color: currentColor }}
        className={classes.top}
        variant="static"
        value={estimated > 100 ? 100 : estimated} />
    </div>
  );
}

export default GraphCircle;


GraphCircle.propTypes = {
  timeRegistered: PropTypes.number.isRequired,
  timeEstimated: PropTypes.number.isRequired,
};
