import instanceAPI from 'services/apis/axiosconfig';

/**
 * Request to get the conversations for a task
 * API Doc: https://hendrix.vincoorbisdev.com/docs/api/#api-conversations-GetConversations
 * @param {string} accountName - User's account name of the desired task stream
 * @param {Number} TaskId - Identifier Task
 *
 */
export const getConversationsAPI = (
  accountName,
  taskId,
  offset,
  limit,
  orderBy,
  filter,

) => {
  let params = '';

  Object.keys(filter).forEach((key) => {
    if (filter[key] !== undefined) params += `${key}=${filter[key]}&`;
  });

  params = `${orderBy ? `&order_by=${orderBy}` : ''}${
    params ? `&${params}` : ''
  }`;

  const request = instanceAPI.get(
    `/accounts/${accountName}/tasks/${taskId}/conversations?offset=${offset}&limit=${limit}${params}`,
  );
  return request.then((res) => ({
    ...res,
    data: {
      results: res.data.results,
      pagination: {
        offset,
        limit,
        orderBy,
        totalCount: res.data.x_total_count,
        filter,
      },
      filter,
    },
  }));
};

/**
 * Request to star a conversation for a task
 * API Doc: https://hendrix.vincoorbisdev.com/docs/api/#api-conversations-StarConversation
 * @param {string} accountName - User's account name of the desired task stream
 * @param {Number} conversationId - Identifier Conversation
 *
 */
export const starConversationAPI = (accountName, conversationId) => (
  instanceAPI.put(
    `/accounts/${accountName}/conversations/starring/${conversationId}`,
  )
);

/**
 * Request to unstar a conversation for a task
 * api doc: https://hendrix.vincoorbisdev.com/docs/api/#api-conversations-unstarconversation
 * @param {string} accountname - user's account name of the desired task stream
 * @param {number} conversationid - identifier conversation
 *
 */
export const unstarConversationAPI = (accountName, conversationId) => (
  instanceAPI.delete(
    `/accounts/${accountName}/conversations/starring/${conversationId}`,
  )
);

/**
 * Request to make public a conversation for a task
 * API Doc: https://hendrix.vincoorbisdev.com/docs/api/#api-conversations-MakePublicConversation
 * @param {string} accountName - User's account name of the desired task stream
 * @param {Number} conversationId - Identifier Conversation
 * @param {Number} taskId - Identifier Task
 *
 */
export const makePublicConversationAPI = (
  accountName,
  conversationId,
) => (
  instanceAPI.put(
    `/accounts/${accountName}/conversations/visible/${conversationId}`,
  )
);

/**
 * Request to make private a conversation for a task
 * API Doc: https://hendrix.vincoorbisdev.com/docs/api/#api-conversations-MakePrivateConversation
 * @param {string} accountName - User's account name of the desired task stream
 * @param {Number} conversationId - Identifier Conversation
 * @param {Number} taskId - Identifier Task
 *
 */
export const makePrivateConversationAPI = (
  accountName,
  conversationId,
) => (
  instanceAPI.delete(
    `/accounts/${accountName}/conversations/visible/${conversationId}`,
  )
);

/*
 *
 * @typedef {Object} dataConversation
 * @property {integer} taskId - Task identifier
 * @property {integer} statusId - Status identifier
 * @property {integer} operationId - Operation identifier
 * @property {integer} roleId(optional) - Role identifier
 * @property {integer} parentId - patent identifier
 * @property {string} content - Content of the comment
 * @property {boolean} isVisible - flag to indicate if the conversation is public or private
 *
 * Request to create a conversation for a task
 * API Doc: https://hendrix.vincoorbisdev.com/docs/api/#api-conversations-PostConversation
 * @param {string} accountName - User's account name of the desired task stream
 * @typedef {dataConversation} data - Object with the data to create a conversation
 *
 */
export const createConversationAPI = (accountName, data) => (
  instanceAPI.post(
    `/accounts/${accountName}/conversations`,
    data,
  )
);

export default createConversationAPI;
