import { GET_TASKS, RESET_TASKS } from 'constants/tasks';
import { getAllTasksAPI } from 'services/apis/tasksList';
/*
 *
 * This action function get all tasks
 *
 */

export const getTasks = (
  accountName,
  offset = 0,
  limit = 10,
  orderBy = '',
  filter = {},
  resourceView = null,
) => ({
  type: GET_TASKS,
  payload: getAllTasksAPI(accountName, offset, limit, orderBy, filter, resourceView),
});

export const resetTasks = () => ({
  type: RESET_TASKS,
  payload: null,
});
