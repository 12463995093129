export const GET_COMPANIES = 'GET_COMPANIES';
export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const DELETE_COMPANY = 'DELETE_COMPANY';
export const CREATE_COMPANY = 'CREATE_COMPANY';
export const RESET_COMPANY = 'RESET_COMPANY';
export const GET_COMPANY = 'GET_COMPANY';
export const RESET_COMPANIES = 'RESET_COMPANIES';


export const moreCompanies = 'VER 10 COMPAÑÍAS MÁS';
export const noCompanies = 'No hay compañías que mostrar';

export default GET_COMPANIES;
