import { makeStyles } from '@material-ui/core/styles';
import colors from 'constants/colors';

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: '14px',
    fontWeight: '600',
    color: colors.secondary,
    '@media (max-width: 400px)': {
      fontSize: '12px',
    },
  },
  textEmpty: {
    fontSize: '12.5px',
    fontWeight: '600',
    color: colors.secondary,
    '@media (max-width: 400px)': {
      fontSize: '11px',
    },
  },
  icon: {
    paddinLeft: '50px',
    width: '60px',
    height: '60px',
  },
  chip: {
    fontWeight: '600',
    fontSize: '12px',
    color: colors.secondary,
    borderColor: 'white',
    paddingLeft: 0,
    paddingRight: 0,

  },
  ulChip: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    listStyle: 'none',
    paddingLeft: 0,
    paddingRight: 0,
    margin: 0,
  },
  expandTransform: {
    transform: 'rotate(-180deg)',
  },
  inputTag: {
    paddingLeft: '5px',
    fontSize: '12px',
    fontWeight: '600',
    '& label.Mui-focused': {
      color: '#CBC9C6',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#CBC9C6',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#CBC9C6',
    },
    '&::placeholder': {
      fontSize: '12px',
    },
  },
  buttonTag: {
    fontSize: '12px',
    fontWeight: '600',
    fontFamily: 'Muli',
  },
  borderPanel: {
    boxShadow: 'none',
    width: 550,
    [theme.breakpoints.down('sm')]: {
      width: 370,
    },
    '@media (max-width: 400px)': {
      width: 320,
    },
  },
  borderPanelExpanded: {
    padding: '5px',
    boxShadow: 'none',
    width: 720,
    [theme.breakpoints.down('sm')]: {
      width: 390,
    },
    '@media (max-width: 400px)': {
      width: 320,
    },
  },
  borderPanelSummary: {
    padding: '2px',
    boxShadow: 'none',
    width: 550,
    [theme.breakpoints.down('sm')]: {
      width: 395,
    },
    '@media (max-width: 400px)': {
      width: 305,
    },
  },
  detailSeparatedIcon: {
    paddingLeft: '8px',
    paddingTop: '10px',
    fontSize: '18px',
    '@media (max-width: 400px)': {
      paddingTop: '0px',
    },
  },
  detailIconReplay: {
    transform: 'scaleX(-1)',
    marginLeft: '15px',
    marginTop: '10px',
    '@media (max-width: 400px)': {
      marginTop: '0px',
      width: 20,
      height: 20,
    },
  },
  detailIcons: {
    marginLeft: '15px',
    marginTop: '10px',
    '@media (max-width: 400px)': {
      marginTop: '0px',
      width: 20,
      height: 20,
    },
  },
  detailHourglass: {
    width: 24,
    height: 24,
    marginLeft: '15px',
    marginTop: '10px',
    '@media (max-width: 400px)': {
      marginTop: '0px',
      width: 20,
      height: 20,
    },
  },
  gridOptions: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  gridDetail: {
    marginTop: '0px', marginBottom: '0px',
  },
}));

export default useStyles;
