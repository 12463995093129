/**
 * Login selector
 *
 * This function create an selector
 *
 */

const login = (state) => state.login;

export const selectorLogin = { login };
