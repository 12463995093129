/**
 * Timesheets selector
 *
 * This function create an selector
 *
 */

export const timesheetsTask = (state) => {
  const { timesheetsTask: { data } } = state;
  return !data ? null : data;
};

export const estimatedTimeTask = (state) => {
  const { task: { data } } = state;
  return (
    data ? data.estimated_time : null
  );
};
