import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { IconButton, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Picker from 'emoji-picker-react';
import LinkIcon from '@material-ui/icons/Link';
import CodeIcon from '@material-ui/icons/Code';
import ListAddIcon from '@material-ui/icons/PlaylistAddCheck';
import QuoteIcon from '@material-ui/icons/FormatQuote';
import BoldIcon from '@material-ui/icons/FormatBold';
import ItalicIcon from '@material-ui/icons/FormatItalic';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import useOpenClose from 'hooks/useOpenClose';
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import { insertSymbol, wrapSelectedRangeWithSymbols } from '../../utils/helpersMDEditor';

const styles = () => ({
  root: {
    padding: '8px',
  },
  emoticon: {
    zIndex: 3000,
    position: 'absolute',
  },
});

function Toolbar(props) {
  const {
    value,
    onChange,
    setCaretPosition,
    getSelectedRange,
    classes,
  } = props;
  const { open, handleClose, handleOpen } = useOpenClose();

  const onClickEmoticons = () => {
    if (open) {
      handleClose();
    } else {
      handleOpen();
    }
  };

  const insertSymbols = (action) => {
    const range = getSelectedRange();
    const { start, end } = range;

    if (start === end) {
      const caretPosition = start;
      const { text: newValue, added } = insertSymbol(
        value,
        caretPosition,
        action,
      );
      onChange({ target: { value: newValue } });
      setTimeout(() => {
        const newPosition = added
          ? caretPosition + action.prefix.length
          : caretPosition - action.prefix.length;
        setCaretPosition(newPosition);
      }, 1000);
    } else {
      const caretPosition = end;
      const { text: newValue, added } = wrapSelectedRangeWithSymbols(
        value,
        range,
        action,
      );
      onChange({ target: { value: newValue } });
      setTimeout(() => {
        const newPosition = added
          ? caretPosition + action.prefix.length
          : caretPosition - action.prefix.length;
        setCaretPosition(newPosition);
      }, 1000);
    }
  };

  const onEmojiClick = (e, emoji) => {
    insertSymbols({ prefix: emoji.emoji, suffix: '' });
    handleClose();
  };

  const insertUrl = () => {
    insertSymbols({ prefix: '[](url)', suffix: '' });
  };

  const insertQuote = () => {
    insertSymbols({ prefix: '>', suffix: ' ' });
  };

  const insertCode = () => {
    insertSymbols({ prefix: '`', suffix: '`' });
  };

  const insertBold = () => {
    insertSymbols({ prefix: '**', suffix: '**' });
  };

  const insertItalics = () => {
    insertSymbols({ prefix: '_', suffix: '_' });
  };

  const insertBulletedList = () => {
    insertSymbols({ prefix: '- ', suffix: ' ' });
  };

  const insertNumberedList = () => {
    insertSymbols({ prefix: '1. ', suffix: ' ' });
  };

  const insertTaskList = () => {
    insertSymbols({ prefix: '- [ ] ', suffix: ' ' });
  };

  return (
    <Grid>
      <Tooltip title="Add emoticons">
        <IconButton className={classes.root} onClick={onClickEmoticons}>
          <InsertEmoticonIcon />
        </IconButton>
      </Tooltip>
      { open
        && (
        <ClickAwayListener onClickAway={handleClose}>
          <div className={classes.emoticon}>
            <Picker onEmojiClick={onEmojiClick} disableSkinTonePicker />
          </div>
        </ClickAwayListener>
        )}

      <Tooltip title="Add a link">
        <IconButton className={classes.root} onClick={insertUrl}>
          <LinkIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Insert a quote">
        <IconButton className={classes.root} onClick={insertQuote}>
          <QuoteIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Insert code">
        <IconButton className={classes.root} onClick={insertCode}>
          <CodeIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Add bold text">
        <IconButton className={classes.root} onClick={insertBold}>
          <BoldIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Add italic text">
        <IconButton className={classes.root} onClick={insertItalics}>
          <ItalicIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Add a bulleted list">
        <IconButton
          className={classes.root}
          onClick={insertBulletedList}>
          <FormatListBulletedIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Add a numbered list">
        <IconButton
          className={classes.root}
          onClick={insertNumberedList}>
          <FormatListNumberedIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Add a task list">
        <IconButton className={classes.root} onClick={insertTaskList}>
          <ListAddIcon />
        </IconButton>
      </Tooltip>
    </Grid>
  );
}

Toolbar.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  setCaretPosition: PropTypes.func.isRequired,
  getSelectedRange: PropTypes.func.isRequired,
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(Toolbar);
