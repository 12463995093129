import instanceAPI from 'services/apis/axiosconfig';

/*
 * Reset user Password
 *
 * @property {string} password . User's password
 * @property {confirm} confirm - Confirm user's password
 *
 * This function create a request to change password to the API
 *
 * @param {resetData} resetData - Data for request a change of password
 *
 * @return {object} - resetData information about password changed or errors
 */
export function resetUserPasswordAPI(resetData) {
  const { token } = resetData;
  return instanceAPI.put(
    `/users/reset/${token}`,
    { ...resetData },
  ).then((response) => response).catch((response) => {
    const {
      data, status,
    } = response;

    return {
      data,
      status,
    };
  });
}

export default resetUserPasswordAPI;
