import { makeStyles } from '@material-ui/core/styles';
import colors from 'constants/colors';

const useStyles = makeStyles((theme) => ({
  autocomplete: {
    width: '100%',
  },
  inputContainer: {
    borderRadius: '5px',
    width: '100%',
  },
  avatar: {
    width: '26px',
    height: '26px',
    fontSize: '11pt',
  },
  inputSearch: {
    width: 'calc( 100% - 2px)',
    border: '1px solid #ccc',
    borderRadius: '5px',
    margin: '1px auto',
    fontSize: '12px',
  },
  list: {
    paddingTop: '0px',
    paddingBottom: '0px',
    display: 'inline-flex',
  },
  listItem: {
    padding: '0px 5px 0px 0px',
  },
  itemsList: {
    color: colors.fourth,
    '&:hover': {
      color: colors.primary,
    },
  },
  iconButton: {
    color: '#ccc',
  },
  root: {
    minWidth: '0px',
    '& .MuiListItemAvatar-root': {
      minWidth: '16px',
      minHeight: '16px',
    },
  },
  listItemText: {
    marginLeft: '5px',
    marginTop: '0px',
    '& .MuiTypography-body2': {
      fontSize: '15px',
    },
  },
  marginDiv: {
    margin: 0,
    display: 'inline-block',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '7px',
    },
  },
  iconChip: {
    fontWeight: '600',
    fontSize: '12px',
    color: colors.secondary,
    borderColor: 'white',
    paddingLeft: 0,
    paddingRight: 0,
    marginLeft: '-12px',
  },
}));

export default useStyles;
