export const statuses = [
  {
    id: 1,
    name: 'Urgente',
  },
  {
    id: 2,
    name: 'Muy Alta',
  },
  {
    id: 3,
    name: 'Alta',
  },
  {
    id: 4,
    name: 'Normal',
  },
  {
    id: 5,
    name: 'Baja',
  },
  {
    id: 6,
    name: 'Muy Baja',
  },
];

export default statuses;
