import { recoverUserPasswordAPI } from 'services/apis/recoverPassword';
import { RECOVER_PASSWORD } from 'constants/password';

/*
 * RecoverPassword's actions
 */

/*
 * @typedef {Object} data
 * @property {email} email - User's email
 */
export const recoverPasswordUser = (data) => ({
  type: RECOVER_PASSWORD,
  payload: recoverUserPasswordAPI(data),
});

export default recoverPasswordUser;
