import { resetUserPasswordAPI } from 'services/apis/resetPassword';
import { RESET_PASSWORD } from 'constants/password';

/*
 * ResetPassword's actions
 */

/*
 * @typedef {Object} data
 * @property {string} password . User's password
 * @property {confirm} confirm - Confirm user's password
 */
export const resetPasswordUser = (data) => ({
  type: RESET_PASSWORD,
  payload: resetUserPasswordAPI(data),
});

export default resetPasswordUser;
