import { GET_PROJECTS, RESET_PROJECTS } from 'constants/projects';

/*
 * Reducer Projects
 */

const initialState = {
  isLoading: false,
  data: [],
  pagination: {
    limit: 10,
    offset: 0,
    orderBy: null,
    totalCount: 0,
  },
  filter: {},
  error: null,
};

const projectsList = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case RESET_PROJECTS: {
      return {
        ...initialState,
      };
    }
    case `${GET_PROJECTS}_REJECTED`: {
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    }
    case `${GET_PROJECTS}_PENDING`: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case `${GET_PROJECTS}_FULFILLED`: {
      const { data: { pagination, results } } = payload;

      return {
        ...state,
        isLoading: false,
        error: null,
        pagination: {
          limit: pagination.limit,
          offset: pagination.offset,
          orderBy: pagination.orderBy,
          totalCount: pagination.totalCount,
        },
        data: [...state.data, ...results],
        filter: pagination.filter,
      };
    }
    default:
      return state;
  }
};

export default projectsList;
