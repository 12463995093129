/* ---------------------------------------------------------
 * Actions project privileges
 * ---------------------------------------------------------- */
import {
  RESET_PROJECT_PRIVILEGES,
  GET_PROJECT_PRIVILEGES,
} from 'constants/projectPrivileges';

import {
  getProjectPrivilegesAPI,
} from 'services/apis/projectPrivileges';

/**
 * Action creator to get the project privileges
 * @param {string} accountName - User's account name
 * @param {number} projectId - project's id
 * @param {number} offset - number since we wish take the privileges for paganition
 * @param {number} limit - limit to get elements, pagination.
 * @param {object} orderBy - Object with key to order the request
 * @param {object} filter - Object with filters int the request
 *
 */
export const getProjectPrivileges = (
  accountName,
  projectId,
  offset = 0,
  limit = 500,
  orderBy = '',
  filter = {},
) => ({
  type: GET_PROJECT_PRIVILEGES,
  payload: getProjectPrivilegesAPI(accountName, projectId, offset, limit, orderBy, filter),
});

export const resetProjectPrivileges = () => ({
  type: RESET_PROJECT_PRIVILEGES,
  payload: null,
});

export default getProjectPrivileges;
