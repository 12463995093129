import { makeStyles } from '@material-ui/core/styles';
import colors from 'constants/colors';

const useStyles = makeStyles(() => ({
  iconLabel: {
    transform: 'rotate(-180deg)',
    width: 20,
    height: 20,
  },
  iconAdd: {
    fill: colors.primary,
    width: 20,
    height: 20,
  },
  textCount: {
    marginRight: '5px',
    fontSize: '12px',
    fontWeight: '600',
    color: colors.secondary,
  },
}));

export default useStyles;
