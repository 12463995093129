import instanceAPI from 'services/apis/axiosconfig';

export const getAllTasksAPI = (
  accountName,
  offset,
  limit,
  orderBy,
  filter,
  resourceView,
) => {
  let params = '';

  Object.keys(filter).forEach((key) => {
    if (filter[key] !== undefined) params += `${key}=${filter[key]}&`;
  });

  params = `${orderBy ? `&order_by=${orderBy}` : ''}${
    params ? `&${params}` : ''
  }`;
  const request = instanceAPI.get(`/accounts/${accountName}/finder/tasks?offset=${offset}&limit=${limit}${params}&${resourceView ? `resource_view=${resourceView}` : ''}`);
  return request.then((res) => ({
    ...res,
    data: {
      results: res.data.results,
      pagination: {
        offset,
        limit,
        orderBy,
        totalCount: res.data.x_total_count,
      },
      filter,
    },
  }));
};
