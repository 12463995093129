import {
  getUserProfileAPI,
} from 'services/apis/userProfile';

import {
  GET_USER_PROFILE,
  RESET_USER_PROFILE,
} from 'constants/userProfile';

export const getUserProfile = (account, globalId) => ({
  type: GET_USER_PROFILE,
  payload: getUserProfileAPI(account, globalId),
});

export const resetUserProfile = () => ({
  type: RESET_USER_PROFILE,
  payload: {},
});

export default getUserProfile;
