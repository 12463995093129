import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import {
  getConversations,
  resetConversations,
  makePrivateConversation,
  makePublicConversation,
  unstarConversation,
  starConversation,
  createConversation,
} from 'services/actions/conversations';
import { selectorConversations } from 'services/selectors/conversations';
import { selectorActualAccountName } from 'services/selectors/account';
import { selectorTaskCreatedAt, selectorTask } from 'services/selectors/task';
import Conversations from 'components/conversations/index';
import { useParams } from 'react-router-dom';
import Error from 'components/error/index';
import { selectorUserHasInsideView, selectorUserNickName } from 'services/selectors/userProfile';
import { selectorLogin } from 'services/selectors/login';
import { getUserProfile } from 'services/actions/userProfile';
import SnackbarHendrix from 'components/snackbarHendrix';
import ConversationsTimeline from 'components/conversationsTimeline/';
import useWindowSize from 'hooks/useWindowSize';
import {
  Grid,
  Tooltip,
  IconButton,
  Typography,
} from '@material-ui/core';
import TimelineIcon from '@material-ui/icons/Timeline';
import moment from 'moment';
import useOpenClose from 'hooks/useOpenClose';
import { GET_CONVERSATIONS } from 'constants/conversations';
import widthMobile from 'constants/responsive';
import MDEditor from 'components/mdEditor/index';
import { createTimesheets, deleteTimesheets, updateTimesheets } from 'services/actions/timesheetsTask';
import { convertHoursToMins, convertMinsToTime, verifyTimesOfCurrentDay } from 'utils/convertMinsToTime';
import { timesheetsTask } from 'services/selectors/timesheetsTask';
import { usersOfProjectSelector } from 'services/selectors/usersOfProject';

import { usersOfProject } from 'services/actions/usersOfProject';

function ConversationsContainer(props) {
  const { optionMenu, handleCloseMenu } = props;
  const showComment = optionMenu === 'Comentar';
  let timeValue = '00:00';
  const dispatch = useDispatch();
  const { id } = useParams();
  const timesheets = useSelector(timesheetsTask);
  // eslint-disable-next-line camelcase
  const { data: { project_id } } = useSelector(selectorTask);
  const nickname = useSelector(selectorUserNickName);
  const date = moment().format('YYYY-MM-DD');
  const actualAccountName = useSelector(selectorActualAccountName);
  const taskCreatedAt = useSelector(selectorTaskCreatedAt);
  const userProject = useSelector(usersOfProjectSelector);
  const size = useWindowSize();
  const {
    open: openTimeline, handleOpen: handleOpenTimeline, handleClose: handleCloseTimeline,
  } = useOpenClose();
  const hasInsideView = useSelector(selectorUserHasInsideView);
  const { data: { id: globalId } } = useSelector(selectorLogin.login);
  const { open, handleOpen, handleClose } = useOpenClose(false);
  const {
    data,
    pagination: {
      offset,
      limit,
      orderBy,
      totalCount,
    },
    filter,
    error,
  } = useSelector(selectorConversations);
  const [hasNextPage, setHasNextPage] = useState();
  const [loadingScroll, setLoadingScroll] = useState();
  const [messageComment, setMessageComment] = useState('');
  const [visibilityFlag, setVisibilityFlag] = useState(false);
  const [valueTime, setValueTime] = useState(timeValue);

  let widthConversations = openTimeline ? 6 : 11;
  let widthSystemConversations = openTimeline ? 6 : 1;
  const currentDayTime = verifyTimesOfCurrentDay(timesheets, nickname);

  if (currentDayTime) {
    const { time } = currentDayTime;
    timeValue = convertMinsToTime(time);
  }

  if (size.width <= widthMobile) {
    widthConversations = openTimeline ? 0 : 12;
    widthSystemConversations = openTimeline ? 12 : 0;
  }

  const handleScroll = () => {
    if (offset < totalCount) {
      setLoadingScroll(true);
      dispatch(
        getConversations(
          actualAccountName, id, offset + 10, limit, orderBy, filter,
        ),
      ).then(() => {
        setLoadingScroll(false);
        setHasNextPage(offset < totalCount);
      });
    }
  };

  const searchConversations = () => {
    dispatch(getConversations(actualAccountName, id)).then(() => {
      setHasNextPage(true);
    });
  };

  const infiniteRef = useInfiniteScroll({
    loading: loadingScroll,
    hasNextPage,
    onLoadMore: handleScroll,
    scrollContainer: 'window',
  });

  const cleanConversations = () => {
    dispatch(resetConversations());
  };

  useEffect(() => {
    if (hasInsideView === null) {
      dispatch(getUserProfile(actualAccountName, globalId));
    }
  }, [
    actualAccountName,
    globalId,
    hasInsideView,
    dispatch,
  ]);

  useEffect(() => {
    // eslint-disable-next-line camelcase
    dispatch(usersOfProject(actualAccountName, project_id));
  }, [dispatch, project_id, actualAccountName]); // eslint-disable-line camelcase

  useEffect(() => {
    let isMounted = true;
    dispatch(getConversations(actualAccountName, id)).then(() => {
      if (isMounted) {
        setHasNextPage(true);
      }
    });

    return () => { isMounted = false; cleanConversations(); };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  if (error) {
    if (error.fromAction === GET_CONVERSATIONS) {
      return (
        <Error message={error.message} />
      );
    }
    handleOpen();
  }

  const handleClickVisibility = (conversationId, isVisible) => {
    if (isVisible) {
      dispatch(makePrivateConversation(actualAccountName, conversationId));
    } else {
      dispatch(makePublicConversation(actualAccountName, conversationId));
    }
  };

  const handleClickFavorite = (conversationId, isStarring) => {
    if (isStarring) {
      dispatch(unstarConversation(actualAccountName, conversationId));
    } else {
      dispatch(starConversation(actualAccountName, conversationId));
    }
  };

  const handleClickTimeline = () => {
    if (openTimeline) {
      handleCloseTimeline();
    } else {
      handleOpenTimeline();
    }
  };

  const formatConversationsTimeline = () => {
    const timeline = {};
    data.forEach((item) => {
      // eslint-disable-next-line no-shadow
      const date = moment(item.created_at).format('YYYY-MM-DD');
      if (!(item.operation.name === 'create new comment' && item.status_id === 2)) {
        if (!timeline[date]) {
          timeline[date] = [];
        }
        timeline[date].push(item);
      }
    });
    return timeline;
  };

  const handleSendTime = async (addTime) => {
    const timesRecordedToday = verifyTimesOfCurrentDay(timesheets, nickname);
    const request = {};
    let response;

    if (timesRecordedToday) {
      if (addTime === '00:00') {
        try {
          response = await dispatch(deleteTimesheets(actualAccountName, timesRecordedToday.id));
        } catch (errSend) {
          response = errSend;
        }
      } else {
        request.time = convertHoursToMins(addTime);
        request.on_behalf_of = timesRecordedToday.on_behalf_of;
        request.date = date;
        try {
          response = await dispatch(
            updateTimesheets(actualAccountName, timesRecordedToday.id, request),
          );
        } catch (errSend) {
          response = errSend;
        }
      }
    } else {
      if (addTime === '00:00') {
        setMessageComment('Hubo un error, por favor intenta ingresar un tiempo superior a 0.');
        handleOpen();
        return;
      }

      request.task_id = id;
      request.time = convertHoursToMins(addTime);
      request.date = date;
      try {
        response = await dispatch(createTimesheets(actualAccountName, request));
      } catch (errSend) {
        response = errSend;
      }
    }

    const {
      status,
      data, // eslint-disable-line no-shadow
    } = response;

    if (status >= 500 || status === 404) {
      setMessageComment('Hubo un error, por favor intenta más tarde.');
      handleOpen();
    } else if (status > 399 && status <= 499) {
      const errorsRes = {};
      data.forEach((item) => {
        errorsRes[item.param] = item.message;
      });
      setMessageComment(errorsRes);
      handleOpen();
    }
  };

  const handleCreateConversation = async (value) => {
    if (!value) {
      return;
    }

    const dataConversation = {
      actualAccountName,
      task_id: id,
      status_id: 2, // statusId 2 means the conversation is published
      operation_id: 3, // operationId 3 means creation of new conversation
      content: value,
      is_visible: visibilityFlag,
    };

    setHasNextPage(false);
    setLoadingScroll(false);
    Promise.all([
      await dispatch(createConversation(actualAccountName, dataConversation)),
      await handleSendTime(valueTime),
    ])
      .then(() => {
        setMessageComment('Tu comentario se ha guardado exitosamente. ');
        handleOpen();
      })
      .catch(() => {
        setMessageComment('Tu comentario no se pudo guardar, intentalo de nuevo.');
        handleOpen();
      });

    dispatch(resetConversations());
    searchConversations();
    handleCloseMenu();
  };

  const handleToggleVisibility = () => {
    setVisibilityFlag(!visibilityFlag);
  };

  const handleValueTimeChange = (e) => {
    setValueTime(e);
  };

  return (
    <div>
      <SnackbarHendrix
        handleClose={handleClose}
        open={open}>
        <Typography variant="body2" styles={{ color: '#FFFFFF' }}>
          {messageComment}
        </Typography>
      </SnackbarHendrix>
      <Grid container item xs={12} direction="column" justify="flex-end">
        <Grid item container direction="row" alignItems="center">
          { showComment && (
            <Grid item xs={12}>
              <MDEditor
                actionText="Guardar"
                isToggleVisibility={visibilityFlag}
                handleToggleVisibility={handleToggleVisibility}
                handleCancelComment={handleCloseMenu}
                onSubmit={handleCreateConversation}
                handleValueTimeChange={handleValueTimeChange}
                valueTime={timeValue}
                WithUsers
                users={userProject}
                placeholder="Escribe aquí tu comentario..." />
            </Grid>
          )}
          <Grid item xs={10} sm={10}>
            <Typography variant="body1">
              Mostrar
            </Typography>
          </Grid>
          <Grid item xs={1} sm={1}>
            <Tooltip title="Conversaciones de sistema">
              <IconButton onClick={handleClickTimeline}>
                <TimelineIcon color={openTimeline ? 'secondary' : 'primary'} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid item container direction="row">
          {!(size.width <= widthMobile && openTimeline) && (
            <Grid
              item
              xs={widthConversations}
              ref={infiniteRef}>
              <Conversations
                hasInsideView={hasInsideView}
                handleClickFavorite={handleClickFavorite}
                handleClickVisibility={handleClickVisibility}
                conversations={
                  data.filter((item) => (
                    item.operation.name === 'create new comment' && item.status_id === 2
                  ))
                } />
            </Grid>
          )}
          {!(size.width <= widthMobile && !openTimeline) && (
            <Grid
              item
              xs={widthSystemConversations}>
              <ConversationsTimeline
                showDate={
                  taskCreatedAt
                    ? `${moment(taskCreatedAt).format('MMMM')}/ ${moment().format('MMMM YYYY')}`
                    : moment().format('MMMM YYYY')
                }
                showSystemConversations={openTimeline}
                conversations={formatConversationsTimeline(data)}
                hasInsideView={hasInsideView} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

ConversationsContainer.propTypes = {
  optionMenu: PropTypes.string,
  handleCloseMenu: PropTypes.func.isRequired,
};

ConversationsContainer.defaultProps = {
  optionMenu: null,
};

export default ConversationsContainer;
