import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { AppBar, Tabs, Tab } from '@material-ui/core';
import { Chat, AccessAlarm, SkipPrevious } from '@material-ui/icons';
import TabPanel from 'components/boxTabs/index';
import Timesheets from 'containers/timesheets/index';
import ConversationsContainer from 'containers/conversations/index';
import DependenciaTasksContainer from 'containers/dependenciesTasks';
import { selectorTask } from 'services/selectors/task';
import useStyles from './styles';

function TabsDetailTask(props) {
  const classes = useStyles();
  const { account, taskId, optionMenu, handleCloseMenu } = props;
  const [value, setValue] = React.useState(0);
  const tasksList = useSelector(selectorTask);
  const projectID = tasksList.data ? tasksList.data.project_id : 0;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs task"
          variant="fullWidth"
          centered>
          <Tab
            icon={<Chat />}
            label="Conversación"
            id="simple-tab-0"
            aria-controls="simple-tabpanel-0" />
          <Tab
            icon={<AccessAlarm />}
            label="Tiempos"
            id="simple-tab-1"
            aria-controls="simple-tabpanel-1" />
          <Tab
            icon={<SkipPrevious />}
            label="Dependencias"
            id="simple-tab-2"
            aria-controls="simple-tabpanel-2" />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <ConversationsContainer optionMenu={optionMenu} handleCloseMenu={handleCloseMenu} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Timesheets account={account} taskId={taskId} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <DependenciaTasksContainer account={account} projectID={projectID} />
      </TabPanel>
    </div>
  );
}

TabsDetailTask.propTypes = {
  account: PropTypes.string.isRequired,
  taskId: PropTypes.string.isRequired,
  handleCloseMenu: PropTypes.func.isRequired,
  optionMenu: PropTypes.string,
};

TabsDetailTask.defaultProps = {
  optionMenu: null,
};

export default TabsDetailTask;
