import React from 'react';
import { makeStyles } from '@material-ui/core';
import {
  Remove,
  Whatshot,
} from '@material-ui/icons';
import veryHigh from 'assets/iconsSvgs/muyAlta.svg';
import high from 'assets/iconsSvgs/alta.svg';
import low from 'assets/iconsSvgs/baja.svg';
import veryLow from 'assets/iconsSvgs/muyBaja.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  urgent: {
    color: '#E01731',
  },
  normal: {
    color: '#2AAB7E',
  },
  veryHigh: {
    color: '#FAA045',
  },
  high: {
    color: '#FAA045',
  },
  low: {
    color: '#186A72',
  },
  veryLow: {
    color: '#59C5D6',
  },
  icon: {
    width: '14pt',
    height: '14pt',
  },
  iconContainer: {
    marginTop: '5px',
    marginRight: '11px',
    [theme.breakpoints.down('sm')]: {
      marginRight: '8px',
    },
  },
  priorityName: {
    textTransform: 'capitalize',
    fontWeight: '300',
    fontSize: '12pt',
    margin: '0',
    paddingBottom: '5px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
}));

const PriorityItem = (props) => {
  const classes = useStyles();
  const { priority, priorityName } = props;

  const checkPriority = () => {
    switch (priority) {
      case 1:
        return (
          <div className={classes.root}>
            <div className={classes.iconContainer}>
              <Whatshot className={`${classes.urgent} ${classes.icon}`} />
            </div>
            <p className={`${classes.priorityName} ${classes.urgent}`}>{priorityName}</p>
          </div>
        );
      case 2:
        return (
          <div className={classes.root}>
            <div className={classes.iconContainer}>
              <img src={veryHigh} alt="very high" className={`${classes.icon}`} />
            </div>
            <p className={`${classes.priorityName} ${classes.veryHigh}`}>{priorityName}</p>
          </div>
        );
      case 3:
        return (
          <div className={classes.root}>
            <div className={classes.iconContainer}>
              <img src={high} alt="high" className={`${classes.icon}`} />
            </div>
            <p className={`${classes.priorityName} ${classes.high}`}>{priorityName}</p>
          </div>
        );
      case 4:
        return (
          <div className={classes.root}>
            <div className={classes.iconContainer}>
              <Remove className={`${classes.normal} ${classes.icon}`} />
            </div>
            <p className={`${classes.priorityName} ${classes.normal}`}>{priorityName}</p>
          </div>
        );
      case 5:
        return (
          <div className={classes.root}>
            <div className={classes.iconContainer}>
              <img src={low} alt="low" className={`${classes.icon}`} />
            </div>
            <p className={`${classes.priorityName} ${classes.low}`}>{priorityName}</p>
          </div>
        );
      case 6:
        return (
          <div className={classes.root}>
            <div className={classes.iconContainer}>
              <img src={veryLow} alt="very low" className={`${classes.icon}`} />
            </div>
            <p className={`${classes.priorityName} ${classes.veryLow}`}>{priorityName}</p>
          </div>
        );
      default:
        return '';
    }
  };

  return checkPriority();
};

export default PriorityItem;
