import { GET_TASKS, RESET_TASKS } from 'constants/tasks';
/*
* Reducer get al tasks
*/
const initialState = {
  isLoading: false,
  data: [],
  pagination: {
    limit: 10,
    offset: 0,
    orderBy: null,
    totalCount: 0,
  },
  filter: {},
  error: null,
};

const tasksList = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case RESET_TASKS: {
      return {
        ...initialState,
      };
    }
    case `${GET_TASKS}_REJECTED`: {
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    }

    case `${GET_TASKS}_PENDING`: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case `${GET_TASKS}_FULFILLED`: {
      const { data: { pagination, results, filter } } = payload;

      const newResults = results.map((list) => ({
        id: list.id,
        text: list.concept,
        priority: list.priority.id,
        priorityName: list.priority.name,
        status: list.status.id,
        statusName: list.status.name,
        estimatedTime: list.estimated_time,
        timeSpent: (list.timesheets.map((t) => t.time)).reduce((a, b) => a + b, 0),
        responsible: list.responsible,
        projectID: list.project.id,
      }));
      return {
        ...state,
        isLoading: false,
        data: [...state.data, ...newResults],
        pagination: {
          limit: pagination.limit,
          offset: pagination.offset,
          orderBy: pagination.orderBy,
          totalCount: pagination.totalCount,
        },
        filter,
        error: null,
      };
    }
    default:
      return state;
  }
};

export default tasksList;
