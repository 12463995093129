import React, { useState } from 'react';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import Collapse from '@material-ui/core/Collapse';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Tooltip from '@material-ui/core/Tooltip';
import useStyles from './styles';


function Menu(props) {
  const classes = useStyles();
  const [expandedItems, setExpandedItems] = useState([]);
  const {
    open, menuItems, handleOpen, handleClose,
  } = props;

  const handleClickItem = (element) => {
    const items = [...expandedItems];
    const index = items.indexOf(element);

    if (index > -1) {
      items.splice(index, 1);
    } else {
      items.push(element);
    }
    setExpandedItems(items);
  };

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      open={open}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}>
      <div className={classes.toolbar} />
      <List>
        { menuItems.map((item, index) => {
          const expandedItem = expandedItems.indexOf(index) !== -1;
          return (
            <div key={`div-${item.title}`}>
              <ListItem
                dense
                button
                key={item.title}
                id={index + 1}
                className={open && expandedItem ? classes.activeItem : classes.item}
                onClick={() => handleClickItem(index)}>
                <ListItemIcon onClick={handleOpen}>
                  <Tooltip title={item.title}>
                    <item.icon className={classes.icon} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText>
                  <Typography variant="body1" className={classes.font}>{item.title}</Typography>
                </ListItemText>
                {expandedItem ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
              </ListItem>
              <Collapse
                in={expandedItem}
                timeout="auto"
                unmountOnExit
                key={`collapse-${item.title}`}>
                {open && item.items.map((subItem) => (
                  <List component="div" disablePadding key={`subList-${subItem.title}`}>
                    <NavLink
                      key={`subItemNav--${subItem.title}`}
                      to={subItem.link}
                      activeClassName={classes.activeLink}
                      className={classes.planeLink}>
                      <ListItem
                        dense
                        button
                        key={`subItem-${subItem.title}`}
                        className={classes.nested}
                        onClick={handleClose}>
                        <ListItemText>
                          <span className={classes.font}>{subItem.title}</span>
                        </ListItemText>
                      </ListItem>
                    </NavLink>
                  </List>
                ))}
              </Collapse>
            </div>
          );
        })}
      </List>
    </Drawer>
  );
}

Menu.propTypes = {
  open: PropTypes.bool.isRequired,
  handleOpen: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  menuItems: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    icon: PropTypes.instanceOf(Object).isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    })).isRequired,
  })).isRequired,
};


export default Menu;
