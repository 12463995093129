/**
 * Users of project selector
 *
 * This function create an selector
 *
 */

export const usersOfProjectSelector = (state) => {
  const { usersOfProject: { data } } = state;

  return data && data.map((x) => (
    x.user
  ));
};

export default usersOfProjectSelector;
