import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import colors from 'constants/colors';

const useStyles = makeStyles(() => ({
  number: {
    paddingLeft: '7px',
  },
  buttonList: {
    width: '22px',
    paddingTop: '7px',
    paddingBottom: '7px',
    background: '#fff',
    height: '100%',
    border: 'none',
    color: colors.secondary,
    '&:hover': {
      border: 'initial',
      backgroundColor: colors.primary,
      color: '#fff',
    },
    '&:focus': {
      backgroundColor: colors.primary,
      color: '#fff',
    },
    '&:active': {
      backgroundColor: colors.primary,
      color: '#fff',
    },
  },
  selectedTime: {
    backgroundColor: colors.primary,
    color: '#fff',
    border: 'initial',
    borderRadius: '3px',
    width: '22px',
  },
}));

export function HourField(props) {
  const {
    currentHour,
    number,
    handleHour,
  } = props;
  const classes = useStyles();

  const handleClick = () => {
    const value = String(number).length === 1 ? `0${number}` : number;
    handleHour(value);
  };

  return (
    <li className={classes.number}>
      <button
        type="button"
        onClick={handleClick}
        className={`${classes.buttonList} ${currentHour === number ? classes.selectedTime : ''}`}>
        {number}
      </button>
    </li>
  );
}

HourField.propTypes = {
  currentHour: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  handleHour: PropTypes.func.isRequired,
};

export function MinuteField(props) {
  const {
    currentMinute,
    number,
    handleMinute,
  } = props;

  const classes = useStyles();

  const handleClick = () => {
    const value = String(number).length === 1 ? `0${number}` : number;
    handleMinute(value);
  };

  return (
    <li className={classes.number}>
      <button
        type="button"
        onClick={handleClick}
        className={`${classes.buttonList} ${currentMinute === number ? classes.selectedTime : ''}`}>
        {number}
      </button>
    </li>
  );
}

MinuteField.propTypes = {
  currentMinute: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  handleMinute: PropTypes.func.isRequired,
};
