import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { selectorActualAccountName } from 'services/selectors/account';
import { selectorTask } from 'services/selectors/task';
import { getTask, updateTask } from 'services/actions/task';

import { selectorUserProfileId } from 'services/selectors/userProfile';
import { getTasks, resetTasks } from 'services/actions/tasksList';
import { selectorTasksList } from 'services/selectors/tasksList';

import Error from 'components/error/index';
import Loading from 'components/loading/index';
import WhiteModalComponent from 'components/whiteModalComponent/index';
import FloatingMenu from 'components/floatingMenu/index';
import SnackbarHendrix from 'components/snackbarHendrix/index';

import TabsDetailTask from 'containers/tabs/index';
import TagListContainer from 'containers/tags/index';
import TaskForm from 'containers/updateTask/index';

import actionsTaskMenu from 'constants/taskMenu';
import useBeforeUnload from 'hooks/useBeforeUnload';
import { Prompt } from 'react-router';
import AddTimeContainer from 'containers/timeContainer';

import { taskDistilledWithTimesheets } from 'constants/tasks';
import prepareDataTask from 'utils/prepareDataTask';
import useOpenClose from 'hooks/useOpenClose';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
}));

function TaskContainer() {
  const { askBeforeUnload, removeAskBeforeUnload, setAskBeforeUnload } = useBeforeUnload(false);
  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const [messageAfterSubmit, setMessageAfterSubmit] = useState('');
  const [messageAfterSubmitModal, setMessageAfterSubmitModal] = useState('');
  const [helperTexts, setHelperTexts] = useState({});
  const [activeOptionMenu, setActiveOptionMenu] = useState(null);

  const task = useSelector(selectorTask);
  const actualAccountName = useSelector(selectorActualAccountName);

  const [timeMessage, setTimeMessage] = useState();

  const profileId = useSelector(selectorUserProfileId);
  const {
    pagination: {
      limit,
    },
  } = useSelector(selectorTasksList);

  const handleOptionMenu = (option) => {
    setActiveOptionMenu(option);
    if (option) {
      setAskBeforeUnload();
    } else {
      removeAskBeforeUnload();
    }
  };

  const handleCloseMenu = () => {
    removeAskBeforeUnload();
    setActiveOptionMenu(null);
  };

  const {
    open,
    handleClose,
    handleOpen,
  } = useOpenClose(false);

  const {
    open: openModal,
    handleClose: handleCloseModal,
    handleOpen: handleOpenModal,
  } = useOpenClose(false);

  useEffect(() => {
    dispatch(getTask(actualAccountName, id));
    setActiveOptionMenu(null);
  }, [actualAccountName, id, dispatch]);

  useEffect(() => {
    removeAskBeforeUnload();
    setActiveOptionMenu(null);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [id]);

  const {
    open: showAddTime,
    handleOpen: handleOpenAddTime,
    handleClose: handleCloseAddTime,
  } = useOpenClose();


  useEffect(() => {
    dispatch(getTask(actualAccountName, id));
    setActiveOptionMenu(null);
  }, [actualAccountName, id, dispatch]);


  if (!task.data || task.isLoading) {
    return <Loading />;
  }

  if (task.error && task.error.status >= 500) {
    return (
      <Error message={task.error.message} />
    );
  }

  const editing = activeOptionMenu === 'Editar tarea';

  const handleReloadPanelOfDay = () => {
    dispatch(resetTasks());
    dispatch(
      getTasks(
        actualAccountName,
        0,
        limit,
        '',
        { task_responsible: profileId },
        taskDistilledWithTimesheets,
      ),
    );
  };

  const handleRedirectToTaskList = () => {
    history.push(`/dashboard/tasks/${id}`);
  };

  const handleRedirectToPanelOfDay = () => {
    history.push(`/dashboard/panel/${id}`);
  };

  const handleSend = async (dataUser) => {
    const { projectId, responsibleId } = dataUser;
    if (projectId !== 0 && responsibleId !== 0) {
      const formattedData = prepareDataTask(dataUser);
      let response = {};
      try {
        response = await dispatch(updateTask(actualAccountName, id, formattedData));
      } catch (e) {
        response = e;
      }

      const {
        data,
        status,
      } = response;

      if (status >= 500 || status === 404) {
        setMessageAfterSubmit('Hubo un error, por favor intenta más tarde.');
        handleOpen();
      } else if (status > 399) {
        const errors = {};
        data.forEach((item) => {
          errors[item.param] = item.message;
        });
        setHelperTexts(errors);
        handleOpen();
      } else {
        let { location: { pathname } } = history;
        pathname = pathname.replace(/\/[0-9]+/, '');
        if ((task && task.data)
          && ((task.data.responsible.id !== responsibleId)
          || (responsibleId !== profileId)) && pathname !== '/dashboard/tasks') {
          handleOpenModal();
          setMessageAfterSubmitModal(
            <div>
              <center>
                Se ha actualizado correctamente.
                {' '}
                <br />
                La tarea ha cambiado de responsable,
                {' '}
                <br />
                ya no se mostrará en tu Panel del día.
              </center>
            </div>,
          );
          removeAskBeforeUnload();
          setActiveOptionMenu(null);
          handleRedirectToTaskList();
        } else if (responsibleId === profileId && pathname === '/dashboard/tasks') {
          handleOpen();
          setMessageAfterSubmit('Se ha actualizado la tarea correctamente. ');
          handleRedirectToPanelOfDay();
        } else {
          handleOpen();
          setMessageAfterSubmit('Se ha actualizado la tarea correctamente.');
          handleReloadPanelOfDay();
        }
        setActiveOptionMenu(null);
      }
    } else {
      if (projectId === 0) {
        setMessageAfterSubmit('El proyecto es requerido.');
        handleOpen();
      }

      if (projectId !== 0 && responsibleId === 0) {
        setMessageAfterSubmit('El responsable es requerido.');
        handleOpen();
      }
    }
  };

  const handleCloseSnackbarHendrix = () => {
    setMessageAfterSubmit('');
    handleClose();
    setHelperTexts({});
  };

  const handleCancel = () => {
    removeAskBeforeUnload();
    setActiveOptionMenu(null);
  };

  return (
    <>
      <Prompt
        when={askBeforeUnload}
        message="Tienes cambios sin guardar, ¿estás seguro de salir?" />
      <Grid container direction="column">
        <FloatingMenu
          onAction={handleOptionMenu}
          actions={actionsTaskMenu}
          disabled={activeOptionMenu !== null} />
        <Grid item>
          <TagListContainer />
        </Grid>
        <Grid item>
          <TaskForm
            editing={editing}
            taskId={id}
            task={task.data}
            handleSend={handleSend}
            handleCancel={handleCancel} />
        </Grid>
        { activeOptionMenu === 'Agregar Tiempos'
          && (
          <Grid item>
            <AddTimeContainer
              taskId={id}
              setTimeMessage={setTimeMessage}
              open={showAddTime}
              handleOpenTime={handleOpenAddTime}
              handleClose={() => {
                removeAskBeforeUnload();
                setActiveOptionMenu(null);
              }} />
          </Grid>
          )}
        <Grid item className={classes.root}>
          <TabsDetailTask
            optionMenu={activeOptionMenu}
            handleCloseMenu={handleCloseMenu}
            account={actualAccountName}
            taskId={id} />
        </Grid>
        <SnackbarHendrix
          open={open}
          handleClose={handleCloseSnackbarHendrix}>
          {helperTexts && Object.keys(helperTexts).length > 0
            ? Object.values(helperTexts).map((x) => (
              <Typography variant="body2" color="error" key={x}>
                {x}
                <br />
              </Typography>
            )) : (
              <Typography variant="body2" styles={{ color: '#FFFFFF' }}>
                {messageAfterSubmit}
              </Typography>
            )}
        </SnackbarHendrix>
        <WhiteModalComponent
          open={openModal}
          onClose={handleCloseModal}
          valueRadio="aceptar"
          variantTypography="subtitle1"
          textTypography="Actualización de tarea"
          textButtonRight="ACEPTAR"
          handleSubmitRight={handleCloseModal}
          showButtonLeft={false}
          styleTitle={{ paddingBottom: '1px' }}>
          {messageAfterSubmitModal}
        </WhiteModalComponent>
      </Grid>
      <SnackbarHendrix
        open={showAddTime}
        handleClose={handleCloseAddTime}>
        { (typeof timeMessage === 'object' ? (
          Object.values(timeMessage).map((msg) => (
            <Typography variant="body2" color="error" key={msg}>
              {msg}
            </Typography>
          ))
        ) : (
          <Typography variant="body2" styles={{ color: '#FFFFFF' }}>
            {timeMessage}
          </Typography>
        ))}
      </SnackbarHendrix>
    </>
  );
}

export default TaskContainer;
