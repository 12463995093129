import {
  GET_USERS_OF_PROJECT,
} from 'constants/usersOfProject';

/*
 *
 * Reducer of users of project
 *
 */

const initialState = {
  isLoading: false,
  data: [],
  error: null,
};

const usersOfProject = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case `${GET_USERS_OF_PROJECT}_REJECTED`: {
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    }
    case `${GET_USERS_OF_PROJECT}_PENDING`: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case `${GET_USERS_OF_PROJECT}_FULFILLED`: {
      const { data: { results } } = payload;
      return {
        ...state,
        isLoading: false,
        data: results,
        error: null,
      };
    }
    default:
      return state;
  }
};

export default usersOfProject;
