import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import FormHelperText from '@material-ui/core/FormHelperText';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

function RenderTextAreaAutosize(props) {
  const {
    placeholder,
    helperText,
    id,
    className,
    input: {
      name,
      onBlur,
      onDragStart,
      onDrop,
      onFocus,
      onChange,
    },
    inputProps: { value },
    meta: { touched, error },
    showProject,
    withIconNext,
    styleIcon,
    handleShowProjectGrid,
    setCharacters,
    changeConcept,
    defaultRows,
  } = props;

  const [concept, setConcept] = useState(value);

  useEffect(() => {
    setConcept(value);
    onChange(value);
  }, [onChange, value]);

  const handleChangeText = (e) => {
    setCharacters(e.target.value.length);
    setConcept(e.target.value);
    changeConcept(e.target.value);
    onChange(e.target.value);
  };

  return (
    <>
      <TextareaAutosize
        aria-label={id}
        rows={defaultRows}
        placeholder={placeholder}
        className={className}
        name={name}
        onChange={(e) => handleChangeText(e)}
        onBlur={onBlur}
        onDragStart={onDragStart}
        onDrop={onDrop}
        onFocus={onFocus}
        value={concept} />
      {!showProject && withIconNext && (
        <NavigateNextIcon
          className={styleIcon}
          onClick={handleShowProjectGrid} />
      )}
      <FormHelperText
        style={{ color: 'red' }}
        error={touched && error !== ''}>
        {touched && error ? error : helperText}
      </FormHelperText>
    </>
  );
}

RenderTextAreaAutosize.defaultProps = {
  defaultRows: 1,
  helperText: '',
  showProject: false,
  styleIcon: '',
  withIconNext: false,
  handleShowProjectGrid: () => {},
  placeholder: '',
  setCharacters: () => {},
  changeConcept: () => {},
  inputProps: {
    value: '',
  },
};

RenderTextAreaAutosize.propTypes = {
  defaultRows: PropTypes.number,
  placeholder: PropTypes.string,
  id: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  input: PropTypes.instanceOf(Object).isRequired,
  helperText: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }).isRequired,
  showProject: PropTypes.bool,
  styleIcon: PropTypes.string,
  withIconNext: PropTypes.bool,
  handleShowProjectGrid: PropTypes.func,
  setCharacters: PropTypes.func,
  changeConcept: PropTypes.func,
  inputProps: PropTypes.instanceOf(Object),
};

export default RenderTextAreaAutosize;
