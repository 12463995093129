/**
 * Project selector
 *
 * This function create an selector
 *
 */

export const selectorProject = (state) => state.project;

export default selectorProject;
