import React from 'react';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

const actionsProjectMenu = [
  {
    icon: <EditIcon />,
    name: 'Editar proyecto',
  },
  {
    icon: <DeleteIcon />,
    name: 'Eliminar proyecto',
  },
];
export default actionsProjectMenu;
