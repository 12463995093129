import React from 'react';
import { Finder } from 'components/finder/index';
import PropTypes from 'prop-types';

const FinderContainer = (props) => {
  const {
    open,
    handleOpen,
    handleClose,
  } = props;

  const handleSubmit = () => {
  };

  return (
    <div>
      <Finder
        onSubmit={handleSubmit}
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose} />
    </div>
  );
};

FinderContainer.propTypes = {
  open: PropTypes.bool.isRequired,
  handleOpen: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default FinderContainer;
