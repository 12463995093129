import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import RenderTextField from 'components/renderTextField/index';
import RenderDatepicker from 'components/renderDatepicker/index';
import StatusSelector from 'containers/statusBagde';
import TaskDetailSelector from 'containers/taskUserSelector';
import { Grid, Typography, InputLabel } from '@material-ui/core';
import RenderTextAreaAutosize from 'components/renderTextAreaAutosize/index';
import { PrimaryButton } from 'components/primaryButton/index';
import { SecondaryButton } from 'components/secondaryButton/index';
import SnackbarHendrix from 'components/snackbarHendrix/index';
import colors from 'constants/colors';
import { alphaNumericProject, startDateProject } from 'utils/formValidation';
import moment from 'moment';
import useStyles from './styles';

const validate = (values) => {
  const errors = {};
  const fields = {
    nameProject: 'Nombre del Proyecto',
    key: 'Clave del proyecto',
    startDate: 'Fecha de Inicio',
    endDate: 'Fecha de entrega',
    description: 'Descripción del proyecto',
  };

  Object.keys(fields).forEach((key) => {
    if (!values[key]) errors[key] = `El campo ${fields[key]} es requerido`;
  });
  if (values.description) errors.description = alphaNumericProject(values.description);

  if (values.startDate) errors.startDate = startDateProject(values.startDate, values.endDate);
  return errors;
};

const CreateProject = (props) => {
  const {
    open,
    handleClose,
    handleSubmit,
    handleReset,
    pristine,
    submitting,
    invalid,
    handleSend,
    helperTexts,
    messageAfterSubmit,
    projectStatuses,
    idStatus,
    setStatus,
    setIdStatus,
    status,
    companies,
    companyLabel,
    changeCompanyLabel,
    idCompany,
    setIdCompany,
    roles,
    rolLabel,
    changeRolLabel,
    idRol,
    setIdRol,
    nameProject,
    setNameProject,
    projectDescription,
    setProjectDescription,
    projectKey,
    setProjectKey,
  } = props;

  const today = moment().format('YYYY-MM-DD');
  const [endDate, setEndDate] = useState('');
  const [startDate, setStartDate] = useState(today);

  const classes = useStyles();
  const colorText = { color: colors.primary, fontSize: '15px' };
  const fontDate = { color: colors.primary, fontSize: '13px' };

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start">
        <form className={classes.form} onSubmit={handleSubmit(handleSend)}>
          <Grid item xs={12}>
            <InputLabel htmlFor="nameProject" className={classes.label}>Nombre:</InputLabel>
            <Field
              id="nameProject"
              name="nameProject"
              placeholder="Ingresa el nombre del proyecto"
              component={RenderTextField}
              inputProps={{ value: nameProject }}
              setTextContent={setNameProject}
              required
              type="text"
              fullWidth
              style={colorText}
              className={classes.formField} />
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="flex-start">
              <Grid item xs={5}>
                <InputLabel
                  htmlFor="startDate"
                  className={classes.labelTime}
                  style={{ marginTop: '12px' }}>
                  Fecha de inicio:
                </InputLabel>
                <Field
                  id="startDate"
                  name="startDate"
                  initialDate={startDate}
                  setChangeDate={setStartDate}
                  component={RenderDatepicker}
                  type="text"
                  fullWidth
                  style={fontDate}
                  className={classes.formField} />
              </Grid>

              <Grid item xs={5}>
                <InputLabel
                  htmlFor="endDate"
                  style={{ marginTop: '12px' }}
                  className={classes.labelTime}>
                  Fecha de entrega:
                </InputLabel>
                <Field
                  id="endDate"
                  name="endDate"
                  label="Fecha de entrega"
                  initialDate={endDate}
                  setChangeDate={setEndDate}
                  component={RenderDatepicker}
                  type="text"
                  fullWidth
                  style={fontDate}
                  className={classes.formField} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <InputLabel
              htmlFor="statusId"
              style={{ marginTop: '12px' }}
              className={classes.label}>
              Estado:
            </InputLabel>
            <Field
              id="statusId"
              name="statusId"
              idStatus={idStatus}
              nameStatus={status}
              setIdStatus={setIdStatus}
              setStatus={setStatus}
              statuses={projectStatuses}
              component={StatusSelector} />
          </Grid>

          <Grid item xs={12}>
            <InputLabel
              htmlFor="key"
              style={{ marginTop: '12px' }}
              className={classes.label}>
              Clave del Proyecto:
            </InputLabel>
            <Field
              id="key"
              name="key"
              placeholder="Ingresa la clave del Proyecto"
              component={RenderTextField}
              inputProps={{ value: projectKey }}
              setTextContent={setProjectKey}
              required
              type="text"
              fullWidth
              style={colorText}
              className={classes.formField} />
          </Grid>

          <Grid item xs={12}>
            <InputLabel
              htmlFor="conclusionRequirements"
              style={{ marginTop: '12px' }}
              className={classes.label}>
              Definición de la conclusión :
            </InputLabel>
            <Field
              id="conclusionRequirements"
              name="conclusionRequirements"
              defaultRows={3}
              placeholder="Ingresa los requerimientos finales del proyecto"
              component={RenderTextAreaAutosize}
              type="text"
              fullWidth
              style={colorText}
              className={classes.formFieldAutoSize} />
          </Grid>

          <Grid item xs={12}>
            <InputLabel
              htmlFor="invoicedBy"
              style={{ marginTop: '12px' }}
              className={classes.label}>
              Compañia que facturó el proyecto:
            </InputLabel>
            <Field
              id="invoicedBy"
              name="invoicedBy"
              haveLabel
              haveAvatar={false}
              placeholder="elige una compañia"
              data={companies}
              label={companyLabel}
              changeLabel={changeCompanyLabel}
              withDeleteIcon={false}
              idItem={idCompany}
              changeIdItem={setIdCompany}
              component={TaskDetailSelector} />
          </Grid>

          <Grid item xs={12}>
            <InputLabel
              htmlFor="defaultTicket"
              style={{ marginTop: '12px' }}
              className={classes.label}>
              Ticket Default:
            </InputLabel>
            <Field
              id="defaultTicket"
              name="defaultTicket"
              defaultRows={3}
              placeholder="Ingresa el ticket por default"
              component={RenderTextAreaAutosize}
              type="text"
              fullWidth
              style={colorText}
              className={classes.formFieldAutoSize} />
          </Grid>

          <Grid item xs={12}>
            <InputLabel
              htmlFor="description"
              style={{ marginTop: '12px' }}
              className={classes.label}>
              Descripción del proyecto:
            </InputLabel>
            <Field
              id="description"
              name="description"
              placeholder="Ingresa la descripción del proyecto"
              component={RenderTextField}
              inputProps={{ value: projectDescription }}
              setTextContent={setProjectDescription}
              type="text"
              fullWidth
              style={colorText}
              className={classes.formField} />
          </Grid>

          <Grid item xs={12}>
            <InputLabel
              htmlFor="roleId"
              style={{ marginTop: '12px' }}
              className={classes.label}>
              Rol en el proyecto:
            </InputLabel>
            <Field
              id="roleId"
              name="roleId"
              haveLabel
              haveAvatar={false}
              placeholder="selecciona un rol"
              data={roles}
              label={rolLabel}
              changeLabel={changeRolLabel}
              withDeleteIcon={false}
              idItem={idRol}
              style={{ marginRight: '15px' }}
              changeIdItem={setIdRol}
              component={TaskDetailSelector} />
          </Grid>

          <Grid item xs={12}>
            <div className={classes.actions}>
              <SecondaryButton
                styles={{
                  marginTop: '4px',
                  marginRight: '12px',
                }}
                fullWidth={false}
                size="medium"
                variant="contained"
                type="button"
                onClick={handleReset}
                text="CANCELAR" />
              <PrimaryButton
                size="medium"
                variant="contained"
                type="submit"
                disabled={pristine || submitting || invalid}
                text="CREAR PROYECTO" />
            </div>
          </Grid>

        </form>
        <SnackbarHendrix
          open={open}
          handleClose={handleClose}>
          {helperTexts && Object.keys(helperTexts).length > 0
            ? Object.values(helperTexts).map((x) => (
              <Typography variant="body2" color="error" key={x}>
                {x}
                <br />
              </Typography>
            )) : (
              <Typography variant="body2" color="primary">
                {messageAfterSubmit}
              </Typography>
            )}
        </SnackbarHendrix>
      </Grid>
    </div>
  );
};
CreateProject.defaultProps = {
  companies: {},
  roles: {},
  projectStatuses: [],
  messageAfterSubmit: '',
  helperTexts: {},
  setNameProject: () => {},
  nameProject: '',
  setProjectDescription: () => {},
  projectDescription: '',
  setProjectKey: () => {},
  projectKey: '',
};


CreateProject.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  handleSend: PropTypes.func.isRequired,
  helperTexts: PropTypes.instanceOf(Object),
  messageAfterSubmit: PropTypes.string,
  projectStatuses: PropTypes.instanceOf(Array),
  idStatus: PropTypes.number.isRequired,
  setStatus: PropTypes.func.isRequired,
  setIdStatus: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  companies: PropTypes.instanceOf(Array),
  companyLabel: PropTypes.string.isRequired,
  changeCompanyLabel: PropTypes.func.isRequired,
  idCompany: PropTypes.number.isRequired,
  setIdCompany: PropTypes.func.isRequired,
  roles: PropTypes.instanceOf(Array),
  rolLabel: PropTypes.string.isRequired,
  changeRolLabel: PropTypes.func.isRequired,
  idRol: PropTypes.number.isRequired,
  setIdRol: PropTypes.func.isRequired,
  setNameProject: PropTypes.func,
  setProjectDescription: PropTypes.func,
  nameProject: PropTypes.string,
  projectDescription: PropTypes.string,
  projectKey: PropTypes.string,
  setProjectKey: PropTypes.func,
};

const CreateProjectForm = reduxForm({
  form: 'CreateProjectForm',
  validate,
})(CreateProject);

export default CreateProjectForm;
