import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import useStyles from './styles';

function WhiteModalComponent(props) {
  const classes = useStyles();
  const {
    onClose,
    open,
    children,
    valueRadio,
    variantTypography,
    textTypography,
    showButtonLeft,
    showButtonRigh,
    textButtonLeft,
    textButtonRight,
    handleSubmitLeft,
    handleSubmitRight,
    styleTitle,
  } = props;

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      disableBackdropClick
      disableEscapeKeyDown
      BackdropProps={{ style: { backgroundColor: 'transparent' } }}
      maxWidth="lg">
      <DialogTitle id="simple-dialog-title" style={styleTitle}>
        <DialogTitle disableTypography className={classes.root}>
          <Typography
            color="secondary"
            variant={variantTypography}
            align="center">
            {textTypography}
          </Typography>
          {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          ) : null}
        </DialogTitle>
      </DialogTitle>
      <MuiDialogContent>
        {children}
      </MuiDialogContent>
      <MuiDialogActions>
        {showButtonLeft && (
        <Button size="small" className={classes.buttonLeft} onClick={handleSubmitLeft}>
          {textButtonLeft}
        </Button>
        )}
        {showButtonRigh && (
        <Button size="small" className={classes.margin} onClick={handleSubmitRight} disabled={!valueRadio && true}>
          {textButtonRight}
        </Button>
        )}
      </MuiDialogActions>
    </Dialog>
  );
}

WhiteModalComponent.defaultProps = {
  valueRadio: '',
  handleSubmitLeft: () => {},
  handleSubmitRight: () => {},
  textButtonLeft: '',
  textButtonRight: '',
  showButtonLeft: true,
  showButtonRigh: true,
  styleTitle: {},
};

WhiteModalComponent.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  valueRadio: PropTypes.string,
  variantTypography: PropTypes.string.isRequired,
  textTypography: PropTypes.string.isRequired,
  textButtonLeft: PropTypes.string,
  textButtonRight: PropTypes.string,
  handleSubmitLeft: PropTypes.func,
  handleSubmitRight: PropTypes.func,
  showButtonLeft: PropTypes.bool,
  showButtonRigh: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
  ]).isRequired,
  styleTitle: PropTypes.instanceOf(Object),
};

export default WhiteModalComponent;
