import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import useStyles from './styles';

export default function Visibility(props) {
  const classes = useStyles();
  const { isVisible, hasInsideView, handleClick, conversationId } = props;
  const disabled = !hasInsideView;

  const icon = isVisible ? (
    <VisibilityIcon className={classes.iconVisible} disabled={disabled} />
  ) : (
    <VisibilityOffIcon className={classes.icon} disabled={disabled} />
  );
  return (
    <IconButton
      className={classes.iconButton}
      aria-label="Visibilidad"
      disabled={disabled}
      onClick={() => handleClick(conversationId, isVisible)}>
      {icon}
    </IconButton>
  );
}

Visibility.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  hasInsideView: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  conversationId: PropTypes.number.isRequired,
};
