import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import WhiteModalComponent from 'components/whiteModalComponent/index';
import ListItemsAccount from 'components/selectAccount/index';
import { actions } from 'services/actions/searchAccount';

function Accounts(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { acts, open, handleCloseAccounts } = props;
  const [valueRadio, setValueRadio] = useState('');
  const [accounts, setAccounts] = useState([]);
  useEffect(() => {
    setAccounts(acts);
  }, [acts]);

  const [selectedValue, setSelectedValue] = useState('');

  const handleClose = () => {
    handleCloseAccounts(false);
  };

  const handleSubmit = () => {
    setSelectedValue(valueRadio);
    const accountSelected = accounts.find((x) => x.id === valueRadio);
    const { hasAccount } = actions;
    dispatch(hasAccount(accountSelected));
    localStorage.setItem('act', accountSelected.id);
    history.push('/');
    handleClose(false);
  };

  const handleToggle = (event) => () => {
    setValueRadio(event);
  };

  return (
    <div>
      {accounts && accounts.length > 1
        && (
          <WhiteModalComponent
            selectedValue={selectedValue}
            open={open}
            onClose={handleClose}
            valueRadio={valueRadio}
            variantTypography="subtitle1"
            textTypography="Selecciona una cuenta"
            textButtonLeft="REGRESAR"
            textButtonRight="INGRESAR CON CUENTA SELECCIONADA"
            handleSubmitRight={handleSubmit}
            handleSubmitLeft={handleClose}>
            <ListItemsAccount
              data={accounts}
              valueRadio={valueRadio}
              handleToggle={handleToggle} />
          </WhiteModalComponent>
        )}
      { selectedValue !== '' && <Redirect to="/dashboard/panel" /> }
    </div>
  );
}

Accounts.propTypes = {
  acts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  open: PropTypes.bool.isRequired,
  handleCloseAccounts: PropTypes.func.isRequired,
};

export default Accounts;
