import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { TransparentDialog } from 'components/transparentDialog/index';
import Grid from '@material-ui/core/Grid';
import PersonAdd from '@material-ui/icons/PersonAdd';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import RenderPasswordField from 'components/renderPasswordField';
import RenderTextField from 'components/renderTextField';
import { email, alphaNumeric } from 'utils/validations';


const CustomFieldPassword = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#CBC9C6',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#CBC9C6',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#CBC9C6',
    },
  },
})(RenderPasswordField);

const styles = (theme) => ({
  fieldText: {
    '& label.Mui-focused': {
      color: '#CBC9C6',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#CBC9C6',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#CBC9C6',
    },
    marginRight: theme.spacing(1),
    width: '100%',
  },
});

const validate = (values) => {
  const errors = {};
  const minLengthPassword = 8;
  const fields = {
    username: 'Nombre de Usuario',
    email: 'Correo Electrónico',
    password: 'Contraseña',
    confirm: 'Confirmar Contraseña',
  };
  Object.keys(fields).forEach((key) => {
    if (!values[key]) errors[key] = `El campo ${fields[key]} es requerido`;
  });

  if (values.password && values.password.length < minLengthPassword) {
    errors.password = 'La contraseña debe tener al menos 6 caracteres';
  }

  if (values.password !== values.confirm) {
    errors.confirm = 'Las contraseñas no coinciden';
  }

  if (values.email) errors.email = email(values.email);
  if (values.username) {
    errors.username = alphaNumeric(values.username, fields.username);
  }
  return errors;
};

export function CreateUserForm(props) {
  const {
    handleSubmit,
    pristine,
    invalid,
    submitting,
    classes,
    onSubmit,
    open,
    handleClose,
    helperTexts,
  } = props;

  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');

  const colorText = { color: '#CBC9C6' };

  return (
    <div>
      <TransparentDialog
        handleClose={handleClose}
        open={open}
        aria-labelledby="dialog-register-user"
        buttonProps={{
          fullWidth: true,
          type: 'submit',
          disabled: pristine || submitting || invalid,
          onClick: handleSubmit(onSubmit),
        }}
        disableBackdropClick
        fullWidth
        maxWidth="xs"
        actionText="REGISTRAR NUEVO USUARIO">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            direction="column"
            justify="space-between"
            alignItems="stretch"
            spacing={2}>
            <Grid
              item
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={1}>
              <Grid item xs={2} sm={1}>
                <PersonAdd style={{ color: 'white' }} />
              </Grid>
              <Grid item xs={6} sm={8}>
                <Typography variant="h6" style={{ color: 'white' }}>
                  Registrar un usuario nuevo
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Field
                name="username"
                id="current-user"
                placeholder="| Ingresa nombre para tu usuario"
                component={RenderTextField}
                inputProps={{ value: userName }}
                setTextContent={setUserName}
                required
                helperText={helperTexts.username}
                type="text"
                fullWidth={false}
                style={colorText}
                className={classes.fieldText} />
            </Grid>
            <Grid item>
              <Field
                name="email"
                id="current-email"
                placeholder="| Ingresa tu correo electrónico"
                component={RenderTextField}
                inputProps={{ value: userEmail }}
                setTextContent={setUserEmail}
                type="text"
                fullWidth
                helperText={helperTexts.email}
                className={classes.fieldText}
                style={colorText}
                required />
            </Grid>
            <Grid item>
              <Field
                id="password"
                name="password"
                placeholder="| Ingresa tu contraseña"
                autocomplete="new-password"
                component={CustomFieldPassword}
                type="password"
                fullWidth
                helperText={helperTexts.password}
                style={colorText}
                required />
            </Grid>
            <Grid item>
              <Field
                name="confirm"
                id="confirm"
                placeholder="| Confirma tu contraseña"
                component={CustomFieldPassword}
                autocomplete="new-password"
                type="password"
                fullWidth
                required
                style={colorText} />
            </Grid>
          </Grid>
        </form>
      </TransparentDialog>
    </div>
  );
}

CreateUserForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  classes: PropTypes.shape({ fieldText: PropTypes.string }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  helperTexts: PropTypes.shape({
    username: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
    confirm: PropTypes.string,
  }).isRequired,
};

export const CreateUser = withStyles(styles)(reduxForm({
  form: 'CreateUser',
  validate,
})(CreateUserForm));

export default CreateUser;
