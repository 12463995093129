import React from 'react';
import PropTypes from 'prop-types';
import stringToColor from 'string-to-color';
import {
  Avatar,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  IconButton,
  ListItemIcon,
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';

import IconMD from 'components/iconMD';
import IconInbox from 'components/iconInbox';
import useWindowSize from 'hooks/useWindowSize';
import widthMobile from 'constants/responsive';
import useStyles from 'styles/list';

const ListTask = (props) => {
  const classes = useStyles();
  const { tasks, checkbox, clear } = props;
  const [checked, setChecked] = React.useState([1]);
  const OnlyLetter = (name) => name.charAt(0).toUpperCase();

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const size = useWindowSize();

  return (
    <List className={classes.root}>
      {tasks && tasks !== undefined ? tasks.map((item) => {
        const labelId = `checkbox-list-secondary-label-${item.id}`;

        return (
          <div key={labelId}>
            <ListItem className={classes.iconsStyles} button>
              <IconButton aria-label="icons-tools" className={classes.avatarStyle}>
                <Avatar
                  className={classes.small}
                  style={{ background: `${stringToColor(item.responsible.nickname)}` }}>
                  {OnlyLetter(item.responsible.nickname)}
                </Avatar>
              </IconButton>
              <IconMD priority={item.priority} priorityName={item.priorityName} />
              <IconInbox status={item.status} statusName={item.statusName} />
              {
              size.width > widthMobile ? (
                <>
                  <ListItemText className={`${classes.textItem} ${classes.textAlign}`} id={labelId} primary={item.text} />
                  <ListItemIcon className={classes.checkStyle} key={`task-icon-${item.id}`}>
                    { checkbox
                      ? (
                        <Checkbox
                          className={classes.checkbox}
                          color="primary"
                          edge="start"
                          onChange={handleToggle(labelId)}
                          inputProps={{ 'aria-labelledby': labelId }} />
                      )
                      : ''}
                    { clear ? <Clear /> : '' }
                  </ListItemIcon>
                </>
              ) : ''
            }
            </ListItem>
            { size.width <= widthMobile ? (
              <ListItem className={classes.iconsStyles}>
                <ListItemText className={`${classes.textItem} ${classes.textAlign}`} id={labelId} primary={item.text} />
                <ListItemIcon className={classes.checkStyle} key={`task-icon-${item.id}`}>
                  { checkbox
                    ? (
                      <Checkbox
                        className={classes.checkbox}
                        color="primary"
                        edge="start"
                        onChange={handleToggle(labelId)}
                        inputProps={{ 'aria-labelledby': labelId }} />
                    )
                    : ''}
                  { clear ? <Clear /> : '' }
                </ListItemIcon>
              </ListItem>
            ) : ''}
          </div>
        );
      }) : '' }
    </List>
  );
};

ListTask.defaultProps = {
  checkbox: true,
  clear: false,
  tasks: [],
};

ListTask.propTypes = {
  tasks: PropTypes.instanceOf(Array),
  checkbox: PropTypes.bool,
  clear: PropTypes.bool,
};

export default ListTask;
