import { makeStyles } from '@material-ui/core';
import colors from 'constants/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: '25px',
    '@media (max-width: 400px)': {
      marginTop: '16px',
    },
  },
  grid: {
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 400px)': {
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
  },
  wrap: {
    flexWrap: 'wrap',
    marginTop: '12px',
    marginBottom: '12px',
  },
  gridPaddingButton: {
    marginTop: '12px',
  },
  userResponsibles: {
    paddingRight: '8px',
    borderRight: '1px solid #ccc',
  },
  estimatedColor: {
    fontSize: '14px',
    color: '#A1C45A',
    marginLeft: '4px',
    paddingBottom: '4px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      paddingBottom: '8px',
    },
  },
  registeredColor: {
    fontSize: '12pt',
    marginLeft: '8px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      marginLeft: '8px',
    },
  },
  concept: {
    fontSize: '15pt',
    fontWeight: 'bold',
    lineHeight: '25px',
    '& > p': {
      margin: '0',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '13pt',
      marginTop: '12px',
    },
  },
  conceptUpdate: {
    color: colors.primary,
    borderTopWidth: '0px',
    borderRightWidth: '0px',
    borderBottomWidth: '1px',
    borderLeftWidth: '0px',
    fontSize: '15pt',
    fontFamily: 'Muli',
    fontWeight: 'bold',
    borderBottomColor: colors.primary,
    [theme.breakpoints.down('sm')]: {
      marginTop: '12px',
    },
    width: '100%',
    '&:focus': {
      outlineColor: '-webkit-focus-ring-color',
      outlineStyle: 'auto',
      outlineWidth: '0px',
    },
  },
  styleCharactersGrid: {
    [theme.breakpoints.down('sm')]: {
      width: '390px',
    },
    '@media (max-width: 400px)': {
      width: '335px',
    },
  },
  fontStyle: {
    fontSize: '11pt',
    display: 'block',
    fontWeight: 'lighter',
  },
  spacing: {
    color: colors.secondary,
    margin: '0',
  },
  circle: {
    width: '26px',
    height: '26px',
  },
  timeZone: {
    '@media (max-width: 400px)': {
      marginTop: '12px',
    },
  },
  field: {
    height: '30px',
    width: '700px',
    color: '#9A9895',
    borderTopWidth: '0px',
    borderRightWidth: '0px',
    borderBottomWidth: '1px',
    borderLeftWidth: '0px',
    fontSize: '15px',
    fontFamily: 'Muli',
    fontWeight: '400',
    borderBottomColor: colors.primary,
    [theme.breakpoints.down('sm')]: {
      width: '320px',
    },
    [theme.breakpoints.up('sm')]: {
      width: '600px',
    },
    '&:focus': {
      outlineColor: '-webkit-focus-ring-color',
      outlineStyle: 'auto',
      outlineWidth: '0px',
    },
  },
}));

export default useStyles;
