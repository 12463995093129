import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { reset } from 'redux-form';
import { ResetPassword } from 'components/resetPassword/index';
import RecoverPasswordContainer from 'containers/recoverPassword/';
import { resetPasswordUser } from 'services/actions/resetPassword/index';
import { TransparentDialog } from 'components/transparentDialog/index';
import useOpenClose from 'hooks/useOpenClose';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';


export default function ResetPasswordContainer(props) {
  const {
    open,
    handleClose,
    token,
  } = props;
  const dispatch = useDispatch();
  const [helperTexts, setHelperTexts] = useState({
    password: '',
    confirm: '',
  });
  const [messageAfterSubmit, setMessageAfterSubmit] = useState('');
  const [titleAfterSubmit, setTitleAfterSubmit] = useState('');
  const [buttonTextAfterSubmit, setButtonTextAfterSubmit] = useState('');
  const {
    open: openDialog,
    handleClose: handleCloseDialog,
    handleOpen: handleOpenDialog,
  } = useOpenClose();

  const {
    open: openRecoverPassword,
    handleOpen: handleOpenRecoverPassword,
    handleClose: handleCloseRecoverPassword,
  } = useOpenClose();

  const showRecovery = () => {
    if (buttonTextAfterSubmit === 'OK') {
      handleCloseDialog();
    } else {
      handleCloseDialog();
      handleOpenRecoverPassword();
    }
  };

  const handleSubmit = async (dataUser) => {
    const response = await dispatch(resetPasswordUser({ ...dataUser, token }));
    const { action: { payload: { data, status } } } = response;

    if (status >= 500) {
      setMessageAfterSubmit('Hubo un error, por favor intenta más tarde.');
      dispatch(reset('ResetPassword'));
      handleOpenDialog();
      handleClose();
    } else if (status === 400) {
      const errors = {};
      data.forEach((item) => {
        errors[item.param] = item.msg;
      });
      setHelperTexts(errors);
    } else if (status === 401) {
      setMessageAfterSubmit(data.message);
      setTitleAfterSubmit('Error al recuperar contraseña');
      setButtonTextAfterSubmit('Volver a solicitar');
      dispatch(reset('RecoverPassword'));
      handleOpenDialog();
      handleClose();
    } else {
      setHelperTexts({});
      dispatch(reset('RecoverPassword'));
      setMessageAfterSubmit(
        'Tu contraseña se ha modificado exitosamente.',
      );
      setTitleAfterSubmit('Recuperar contraseña');
      setButtonTextAfterSubmit('OK');
      handleOpenDialog();
      handleClose();
    }
  };

  return (
    <div>
      <ResetPassword
        onSubmit={handleSubmit}
        open={open}
        handleClose={handleClose}
        helperTexts={helperTexts}
        token={token}
        initialValues={{
          email: '',
          confirm: '',
        }} />
      <RecoverPasswordContainer
        open={openRecoverPassword}
        handleOpen={handleOpenRecoverPassword && handleClose}
        handleClose={handleCloseRecoverPassword} />
      <TransparentDialog
        handleClose={handleCloseDialog}
        open={openDialog}
        aria-labelledby="dialog-reset-messages-password"
        buttonProps={{
          fullWidth: false,
          type: 'button',
          disabled: false,
          onClick: showRecovery,
        }}
        disableBackdropClick
        fullWidth
        maxWidth="xs"
        actionText={buttonTextAfterSubmit}>
        <Grid
          container>
          <Grid
            item
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={1}>
            <Grid item xs={12} sm={9}>
              <center>
                <Typography variant="h6" style={{ color: 'white' }}>
                  {titleAfterSubmit}
                </Typography>
              </center>
            </Grid>
            <Grid item>
              <center>
                <Typography color="primary">
                  {messageAfterSubmit}
                </Typography>
              </center>
            </Grid>
          </Grid>
        </Grid>
      </TransparentDialog>

    </div>
  );
}

ResetPasswordContainer.propTypes = {
  token: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
