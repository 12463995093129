/* ---------------------------------------------------------
 * Actions companies
 * ---------------------------------------------------------- */
import {
  RESET_COMPANIES,
  RESET_COMPANY,
  GET_COMPANIES,
  GET_COMPANY,
  CREATE_COMPANY,
  UPDATE_COMPANY,
  DELETE_COMPANY,
} from 'constants/companies';

import {
  getCompaniesAPI,
  getCompanyAPI,
  updateCompanyAPI,
  deleteCompanyAPI,
  createCompanyAPI,
} from 'services/apis/companies';

/**
 * Action creator to get the companies
 * @param {string} accountName - User's account name
 *
 */
export const getCompanies = (
  accountName,
  offset = 0,
  limit = 10,
  orderBy = '',
  filter = {},
) => ({
  type: GET_COMPANIES,
  payload: getCompaniesAPI(accountName, offset, limit, orderBy, filter),
});

/**
 * Action creator to get a company
 * @param {string} accountName - User's account name
 * @param {Number} companyId - Identifier Company
 *
 */
export const getCompany = (accountName, companyId) => ({
  type: GET_COMPANY,
  payload: getCompanyAPI(accountName, companyId),
});

/**
 * action creator to update a company
 *
 * @typedef {Object} dataCompany
 * @property {string} name - Company's name
 * @property {string} tax_id_number - TaxID for the company
 * @property {boolean} is_invoice_issuer  - Flag to identify if the company is invoice issuer
 * @property {boolean} is_default - Flag to identify if the company is default for create resource
 *
 * @param {string} accountname - user's account name
 * @param {number} companyId - identifier company
 * @param {dataCompany} data - data to update the company
 *
 */
export const updateCompany = (accountName, companyId, data) => ({
  type: UPDATE_COMPANY,
  payload: updateCompanyAPI(accountName, companyId, data),
});

/**
 * Action creator to create a company
 *
 * @typedef {Object} dataCompany
 * @property {string} name - Company's name
 * @property {string} tax_id_number - TaxID for the company
 * @property {boolean} is_invoice_issuer  - Flag to identify if the company is invoice issuer
 * @property {boolean} is_default - Flag to identify if the company is default for create resource
 *
 * @param {string} accountName - User's account name
 * @param {dataCompany} data - data to create the Company
 *
 */
export const createCompany = (
  accountName,
  data,
) => ({
  type: CREATE_COMPANY,
  payload: createCompanyAPI(accountName, data),
});

/**
 * Action creator to delete company
 * @param {string} accountName - User's account name
 * @param {Number} companyId - Identifier Company
 *
 */
export const deleteCompany = (
  accountName,
  companyId,
) => ({
  type: DELETE_COMPANY,
  payload: deleteCompanyAPI(accountName, companyId),
});

/**
 *
 * Action creator to reset companies
 *
 */
export const resetCompanies = () => ({
  type: RESET_COMPANIES,
  payload: null,
});

/**
 *
 * Action creator to reset company
 *
 */
export const resetCompany = () => ({
  type: RESET_COMPANY,
  payload: null,
});

export default getCompanies;
