// Own actions/constants/components
import { LOCALE_ES, LOCALE_EN } from 'constants/strings';

//-----------------------------------------------------------
// Actions
//-----------------------------------------------------------

const setLanguage = (language) => ({
  type: 'CHANGE_LANGUAGE',
  selectedLanguage: language,
  constants: language === 'ES' ? LOCALE_ES : LOCALE_EN,
});

export const actions = {
  setLanguage,
};
