/*
* Reduceer has account
*/

const initialState = {
  accounts: '',
};

const currentHasAccount = (state = initialState, action) => {
  switch (action.type) {
    case 'RESET_ACCOUNT':
      return { ...initialState };
    case 'NO_ACCOUNT':
      return {
        ...state,
        accounts: action.accounts,
      };
    case 'HAS_ACCOUNT':
      return {
        ...state,
        accounts: action.accounts,
      };
    case 'HAS_MULTIPLE_ACCOUNTS':
      return {
        ...state,
        accounts: action.accounts,
      };
    default:
      return state;
  }
};

export default currentHasAccount;
