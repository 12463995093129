// -----------------------------------------------------------------------------
// This file contains all application-wide strings for both ES and EN
// -----------------------------------------------------------------------------

export const LOCALE_ES = {
  pages: {
    notFound: {
      title: 'Ops! No se encontró la página',
      description: 'Desafortunadamente la página que estás buscando se ha movido o eliminado',
      button: 'Ir a Inicio',
    },
  },
};

export const LOCALE_EN = {
  pages: {
    notFound: {
      title: 'Ops! Page not found',
      description: 'Unfortunately the page you were looking for was moved or deleted',
      button: 'Go to Home',
    },
  },
};

export const loginError = [
  {
    id: 1,
    text: '- En caso de haber olvidado tu contraseña selecciona la opción de recuperar.',
  },
  {
    id: 2,
    text: '- En caso de no tener un usuario, selecciona la opción de crear uno.',
  },
];
