import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Tabs, Tab, Typography, Box } from '@material-ui/core';

export function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div
      style={{ color: '#FFF' }}
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}>
      {value === index && (
        <Box component="div" p={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.node.isRequired,
  value: PropTypes.node.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '450px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '450px',
    },
  },
  tabBackground: {
    backgroundColor: 'transparent',
    width: '450px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '450px',
    },
  },
  tabLabel: {
    color: '#FFF',
    fontSize: '18px',
    fontWeight: '600',
    textTransform: 'capitalize',
    padding: '4px 0px',
    minWidth: '150px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '33%',
    },
  },
}));

export default function CenteredTabs(props) {
  const { notifications } = props;

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        className={classes.tabBackground}
        value={value}
        onChange={handleChange}
        centered
        indicatorColor="primary"
        textColor="inherit"
        aria-label="scrollable force tabs example">
        <Tab className={classes.tabLabel} label="Todas" id="scrollable-force-tab-1" aria-controls="scrollable-force-tabpanel-1" />
        {
          notifications.map((tabLabel, index) => (
            <Tab
              key={`tab-label-${tabLabel.title.replace(' ', '-')}}`}
              className={classes.tabLabel}
              label={tabLabel.title}
              id={`scrollable-force-tab-${index + 1}`}
              aria-controls={`scrollable-force-tab-${index + 1}`} />
          ))
        }
      </Tabs>

      <TabPanel value={value} index={0}>
        <ul>
          {
            notifications.map((tabLabel) => (
              tabLabel.items.map((item) => (<li key={item.id}>{item.content}</li>))
            ))
          }
        </ul>
      </TabPanel>
      {
        notifications.map((tabLabel, index) => (
          <TabPanel key={`tab-pane-${tabLabel.title.replace(' ', '-')}`} value={value} index={index + 1}>
            { tabLabel.items.map((item) => item.content) }
          </TabPanel>
        ))
      }
    </div>
  );
}

CenteredTabs.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      content: PropTypes.string.isRequired,
    })).isRequired,
  })).isRequired,
};
