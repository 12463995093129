/* ---------------------------------------------------------
 * Actions companies
 * ---------------------------------------------------------- */
import { GET_ROLES, RESET_ROLES } from 'constants/roles';

import { getRolesAPI } from 'services/apis/roles';

/**
   * Action creator to get the companies
   * @param {string} accountName - User's account name
   *
   */
export const getRoles = (
  accountName,
  offset = 0,
  limit = 10,
  orderBy = '',
  filter = {},
) => ({
  type: GET_ROLES,
  payload: getRolesAPI(accountName, offset, limit, orderBy, filter),
});

export const resetRoles = () => ({
  type: RESET_ROLES,
  payload: null,
});
